import React, { createContext, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab/";

export const SnackbarContext = createContext();
SnackbarContext.displayName = "SnackbarContext";

const SnackbarProvider = ({ children }) => {
    const [message, setMessage] = useState("");
    const [type, setType] = useState("");
    const [open, setOpen] = useState(false);
    const [hideDuration, setHideDuration] = useState(3000);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleOpen = (type) => (message) => {
        setMessage(message);
        setType(type);
        setOpen(true);
        setHideDuration(type === "error" ? 8000 : 3000);
    };

    return (
        <SnackbarContext.Provider value={{ success: handleOpen("success"), error: handleOpen("error") }}>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={hideDuration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={type}>
                    {message}
                </Alert>
            </Snackbar>
            {children}
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;
