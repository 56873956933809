import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, makeStyles, TextField } from "@material-ui/core";
import React, { useContext, useState } from "react";
import WebsiteContext from "../../../../../utils/Website";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import StyledInput from "../../../../StyledInput/StyledInput";
import StyledButton from "../../../../StyledButton/StyledButton";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        width: "160px",
        marginRight: 16,
        height: "33px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    buttonWrapper: {
        background: "white",
        padding: "18px 0px 18px 0",
    },
    header: {
        background: "#FAFAFA",
        border: "1px solid #ECECEC",
    },
    row: {
        background: "#FFFFFF",
        border: "1px solid #ECECEC",
    },
    cell: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        left: "unset",
    },
    headerCell: {
        color: "#777777",
    },
    tableContainer: {
        height: "100%",
    },
    inputFile: {
        opacity: 0,
        width: 1,
    },
    openFile: {
        color: "#00A7D1",
        cursor: "pointer",
    },
    uploadFile: {
        backgroundColor: "white",
        marginRight: "10px",
    },
    filenameEditModeWrapper: {
        marginLeft: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
        height: "15p",
        lineHeight: "15px",
        textOverflow: "ellipsis",
    },
}));

const CoolingLayoutTable = (props) => {
    const classes = useStyles();
    const { languageData, role } = useContext(WebsiteContext);

    const [showNewRow, setShowNewRow] = useState(false);
    const [newItem, setNewItem] = useState({});
    const [editItem, setEditItem] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(null);

    const onSave = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${props.coolingLayoutId}/table`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: newItem,
        };
        Axios(options).then((_) => {
            setNewItem({});
            setShowNewRow(false);
            props.getData();
        });
    };

    const onEdit = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${props.coolingLayoutId}/table/${editItem.id}`,
            method: "PUT",
            data: editItem,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            setEditItem(null);
            props.getData();
        });
    };

    const changeInput = (addMode, field, value) => {
        if (addMode === true) {
            const tmpData = { ...newItem };
            tmpData[field] = value;
            setNewItem(tmpData);
        } else if (addMode === false) {
            const tmpData = JSON.parse(JSON.stringify(editItem));
            tmpData[field] = value;
            setEditItem(tmpData);
        }
    };

    const handleDeleteItem = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${props.coolingLayoutId}/table/${showDeleteDialog}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            setShowDeleteDialog(null);
            props.getData();
        });
    };

    return (
        <div className={classes.contentWrapper}>
            {(role === 0 || role === 2) && props.readOnly === false && (
                <div className={classes.buttonWrapper}>
                    <StyledButton onClick={() => setShowNewRow(!showNewRow)} className={classes.button} color="primary" variant="contained">
                        {showNewRow ? languageData?.Cancel.toLocaleUpperCase() : languageData?.AddNewItem.toLocaleUpperCase()}
                    </StyledButton>
                    {showNewRow && (
                        <StyledButton form="newItem" type="submit" className={classes.button} color="secondary" variant="contained">
                            {languageData?.Save.toLocaleUpperCase()}
                        </StyledButton>
                    )}
                </div>
            )}
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.header}>
                            {props.readOnly === true && <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.ItemName}</TableCell>}
                            <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Item}</TableCell>
                            <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Name}</TableCell>
                            <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.WaterOrOilTemp}</TableCell>
                            <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.MoldTemp}</TableCell>
                            <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.FlowLMin}</TableCell>
                            <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.LengthOrDiameter}</TableCell>
                            <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.CoolingUnit}</TableCell>
                            {(role === 0 || role === 2) && props.readOnly === false && (
                                <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Actions}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showNewRow && (
                            <TableRow className={classes.row} key="new">
                                {/*<TableCell component="th" scope="row">*/}
                                {/*    */}
                                {/*    /!*<IconButton type="submit" form="newChange">*!/*/}
                                {/*    /!*    <SaveIcon fontSize="small" />*!/*/}
                                {/*    /!*</IconButton>*!/*/}
                                {/*    /!*<IconButton*!/*/}
                                {/*    /!*    onClick={() => {*!/*/}
                                {/*    /!*        setShowNewRow(false);*!/*/}
                                {/*    /!*    }}*!/*/}
                                {/*    /!*>*!/*/}
                                {/*    /!*    <CancelIcon fontSize="small" />*!/*/}
                                {/*    /!*</IconButton>*!/*/}
                                {/*</TableCell>*/}
                                <TableCell>
                                    <form
                                        id="newItem"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            onSave();
                                        }}
                                    />
                                    <StyledInput onChange={(e) => changeInput(true, "item", e.target.value)} type="text" required={true} form="newItem" />
                                </TableCell>
                                <TableCell>
                                    <StyledInput onChange={(e) => changeInput(true, "name", e.target.value)} type="text" required={true} form="newItem" />
                                </TableCell>
                                <TableCell>
                                    <StyledInput
                                        onChange={(e) => changeInput(true, "water_or_oil_temp", e.target.value)}
                                        type="text"
                                        required={true}
                                        form="newItem"
                                    />
                                </TableCell>
                                <TableCell>
                                    <StyledInput onChange={(e) => changeInput(true, "mold_temp", e.target.value)} type="text" required={true} form="newItem" />
                                </TableCell>
                                <TableCell>
                                    <StyledInput onChange={(e) => changeInput(true, "flow_i_min", e.target.value)} type="text" required={true} form="newItem" />
                                </TableCell>
                                <TableCell>
                                    <StyledInput
                                        onChange={(e) => changeInput(true, "length_or_diameter", e.target.value)}
                                        type="text"
                                        required={true}
                                        form="newItem"
                                    />
                                </TableCell>
                                <TableCell>
                                    <StyledInput
                                        onChange={(e) => changeInput(true, "cooling_unit", e.target.value)}
                                        type="text"
                                        required={true}
                                        form="newItem"
                                    />
                                </TableCell>
                                {(role === 0 || role === 2) && <TableCell></TableCell>}
                            </TableRow>
                        )}
                        {props.data?.map((element) => (
                            <TableRow className={classes.row} key={element.id}>
                                {props.readOnly === true && <TableCell className={classes.cell}>{element.itemName}</TableCell>}
                                {editItem && editItem.id === element.id ? (
                                    <TableCell>
                                        <StyledInput
                                            defaultValue={editItem.item}
                                            onChange={(e) => changeInput(false, "item", e.target.value)}
                                            type="text"
                                            required={true}
                                            form={`editItem-${element.id}`}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.cell}>{element.item}</TableCell>
                                )}
                                {editItem && editItem.id === element.id ? (
                                    <TableCell>
                                        <StyledInput
                                            defaultValue={editItem.name}
                                            onChange={(e) => changeInput(false, "name", e.target.value)}
                                            type="text"
                                            required={true}
                                            form={`editItem-${element.id}`}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.cell}>{element.name}</TableCell>
                                )}
                                {editItem && editItem.id === element.id ? (
                                    <TableCell>
                                        <StyledInput
                                            defaultValue={editItem.water_or_oil_temp}
                                            onChange={(e) => changeInput(false, "water_or_oil_temp", e.target.value)}
                                            type="text"
                                            required={true}
                                            form={`editItem-${element.id}`}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.cell}>{element.water_or_oil_temp}</TableCell>
                                )}
                                {editItem && editItem.id === element.id ? (
                                    <TableCell>
                                        <StyledInput
                                            defaultValue={editItem.mold_temp}
                                            onChange={(e) => changeInput(false, "mold_temp", e.target.value)}
                                            type="text"
                                            required={true}
                                            form={`editItem-${element.id}`}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.cell}>{element.mold_temp}</TableCell>
                                )}
                                {editItem && editItem.id === element.id ? (
                                    <TableCell>
                                        <StyledInput
                                            defaultValue={editItem.flow_i_min}
                                            onChange={(e) => changeInput(false, "flow_i_min", e.target.value)}
                                            type="text"
                                            required={true}
                                            form={`editItem-${element.id}`}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.cell}>{element.flow_i_min}</TableCell>
                                )}
                                {editItem && editItem.id === element.id ? (
                                    <TableCell>
                                        <StyledInput
                                            defaultValue={editItem.length_or_diameter}
                                            onChange={(e) => changeInput(false, "length_or_diameter", e.target.value)}
                                            type="text"
                                            required={true}
                                            form={`editItem-${element.id}`}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.cell}>{element.length_or_diameter}</TableCell>
                                )}
                                {editItem && editItem.id === element.id ? (
                                    <TableCell>
                                        <StyledInput
                                            defaultValue={editItem.cooling_unit}
                                            onChange={(e) => changeInput(false, "cooling_unit", e.target.value)}
                                            type="text"
                                            required={true}
                                            form={`editItem-${element.id}`}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell className={classes.cell}>{element.cooling_unit}</TableCell>
                                )}
                                {(role === 0 || role === 2) && props.readOnly === false && (
                                    <TableCell className={classes.cell}>
                                        {element.id === editItem?.id ? (
                                            <React.Fragment>
                                                <form
                                                    id={`editItem-${element.id}`}
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        onEdit();
                                                    }}
                                                />
                                                <IconButton size="small" type="submit" form={`editItem-${element.id}`}>
                                                    <SaveIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton size="small" onClick={() => setEditItem(null)}>
                                                    <CancelIcon fontSize="small" />
                                                </IconButton>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <IconButton size="small" onClick={() => setEditItem({ ...element })}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton size="small" onClick={() => setShowDeleteDialog(element.id)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </React.Fragment>
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={showDeleteDialog !== null}
                onClose={() => {
                    setShowDeleteDialog(null);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{languageData?.DeleteCoolingLayoutTableRowMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setShowDeleteDialog(null);
                        }}
                        color="primary"
                        autoFocus
                    >
                        {languageData?.No}
                    </Button>
                    <Button onClick={handleDeleteItem} color="primary" autoFocus>
                        {languageData?.Yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CoolingLayoutTable;
