import React, { useContext } from "react";
import { Dialog, DialogContent, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import WebsiteContext from "../../utils/Website";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: "50vw",
        maxWidth: "unset",
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(1000)]: {
            width: "80vw",
        },
    },
    dialog: {
        maxWidth: "unset",
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    dialogContentWrapper: {
        "&:first-child": {
            padding: 0,
        },
        padding: 0,
    },
    title: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "16px",
        textAlign: "center",
        marginTop: "24px",
        color: "#1F1F1F",
    },
    tableContainer: {
        maxHeight: "80vh",
        overflow: "auto",
        marginTop: "16px",
    },
    clickable: {
        textDecorationLine: "underline",
        cursor: "pointer",
        color: "#00A7D1",
    },
}));

const HistoryFiles = ({ open, onClose, files, onClick }) => {
    const classes = useStyles();
    const { languageData } = useContext(WebsiteContext);

    return (
        <Dialog open={open} onClose={onClose} classes={{ paperWidthSm: classes.dialog }}>
            <DialogContent className={classes.dialogContentWrapper}>
                <div className={classes.modalWrapper}>
                    <IconButton className={classes.icon} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <div className={classes.title}>{languageData?.HistoryFiles}</div>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ left: "unset" }}>{languageData?.Filename}</TableCell>
                                    <TableCell style={{ left: "unset" }}>{languageData?.UploadDate}</TableCell>
                                    <TableCell style={{ left: "unset" }}>{languageData?.CreatorName}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files?.map((file) => (
                                    <TableRow key={file?.id}>
                                        <TableCell className={classes.clickable} onClick={() => onClick(file.id)}>
                                            {file.fileName}
                                        </TableCell>
                                        <TableCell>{file.uploadDate}</TableCell>
                                        <TableCell>{file.userName}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default HistoryFiles;
