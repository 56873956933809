import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Grid, makeStyles, MenuItem, Select } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
        marginBottom: 4,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        marginLeft: 5,
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    checkboxWrapper: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
        margin: 5,
    },
    checked: {},
    optionsWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    parentCheckbox: {
        width: 100,
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    selectWrapper: {
        width: "210px",
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
}));

const Demolding = (props) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);
    const [demoldingData, setDemoldingData] = useState({
        slider: {
            checked: false,
            options: [
                {
                    id: 1,
                    key: "coreSide",
                    checked: false,
                    options: [
                        {
                            id: 1,
                            checked: false,
                            key: "MechanicalFunction",
                        },
                        {
                            id: 2,
                            checked: false,
                            key: "HydraulicFunction",
                        },
                    ],
                },
                {
                    id: 2,
                    key: "cavitySide",
                    checked: false,
                    options: [
                        {
                            id: 1,
                            checked: false,
                            key: "MechanicalFunction",
                        },
                        {
                            id: 2,
                            checked: false,
                            key: "HydraulicFunction",
                        },
                    ],
                },
            ],
        },
        lifter: {
            checked: false,
            options: [
                {
                    id: 1,
                    key: "coreSide",
                    checked: false,
                    options: [
                        {
                            id: 1,
                            checked: false,
                            key: "MechanicalFunction",
                        },
                        {
                            id: 2,
                            checked: false,
                            key: "HydraulicFunction",
                        },
                    ],
                },
                {
                    id: 2,
                    key: "cavitySide",
                    checked: false,
                    options: [
                        {
                            id: 1,
                            checked: false,
                            key: "MechanicalFunction",
                        },
                        {
                            id: 2,
                            checked: false,
                            key: "HydraulicFunction",
                        },
                    ],
                },
            ],
        },
        ejectorDesign: [
            {
                id: 1,
                key: "EjectorPin",
                checked: false,
            },
            {
                id: 2,
                key: "BladeEjector",
                checked: false,
            },
            {
                id: 3,
                key: "EjectorBlock",
                checked: false,
            },
            {
                id: 4,
                key: "EjectorSleeve",
                checked: false,
            },
            {
                id: 5,
                key: "StripperSegment",
                checked: false,
            },
            {
                id: 6,
                key: "stripperPlate",
                checked: false,
            },
            {
                id: 7,
                key: "ForceEjection",
                checked: false,
            },
            {
                id: 8,
                key: "CollapsableCore",
                checked: false,
            },
        ],
        ejectorFunction: [
            {
                id: 1,
                key: "coreSide",
                checked: false,
                options: [
                    {
                        id: 1,
                        checked: false,
                        key: "WithMachineEjector",
                    },
                    {
                        id: 2,
                        checked: false,
                        key: "WithHydraulicCylinder",
                    },
                ],
            },
            {
                id: 2,
                key: "cavitySide",
                checked: false,
                options: [
                    {
                        id: 1,
                        checked: false,
                        key: "WithMachineEjector",
                    },
                    {
                        id: 2,
                        checked: false,
                        key: "WithHydraulicCylinder",
                    },
                ],
            },
        ],
        unscrewing: [
            {
                id: 1,
                key: "Mechanical",
            },
            {
                id: 2,
                key: "WithHydraulicCylinderAndGearRack",
            },
            {
                id: 3,
                key: "WithHydraulicMotor",
            },
            {
                id: 4,
                key: "WithElServoMotor",
            },
        ],
    });

    useEffect(() => {
        if (props.demoldingData) {
            const data = props.demoldingData;
            const dataToView = { ...demoldingData };
            if (data.slider.length > 0) {
                dataToView.slider.checked = true;
                data.slider.forEach((slider, index) => {
                    if (slider.options.length > 0) {
                        const op = dataToView.slider.options.find((el) => el.id === slider.id);
                        op.checked = true;
                        slider.options.forEach((option) => {
                            op.options.find((el) => el.id === Number(option)).checked = true;
                        });
                    }
                });
            }

            if (data.lifter.length > 0) {
                dataToView.lifter.checked = true;
                data.lifter.forEach((lifter, index) => {
                    if (lifter.options.length > 0) {
                        const op = dataToView.lifter.options.find((el) => el.id === lifter.id);
                        op.checked = true;
                        lifter.options.forEach((option) => {
                            op.options.find((el) => el.id === Number(option)).checked = true;
                        });
                    }
                });
            }

            data.ejectorFunction.forEach((ejectorFunc) => {
                if (ejectorFunc.options.length > 0) {
                    const op = dataToView.ejectorFunction.find((el) => el.id === ejectorFunc.id);
                    op.checked = true;
                    ejectorFunc.options.forEach((option) => {
                        op.options.find((el) => el.id === Number(option)).checked = true;
                    });
                }
            });

            data.ejectorDesign.forEach((option) => {
                dataToView.ejectorDesign.find((el) => el.id === Number(option)).checked = true;
            });

            setDemoldingData(dataToView);
        }
    }, [props.demoldingData]);

    const handleChangeCheckox = (section, id, subsectionId, value) => {
        const data = { ...demoldingData };
        switch (section) {
            case "slider":
                if (subsectionId === null) {
                    const element = data.slider.options.find((el) => el.id === id);
                    element.checked = value;
                    if (value === false) {
                        element.options.forEach((el) => (el.checked = false));
                    }
                } else {
                    data.slider.options.find((el) => el.id === subsectionId).options.find((subEl) => subEl.id === id).checked = value;
                }
                props.updateDemoldingData(
                    "demolding",
                    "slider",
                    demoldingData.slider.options.map((option) => {
                        return {
                            id: option.id,
                            options: option.options.filter((subOption) => subOption.checked === true).map((subOption) => subOption.id),
                        };
                    })
                );
                break;
            case "lifter":
                if (subsectionId === null) {
                    const element = data.lifter.options.find((el) => el.id === id);
                    element.checked = value;
                    if (value === false) {
                        element.options.forEach((el) => (el.checked = false));
                    }
                } else {
                    data.lifter.options.find((el) => el.id === subsectionId).options.find((subEl) => subEl.id === id).checked = value;
                }
                props.updateDemoldingData(
                    "demolding",
                    "lifter",
                    demoldingData.lifter.options.map((option) => {
                        return {
                            id: option.id,
                            options: option.options.filter((subOption) => subOption.checked === true).map((subOption) => subOption.id),
                        };
                    })
                );
                break;
            case "ejectorFunction":
                if (subsectionId === null) {
                    const element = data.ejectorFunction.find((el) => el.id === id);
                    element.checked = value;
                    if (value === false) {
                        element.options.forEach((el) => (el.checked = false));
                    }
                } else {
                    data.ejectorFunction.find((el) => el.id === subsectionId).options.find((subEl) => subEl.id === id).checked = value;
                }
                props.updateDemoldingData(
                    "demolding",
                    "ejectorFunction",
                    demoldingData.ejectorFunction.map((option) => {
                        return {
                            id: option.id,
                            options: option.options.filter((subOption) => subOption.checked === true).map((subOption) => subOption.id),
                        };
                    })
                );
                break;
            case "ejectorDesign":
                data.ejectorDesign.find((el) => el.id === id).checked = value;
                props.updateDemoldingData(
                    "demolding",
                    "ejectorDesign",
                    demoldingData.ejectorDesign.filter((el) => el.checked === true).map((el) => el.id)
                );
                break;
            default:
                break;
        }
        setDemoldingData(data);
    };

    const options = (options, section) => {
        return (
            <div>
                {options.map((option) => (
                    <Grid key={option.id} container justifyContent={"flex-start"} alignItems={"flex-start"}>
                        <Grid container justifyContent={"flex-start"} alignItems={"flex-start"}>
                            <FormControlLabel
                                disabled={props.disabled}
                                className={classes.parentCheckbox}
                                classes={{ label: classes.textWrapper }}
                                control={
                                    <Checkbox
                                        // required={
                                        //   section === "slider" || section === "lifter"
                                        //     ? demoldingData[section].checked === true &&
                                        //       options.filter((option) => option.checked === true)
                                        //         .length === 0
                                        //     : options.filter((option) => option.checked === true)
                                        //         .length === 0
                                        // }
                                        checked={option.checked}
                                        className={classes.checkboxWrapper}
                                        classes={{ checked: classes.checked }}
                                        onChange={(e) => handleChangeCheckox(section, option.id, null, e.target.checked)}
                                    />
                                }
                                label={languageData[option.key]}
                            />
                            <div className={classes.optionsWrapper}>
                                {option.options.map((subOption) => (
                                    <FormControlLabel
                                        key={subOption.id}
                                        classes={{ label: classes.textWrapper }}
                                        control={
                                            <Checkbox
                                                required={option.options.filter((el) => el.checked === true).length === 0}
                                                disabled={props.disabled || !option.checked}
                                                checked={subOption.checked}
                                                className={classes.checkboxWrapper}
                                                classes={{ checked: classes.checked }}
                                                onChange={(e) => handleChangeCheckox(section, subOption.id, option.id, e.target.checked)}
                                            />
                                        }
                                        label={languageData[subOption.key]}
                                    />
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                ))}
            </div>
        );
    };

    const onRadioButtonChanged = (type, value) => {
        const data = { ...demoldingData };
        if (type === "slider") {
            data.slider.checked = value === "true";
            if (!data.slider.checked) {
                data.slider.options.forEach((option) => {
                    option.checked = false;
                    option.options.forEach((op) => {
                        op.checked = false;
                    });
                });
                props.updateDemoldingData("demolding", "slider", []);
            } else {
                props.updateDemoldingData(
                    "demolding",
                    "slider",
                    demoldingData.slider.options.map((el) => {
                        return {
                            id: el.id,
                            options: [],
                        };
                    })
                );
            }
        } else if (type === "lifter") {
            data.lifter.checked = value === "true";
            if (!data.lifter.checked) {
                data.lifter.options.forEach((option) => {
                    option.checked = false;
                    option.options.forEach((op) => {
                        op.checked = false;
                    });
                });
                props.updateDemoldingData("demolding", "lifter", []);
            } else {
                props.updateDemoldingData(
                    "demolding",
                    "lifter",
                    demoldingData.lifter.options.map((el) => {
                        return {
                            id: el.id,
                            options: [],
                        };
                    })
                );
            }
        }
        setDemoldingData(data);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Demolding.toUpperCase()}</div>
            <div>
                <Grid container justifyContent={"flex-start"} alignItems={"flex-start"}>
                    <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                        <InputLabel className={classes.labelWrapper}>{languageData?.slider}</InputLabel>
                        <RadioGroup
                            className={classes.radioButtonsWrapper}
                            value={demoldingData.slider.checked}
                            onChange={(e) => onRadioButtonChanged("slider", e.target.value)}
                        >
                            <FormControlLabel
                                disabled={props.disabled}
                                classes={{ label: classes.textWrapper }}
                                name="slider"
                                value={true}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.Yes}
                            />
                            <FormControlLabel
                                disabled={props.disabled}
                                classes={{ label: classes.textWrapper }}
                                name="slider"
                                value={false}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.No}
                            />
                        </RadioGroup>
                        {demoldingData.slider.checked && options(demoldingData.slider.options, "slider")}
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                        <InputLabel className={classes.labelWrapper}>{languageData?.lifter}</InputLabel>
                        <RadioGroup
                            className={classes.radioButtonsWrapper}
                            value={demoldingData.lifter.checked}
                            onChange={(e) => onRadioButtonChanged("lifter", e.target.value)}
                        >
                            <FormControlLabel
                                disabled={props.disabled}
                                classes={{ label: classes.textWrapper }}
                                name="lifter"
                                value={true}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.Yes}
                            />
                            <FormControlLabel
                                disabled={props.disabled}
                                classes={{ label: classes.textWrapper }}
                                name="lifter"
                                value={false}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.No}
                            />
                        </RadioGroup>
                        {demoldingData.lifter.checked && options(demoldingData.lifter.options, "lifter")}
                    </Grid>
                </Grid>
                <Grid container justifyContent={"flex-start"} alignItems={"flex-start"}>
                    <Grid className={classes.optionsWrapper} item xl={2} lg={3} md={3} sm={12} xs={12}>
                        <InputLabel className={classes.labelWrapper}>{languageData?.EjectorDesign}</InputLabel>
                        {demoldingData.ejectorDesign.map((option) => (
                            <FormControlLabel
                                disabled={props.disabled}
                                key={option.id}
                                classes={{ label: classes.textWrapper }}
                                control={
                                    <Checkbox
                                        // required={
                                        //   props.demoldingData &&
                                        //   props.demoldingData.ejectorDesign.length === 0
                                        // }
                                        checked={option.checked}
                                        className={classes.checkboxWrapper}
                                        classes={{ checked: classes.checked }}
                                        onChange={(e) => handleChangeCheckox("ejectorDesign", option.id, null, e.target.checked)}
                                    />
                                }
                                label={languageData[option.key]}
                            />
                        ))}
                    </Grid>
                    <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                        <InputLabel className={classes.labelWrapper}>{languageData?.EjectorFunction}</InputLabel>
                        {options(demoldingData.ejectorFunction, "ejectorFunction")}
                    </Grid>
                    <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                        <div className={classes.labelWrapper}>{languageData?.Unscrewing}</div>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={props.demoldingData && props.demoldingData.unscrewing ? props.demoldingData.unscrewing : ""}
                            displayEmpty={!(props.demoldingData && props.demoldingData.unscrewing)}
                            renderValue={
                                !(props.demoldingData && props.demoldingData.unscrewing)
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateDemoldingData("demolding", "unscrewing", e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {demoldingData.unscrewing.map((option) => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        <div className={classes.selectTextWrapper}>{languageData[option.key]}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Demolding;
