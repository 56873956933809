import { Card, IconButton, makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { Route, Switch } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import GenericModal from "../GenericModal";
import SimpleEntityCard from "../SimpleEntityCard";
import ClientView from "../../views/Clients/Client";
import WebsiteContext from "../../utils/Website";
import styles from "../../assets/jss/material-dashboard-react/components/gridStyle";
import RoutingContext from "../../utils/RoutingContext";

const useStyles = makeStyles(styles);

const Clients = (props) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [customers, setCustomers] = useState([]);
    const { languageData } = useContext(WebsiteContext);
    const { setEntityPath, setRoutingData } = useContext(RoutingContext);

    useEffect(() => {
        getData();
    }, [props.location.pathname]);

    const getData = () => {
        setRoutingData();
        setEntityPath(props.match.url);

        if (props.location.pathname === props.match.path) {
            const options = {
                url: props.match.path,
                method: "GET",
            };
            Axios(options).then((response) => {
                setCustomers(response.data.data);
            });
        }
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const customerCards = customers.map((customer) => {
        return (
            <SimpleEntityCard
                {...props}
                key={customer.id}
                id={customer.id}
                name={customer.name}
                address={customer.address}
                logo={customer.logo}
                link={props.match.path + "/" + customer.id + "/projects"}
            />
        );
    });

    return (
        <Switch>
            <Route path={`${props.match.url}/:id/projects`} render={() => <ClientView />} />
            <Route path={`${props.match.url}/:id/gts`} render={() => <ClientView />} />
            <Route path={`${props.match.url}/:id/machine-list`} render={() => <ClientView />} />
            <Route path={`${props.match.url}/:id/users`} render={() => <ClientView />} />
            <Route
                path={props.match.url}
                render={() => (
                    <React.Fragment>
                        <div className={classes.cardsWrapper}>
                            <Card classes={{ root: classes.root }}>
                                <div className={classes.cardWrapper}>
                                    <div className={classes.addNew}>{languageData?.AddNewClient}</div>
                                    <IconButton className={classes.icon} onClick={handleOpenModal}>
                                        <AddCircleIcon color="primary" fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </Card>
                            {customerCards}
                        </div>
                        <GenericModal {...props} open={openModal} onClose={handleCloseModal} addMode={true} getData={getData} />
                    </React.Fragment>
                )}
            />
        </Switch>
    );
};

export default withWidth()(Clients);
