import React from "react";

const FormLayout = ({ onSubmit, children, className }) => {
    return (
        <form className={className} onSubmit={onSubmit}>
            {children}
        </form>
    );
};

export default FormLayout;
