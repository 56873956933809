import { Card, IconButton, makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { Route, Switch } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import SimpleEntityCard from "../SimpleEntityCard";
import WebsiteContext from "../../utils/Website";
import GenericModal from "../GenericModal";
import ToolMakerView from "../../views/ToolMaker/ToolMaker";
import styles from "../../assets/jss/material-dashboard-react/components/gridStyle";
import RoutingContext from "../../utils/RoutingContext";

const useStyles = makeStyles(styles);

const ToolMakers = (props) => {
    const classes = useStyles(useStyles);
    const [openModal, setOpenModal] = useState(false);
    const [toolMakers, setToolMakers] = useState([]);
    const { languageData } = useContext(WebsiteContext);
    const { setEntityPath, setRoutingData } = useContext(RoutingContext);

    useEffect(() => {
        getData();
    }, [props.location.pathname]);

    const getData = () => {
        setRoutingData();
        setEntityPath(props.match.url);
        const options = {
            url: props.match.path,
            method: "GET",
        };
        Axios(options).then((response) => {
            setToolMakers(response.data.data);
        });
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const toolMakerCards = toolMakers.map((toolMaker) => {
        return (
            <SimpleEntityCard
                {...props}
                id={toolMaker.id}
                key={toolMaker.id}
                name={toolMaker.name}
                address={toolMaker.address}
                logo={toolMaker.logo}
                // handleClickEvent={() => props.history.push(props.match.path + "/" + toolMaker.id)}
                link={props.match.path + "/" + toolMaker.id}
            />
        );
    });

    return (
        <Switch>
            <Route path={`${props.match.url}/:id`} render={() => <ToolMakerView />} />
            <Route
                path={props.match.url}
                render={() => (
                    <React.Fragment>
                        <div className={classes.cardsWrapper}>
                            <Card classes={{ root: classes.root }}>
                                <div className={classes.cardWrapper}>
                                    <div className={classes.addNew}>{languageData?.AddNewToolMaker}</div>
                                    <IconButton className={classes.icon} onClick={handleOpenModal}>
                                        <AddCircleIcon color="primary" fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </Card>
                            {toolMakerCards}
                        </div>
                        <GenericModal {...props} open={openModal} onClose={handleCloseModal} addMode={true} getData={getData} />
                    </React.Fragment>
                )}
            />
        </Switch>
    );
};

export default withWidth()(ToolMakers);
