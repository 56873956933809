const HourglassIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.906 12.6 1.4 10.094 1.4 7C1.4 3.906 3.906 1.4 7 1.4C10.094 1.4 12.6 3.906 12.6 7C12.6 10.094 10.094 12.6 7 12.6Z"
                fill="#E39600"
            />
            <path
                d="M4.25105 3L4.24733 5.96209L4.24688 6L5.75105 7.25L4.24609 8.5L4.25026 8.504H4.24609L4.24609 11H9.75105V8.504H9.74688L9.75105 8.5L8.25105 7.2L9.75105 6V3H4.25105ZM8.66771 8.7V10.2H5.33438V8.7L7.00105 7.2L8.66771 8.7ZM7.00105 6.8L5.33438 5.45V3.8H8.66771V5.45L7.00105 6.8Z"
                fill="#E39600"
            />
            <path d="M5 9L7 7L9 9V10.5H5V9Z" fill="#E39600" />
        </svg>
    );
};

export default HourglassIcon;
