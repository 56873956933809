import React from "react";

import { FormControl, FormHelperText, InputLabel, makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(
    (theme) => ({
        error: {
            fontSize: 10,
            lineHeight: "12px",
            fontWeight: 500,
            fontStyle: "normal",
            color: "red",
        },
        label: {
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "14px",
            color: theme.palette.text.primary,
            marginBottom: "5px",
        },
        root: {
            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                // Default transform is "translate(14px, 20px) scale(1)""
                // This lines up the label with the initial cursor position in the input
                // after changing its padding-left.
                transform: "translate(34px, 20px) scale(1);",
            },
        },
        inputRoot: {
            "& input": {
                fontSize: 12,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                color: theme.palette.text.primary,
                fontWeight: "600",
                lineHeight: "15px",
            },
            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                // Default left padding is 6px
                padding: "10px 7px 10px 7px",
            },
            "& .MuiAutocomplete-input": {
                width: "100%",
            },
            '&[class*="MuiOutlinedInput-root"]': {
                padding: 0,
                paddingRight: "65px !important",
            },
        },
        input: {
            fontSize: 12,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            color: theme.palette.text.primary,
            fontWeight: "600",
            lineHeight: "15px",
            backgroundColor: "#fff",
        },
        disabledInput: {
            backgroundColor: "rgba(97, 97, 97, 0.1) !important",
        },
        value: {
            fontWeight: "600 !important",
            padding: "5px 0px",
            color: theme.palette.grey["800"],
            minHeight: "15px",
            maxWidth: "100%",
            wordBreak: "break-all",
        },
        textWrapper: {
            fontWeight: "600",
            fontSize: 12,
            fontFamily: "Montserrat",
        },
    }),
    { name: "CustomDropdownStyles" }
);

const StyledDropdown = ({
    activeLabel,
    label,
    required,
    className,
    value,
    onChange,
    width,
    error,
    helperText,
    options,
    disabled,
    inputClassName,
    displayField = "name",
    inputColorClass,
    labelClassName,
    placeholder = "Cauta...",
    viewMode,
    viewModeClassName,
}) => {
    const classes = useStyles(useStyles);

    return (
        <div>
            {activeLabel && (
                <InputLabel required={required} className={labelClassName ? labelClassName : classes.label}>
                    {label}
                </InputLabel>
            )}
            {!viewMode ? (
                <FormControl style={{ width: width }} className={className}>
                    <Autocomplete
                        disabled={!!disabled}
                        popupIcon={<KeyboardArrowDownIcon />}
                        value={value}
                        onChange={onChange}
                        id="search-dropdown"
                        options={options}
                        defaultValue={value}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => {
                            return displayField ? option[displayField] || "" : option?.name || "";
                        }}
                        classes={{
                            root: classes.root,
                            inputRoot:
                                classes.inputRoot +
                                " " +
                                (inputClassName ? inputClassName : classes.input) +
                                " " +
                                (inputColorClass ? inputColorClass : "") +
                                " " +
                                (disabled ? classes.disabledInput : ""),
                        }}
                        className={className}
                        style={{ width: width }}
                        renderInput={(params) => (
                            <TextField
                                required={!!required}
                                className={inputClassName}
                                {...params}
                                placeholder={placeholder}
                                variant="outlined"
                                disabled={!!disabled}
                            />
                        )}
                        renderOption={(option) => {
                            return <div className={classes.textWrapper}>{option[displayField] || ""}</div>;
                        }}
                    />
                    {helperText && (
                        <FormHelperText classes={{ root: classes.error }} error={error}>
                            {helperText}
                        </FormHelperText>
                    )}
                </FormControl>
            ) : (
                <Typography variant={"h6"} className={`${classes.value} ${viewModeClassName || ""}`}>
                    {(value && value[displayField]) || "-"}
                </Typography>
            )}
        </div>
    );
};

export default StyledDropdown;
