import React from "react";
import { Helmet } from "react-helmet";

import Projects from "../../components/Clients/Projects/Projects";
import CustomerPage from "../../components/CustomerPage/CustomerPage";

const CustomerProjectsView = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Projects</title>
            </Helmet>
            <CustomerPage {...props} />
        </React.Fragment>
    );
};

export default CustomerProjectsView;
