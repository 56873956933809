import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import withWidth from "@material-ui/core/withWidth";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button, Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import WebsiteContext from "../../../utils/Website";
import StyledButton from "../../StyledButton/StyledButton";
import CustomLabelsModal from "./CustomLabelsModal";
import StyledInput from "../../StyledInput/StyledInput";
import { newRow } from "./newRowData";
import machineListStyle from "../../../assets/jss/material-dashboard-react/components/machineListStyle";

const useStyles = makeStyles(machineListStyle);

const MachineList = (props) => {
    const [existingMachineList, setExistingMachineList] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditLabelsDialog, setShowEditLabelsDialog] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [showNewRow, setShowNewRow] = useState(false);
    const [newTextInputs, setNewTextInputs] = useState(newRow);
    const [newFile, setNewFile] = useState(null);
    const [newFilename, setNewFilename] = useState(null);
    const [editFile, setEditFile] = useState(null);
    const [newCustomInputs, setNewCustomInputs] = useState([]);

    const { languageData, role } = useContext(WebsiteContext);

    const classes = useStyles();

    useEffect(() => {
        getExistingMachineList();
    }, []);

    const getExistingMachineList = () => {
        let options = null;
        if (role !== 1 || localStorage.getItem("customerId").length !== 0) {
            if (role === 0) {
                options = {
                    url: "/business-logic/customers/" + props.id + "/machine-list",
                    method: "GET",
                };
            } else if (role === 1) {
                options = {
                    url: "/business-logic/customers/" + localStorage.getItem("customerId") + "/machine-list",
                    method: "GET",
                };
            }
            Axios(options).then((response) => {
                let data;
                let tableData = [];
                let newCstInputs = [];

                if (response?.data?.data?.customizedLabels) {
                    Object.entries(response?.data?.data?.customizedLabels).forEach(([key, value]) => {
                        newCstInputs.push({
                            custom: key,
                            name: value,
                            value: "",
                        });
                    });
                }

                response?.data?.data?.machineList?.reverse().forEach((element) => {
                    let textInputs = [];
                    let customInputs = [];

                    Object.entries(element.textFields).forEach(([key, value]) => textInputs.push({ name: key, value: value }));

                    Object.entries(response?.data?.data?.customizedLabels).forEach(([key, value]) => {
                        customInputs.push({
                            custom: key,
                            name: value,
                            value: element.customFields[key],
                        });
                    });

                    tableData.push({
                        id: element.id,
                        textInputs,
                        customInputs,
                        file: {
                            fileId: element.fileId,
                            fileName: element.fileName,
                        },
                    });
                });

                data = {
                    customLabels: newCstInputs,
                    tableData,
                };
                setNewCustomInputs(newCstInputs);
                setExistingMachineList(data);
            });
        }
    };

    const handleFileOpen = (id) => {
        if (role === 0) window.open(`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}/files/${id}`);
        else if (role === 1 && localStorage.getItem("customerId").length !== 0)
            window.open(`${process.env.REACT_APP_URL}file-operations/${localStorage.getItem("customerId")}/files/${id}`);
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const handleOpenEditCustomLabelsDialog = () => {
        setShowEditLabelsDialog(true);
    };

    const handleCloseEditLabelsDialog = () => {
        setShowEditLabelsDialog(false);
        getExistingMachineList();
    };

    const uploadNewFile = (event) => {
        let file = event?.target?.files[0];
        if (file) {
            const fileData = new FormData();
            fileData.append("files", file, file.name);
            setNewFile(fileData);
            setNewFilename(file.name);
        }
    };

    const uploadEditFile = (event) => {
        let file = event?.target?.files[0];
        if (file) {
            const fileData = new FormData();
            fileData.append("files", file, file.name);

            let copy = { ...editRow };
            copy.file.fileName = file.name;
            setEditRow(copy);

            setEditFile(fileData);
        }
    };

    const handleSubmitWithFile = () => {
        let options;

        if (role === 0) {
            options = {
                url: "/file-operations/" + props.id + "/files",
                method: "POST",
                data: newFile,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Loading": false,
                },
            };
        } else if (role === 1 && localStorage.getItem("customerId").length !== 0) {
            options = {
                url: "/file-operations/" + localStorage.getItem("customerId") + "/files",
                method: "POST",
                data: newFile,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Loading": false,
                },
            };
        }
        Axios(options).then((fileResponse) => {
            handleSubmit(fileResponse.data.data.files[0].id, fileResponse.data.data.files[0].name);
        });
    };

    const handleSubmit = (fileId, fileName) => {
        const data = {
            customFields: {},
            fileId: fileId ? fileId : "",
            fileName: fileName ? fileName : "",
            textFields: {},
        };
        newTextInputs.forEach((input) => {
            data.textFields[input.name] = input.value;
        });

        newCustomInputs.forEach((input) => {
            data.customFields[input.custom] = input.value;
        });
        let options;
        if (role === 0) {
            options = {
                url: "/business-logic/customers/" + props.id + "/machine-list",

                method: "POST",
                data: data,
                headers: {
                    "X-Loading": false,
                },
            };
        } else if (role === 1 && localStorage.getItem("customerId").length !== 0) {
            options = {
                url: "/business-logic/customers/" + localStorage.getItem("customerId") + "/machine-list",
                method: "POST",
                data: data,
                headers: {
                    "X-Loading": false,
                },
            };
        }
        Axios(options).then(() => {
            getExistingMachineList();
            setShowNewRow(false);
            setNewTextInputs(newRow);
            setNewFile(null);
            setNewFilename(null);
        });
    };

    const handleSaveWithFile = () => {
        let options1;
        if (role === 0) {
            options1 = {
                url: "/file-operations/" + props.id + "/files/" + existingMachineList.tableData.find((el) => el.id === editRow.id).file.fileId,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
        } else if (role === 1 && localStorage.getItem("customerId").length !== 0) {
            options1 = {
                url:
                    "/file-operations/" +
                    localStorage.getItem("customerId") +
                    "/files/" +
                    existingMachineList.tableData.find((el) => el.id === editRow.id).file.fileId,
                method: "DELETE",
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Loading": false,
                },
            };
        }
        Axios(options1).then(() => {
            let options2;
            if (role === 0) {
                options2 = {
                    url: "/file-operations/" + props.id + "/files",
                    method: "POST",
                    data: editFile,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-Loading": false,
                    },
                };
            } else if (role === 1 && localStorage.getItem("customerId").length !== 0) {
                options2 = {
                    url: "/file-operations/" + localStorage.getItem("customerId") + "/files",
                    method: "POST",
                    data: editFile,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-Loading": false,
                    },
                };
            }
            Axios(options2).then((fileResponse) => {
                handleSave(fileResponse.data.data.files[0].id, fileResponse.data.data.files[0].name);
            });
        });
    };

    const handleSave = (fileId, fileName) => {
        const data = {
            customFields: {},
            fileName: fileName ? fileName : editRow.file.fileName,
            fileId: fileId ? fileId : editRow.file.fileId,
            textFields: {},
        };
        editRow.textInputs.forEach((input) => {
            data.textFields[input.name] = input.value;
        });

        editRow.customInputs.forEach((input) => {
            data.customFields[input.custom] = input.value;
        });

        const options = {
            url: "/business-logic/customers/machine-list/" + editRow.id,
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            getExistingMachineList();
            setEditRow(null);
            setEditFile(null);
        });
    };

    const handleDeleteMachine = (machine) => {
        setShowDeleteDialog(true);
        setSelectedId(machine);
    };

    const handleConfirmDeleteMachine = () => {
        const options1 = {
            url: "/business-logic/customers/machine-list/" + selectedId.id,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        if (selectedId.file.fileId) {
            let options2;
            if (role === 0) {
                options2 = {
                    url: "/file-operations/" + props.id + "/files/" + selectedId.file.fileId,
                    method: "DELETE",
                    headers: {
                        "X-Loading": false,
                    },
                };
            } else if (role === 1 && localStorage.getItem("customerId").length !== 0) {
                options2 = {
                    url: "/file-operations/" + localStorage.getItem("customerId") + "/files/" + selectedId.file.fileId,
                    method: "DELETE",
                    headers: {
                        "X-Loading": false,
                    },
                };
            }
            Axios(options2).then(() => {
                Axios(options1).then((_) => {
                    setShowDeleteDialog(false);
                    getExistingMachineList();
                    setSelectedId(null);
                });
            });
        } else {
            Axios(options1).then((_) => {
                setShowDeleteDialog(false);
                getExistingMachineList();
                setSelectedId(null);
            });
        }
    };

    const handleEditMachine = (id) => {
        setEditRow(existingMachineList.tableData.find((el) => el.id === id));
    };

    const handleCancel = () => {
        setEditRow(null);
    };

    const handleChangeNewTextInput = (value, index) => {
        let arrCopy = JSON.parse(JSON.stringify(newTextInputs));
        arrCopy[index].value = value;
        setNewTextInputs(arrCopy);
    };

    const handleChangeNewCustomInput = (value, index) => {
        let arrCopy = JSON.parse(JSON.stringify(newCustomInputs));
        arrCopy[index].value = value;
        setNewCustomInputs(arrCopy);
    };

    const handleChangeTextInput = (value, index) => {
        let copy = JSON.parse(JSON.stringify(editRow));
        copy.textInputs[index].value = value;
        setEditRow(copy);
    };

    const handleChangeCustomInput = (value, index) => {
        let copy = JSON.parse(JSON.stringify(editRow));
        copy.customInputs[index].value = value;
        setEditRow(copy);
    };

    return (
        <React.Fragment>
            <div>
                <StyledButton variant="contained" color="secondary" fullWidth className={classes.buttonAdd} onClick={() => handleOpenEditCustomLabelsDialog()}>
                    {languageData?.EditCustomLabels}
                </StyledButton>
                <StyledButton variant="contained" color="primary" fullWidth className={classes.buttonAdd} onClick={() => setShowNewRow(true)}>
                    {languageData?.AddNewMachine}
                </StyledButton>
            </div>
            <TableContainer className={classes.table} component={Paper}>
                <Table size="small" stickyHeader={true} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.firstLastColumn}>{languageData?.Actions}</TableCell>
                            {languageData?.MachineListHeader.map((item, index) => (
                                <TableCell key={index} className={classes.columnStyle} align="center">
                                    {item.name}
                                </TableCell>
                            ))}
                            {newCustomInputs?.map((label) => (
                                <TableCell key={label.custom} className={classes.columnStyle} align="center">
                                    {label.name}
                                </TableCell>
                            ))}
                            <TableCell className={classes.firstLastColumn} align="right">
                                {languageData?.Actions}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showNewRow && (
                            <TableRow className={classes.rowTable} key="new">
                                <TableCell component="th" scope="row">
                                    <form
                                        id="newMachineForm"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            newFile ? handleSubmitWithFile() : handleSubmit();
                                        }}
                                    />
                                    <IconButton type="submit" form="newMachineForm">
                                        <SaveIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setShowNewRow(false);
                                            setNewTextInputs(newRow);
                                        }}
                                    >
                                        <CancelIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                                {newTextInputs?.map((textInput, index) => (
                                    <TableCell key={textInput.name + "new"} className={classes.columnStyle} align="center">
                                        <StyledInput
                                            defaultValue={textInput.value}
                                            onChange={(e) => handleChangeNewTextInput(e.target.value, index)}
                                            type="text"
                                            required={true}
                                            form="newMachineForm"
                                        />
                                    </TableCell>
                                ))}
                                <TableCell className={classes.columnStyle} align="center">
                                    <input
                                        className={classes.inputFile}
                                        id="contained-button-file"
                                        form="newMachineForm"
                                        type="file"
                                        onChange={(e) => uploadNewFile(e)}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button
                                            variant="contained"
                                            component="span"
                                            className={classes.uploadFile}
                                            startIcon={<CloudUploadIcon color="primary" />}
                                        >
                                            {newFile ? languageData?.ChangeFile : languageData?.UploadFile}
                                        </Button>
                                    </label>
                                    {newFile && <span className={classes.filenameEditModeWrapper}>{newFilename}</span>}
                                </TableCell>
                                {newCustomInputs?.map((customInput, index) => (
                                    <TableCell key={customInput.name + "new"} className={classes.columnStyle} align="center">
                                        <StyledInput
                                            defaultValue={customInput.value}
                                            onChange={(e) => handleChangeNewCustomInput(e.target.value, index)}
                                            type="text"
                                            form="newMachineForm"
                                        />
                                    </TableCell>
                                ))}
                                <TableCell component="th" scope="row" align="right">
                                    <IconButton type="submit" form="newMachineForm">
                                        <SaveIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setShowNewRow(false);
                                            setNewTextInputs(newRow);
                                        }}
                                    >
                                        <CancelIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                        {existingMachineList?.tableData?.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id === editRow?.id ? (
                                        <React.Fragment>
                                            <form
                                                id={"editMachineForm-" + row.id}
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    editFile ? handleSaveWithFile() : handleSave();
                                                }}
                                            />
                                            <IconButton type="submit" form={"editMachineForm-" + row.id}>
                                                <SaveIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton onClick={() => handleCancel(row.id)}>
                                                <CancelIcon fontSize="small" />
                                            </IconButton>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <IconButton onClick={() => handleEditMachine(row.id)}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton onClick={() => handleDeleteMachine(row)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </React.Fragment>
                                    )}
                                </TableCell>
                                {row?.textInputs?.map((textInput, index) => (
                                    <TableCell key={textInput.name} className={classes.columnStyle} align="center">
                                        {row.id === editRow?.id ? (
                                            <StyledInput
                                                defaultValue={editRow.textInputs[index].value}
                                                onChange={(e) => handleChangeTextInput(e.target.value, index)}
                                                type="text"
                                                required={true}
                                                form={"editMachineForm-" + row.id}
                                            />
                                        ) : (
                                            textInput.value
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell className={classes.columnStyle} align="center">
                                    {row.id === editRow?.id ? (
                                        <React.Fragment>
                                            <input
                                                className={classes.inputFile}
                                                id={"contained-button-file-" + row.id}
                                                form={"editMachineForm-" + row.id}
                                                type="file"
                                                onChange={(e) => uploadEditFile(e)}
                                            />
                                            <label htmlFor={"contained-button-file-" + row.id}>
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    className={classes.uploadFile}
                                                    startIcon={<CloudUploadIcon color="primary" />}
                                                >
                                                    {row?.file?.fileName ? languageData?.ChangeFile : languageData?.UploadFile}
                                                </Button>
                                            </label>
                                            {row?.file?.fileName && <span className={classes.filenameEditModeWrapper}>{row?.file?.fileName}</span>}
                                        </React.Fragment>
                                    ) : row?.file?.fileName ? (
                                        <div className={classes.openFile} onClick={() => handleFileOpen(row?.file?.fileId)}>
                                            {row?.file?.fileName}
                                        </div>
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>
                                {row?.customInputs?.map((customInput, index) => (
                                    <TableCell key={customInput.name} className={classes.columnStyle} align="center">
                                        {row.id === editRow?.id ? (
                                            <StyledInput
                                                defaultValue={editRow.customInputs[index].value}
                                                onChange={(e) => handleChangeCustomInput(e.target.value, index)}
                                                type="text"
                                                form={"editMachineForm-" + row.id}
                                            />
                                        ) : (
                                            customInput.value
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell component="th" scope="row" align="right">
                                    {row.id === editRow?.id ? (
                                        <React.Fragment>
                                            <form
                                                id={"editMachineForm-" + row.id}
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSave();
                                                }}
                                            />
                                            <IconButton type="submit" form={"editMachineForm-" + row.id}>
                                                <SaveIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton onClick={() => handleCancel(row.id)}>
                                                <CancelIcon fontSize="small" />
                                            </IconButton>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <IconButton onClick={() => handleEditMachine(row.id)}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton onClick={() => handleDeleteMachine(row)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </React.Fragment>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={showDeleteDialog} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{languageData?.DeleteMachineMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
                        {languageData?.No}
                    </Button>
                    <Button onClick={handleConfirmDeleteMachine} color="primary" autoFocus>
                        {languageData?.Yes}
                    </Button>
                </DialogActions>
            </Dialog>
            {existingMachineList?.customLabels && (
                <CustomLabelsModal
                    labels={existingMachineList?.customLabels}
                    customerId={role === 0 ? props.id : role === 1 && localStorage.getItem("customerId").length !== 0 && localStorage.getItem("customerId")}
                    onClose={handleCloseEditLabelsDialog}
                    open={showEditLabelsDialog}
                    title={languageData?.EditCustomLabels}
                />
            )}
        </React.Fragment>
    );
};

export default withWidth()(MachineList);
