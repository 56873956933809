import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import WebsiteContext from "../../../../utils/Website";
import FormLayout from "../../../../containers/FormLayout/FormLayout";
import StyledButton from "../../../StyledButton/StyledButton";
import StyledInput from "../../../StyledInput/StyledInput";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100% - 18px)",
        padding: 9,
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        // marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    divider: {
        width: "100%",
    },
    buttonWrapper: {
        padding: 18,
        background: "white",
    },
    sectionTitle: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        margin: "18px 0 18px 0",
    },
    sectionSubtitle: {
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "15px",
        textAlign: "center",
        margin: "0px 0 18px 0",
    },
    sectionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    gridWrapper: {},
    questionWrapper: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        width: 210,
        marginBottom: 5,
        marginTop: 16,
    },
    answerWrapper: {
        width: 210,
    },
}));

const SamplingFlowProcess = (props) => {
    const [data, setData] = useState([]);
    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/sampling-process-flow`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setData(response.data.data);
        });
    };

    const handleSubmit = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/sampling-process-flow`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: { questions: data },
        };
        Axios(options).then((response) => {
            getData();
        });
    };

    const changeInput = (sectionIndex, questionIndex, value) => {
        const tmp = [...data];
        tmp[sectionIndex].questions[questionIndex].answer = value;
        setData(tmp);
    };

    return (
        <div className={classes.wrapper}>
            <FormLayout
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                className={classes.layoutWrapper}
            >
                {(role === 0 || role === 2) && (
                    <div className={classes.buttonWrapper}>
                        <StyledButton className={classes.button} color="primary" variant="contained" type="submit">
                            {languageData?.Save.toUpperCase()}
                        </StyledButton>
                    </div>
                )}
                <div className={classes.contentWrapper}>
                    {data?.map((section, sectionIndex) => {
                        return (
                            <div className={classes.sectionWrapper} key={section.name}>
                                <div className={classes.sectionTitle}>{languageData[section.name]}</div>
                                {section.name === "Start sampling process" && (
                                    <div className={classes.sectionSubtitle}>{languageData["Process Guideline"]}</div>
                                )}
                                <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                    {section.questions.map((question, questionIndex) => {
                                        return question.question !== "Process Guideline" ? (
                                            <Grid item container xs={12} sm={12} md={4} lg={3} key={question.question}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                    <StyledInput
                                                        disabled={role === 1}
                                                        defaultValue={question.answer}
                                                        onChange={(e) => changeInput(sectionIndex, questionIndex, e.target.value)}
                                                        className={classes.answerWrapper}
                                                        type={question.question !== "Number of cavities" ? "text" : "number"}
                                                    />
                                                </div>
                                            </Grid>
                                        ) : (
                                            <div></div>
                                        );
                                    })}
                                </Grid>
                            </div>
                        );
                    })}
                </div>
            </FormLayout>
        </div>
    );
};

export default SamplingFlowProcess;
