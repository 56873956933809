import React, { useContext } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Button, makeStyles } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledInput from "../../../StyledInput/StyledInput";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        width: 110,
    },
    shortSection: {
        width: 70,
    },
    rowContentWrapper: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(790)]: {
            flexDirection: "column",
        },
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        marginTop: 10,
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    checkboxRowWrapper: {
        display: "flex",
        flexDirection: "row",
        marginTop: 10,
        marginLeft: 5,
    },
    checkboxWrapper: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
        margin: 5,
    },
    checked: {},
    inputWrapper: {
        width: 210,
        height: 33,
    },
    disabled: {
        opacity: 0.5,
    },
    columnWrapper: {
        marginRight: 20,
    },
    checkboxComponent: {
        marginTop: 20,
    },
}));

const Steel = (props) => {
    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const handleOpenCustomerGMS = () => {
        if (role === 0) {
            window.open(window.location.origin + "/customers/" + props.customerId + "/gts", "_blank", "noopener,noreferrer");
        } else if (role === 1) {
            window.open(window.location.origin + "/customer/gts", "_blank", "noopener,noreferrer");
        } else {
            window.open(window.location.origin + "/tool/" + props.toolId + "/gms", "_blank", "noopener,noreferrer");
        }
    };

    const handleChangeCheckbox = (section, subsection, value) => {
        const newValues = { ...props.steelData[section] };
        newValues[subsection].checked = value;
        props.updateSteelData("steel", section, newValues);
    };

    const handleChangeText = (section, subsection, value) => {
        const newValues = { ...props.steelData[section] };
        newValues[subsection].value = value;
        props.updateSteelData("steel", section, newValues);
    };

    const checkboxOptions = (options, section) => {
        const required = Object.keys(options).filter((k, v) => options[k] && options[k].checked && options[k].checked === true).length === 0;
        return Object.keys(options).map((key, index) => {
            return (
                <div key={index} className={classes.checkboxRowWrapper}>
                    <FormControlLabel
                        disabled={props.disabled}
                        classes={{
                            label: classes.textWrapper + " " + (section === "insertSteel" && classes.shortSection),
                        }}
                        control={
                            <Checkbox
                                // required={required}
                                checked={options[key] && options[key].checked && options[key].checked === true}
                                className={classes.checkboxWrapper}
                                classes={{ checked: classes.checked }}
                                onChange={(e) => handleChangeCheckbox(section, key, e.target.checked)}
                            />
                        }
                        label={languageData[key]}
                    />
                    <StyledInput
                        disabled={options[key] === null || options[key].checked === null || options[key].checked === false || props.disabled}
                        required={options[key] && options[key].checked && options[key].checked === true}
                        defaultValue={options[key] !== null && options[key].value !== null ? options[key].value : ""}
                        className={
                            classes.inputWrapper +
                            " " +
                            (options[key] === null || options[key].checked === null || (options[key].checked === false && classes.disabled))
                        }
                        label={languageData?.Name}
                        onChange={(e) => handleChangeText(section, key, e.target.value)}
                        type="text"
                    />
                </div>
            );
        });
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Steel.toUpperCase()}</div>
            <div className={classes.rowContentWrapper}>
                <div className={classes.columnWrapper}>
                    <InputLabel className={classes.labelWrapper}>{languageData?.InsertSteel}</InputLabel>
                    <div className={classes.contentMargin}>
                        <Button
                            variant="contained"
                            component="label"
                            className={classes.uploadFileButton}
                            startIcon={<OpenInNewIcon color="primary" />}
                            onClick={handleOpenCustomerGMS}
                        >
                            {languageData?.OpenCustomerGMS}
                        </Button>
                    </div>
                    <div className={classes.checkboxComponent}>{props.steelData && checkboxOptions(props.steelData.insertSteel, "insertSteel")}</div>
                </div>
                <div className={classes.columnWrapper}>
                    <InputLabel className={classes.labelWrapper}>{languageData?.Mouldbase}</InputLabel>
                    <div className={classes.checkboxComponent}>{props.steelData && checkboxOptions(props.steelData.mouldBase, "mouldBase")}</div>
                </div>
            </div>
        </div>
    );
};

export default Steel;
