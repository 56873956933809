import React, { useCallback, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import Axios from "axios";

import { makeStyles } from "@material-ui/core";

import Users from "./Users/Users";
import WebsiteContext from "../../utils/Website";
import RoutingContext from "../../utils/RoutingContext";

const useStyles = makeStyles((theme) => ({
    usersStyle: {
        padding: 16,
        paddingBottom: 0,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "15px",
        lineHeight: "15px",
        color: theme.palette.primary.main,
        marginBottom: "10px",
    },
}));

const ToolMaker = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const { setCustomerData, setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData } = useContext(RoutingContext);

    const getToolMaker = useCallback(() => {
        const options = {
            url: props.match.url,
            method: "GET",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setRoutingData();
            setCustomerData({
                visibleContent: true,
                name: data.name,
                logo: data.logo,
                address: data.address,
            });
            setEntityPath(props.match.url);
            setBackPath("/tool-makers");
        });
    }, [props.match.path]);

    useEffect(() => {
        getToolMaker();
    }, [getToolMaker]);

    return (
        <div className={classes.usersStyle}>
            <div className={classes.titleWrapper}>{languageData?.Users}</div>
            <div>
                <Users {...props} />
            </div>
        </div>
    );
};

export default withRouter(ToolMaker);
