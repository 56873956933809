import React from "react";
import { makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import backgroundImage from "../../assets/img/loginImage.png";
import ResetPasswordForm from "../../components/ResetPasswordForm/ResetPasswordForm";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: "url(" + backgroundImage + ")",
        width: "100%",
        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        display: "flex",
    },
    wrapper: {
        width: "514px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        padding: "24px 0",
        [theme.breakpoints.down("xs")]: {
            width: "90%",
        },
    },
    title: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "34.2857px",
        lineHeight: "39px",
        letterSpacing: "0.1em",
        color: "#FFFFFF",
        textAlign: "center",
        marginBottom: 72,
    },
}));

const ResetPassword = (props) => {
    const classes = useStyles(useStyles);

    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Reset password</title>
            </Helmet>
            <div className={classes.background}>
                <div className={classes.wrapper}>
                    <div className={classes.title}>HANNES</div>
                    <ResetPasswordForm {...props} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;
