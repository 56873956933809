import { dangerColor, defaultFont, whiteColor } from "../../material-dashboard-react";

import dropdownStyle from "../../material-dashboard-react/dropdownStyle";

const headerLinksStyle = (theme) => ({
    ...dropdownStyle(theme),
    search: {
        "& > div": {
            marginTop: "0",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "10px 15px !important",
            float: "none !important",
            paddingTop: "1px",
            paddingBottom: "1px",
            padding: "0!important",
            width: "60%",
            marginTop: "40px",
            "& input": {
                color: whiteColor,
            },
        },
    },
    linkText: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "9px",
        maxWidth: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "none",
    },
    headerTitleWrapper: {
        maxWidth: "calc(100% - 30px - 64px - 48px)",
    },
    buttonLink: {
        // [theme.breakpoints.down("sm")]: {
        //     display: "flex",
        //     margin: "10px 15px 0",
        //     width: "-webkit-fill-available",
        //     "& svg": {
        //         width: "24px",
        //         height: "30px",
        //         marginRight: "15px",
        //         marginLeft: "-15px",
        //     },
        //     "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        //         fontSize: "24px",
        //         lineHeight: "30px",
        //         width: "24px",
        //         height: "30px",
        //         marginRight: "15px",
        //         marginLeft: "-15px",
        //     },
        //     "& > span": {
        //         justifyContent: "flex-start",
        //         width: "125px",
        //         lineHeight: "1.5em",
        //         height: "3em",
        //         overflow: "hidden",
        //     },
        // },
    },
    searchButton: {
        [theme.breakpoints.down("sm")]: {
            top: "-50px !important",
            marginRight: "22px",
            float: "right",
        },
    },
    margin: {
        zIndex: "4",
        margin: "0",
    },
    searchIcon: {
        width: "17px",
        zIndex: "4",
    },
    notifications: {
        zIndex: "4",
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            top: "2px",
            border: "1px solid " + whiteColor,
            right: "4px",
            fontSize: "9px",
            background: dangerColor[0],
            color: whiteColor,
            minWidth: "16px",
            height: "16px",
            borderRadius: "10px",
            textAlign: "center",
            lineHeight: "16px",
            verticalAlign: "middle",
            display: "block",
        },
        [theme.breakpoints.down("sm")]: {
            ...defaultFont,
            fontSize: "14px",
            marginRight: "8px",
        },
    },
    manager: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        width: "100%",
        display: "inline-block",
    },
    searchWrapper: {
        [theme.breakpoints.down("sm")]: {
            width: "-webkit-fill-available",
            margin: "10px 15px 0",
        },
        display: "inline-block",
    },
    accountName: {
        width: "856px",
        height: "60px",
        marginLeft: "140px",
        marginTop: "0px",
    },

    accountNameWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "9px",
        overflow: "hidden",
        lineHeight: "15px",
        height: "30px",
        marginRight: "15px",
        width: "100px",
        color: "rgba(31, 31, 31, 0.5)",
    },
    accountNameColorSidenavWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "9px",
        overflow: "hidden",
        lineHeight: "15px",
        height: "30px",
        width: "100px",
        color: "#FFFFFF",
    },
    accountIcon: {
        height: "27px",
        width: "27px",
        backgroundColor: "#777777",
        borderRadius: "50%",
        // display: "inline-block",
        color: "white",
        fontWeight: "500",
        fontSize: "8.5px",
        lineHeight: "11px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        margin: "0 5px",
        textTransform: "uppercase",
    },
    accountButtonWrapper: {
        padding: "12px 0",
        margin: "0 14px",
        width: 140,
    },
    dot: {
        width: "5px",
        height: "5px",
        backgroundColor: "#1FD246",
        borderRadius: "50%",
        position: "absolute",
        left: "4%",
        bottom: "4%",
    },

    menuContainer: {
        position: "relative",
    },

    menu: {
        background: "#ffffff",
        borderRadius: "8px",
    },

    showMenu: {
        opacity: "1px",
        visibility: "visible",
    },

    liMenu: {
        borderBottom: "1px",
    },

    navbarGrid: {
        width: "100%",
    },

    navbarShadow: {
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },

    logo: {
        height: "35px",
        width: "auto",
        marginLeft: 20,
    },

    divider: {
        height: "50px",
        margin: "0 20px",
        width: "0.5px",
        background: "rgb(25,25,25, 0.1)",
    },

    nameStyle: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: 12,
        lineHeight: "15px",
        maxHeight: "15px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    addressStyle: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: 10,
        lineHeight: "15px",
        maxHeight: "15px",
        marginTop: 5,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    backButton: {
        height: 60,
        width: 60,
        margin: 0,
        backgroundColor: "#C00D0E",
        borderRadius: "0",
    },
    smallBackButton: {
        height: 60,
        minWidth: 30,
        width: 30,
        maxWidth: 30,
        margin: 0,
        backgroundColor: "#C00D0E",
        borderRadius: "0",
        padding: 0,
    },

    backIcon: {
        marginLeft: 10,
    },

    gridStyle: {
        padding: 0,
    },

    moreVertWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },

    leadingStyle: {
        padding: "10px 0",
    },

    changeEntityButton: {
        backgroundColor: "#FFFFFF",
        border: "1.01724px solid #ECECEC",
        boxSizing: "border-box",
        borderRadius: "3.05172px",
        maxHeight: 32,
        margin: "0 5px",
        [theme.breakpoints.down(1250)]: {
            margin: "5px 5px 0 0",
        },
    },

    buttonText: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        margin: "0 5px",
    },

    buttonIcon: {
        color: "#C00D0E",
        width: 12,
        height: 12,
        margin: "0 5px",
    },
    stepButtonIcon: {
        color: "#0DC08A",
        width: 12,
        height: 12,
        margin: "0 5px",
    },

    headerTitle: {
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "700",
        marginLeft: 20,
        maxWidth: "95px",
        lineHeight: "1.5em",
        maxHeight: "3em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
    },
    moreVertHeaderTitle: {
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "700",
        lineHeight: "1.5em",
        maxHeight: "1.5em",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    headerText: {
        fontFamily: "Montserrat",
        fontSize: "10px",
        fontWeight: "500",
        maxWidth: "90px",
        lineHeight: "1.5em",
        maxHeight: "3em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        marginTop: 5,
    },
    moreVertHeaderText: {
        fontFamily: "Montserrat",
        fontSize: "10px",
        fontWeight: "500",
        lineHeight: "1.5em",
        maxHeight: "1.5",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tooltipWrapper: {
        "&:hover": {
            cursor: "default",
        },
    },
    navbarDataWrapper: {
        height: "60px",
    },
    extendedNavData: {
        [theme.breakpoints.down(1250)]: {
            display: "none",
        },
    },
    collapsedNavData: {
        [theme.breakpoints.up(1250)]: {
            display: "none",
        },
    },
    progressBarWrapper: {
        marginTop: "10px",
    },
    selectWrapper: {
        width: "100px",
        height: 26,
        marginRight: 5,
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "200%",
        color: "#191919",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    visibleSwitch: {
        display: "block",
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginLeft: 8,
        [theme.breakpoints.down(1249)]: {
            margin: 0,
            padding: 0,
        },
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        color: "#191919",
    },
    title: {
        fontSize: 12,
        fontWeight: 700,
        fontFamily: "Montserrat",
        padding: 20,
    },
});

export default headerLinksStyle;
