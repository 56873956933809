import React, { useContext, useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router";
import Axios from "axios";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

import { makeStyles, Button } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DeleteIcon from "@material-ui/icons/Delete";

import DeleteModal from "../DeleteModal/DeleteModal";
import WebsiteContext from "../../utils/Website";
import RoutingContext from "../../utils/RoutingContext";
import { reminderTypes } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100% - 40px)",
        width: "calc(100% - 40px)",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
        padding: "20px",
        display: "grid",
        gridTemplateRows: "calc(100% - 33px - 20px) 33px",
        gap: 20,
        overflow: "hidden",
    },
    addButton: {
        width: 211,
        height: 33,
        background: "#C00D0E",
        color: "#FFFFFF",
        borderRadius: 2.5,
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: 600,
        fontFamily: "Montserrat",
        "&:hover": {
            background: "#900a0b",
            color: "#FFFFFF",
        },
    },
    remindersWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        overflow: "auto",
    },
    reminder: {
        minWidth: 300,
        minHeight: 78,
        borderRadius: 5,
        border: "1px solid #ECECEC",
        display: "grid",
        gridTemplateColumns: "37px auto 50px",
        cursor: "pointer",
    },
    reminderIconWrapper: {
        backgroundColor: theme.palette.primary.main,
        width: 37,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px 0 0 5px",
    },
    reminderIcon: {
        color: "white",
    },
    reminderTextWrapper: {
        height: "100%",
        padding: "0px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    reminderTitle: {
        textTransform: "uppercase",
        color: "#A5A5A5",
        fontSize: 12,
        fontWeight: 700,
        fontFamily: "Montserrat",
    },
    reminderSubtitle: {
        color: "#000",
        fontSize: 12,
        fontWeight: 700,
        fontFamily: "Montserrat",
    },
    reminderButtonWrapper: {
        width: 50,
        height: "100%",
        borderRadius: "0 5px 5px 0",
        display: "flex",
        alignItems: "center",
    },
    reminderButton: {
        color: theme.palette.primary.main,
    },
}));

const Schedule = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const { setCustomerData, setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData } = useContext(RoutingContext);
    const router = useHistory();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState({ type: null, id: null });

    const labelStyle = "original";
    const timezones = allTimezones;
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

    const modalTypes = {
        Delete: "DELETE",
    };

    useEffect(() => {
        const options = {
            url: "/schedulers",
            method: "GET",
            headers: {
                "X-Loading": true,
            },
        };
        Axios(options).then((res) => {
            setData(res?.data?.data);
        });
    }, []);

    const computeReminderName = (reminder) => {
        const name = reminder?.type === reminderTypes.Daily ? languageData?.DailyReminder : languageData?.WeeklyReminder;
        let formattedMinutes = reminder?.minute?.toString().padStart(2, "0");
        const hour = `${reminder?.hour}:${formattedMinutes}`;

        let days = undefined;

        const timezone = options.find((el) => el?.value === reminder?.timezone)?.label;

        if (!reminder?.dayOfWeek.includes(",")) {
            days = languageData?.Days[reminder?.dayOfWeek];
        } else {
            if (reminder?.type === reminderTypes.Daily) {
                days = reminder?.dayOfWeek.includes("sat") ? languageData?.DailySat : languageData?.DailyFri;
            } else {
                days = reminder?.dayOfWeek
                    ?.split(",")
                    ?.map((day) => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase())
                    ?.join(", ");
            }
        }

        return `${name} - ${days}, ${hour} - ${timezone}`;
    };

    const deleteReminder = (id) => {
        const options = {
            url: "/schedulers/" + id,
            method: "DELETE",
            headers: {
                "X-Loading": true,
            },
        };
        Axios(options).then((res) => {
            setData(data.filter((el) => el?.id !== id));
            setModal({ type: null, id: null });
        });
    };

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.remindersWrapper}>
                    {data?.map((reminder) => {
                        return (
                            <div
                                className={classes.reminder}
                                key={reminder?.id}
                                onClick={(e) => {
                                    router.push(`/reminders/schedule/edit/${reminder.id}`);
                                }}
                            >
                                <div className={classes.reminderIconWrapper}>
                                    <NotificationsIcon className={classes.reminderIcon} />
                                </div>
                                <div className={classes.reminderTextWrapper}>
                                    <div className={classes.reminderTitle}>{languageData?.ReminderEmail}</div>
                                    <div className={classes.reminderSubtitle}>{computeReminderName(reminder)}</div>
                                </div>
                                <div
                                    className={classes.reminderButtonWrapper}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setModal({ type: modalTypes?.Delete, id: reminder?.id });
                                    }}
                                >
                                    <DeleteIcon className={classes.reminderButton} />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Button
                    className={classes.addButton}
                    onClick={() => {
                        router.push(`/reminders/schedule/add`);
                    }}
                >
                    {languageData?.NewReminder}
                </Button>
            </div>
            <DeleteModal
                isOpen={modal?.type === modalTypes.Delete}
                onClose={() => {
                    setModal({ type: null, id: null });
                }}
                onDelete={() => {
                    deleteReminder(modal?.id);
                }}
                message={languageData?.DeleteReminder}
            />
        </>
    );
};

export default withRouter(Schedule);
