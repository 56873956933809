import BuildIcon from "@material-ui/icons/Build";
import LockIcon from "@material-ui/icons/Lock";
import ChangePasswordView from "./views/ChangePassword/ChangePassword";
import CustomerProjectsView from "./views/CustomerProjects/CustomerProjects";
import NoteIcon from "@material-ui/icons/Note";
import ProjectsView from "./views/Projects/Projects";
import ToolsView from "./views/Tools/Tools";
import ProcessStep from "./views/ProcessSteps/ProcessSteps";
import InjectionReport from "./views/InjectionReport/InjectionReport";
import ListIcon from "@material-ui/icons/List";

const dashboardCustomerRoutes = [
    {
        path: "/project/tools/:id/process/step/:stepNumber/injection-report/:injectionNumber",
        name: "Molds",
        icon: NoteIcon,
        component: InjectionReport,
        isOnMenu: false,
    },
    {
        path: "/project/tools/:id/process/step/:stepNumber",
        name: "Molds",
        icon: NoteIcon,
        component: ProcessStep,
        isOnMenu: false,
    },
    {
        path: "/customer/projects",
        name: "Projects",
        icon: NoteIcon,
        component: CustomerProjectsView,
        isOnMenu: true,
    },
    {
        path: "/customer/gts",
        name: "GMS",
        icon: BuildIcon,
        component: CustomerProjectsView,
        isOnMenu: true,
    },
    {
        path: "/customer/machine-list",
        name: "Machine List",
        icon: ListIcon,
        component: CustomerProjectsView,
        isOnMenu: true,
    },
    {
        path: "/user/change-password",
        name: "Change Password",
        icon: LockIcon,
        component: ChangePasswordView,
        isOnMenu: false,
    },
    {
        path: "/project/tools",
        name: "Molds",
        icon: NoteIcon,
        component: ToolsView,
        isOnMenu: false,
    },
    {
        path: "/project",
        name: "Project",
        icon: NoteIcon,
        component: ProjectsView,
        isOnMenu: false,
    },
];

export default dashboardCustomerRoutes;
