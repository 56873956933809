import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ExpandMoreRounded } from "@material-ui/icons";
import StyledButton from "../../../StyledButton/StyledButton";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { Collapse, Divider, makeStyles, MenuItem, Select } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useEffect, useState } from "react";
import styles from "../../../../assets/jss/material-dashboard-react/components/DFMReviewListStyle";
import WebsiteContext from "../../../../utils/Website";
import RoutingContext from "../../../../utils/RoutingContext";
import AddCommentModal from "../../Common/AddCommentModal";
import ChangeControlModal from "../../Common/ChangeControlModal";
import AddMediaModal from "../../Common/AddMediaModal";
import EditModal from "../../Common/EditModal";
import DeleteModal from "../../Common/DeleteModal";
import Axios from "axios";
import AddNew from "../../Common/AddNew";

const useStyles = makeStyles(styles);

const CustomerFileDiscussions = (props) => {
    const [completedColor, setCompletedColor] = useState("#0DC08A");
    const [underReviewColor, setUnderReviewColor] = useState("#E39600");
    const [needsReviewColor, setNeedsReviewColor] = useState("#C00D0E");
    const [openUpload, setOpenUpload] = useState(false);
    const [openEditor, setOpenEditor] = useState(false);
    const [openAddNew, setOpenAddNew] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [elementToEdit, setElementToEdit] = useState(null);
    const [commentToEdit, setCommentToEdit] = useState(null);
    const [commentEn, setCommentEn] = useState("");
    const [commentDe, setCommentDe] = useState("");
    const [itemToDelete, setItemToDelete] = useState(null);
    const [openChangeControlModal, setOpenChangeControlModal] = useState(false);
    const [openDisagreeModal, setOpenDisagreeModal] = useState(false);
    const [accountNameInitials, setAccountNameInitials] = useState("");
    const { languageData, role } = useContext(WebsiteContext);
    const { stepData } = useContext(RoutingContext);
    const classes = useStyles({
        completedColor: completedColor,
        underReviewColor: underReviewColor,
        needsReviewColor: needsReviewColor,
        role: role,
    });

    const handleDelete = () => {
        const deleteOptions = {
            url: "/file-operations/" + `${props.match.params.id}-mold-design-media` + `/files/${elementToDelete.fileId}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(deleteOptions).then((response) => {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/media/${elementToDelete.fileId}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                setElementToDelete(null);
                props.getData();
            });
        });
    };

    const handleCancelDelete = () => {
        setElementToDelete(null);
        setItemToDelete(null);
    };

    const saveComment = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/comments/${commentToEdit.comment.id}`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: {
                de: commentToEdit.language === "de" ? commentToEdit.editedComment : commentToEdit.comment.text.de,
                en: commentToEdit.language === "en" ? commentToEdit.editedComment : commentToEdit.comment.text.en,
            },
        };
        Axios(options).then((_) => {
            props.getData();
            setCommentToEdit(null);
        });
    };

    const cancelEditComment = () => {
        setCommentToEdit(null);
    };

    useEffect(() => {
        const name = localStorage.getItem("name");
        setAccountNameInitials(
            name
                .match(/\b(\w)/g)
                .join("")
                .substring(0, 2)
        );
    }, []);

    const submitComment = (changeState, de, en, cleanHandler) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/comments`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                de: de,
                en: en,
            },
        };
        Axios(options).then((_) => {
            setCommentDe("");
            setCommentEn("");
            setOpenUpload(false);
            setOpenDisagreeModal(false);
            if (cleanHandler) {
                cleanHandler();
            }
            if (changeState && changeState === true) {
                changeStateHandler(1);
            }
            props.getData();
        });
    };

    const moveMedia = (up, mediaId) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/media/${mediaId}`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                up: up,
            },
        };
        Axios(options).then((_) => {
            props.getData();
        });
    };

    const duplicate = (mediaId) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/media/${mediaId}/duplicate`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                folder: `${props.match.params.id}-mold-design-media`,
            },
        };
        Axios(options).then((_) => {
            props.getData();
        });
    };

    const changeStateHandler = (stateId) => {
        if (role === 1 || role === 0) {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/change-state`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
                data: {
                    state: stateId,
                },
            };
            Axios(options).then((_) => {
                setOpenDisagreeModal(false);
                setOpenUpload(false);
                props.getData();
            });
        }
    };

    const uploadFile = (file) => {
        let filesFormatData = new FormData();
        filesFormatData.append("files", file, file.name);

        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-design-media` + "/files",
            method: "POST",
            data: filesFormatData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            const mediaOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/media`,
                method: "POST",
                headers: {
                    "X-Loading": false,
                },
                data: {
                    fileId: response.data.data.files[0].id,
                    type: 0,
                },
            };
            Axios(mediaOptions).then((response) => {
                setOpenUpload(false);
                props.getData();
            });
        });
    };

    const saveNewMedia = (formData, edit, fileId) => {
        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-design-media` + "/files",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            let mediaOptions;
            if (edit === true) {
                mediaOptions = {
                    url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/media/${fileId}`,
                    method: "PUT",
                    headers: {
                        "X-Loading": false,
                    },
                    data: {
                        newFileId: response.data.data.files[0].id,
                    },
                };
            } else {
                mediaOptions = {
                    url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/media`,
                    method: "POST",
                    headers: {
                        "X-Loading": false,
                    },
                    data: {
                        fileId: response.data.data.files[0].id,
                        type: 0,
                    },
                };
            }
            Axios(mediaOptions).then((response) => {
                setOpenEditor(false);
                setElementToEdit(null);
                setOpenUpload(false);
                props.getData();
            });
        });
    };

    const saveMedia = (formData) => {
        if (!openEditor) {
            const deleteOptions = {
                url: "/file-operations/" + `${props.match.params.id}-mold-design-media` + "/files/" + elementToEdit?.fileId,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(deleteOptions).then((_) => {
                saveNewMedia(formData, true, elementToEdit?.fileId);
            });
        } else {
            saveNewMedia(formData, false, elementToEdit?.fileId);
        }
    };

    const changeCustomerState = (stateId) => {
        if (role === 0) {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/change-state`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
                data: {
                    isForCustomer: true,
                    state: stateId,
                },
            };
            Axios(options).then((_) => {
                props.getData();
            });
        }
    };

    const transferMedia = (mediaId) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/customer-file-discussions/${props.element.id}/media/${mediaId}/transfer`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                folder: `${props.match.params.id}-mold-design-media`,
            },
        };
        Axios(options).then((_) => {
            props.getData();
        });
    };

    return (
        <div>
            <AddCommentModal
                open={openDisagreeModal === true}
                onClose={() => {
                    setOpenUpload(false);
                    setOpenDisagreeModal(false);
                }}
                addComment={submitComment}
            />
            {/*<ChangeControlModal*/}
            {/*    open={openChangeControlModal === true}*/}
            {/*    onClose={() => {*/}
            {/*        setOpenChangeControlModal(false);*/}
            {/*        setOpenUpload("");*/}
            {/*    }}*/}
            {/*    changeControl={changeControl}*/}
            {/*/>*/}
            <AddMediaModal
                openEditor={() => {
                    setOpenEditor(true);
                }}
                open={openUpload === true && openEditor === false && openChangeControlModal === false && openDisagreeModal === false}
                onClose={() => setOpenUpload(false)}
                uploadFile={uploadFile}
            />
            <EditModal
                open={!!(elementToEdit || openEditor)}
                onClose={() => {
                    setOpenEditor(false);
                    setElementToEdit(null);
                    setOpenUpload(false);
                }}
                saveMedia={saveMedia}
                src={
                    openEditor === false
                        ? `${process.env.REACT_APP_URL}file-operations/` + props.match.params.id + "-mold-design-media/files/" + elementToEdit?.fileId
                        : ""
                }
            />
            <DeleteModal open={!!elementToDelete} onCancel={() => handleCancelDelete()} onDelete={handleDelete} />
            <div>
                {props.element.media?.map((mediaElement, index) => (
                    <div key={index} className={classes.mediaElement}>
                        <div className={classes.flexCard}>
                            {(role === 0 || role === 2) && stepData.state.id === 1 && props.element.state !== 0 && (
                                <div className={classes.positionButtons}>
                                    {index > 0 && (
                                        <div className={classes.upDownButton} onClick={() => moveMedia(true, mediaElement.fileId)}>
                                            <ExpandLessIcon color="inherit" />
                                        </div>
                                    )}
                                    {index >= 0 && index < props.element.media?.length && index !== props.element.media?.length - 1 && (
                                        <div className={classes.upDownButton} onClick={() => moveMedia(false, mediaElement.fileId)}>
                                            <ExpandMoreRounded color="inherit" />
                                        </div>
                                    )}
                                </div>
                            )}
                            <img
                                src={`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-mold-design-media/files/${mediaElement.fileId}`}
                                alt={mediaElement.id}
                                className={classes.img}
                            />
                        </div>
                        {(role === 0 || role === 2) && stepData.state.id === 1 && props.element.state !== 0 && (
                            <div className={classes.actions}>
                                <StyledButton
                                    onClick={() => {
                                        setOpenUpload(false);
                                        setElementToEdit(mediaElement);
                                    }}
                                    className={classes.actionButton}
                                    variant="outlined"
                                    startIcon={<EditIcon color="primary" />}
                                >
                                    {languageData?.Edit}
                                </StyledButton>
                                <StyledButton
                                    onClick={() => duplicate(mediaElement.fileId)}
                                    className={classes.actionButton}
                                    variant="outlined"
                                    startIcon={<FileCopyIcon color="primary" />}
                                >
                                    {languageData?.Duplicate}
                                </StyledButton>
                                <StyledButton
                                    onClick={() => setElementToDelete(mediaElement)}
                                    className={classes.actionButton}
                                    variant="outlined"
                                    startIcon={<DeleteIcon color="primary" />}
                                >
                                    {languageData?.Delete}
                                </StyledButton>
                                {role === 0 && (
                                    <StyledButton
                                        onClick={() => transferMedia(mediaElement.fileId)}
                                        className={classes.actionButton}
                                        variant="outlined"
                                        startIcon={<SyncAltIcon color="primary" />}
                                    >
                                        {languageData?.Transfer}
                                    </StyledButton>
                                )}
                            </div>
                        )}
                    </div>
                ))}
                {(role === 0 || role === 2) && stepData.state.id === 1 && props.element.state !== 0 && (
                    <div className={classes.addMediaButton} onClick={() => setOpenUpload(true)}>
                        <AddCircleIcon className={classes.addMediaIcon} color="primary" />
                        {languageData?.AddMedia}
                    </div>
                )}
            </div>
            <Divider className={classes.divider} />
            {(role === 0 || role === 1) && props.element.state !== 0 && props.element.state !== 0 && (
                <div className={classes.acceptButtons}>
                    <StyledButton
                        className={classes.acceptButtonAgree}
                        variant="outlined"
                        onClick={() => (role === 1 ? changeStateHandler(0) : changeCustomerState(0))}
                    >
                        {languageData?.Agree}
                    </StyledButton>
                    <StyledButton
                        className={classes.acceptButtonDisagree}
                        variant="outlined"
                        onClick={() => {
                            if (role === 1) {
                                setOpenDisagreeModal(true);
                                setOpenUpload(true);
                            } else {
                                changeCustomerState(1);
                            }
                        }}
                    >
                        {languageData.Disagree}
                    </StyledButton>
                </div>
            )}
            <Divider className={classes.divider} />
            <div className={classes.comments}>
                {props.element.comments?.map((comment, index) => (
                    <div key={comment.id} className={classes.comment}>
                        <div className={classes.commentAuthor}>
                            <div className={classes.nameIcon}>
                                {comment.owner
                                    .match(/\b(\w)/g)
                                    .join("")
                                    .substring(0, 2)}
                            </div>
                            <div className={classes.name}>{comment.owner}</div>
                            <div className={classes.date}>{comment.date}</div>
                        </div>
                        <div className={classes.commentTranslation}>
                            <div className={classes.language}>EN</div>
                            {commentToEdit?.comment.id === comment.id && commentToEdit?.language === "en" ? (
                                <>
                                    <textarea
                                        onChange={(e) =>
                                            setCommentToEdit({
                                                ...commentToEdit,
                                                editedComment: e.target.value,
                                            })
                                        }
                                        value={commentToEdit.editedComment ? commentToEdit.editedComment : commentToEdit.comment.text.en}
                                        className={classes.textarea + " " + classes.textareaEdit}
                                        placeholder="Add a comment.."
                                    />
                                    <div className={classes.listWrapper}>
                                        <div onClick={() => saveComment()} className={classes.editButton}>
                                            <SaveIcon color="inherit" fontSize="inherit" />
                                        </div>
                                        <div onClick={cancelEditComment} className={classes.editButton + " " + classes.margin}>
                                            <CloseIcon color="inherit" fontSize="inherit" />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={classes.commentDescription}>{comment.text.en}</div>
                                    {role === 0 && stepData.state.id === 1 && props.element.state !== 0 && (
                                        <div
                                            onClick={() =>
                                                setCommentToEdit({
                                                    comment: comment,
                                                    language: "en",
                                                    editedComment: comment.text.en,
                                                })
                                            }
                                            className={classes.editButton}
                                        >
                                            <EditIcon color="inherit" fontSize="inherit" />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className={classes.commentTranslation}>
                            <div className={classes.language}>D</div>
                            {commentToEdit?.comment.id === comment.id && commentToEdit?.language === "de" ? (
                                <>
                                    <textarea
                                        onChange={(e) =>
                                            setCommentToEdit({
                                                ...commentToEdit,
                                                editedComment: e.target.value,
                                            })
                                        }
                                        value={commentToEdit.editedComment ? commentToEdit.editedComment : commentToEdit.comment.text.de}
                                        className={classes.textarea + " " + classes.textareaEdit}
                                        placeholder="Einen Kommentar hinzufügen.."
                                    />
                                    <div className={classes.listWrapper}>
                                        <div onClick={() => saveComment()} className={classes.editButton}>
                                            <SaveIcon color="inherit" fontSize="inherit" />
                                        </div>
                                        <div onClick={cancelEditComment} className={classes.editButton + " " + classes.margin}>
                                            <CloseIcon color="inherit" fontSize="inherit" />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={classes.commentDescription}>{comment.text.de}</div>

                                    {role === 0 && stepData.state.id === 1 && props.element.state !== 0 && (
                                        <div
                                            onClick={() =>
                                                setCommentToEdit({
                                                    comment: comment,
                                                    language: "de",
                                                    editedComment: comment.text.de,
                                                })
                                            }
                                            className={classes.editButton}
                                        >
                                            <EditIcon color="inherit" fontSize="inherit" />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                ))}
                {stepData.state.id === 1 && props.element.state !== 0 && (
                    <div className={classes.flex}>
                        <div className={classes.nameIcon}>{accountNameInitials}</div>
                        <div className={classes.commentArea}>
                            <div className={classes.commentAuthor}>
                                <div className={classes.language}>EN</div>
                                <textarea
                                    value={commentEn}
                                    onChange={(e) => setCommentEn(e.target.value)}
                                    className={classes.textarea}
                                    placeholder="Add a comment.."
                                />
                            </div>
                            <div className={classes.commentAuthor}>
                                <div className={classes.language}>D</div>
                                <textarea
                                    value={commentDe}
                                    onChange={(e) => setCommentDe(e.target.value)}
                                    className={classes.textarea}
                                    placeholder="Einen Kommentar hinzufügen.."
                                />
                            </div>
                            <div className={classes.commentButton}>
                                <StyledButton
                                    disabled={commentEn || commentDe ? false : true}
                                    onClick={() => submitComment(false, commentDe, commentEn)}
                                    className={classes.acceptButtonDisagree}
                                    variant="outlined"
                                >
                                    {languageData?.Comment}
                                </StyledButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default CustomerFileDiscussions;
