import React from "react";
import { Button, withStyles } from "@material-ui/core";

const CustomButton = withStyles((theme) => ({
    root: {
        fontStyle: "normal",
        height: 34,
        boxShadow: "none",
        textTransform: "none",
        fontSize: 12,
        fontWeight: "bold",
        padding: "10.5px auto",
        lineHeight: "14px",
        // backgroundColor: theme.palette.gray.main,
        // borderColor: theme.palette.gray.main,
        color: theme.palette.common.white,
        borderRadius: 2.57,
        // fontFamily: "'Montserrat', sans-serif",
        "&:hover": {
            // backgroundColor: theme.palette.gray.main,
            // borderColor: theme.palette.gray.main,
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            // backgroundColor: theme.palette.gray.main,
            // borderColor: theme.palette.gray.main,
        },
        "&:focus": {
            boxShadow: "none",
            // backgroundColor: theme.palette.gray.main,
            // borderColor: theme.palette.gray.main,
        },
        startIcon: {},
    },
}))(Button);

const StyledButton = (props) => {
    return <CustomButton {...props}>{props.children}</CustomButton>;
};

export default StyledButton;
