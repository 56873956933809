import React, { useCallback, useContext, useEffect, useState } from "react";
import withWidth from "@material-ui/core/withWidth";
import { Card, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/components/gridStyle";
import WebsiteContext from "../../../utils/Website";
import ProjectModal from "./ProjectModal";
import Axios from "axios";
import ProjectCard from "./ProjectCard";
import PropTypes from "prop-types";
import RoutingContext from "../../../utils/RoutingContext";

const useStyles = makeStyles(styles);

const Projects = (props) => {
    const classes = useStyles();
    const { languageData, role } = useContext(WebsiteContext);
    const { setRoutingData } = useContext(RoutingContext);
    const [projects, setProjects] = useState([]);

    const [openModal, setOpenModal] = useState(false);

    const getProjects = useCallback(() => {
        let options = null;
        if (role === 0) {
            options = {
                url: "/customers/" + props.id + "/projects",
                method: "GET",
            };
        } else if (role === 1) {
            options = {
                url: "/projects",
                method: "GET",
            };
        }

        if (options !== null) {
            Axios(options)
                .then((response) => {
                    if (role !== 0) {
                        setRoutingData();
                    }
                    setProjects(response.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [props.id]);

    useEffect(() => {
        getProjects();
    }, [getProjects]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        // getUsers();
    };

    const projectCards = projects.map((project) => {
        return (
            <ProjectCard
                {...props}
                id={project.id}
                key={project.id}
                name={project.name}
                project_number={String(project.project_number)}
                progress={project.customerToolsPercentage}
                statecolor={project.customerToolsState.color}
            />
        );
    });

    return (
        <React.Fragment>
            <div className={role === 0 || role === 1 ? classes.tabCardsWrapper : classes.cardsWrapper}>
                {role === 0 && (
                    <Card classes={{ root: classes.root }}>
                        <div className={classes.cardWrapper}>
                            <div className={classes.addNew}>{languageData?.AddNewProject}</div>
                            <IconButton className={classes.icon} onClick={handleOpenModal}>
                                <AddCircleIcon color="primary" fontSize="inherit" />
                            </IconButton>
                        </div>
                    </Card>
                )}
                {projectCards}
            </div>
            <ProjectModal {...props} addMode={true} open={openModal} onClose={handleCloseModal} getData={getProjects} />
        </React.Fragment>
    );
};

Projects.propTypes = {
    id: PropTypes.string,
};

export default withWidth()(Projects);
