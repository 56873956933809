import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../utils/Website";
import RoutingContext from "../../../utils/RoutingContext";
import styles from "../../../assets/jss/material-dashboard-react/components/DFMReviewListStyle";
import { Collapse, Divider, makeStyles } from "@material-ui/core";
import AddNew from "../Common/AddNew";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddCommentModal from "../Common/AddCommentModal";
import ChangeControlModal from "../Common/ChangeControlModal";
import AddMediaModal from "../Common/AddMediaModal";
import EditModal from "../Common/EditModal";
import DeleteModal from "../Common/DeleteModal";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import StyledButton from "../../StyledButton/StyledButton";
import SendIcon from "@material-ui/icons/Send";
import { ExpandMoreRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(styles);

const Manufacture = (props) => {
    const [completedColor, setCompletedColor] = useState("#0DC08A");
    const [underReviewColor, setUnderReviewColor] = useState("#E39600");
    const [needsReviewColor, setNeedsReviewColor] = useState("#C00D0E");

    const [open, setOpen] = useState(null);
    const [openUpload, setOpenUpload] = useState("");
    const [openEditor, setOpenEditor] = useState(false);
    const [openAddNew, setOpenAddNew] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [elementToEdit, setElementToEdit] = useState(null);
    const [commentToEdit, setCommentToEdit] = useState(null);
    const [commentEn, setCommentEn] = useState("");
    const [commentDe, setCommentDe] = useState("");
    const [itemToDelete, setItemToDelete] = useState(null);
    const [openChangeControlModal, setOpenChangeControlModal] = useState(false);
    const [openDisagreeModal, setOpenDisagreeModal] = useState(false);
    const [accountNameInitials, setAccountNameInitials] = useState("");

    const { languageData, role } = useContext(WebsiteContext);
    const { setRoutingData, setStepData, setBackPath, stepData } = useContext(RoutingContext);

    const classes = useStyles({
        completedColor: completedColor,
        underReviewColor: underReviewColor,
        needsReviewColor: needsReviewColor,
        role: role,
    });

    const handleAddItem = (nameDe, nameEn) => {
        const data = {
            de: nameDe,
            en: nameEn,
        };
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: data,
        };
        Axios(options).then((_) => {
            setOpenAddNew(false);
            props.getItems();
        });
    };

    useEffect(() => {
        const name = localStorage.getItem("name");
        setAccountNameInitials(
            name
                .match(/\b(\w)/g)
                .join("")
                .substring(0, 2)
        );
    }, []);

    const handleDelete = () => {
        const deleteOptions = {
            url: "/file-operations/" + `${props.match.params.id}-manufacture-media` + `/files/${elementToDelete.fileId}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(deleteOptions).then((response) => {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${open}/media/${elementToDelete.fileId}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                setElementToDelete(null);
                props.getItems();
            });
        });
    };

    const handleCancelDelete = () => {
        setElementToDelete(null);
        setItemToDelete(null);
    };

    const saveComment = (id) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${id}/comments/${commentToEdit.comment.id}`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: {
                de: commentToEdit.language === "de" ? commentToEdit.editedComment : commentToEdit.comment.text.de,
                en: commentToEdit.language === "en" ? commentToEdit.editedComment : commentToEdit.comment.text.en,
            },
        };
        Axios(options).then((_) => {
            props.getItems();
            setCommentToEdit(null);
        });
    };

    const cancelEditComment = () => {
        setCommentToEdit(null);
    };

    const submitComment = (de, en) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${open}/comments`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                de: de,
                en: en,
            },
        };
        Axios(options).then((_) => {
            setCommentDe("");
            setCommentEn("");
            props.getItems();
        });
    };

    const deleteListItem = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${itemToDelete}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            props.getItems();
            setItemToDelete(null);
        });
    };

    const moveMedia = (up, id, mediaId) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${id}/media/${mediaId}`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                up: up,
            },
        };
        Axios(options).then((_) => {
            props.getItems();
        });
    };

    const duplicate = (itemId, mediaId) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${itemId}/media/${mediaId}/duplicate`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                folder: `${props.match.params.id}-manufacture-media`,
            },
        };
        Axios(options).then((_) => {
            props.getItems();
        });
    };

    const uploadFile = (file) => {
        let filesFormatData = new FormData();
        filesFormatData.append("files", file, file.name);

        const options = {
            url: "/file-operations/" + `${props.match.params.id}-manufacture-media` + "/files",
            method: "POST",
            data: filesFormatData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            const mediaOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${openUpload}/media`,
                method: "POST",
                headers: {
                    "X-Loading": false,
                },
                data: {
                    fileId: response.data.data.files[0].id,
                    type: 0,
                },
            };
            Axios(mediaOptions).then((response) => {
                setOpenUpload("");
                props.getItems();
            });
        });
    };

    const saveNewMedia = (formData, edit, fileId) => {
        const options = {
            url: "/file-operations/" + `${props.match.params.id}-manufacture-media` + "/files",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            let mediaOptions;
            if (edit === true) {
                mediaOptions = {
                    url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${openUpload}/media/${fileId}`,
                    method: "PUT",
                    headers: {
                        "X-Loading": false,
                    },
                    data: {
                        newFileId: response.data.data.files[0].id,
                    },
                };
            } else {
                mediaOptions = {
                    url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items/${openUpload}/media`,
                    method: "POST",
                    headers: {
                        "X-Loading": false,
                    },
                    data: {
                        fileId: response.data.data.files[0].id,
                        type: 0,
                    },
                };
            }
            Axios(mediaOptions).then((response) => {
                setOpenEditor(false);
                setElementToEdit(null);
                setOpenUpload("");
                props.getItems();
            });
        });
    };

    const saveMedia = (formData) => {
        if (!openEditor) {
            const deleteOptions = {
                url: "/file-operations/" + `${props.match.params.id}-manufacture-media` + "/files/" + elementToEdit?.fileId,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(deleteOptions).then((_) => {
                saveNewMedia(formData, true, elementToEdit?.fileId);
            });
        } else {
            saveNewMedia(formData, false, elementToEdit?.fileId);
        }
    };

    return (
        <div className={classes.listWrapper + " " + classes.padding}>
            <div className={classes.innerWrapper}>
                <AddNew handleAdd={handleAddItem} open={openAddNew} onClose={() => setOpenAddNew(false)} />
                <AddMediaModal
                    openEditor={() => {
                        setOpenEditor(true);
                    }}
                    open={openUpload !== "" && openEditor === false && openChangeControlModal === false && openDisagreeModal === false}
                    onClose={() => setOpenUpload("")}
                    uploadFile={uploadFile}
                />
                <EditModal
                    open={!!(elementToEdit || openEditor)}
                    onClose={() => {
                        setOpenEditor(false);
                        setElementToEdit(null);
                        setOpenUpload("");
                    }}
                    saveMedia={saveMedia}
                    src={
                        openEditor === false
                            ? `${process.env.REACT_APP_URL}file-operations/` + props.match.params.id + "-manufacture-media/files/" + elementToEdit?.fileId
                            : ""
                    }
                />
                <DeleteModal
                    open={!!(elementToDelete || itemToDelete)}
                    onCancel={() => handleCancelDelete()}
                    onDelete={itemToDelete ? deleteListItem : handleDelete}
                />
                {props.items?.map((element) => (
                    <div key={element.id} className={classes.listElement}>
                        <div className={classes.title}>
                            <div className={classes.stepName}>{element.name}</div>
                            <div className={classes.status}>
                                {/* {element.state === 0 ? (
                                <span className={classes.needsReview}>needs review</span>
                            ) : element.state === 1 ? (
                                <span className={classes.underReview}>under review</span>
                            ) : null} */}
                                {element.canDelete && role === 0 && (
                                    <DeleteIcon onClick={() => setItemToDelete(element.id)} className={classes.pointerIcons + " " + classes.textareaEdit} />
                                )}
                                {open !== element.id ? (
                                    <ExpandMoreIcon
                                        className={classes.pointerIcons}
                                        onClick={() => {
                                            setCommentDe("");
                                            setCommentEn("");
                                            setOpen(open === element.id ? null : element.id);
                                        }}
                                    />
                                ) : (
                                    <ExpandLessIcon
                                        className={classes.pointerIcons}
                                        onClick={() => {
                                            setOpen(open === element.id ? null : element.id);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <Collapse in={open === element.id}>
                            <div className={classes.content}>
                                {element.media?.map((mediaElement, index) => (
                                    <div key={index} className={classes.mediaElement}>
                                        <div className={classes.flexCard}>
                                            {(role === 0 || role === 2) && stepData.state.id === 1 && (
                                                <div className={classes.positionButtons}>
                                                    {index > 0 && (
                                                        <div className={classes.upDownButton} onClick={() => moveMedia(true, element.id, mediaElement.fileId)}>
                                                            <ExpandLessIcon color="inherit" />
                                                        </div>
                                                    )}
                                                    {index >= 0 && index < element.media?.length && index !== element.media?.length - 1 && (
                                                        <div className={classes.upDownButton} onClick={() => moveMedia(false, element.id, mediaElement.fileId)}>
                                                            <ExpandMoreRounded color="inherit" />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <img
                                                src={`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-manufacture-media/files/${mediaElement.fileId}`}
                                                alt={mediaElement.id}
                                                className={classes.img}
                                            />
                                        </div>
                                        {(role === 0 || role === 2) && stepData.state.id === 1 && (
                                            <div className={classes.actions}>
                                                <StyledButton
                                                    onClick={() => {
                                                        setOpenUpload(element.id);
                                                        setElementToEdit(mediaElement);
                                                    }}
                                                    className={classes.actionButton}
                                                    variant="outlined"
                                                    startIcon={<EditIcon color="primary" />}
                                                >
                                                    {languageData?.Edit}
                                                </StyledButton>
                                                <StyledButton
                                                    onClick={() => duplicate(element.id, mediaElement.fileId)}
                                                    className={classes.actionButton}
                                                    variant="outlined"
                                                    startIcon={<FileCopyIcon color="primary" />}
                                                >
                                                    {languageData?.Duplicate}
                                                </StyledButton>
                                                <StyledButton
                                                    onClick={() => setElementToDelete(mediaElement)}
                                                    className={classes.actionButton}
                                                    variant="outlined"
                                                    startIcon={<DeleteIcon color="primary" />}
                                                >
                                                    {languageData?.Delete}
                                                </StyledButton>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {(role === 0 || role === 2) && stepData.state.id === 1 && (
                                    <div className={classes.addMediaButton} onClick={() => setOpenUpload(element.id)}>
                                        <AddCircleIcon className={classes.addMediaIcon} color="primary" />
                                        {languageData?.AddMedia}
                                    </div>
                                )}
                            </div>
                            <Divider className={classes.divider} />
                            <Divider className={classes.divider} />
                            <div className={classes.comments}>
                                {element.comments?.map((comment, index) => (
                                    <div key={comment.id} className={classes.comment}>
                                        <div className={classes.commentAuthor}>
                                            <div className={classes.nameIcon}>
                                                {comment.owner
                                                    .match(/\b(\w)/g)
                                                    .join("")
                                                    .substring(0, 2)}
                                            </div>
                                            <div className={classes.name}>{comment.owner}</div>
                                            <div className={classes.date}>{comment.date}</div>
                                        </div>
                                        <div className={classes.commentTranslation}>
                                            <div className={classes.language}>EN</div>
                                            {commentToEdit?.comment.id === comment.id && commentToEdit?.language === "en" ? (
                                                <>
                                                    <textarea
                                                        onChange={(e) =>
                                                            setCommentToEdit({
                                                                ...commentToEdit,
                                                                editedComment: e.target.value,
                                                            })
                                                        }
                                                        value={commentToEdit.editedComment ? commentToEdit.editedComment : commentToEdit.comment.text.en}
                                                        className={classes.textarea + " " + classes.textareaEdit}
                                                        placeholder="Add a comment.."
                                                    />
                                                    <div className={classes.listWrapper}>
                                                        <div onClick={() => saveComment(element.id)} className={classes.editButton}>
                                                            <SaveIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                        <div onClick={cancelEditComment} className={classes.editButton + " " + classes.margin}>
                                                            <CloseIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={classes.commentDescription}>{comment.text.en}</div>
                                                    {role === 0 && stepData.state.id === 1 && (
                                                        <div
                                                            onClick={() =>
                                                                setCommentToEdit({
                                                                    comment: comment,
                                                                    language: "en",
                                                                    editedComment: comment.text.en,
                                                                })
                                                            }
                                                            className={classes.editButton}
                                                        >
                                                            <EditIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className={classes.commentTranslation}>
                                            <div className={classes.language}>D</div>
                                            {commentToEdit?.comment.id === comment.id && commentToEdit?.language === "de" ? (
                                                <>
                                                    <textarea
                                                        onChange={(e) =>
                                                            setCommentToEdit({
                                                                ...commentToEdit,
                                                                editedComment: e.target.value,
                                                            })
                                                        }
                                                        value={commentToEdit.editedComment ? commentToEdit.editedComment : commentToEdit.comment.text.de}
                                                        className={classes.textarea + " " + classes.textareaEdit}
                                                        placeholder="Einen Kommentar hinzufügen.."
                                                    />
                                                    <div className={classes.listWrapper}>
                                                        <div onClick={() => saveComment(element.id)} className={classes.editButton}>
                                                            <SaveIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                        <div onClick={cancelEditComment} className={classes.editButton + " " + classes.margin}>
                                                            <CloseIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={classes.commentDescription}>{comment.text.de}</div>
                                                    {role === 0 && stepData.state.id === 1 && (
                                                        <div
                                                            onClick={() =>
                                                                setCommentToEdit({
                                                                    comment: comment,
                                                                    language: "de",
                                                                    editedComment: comment.text.de,
                                                                })
                                                            }
                                                            className={classes.editButton}
                                                        >
                                                            <EditIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {stepData?.state?.id !== 2 && (role === 0 || role === 2) && (
                                    <div className={classes.flex}>
                                        <div className={classes.nameIcon}>{accountNameInitials}</div>
                                        <div className={classes.commentArea}>
                                            <div className={classes.commentAuthor}>
                                                <div className={classes.language}>EN</div>
                                                <textarea
                                                    value={commentEn}
                                                    onChange={(e) => setCommentEn(e.target.value)}
                                                    className={classes.textarea}
                                                    placeholder="Add a comment.."
                                                />
                                            </div>
                                            <div className={classes.commentAuthor}>
                                                <div className={classes.language}>D</div>
                                                <textarea
                                                    value={commentDe}
                                                    onChange={(e) => setCommentDe(e.target.value)}
                                                    className={classes.textarea}
                                                    placeholder="Einen Kommentar hinzufügen.."
                                                />
                                            </div>
                                            <div className={classes.commentButton}>
                                                <StyledButton
                                                    disabled={commentEn || commentDe ? false : true}
                                                    onClick={() => submitComment(commentDe, commentEn)}
                                                    className={classes.acceptButtonDisagree}
                                                    variant="outlined"
                                                >
                                                    {languageData?.Comment}
                                                </StyledButton>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Collapse>
                    </div>
                ))}
                {(role === 0 || role === 2) && stepData?.state?.id !== 2 && (
                    <div className={classes.listElement + " " + classes.addNewItem}>
                        <div className={classes.title} onClick={() => setOpenAddNew(true)}>
                            <div className={classes.stepName}>
                                <div className={classes.addNew}>
                                    <AddCircleIcon color="inherit" />
                                </div>
                                {languageData?.AddNew}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Manufacture;
