import React, { useContext } from "react";
import WebsiteContext from "../../../../utils/Website";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: "8px",
    },
    subtitleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginBottom: 4,
    },
    gridSpacing: {
        padding: 7,
        margin: "0 !important",
    },
    selectWrapper: {
        width: "210px",
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    selectWithLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginRight: "10px",
        marginBottom: "4px",
    },
}));

const Contacts = (props) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);
    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Contacts.toUpperCase()}</div>
            <div className={classes.contentWrapper}>
                <div className={classes.selectWithLabel}>
                    <InputLabel required className={classes.subtitleWrapper}>
                        {languageData?.ContactPersonCustomer}
                    </InputLabel>
                    <FormControl>
                        <Select
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={props.selectedContacts?.customerId && props.selectedContacts?.customerId !== null ? props.selectedContacts.customerId : ""}
                            displayEmpty={props.selectedContacts?.customerId === null}
                            renderValue={
                                props.selectedContacts?.customerId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateContacts("contacts", "customerId", e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props.existingContacts?.customer_users?.map((customerUser) => {
                                return (
                                    <MenuItem value={customerUser.id} key={customerUser.id}>
                                        <div className={classes.selectTextWrapper}>
                                            {customerUser.name + ", " + customerUser.role + ", " + customerUser.email + ", " + customerUser.phone_number}
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.selectWithLabel}>
                    <InputLabel required className={classes.subtitleWrapper}>
                        {languageData?.ContactPersonSws}
                    </InputLabel>
                    <FormControl>
                        <Select
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={props.selectedContacts?.swsId && props.selectedContacts?.swsId !== null ? props.selectedContacts.swsId : ""}
                            displayEmpty={props.selectedContacts?.swsId === null}
                            renderValue={
                                props.selectedContacts?.swsId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => props.updateContacts("contacts", "swsId", e.target.value)}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props.existingContacts?.admin_users?.map((swsUser) => {
                                return (
                                    <MenuItem value={swsUser.id} key={swsUser.id}>
                                        <div className={classes.selectTextWrapper}>{swsUser.name + ", " + swsUser.email + ", " + swsUser.phone_number}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.selectWithLabel}>
                    <InputLabel required className={classes.subtitleWrapper}>
                        {languageData?.ContactPersonMoldMaker}
                    </InputLabel>
                    <FormControl>
                        <Select
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={
                                props.selectedContacts?.moldMakerId && props.selectedContacts?.moldMakerId !== null ? props.selectedContacts.moldMakerId : ""
                            }
                            displayEmpty={props.selectedContacts?.moldMakerId === null}
                            renderValue={
                                props.selectedContacts?.moldMakerId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => props.updateContacts("contacts", "moldMakerId", e.target.value)}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props.existingContacts?.tool_maker_users?.map((moldMakerUser) => {
                                return (
                                    <MenuItem value={moldMakerUser.id} key={moldMakerUser.id}>
                                        <div className={classes.selectTextWrapper}>
                                            {moldMakerUser.name + ", " + moldMakerUser.email + ", " + moldMakerUser.phone_number}
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

Contacts.propTypes = {
    existingContacts: PropTypes.any,
    updateContacts: PropTypes.func,
    selectedContacts: PropTypes.any,
};

export default Contacts;
