import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const RichTextEditor = ({ content, onChange, ...props }) => {
    return (
        <Editor
            apiKey="e3aujxpjltac5j2a2oz2anruyoxbxbs6v857t5gloswsmby7"
            value={content}
            init={{
                plugins: [
                    "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
                    "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
                    "save table directionality emoticons template paste autoresize",
                ],
                toolbar:
                    "undo redo | styleselect | fontselect fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor",
                toolbar_sticky: true,
                branding: false,
                width: "100%",
                height: "500px",
                // images_upload_url: `${Axios.defaults.baseURL}ImageProcessor/SaveImage`,
                images_upload_handler: function (blobInfo, success, failure) {
                    const data = blobInfo.base64();
                    success(`data:image/png;base64,${data}`);
                    failure("Nu stiu ce s-a intamplat");
                },
                contextmenu: "bold italic underline | link linkchecker image imagetools table spellchecker ",
            }}
            onEditorChange={(content) => onChange(content)}
        />
    );
};

export default RichTextEditor;
