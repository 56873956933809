import { Button, GridList, GridListTile, IconButton, makeStyles } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Axios from "axios";
import StyledButton from "../../../StyledButton/StyledButton";

const useStyles = makeStyles((theme) => ({
    inputs: {
        display: "flex",
        flexDirection: "column",
    },
    addButton: {
        width: "fit-content",
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },

    ulWrapper: {
        listStyleType: "disc",
    },
    liWrapper: {
        "&::marker": {
            color: theme.palette.primary.main,
        },
    },
    textWrapper: {
        display: "inline",
        marginRight: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    filenameWrapper: {
        display: "inline",
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    filenameEditModeWrapper: {
        marginLeft: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
        height: "15p",
        lineHeight: "15px",
        textOverflow: "ellipsis",
    },
    uploadFileButton: {
        backgroundColor: "white",
        margin: "6px 10px 7px 0px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    inputClass: {
        width: "210px",
    },
    collapseExpandAll: {
        backgroundColor: "#F8F8F8",
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        justifyContent: "left",
        height: 28.48,
        width: 150,
        padding: "0 10px",
        margin: "5px",
    },
    expandIcon: {
        margin: "0 8px 0 0",
        // fontFamily: "Montserrat",
        // fontWeight: "600"
    },
    gtsForm: {
        background: "#ffffff",
        width: "100%",
        height: "100%",
        borderRadius: "3px",
        // padding: "24px -24px 24px 24px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
    },
    layoutWrapper: {
        height: "100%",
        paddingBottom: 20,
    },
    form: {
        height: "calc(100% - 77px)",
        paddingTop: 18,
        paddingBottom: -18,
        paddingLeft: 18,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down(680)]: {
            height: "calc(100% - 115px)",
        },
    },
    gtsButtons: {
        background: "#ffffff",
        float: "right",
        borderRadius: "3px",
        display: "flex",
        flexDirection: "column",
        padding: 24,
        width: 150,
    },
    editSwitch: {
        display: "block",
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    treeItemStyle: {
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    listItemStyle: {
        height: "auto",
    },
    root: {
        padding: 0,
    },
    content: {
        padding: "0 18px 18px 18px",
    },
    sectionTitle: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        margin: "18px 0 18px 0",
    },
    buttonWrapper: {
        padding: "18px 18px 18px 0",
        background: "white",
    },
}));

const MoldDesignFiles = (props) => {
    const { languageData, role } = useContext(WebsiteContext);
    const [files, setFiles] = useState([]);

    const [filesToDelete, setFilesToDelete] = useState([]);

    const classes = useStyles(useStyles);

    useEffect(() => {
        setFiles(
            props.data?.map((el) => {
                return {
                    ...el,
                    file: null,
                };
            })
        );
    }, [props.data]);

    const uploadFile = (file, index) => {
        const tmpFiles = [...files];

        if (tmpFiles[index].fileId !== "") {
            const tmpFilesToDelete = [...filesToDelete];
            tmpFilesToDelete.push(tmpFiles[index].fileId);
            setFilesToDelete(tmpFilesToDelete);
        }

        tmpFiles[index].file = file;
        tmpFiles[index].fileName = file.name;

        setFiles(tmpFiles);
    };

    const removeInput = (fileId, index) => {
        const tmpFiles = [...files];
        tmpFiles.splice(index, 1);

        setFiles(tmpFiles);

        if (fileId !== "") {
            const tmpFilesToDelete = [...filesToDelete];
            tmpFilesToDelete.push(fileId);
            setFilesToDelete(tmpFilesToDelete);
        }
    };

    const addInput = () => {
        const tmpData = [...files];
        tmpData.push({
            fileName: "",
            fileId: "",
            file: null,
        });
        setFiles(tmpData);
    };

    const handleClickFilename = (fileId) => {
        window.open(`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-mold-design-media/files/${fileId}`);
    };

    const handleSubmit = async () => {
        const clone = [...files];
        if (filesToDelete.length > 0) {
            const options = {
                url: "/file-operations/" + `${props.match.params.id}-mold-design-media/files`,
                method: "DELETE",
                headers: { "X-Files": [...filesToDelete], "X-Loading": false },
            };
            await Axios(options);
        }

        for (const [index, file] of files.entries()) {
            if (file.file !== null) {
                let formData = new FormData();
                formData.append("files", file.file, file.fileName);
                const options = {
                    url: "/file-operations/" + props.match.params.id + `-mold-design-media` + "/files",
                    method: "POST",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                };
                let response = await Axios(options);
                clone[index].fileId = response.data.data.files[0].id;
            }
        }

        const options = {
            url: "/business-logic/tools/" + props.match.params.id + `/processes/mold-design/extra-uploads`,
            method: "PUT",
            data: clone.map((el) => {
                return {
                    fileId: el.fileId,
                    fileName: el.fileName,
                };
            }),
            headers: { "X-Loading": false },
        };
        await Axios(options);
    };

    return (
        <div>
            {(role === 0 || role === 1 || role === 2) && (
                <div className={classes.buttonWrapper}>
                    <StyledButton
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit().then((r) => {
                                props.getMoldDesignData();
                            });
                        }}
                    >
                        {languageData?.Save.toUpperCase()}
                    </StyledButton>
                </div>
            )}
            {files?.length > 0 &&
                files?.map((el, index) => {
                    return (
                        <GridList key={index}>
                            {(role === 0 || role === 1 || role === 2) && (
                                <GridListTile style={{ height: "auto", width: "auto" }}>
                                    {(role === 0 || el.fileName === "") && (
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className={classes.uploadFileButton}
                                            startIcon={<CloudUploadIcon color="primary" />}
                                        >
                                            {el.fileName !== "" ? languageData?.ChangeFile : languageData?.UploadFile}
                                            <input
                                                type="file"
                                                onChange={(e) => uploadFile(e.target.files[0], index)}
                                                onClick={(e) => {
                                                    e.target.value = "";
                                                }}
                                                style={{ display: "none" }}
                                            />
                                        </Button>
                                    )}
                                </GridListTile>
                            )}
                            <GridListTile style={{ height: "auto" }}>
                                <GridList cols={12} style={{ height: "auto", alignItems: "center", flex: 1 }}>
                                    <GridListTile cols={8} style={{ height: "auto" }}>
                                        {el.fileName !== "" && (
                                            <div
                                                className={el.fileId !== "" && el.file === null ? classes.filenameWrapper : classes.filenameEditModeWrapper}
                                                onClick={() => el.fileId !== "" && el.file === null && handleClickFilename(el.fileId)}
                                            >
                                                {el.fileName.length > 15 ? el.fileName.slice(0, 15) + "..." : el.fileName}
                                            </div>
                                        )}
                                    </GridListTile>
                                    {role === 0 && (
                                        <GridListTile cols={4} style={{ height: "50px", width: "50px" }}>
                                            <IconButton className={classes.addButton} onClick={() => removeInput(el.fileId, index)}>
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        </GridListTile>
                                    )}
                                </GridList>
                            </GridListTile>
                        </GridList>
                    );
                })}
            {(role === 0 || role === 1 || role === 2) && (
                <IconButton classes={{ root: classes.root }} color="primary" className={classes.addButton} onClick={() => addInput()}>
                    <AddCircleIcon />
                </IconButton>
            )}
        </div>
    );
};

export default MoldDesignFiles;
