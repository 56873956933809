import React from "react";
import { Helmet } from "react-helmet";
import Client from "../../components/Clients/Client";

const ClientView = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Client</title>
            </Helmet>
            <Client />
        </React.Fragment>
    );
};

export default ClientView;
