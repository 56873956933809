import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../utils/Website";
import RoutingContext from "../../../utils/RoutingContext";
import Axios from "axios";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link, Route, Switch } from "react-router-dom";
import Info from "../../Tools/Info/Info";
import ToolSchedule from "../../Tools/Schedule/Schedule";
import ToolSpecification from "../../Tools/ToolSpecification/ToolSpecification";
import Process from "../../Tools/Process/Process";
import Manufacture from "./Manufacture";
import { makeStyles } from "@material-ui/core";
import { withRouter } from "react-router";

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    padding: {
        padding: 9,
        height: "calc(100% - 67px)",
    },
}));

const ManufactureStep = (props) => {
    const classes = useStyles(useStyles);
    const [projectId, setProjectId] = useState(null);
    const [tool, setTool] = useState(null);
    const { languageData, role, language } = useContext(WebsiteContext);
    const { setRoutingData, setStepData, setBackPath, stepData } = useContext(RoutingContext);

    const [toolMakerId, setToolMakerId] = useState(null);
    const [customerId, setCustomerId] = useState(null);

    const [items, setItems] = useState([]);

    const getItems = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}`,
            method: "GET",
            headers: {
                "X-Loading": false,
                "X-Locale": language,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data.processes;
            setRoutingData();
            setStepData({
                visibleContent: true,
                id: props.match.params.stepNumber,
                toolId: props.match.params.id,
                name: data.name,
                state: data.state,
                canExport: false,
                canClose: data.canClose,
                states: response.data.data.state_resources,
                closeStep,
            });
            setBackPath(
                role === 0
                    ? `/customers/projects/tools/${props.match.params.id}/process`
                    : role === 1
                    ? `/project/tools/${props.match.params.id}/process`
                    : `/tool/${props.match.params.id}/process`
            );
            const manufactureOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/manufacture/items`,
                method: "GET",
                headers: {
                    "X-Locale": language,
                },
            };
            Axios(manufactureOptions).then((rsp) => {
                const manufactureData = rsp.data.data;
                setItems(manufactureData);
            });
        });
    };

    const closeStep = (state) => {
        if (state === 2) {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}/close`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                getItems();
            });
        } else {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}/start`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                getItems();
            });
        }
    };

    useEffect(() => {
        getItems();
    }, [language]);

    return (
        <React.Fragment>
            <Divider />
            {/* <Divider /> */}
            <Tabs value={props.location.pathname} className={classes.wrapper} variant="scrollable" indicatorColor={"primary"} aria-label="simple tabs example">
                <Tab
                    label={languageData?.Manufacturing}
                    component={Link}
                    to={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/manufacturing`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/manufacturing`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/manufacturing`
                    }
                    value={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/manufacturing`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/manufacturing`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/manufacturing`
                    }
                />
                {(role === 0 || role === 1) && (
                    <Tab
                        label={role === 0 ? languageData?.CustomerSchedule : languageData.Schedule}
                        component={Link}
                        to={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/schedule/customer`
                                : `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/schedule`
                        }
                        value={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/schedule/customer`
                                : `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/schedule`
                        }
                    />
                )}
                {(role === 0 || role === 2) && (
                    <Tab
                        label={role === 0 ? languageData?.MoldMakerSchedule : languageData.Schedule}
                        component={Link}
                        to={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/schedule/mold-maker`
                                : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/schedule`
                        }
                        value={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/schedule/mold-maker`
                                : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/schedule`
                        }
                    />
                )}
            </Tabs>
            <div className={classes.padding}>
                <Switch>
                    <Route
                        path={
                            role === 0
                                ? `/customers/projects/tools/:id/process/step/:stepNumber/manufacturing`
                                : role === 1
                                ? `/project/tools/:id/process/step/:stepNumber/manufacturing`
                                : `/tool/:id/process/step/:stepNumber/manufacturing`
                        }
                        render={(props) => <Manufacture getItems={getItems} items={items} {...props} />}
                    />
                    {(role === 0 || role === 1) && (
                        <Route
                            path={
                                role === 0
                                    ? `/customers/projects/tools/:id/process/step/:stepNumber/schedule/customer`
                                    : `/project/tools/:id/process/step/:stepNumber/schedule`
                            }
                            render={(props) => <ToolSchedule projectId={projectId} disableProjSchedule={true} isCustomerSchedule={true} {...props} />}
                        />
                    )}
                    {(role === 0 || role === 2) && (
                        <Route
                            path={
                                role === 0
                                    ? `/customers/projects/tools/:id/process/step/:stepNumber/schedule/mold-maker`
                                    : `/tool/:id/process/step/:stepNumber/schedule`
                            }
                            render={(props) => <ToolSchedule projectId={projectId} disableProjSchedule={true} isCustomerSchedule={false} {...props} />}
                        />
                    )}
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ManufactureStep);
