import React, { useCallback, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WebsiteContext from "../../utils/Website";
import TreelikeForm from "../TreelikeForm/TreelikeForm";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Users from "./Users/Users";
import MachineList from "./MachineList/MachineList";
import Axios from "axios";
import Projects from "./Projects/Projects";
import { Link, Route, Switch } from "react-router-dom";
import RoutingContext from "../../utils/RoutingContext";

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    tabStyle: {
        padding: "16px",
        overflowX: "hidden",
    },
    boxStyle: {
        padding: 0,
    },
    padding: {
        height: "calc(100% - 85px)",
        padding: 18,
    },
}));

const Client = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const { setCustomerData, setProjectData, setRoutingData, setToolData, setEntityPath, setBackPath } = useContext(RoutingContext);

    const getClient = useCallback(() => {
        const options = {
            url: `/customers/${props.match.params.id}`,
            method: "GET",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setRoutingData();
            setCustomerData({
                visibleContent: true,
                name: data.name,
                logo: data.logo,
                address: data.address,
            });
            setEntityPath(`/customers/${props.match.params.id}`);
            setBackPath("/customers");
        });
    }, [props.match.path]);

    useEffect(() => {
        getClient();
    }, [getClient]);

    return (
        <React.Fragment>
            <Divider />
            {/* <Divider /> */}
            <Tabs value={props.location.pathname} className={classes.wrapper} variant="scrollable" indicatorColor={"primary"} aria-label="simple tabs example">
                <Tab
                    label={languageData.Projects}
                    component={Link}
                    to={`/customers/${props.match.params.id}/projects`}
                    value={`/customers/${props.match.params.id}/projects`}
                />
                <Tab
                    label={languageData.GeneralToolStandard}
                    component={Link}
                    to={`/customers/${props.match.params.id}/gts`}
                    value={`/customers/${props.match.params.id}/gts`}
                />
                <Tab
                    label={languageData.MachineList}
                    component={Link}
                    to={`/customers/${props.match.params.id}/machine-list`}
                    value={`/customers/${props.match.params.id}/machine-list`}
                />
                <Tab
                    label={languageData.Users}
                    component={Link}
                    to={`/customers/${props.match.params.id}/users`}
                    value={`/customers/${props.match.params.id}/users`}
                />
            </Tabs>
            <div className={classes.padding}>
                <Switch>
                    <Route path={`/customers/:id/projects`} render={(props) => <Projects {...props} id={props.match.params.id} />} />
                    <Route path={`/customers/:id/gts`} render={(props) => <TreelikeForm {...props} />} />
                    <Route path={`/customers/:id/machine-list`} render={(props) => <MachineList {...props} id={props.match.params.id} />} />
                    <Route path={`/customers/:id/users`} render={(props) => <Users {...props} id={props.match.params.id} />} />
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Client);
