import { Collapse, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../utils/Website";
import RoutingContext from "../../../utils/RoutingContext";
import styles from "../../../assets/jss/material-dashboard-react/components/DFMReviewListStyle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FileVersions from "./FileVersions/FileVersions";
import FileDiscussions from "./FileDiscussions/CustomerFileDiscussions";
import Axios from "axios";
import CustomerFileDiscussions from "./FileDiscussions/CustomerFileDiscussions";
import MoldMakerFileDiscussions from "./FileDiscussions/MoldMakerFileDiscussions";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddNew from "../Common/AddNew";
import AddNewDiscussion from "../Common/AddNewDiscussion";
import ExtraFiles from "../DFM/DFMReviewList/ExtraFiles/ExtraFiles";
import MoldDesignFiles from "./MoldDesignFiles/MoldDesignFiles";

const useStyles = makeStyles(styles);

const MoldDesign = (props) => {
    const [completedColor, setCompletedColor] = useState("#0DC08A");
    const [underReviewColor, setUnderReviewColor] = useState("#E39600");
    const [needsReviewColor, setNeedsReviewColor] = useState("#C00D0E");

    const classes = useStyles({
        completedColor: completedColor,
        underReviewColor: underReviewColor,
        needsReviewColor: needsReviewColor,
        role: role,
    });

    const { languageData, role, language } = useContext(WebsiteContext);
    const { setRoutingData, setStepData, setBackPath, stepData } = useContext(RoutingContext);
    const [fileVersions, setFileVersions] = useState([]);
    const [customerFileDiscussions, setCustomerFileDiscussion] = useState([]);
    const [moldMakerFileDiscussion, setMoldMakerFileDiscussion] = useState([]);
    const [openAddNew, setOpenAddNew] = useState(false);
    const [files, setFiles] = useState([]);

    const [open, setOpen] = useState(null);

    useEffect(() => {
        getData();
    }, [language]);

    const getData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}`,
            method: "GET",
            headers: {
                "X-Loading": false,
                "X-Locale": language,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data.processes;
            setRoutingData();
            setStepData({
                visibleContent: true,
                id: props.match.params.stepNumber,
                toolId: props.match.params.id,
                name: data.name,
                state: data.state,
                canExport: false,
                canClose: data.canClose,
                exportFileId: data.exportFileId,
                states: response.data.data.state_resources,
                closeStep,
            });
            setBackPath(
                role === 0
                    ? `/customers/projects/tools/${props.match.params.id}/process`
                    : role === 1
                    ? `/project/tools/${props.match.params.id}/process`
                    : `/tool/${props.match.params.id}/process`
            );
            const moldDesignOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-design`,
                method: "GET",
                headers: {
                    "X-Locale": language,
                },
            };
            Axios(moldDesignOptions).then((rsp) => {
                const moldDesignData = rsp.data.data;
                setFileVersions(moldDesignData.fileVersions);
                setCustomerFileDiscussion(moldDesignData.customerFileDiscussions);
                setMoldMakerFileDiscussion(moldDesignData.moldMakerFileDiscussions);
                setFiles(moldDesignData.extraUploads);
            });
        });
    };

    const getMoldDesignFileVersions = () => {
        const moldDesignOptions = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/file-version`,
            method: "GET",
        };
        Axios(moldDesignOptions).then((rsp) => {
            const moldDesignData = rsp.data.data;
            setFileVersions(moldDesignData);
        });
    };

    const closeStep = (state) => {
        if (state === 2) {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}/close`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                getData();
            });
        } else {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}/start`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                getData();
            });
        }
    };

    const handleAddDiscussion = (nameDe, nameEn, isMoldMaker) => {
        const options = {
            url:
                `/business-logic/tools/${props.match.params.id}/processes/mold-design/` +
                (isMoldMaker === true ? "mold-maker-file-discussions" : "customer-file-discussions"),
            method: "POST",
            data: {
                de: nameDe,
                en: nameEn,
            },
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            setOpenAddNew(false);
            getData();
        });
    };

    return (
        <div>
            <AddNewDiscussion handleAdd={handleAddDiscussion} open={openAddNew} onClose={() => setOpenAddNew(false)} />
            <div className={classes.listWrapper + " " + classes.padding}>
                <div className={classes.innerWrapper}>
                    <div className={classes.listElement}>
                        <div className={classes.title}>
                            <div className={classes.stepName}>
                                <div className={classes.emptyState}></div>
                                {languageData?.FileVersions}
                            </div>
                            <div className={classes.status}>
                                {fileVersions.hasControl && <span className={classes.needsReview}>{languageData?.NeedsReview}</span>}
                                {open !== 0 ? (
                                    <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === 0 ? null : 0)} />
                                ) : (
                                    <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === 0 ? null : 0)} />
                                )}
                            </div>
                        </div>
                        <Collapse in={open === 0}>
                            <div className={classes.content}>
                                <FileVersions {...props} fileVersions={fileVersions} getData={getMoldDesignFileVersions} />
                            </div>
                        </Collapse>
                    </div>
                    {(role === 0 || role === 2) &&
                        moldMakerFileDiscussion.map((fd) => {
                            return (
                                <div className={classes.listElement} key={fd.id}>
                                    <div className={classes.title}>
                                        <div className={classes.stepName}>
                                            <div
                                                className={
                                                    fd.state === 0 ? classes.iconCompleted : fd.state === 1 ? classes.iconNeedsReview : classes.iconUnderReview
                                                }
                                            >
                                                {fd.state === 0 ? (
                                                    <CheckCircleOutlineIcon color="inherit" />
                                                ) : fd.state === 1 ? (
                                                    <HighlightOffIcon color="inherit" />
                                                ) : (
                                                    <TimelapseIcon color="inherit" />
                                                )}
                                            </div>
                                            {role === 0 ? fd.name + " - " + languageData?.MoldMaker : fd.name}
                                        </div>
                                        <div className={classes.status}>
                                            {fd.hasControl && <span className={classes.needsReview}>{languageData?.NeedsReview}</span>}
                                            {open !== fd.id ? (
                                                <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === fd.id ? null : fd.id)} />
                                            ) : (
                                                <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === fd.id ? null : fd.id)} />
                                            )}
                                        </div>
                                    </div>
                                    <Collapse in={open === fd.id}>
                                        <div className={classes.content}>
                                            <MoldMakerFileDiscussions {...props} element={fd} getData={getData} />
                                        </div>
                                    </Collapse>
                                </div>
                            );
                        })}
                    {(role === 0 || role === 1) &&
                        customerFileDiscussions.map((fd) => (
                            <div className={classes.listElement} key={fd.id}>
                                <div className={classes.title}>
                                    <div className={classes.stepName}>
                                        <div
                                            className={
                                                fd.state === 0 ? classes.iconCompleted : fd.state === 1 ? classes.iconNeedsReview : classes.iconUnderReview
                                            }
                                        >
                                            {fd.state === 0 ? (
                                                <CheckCircleOutlineIcon color="inherit" />
                                            ) : fd.state === 1 ? (
                                                <HighlightOffIcon color="inherit" />
                                            ) : (
                                                <TimelapseIcon color="inherit" />
                                            )}
                                        </div>
                                        {role === 0 ? fd.name + " - " + languageData?.Customer : fd.name}
                                    </div>
                                    <div className={classes.status}>
                                        {fd.hasControl && <span className={classes.needsReview}>{languageData?.NeedsReview}</span>}
                                        {open !== fd.id ? (
                                            <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === fd.id ? null : fd.id)} />
                                        ) : (
                                            <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === fd.id ? null : fd.id)} />
                                        )}
                                    </div>
                                </div>
                                <Collapse in={open === fd.id}>
                                    <div className={classes.content}>
                                        <CustomerFileDiscussions {...props} element={fd} getData={getData} />
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                    {role === 0 && stepData?.state?.id !== 2 && (
                        <div className={classes.listElement}>
                            <div className={classes.title} onClick={() => setOpenAddNew(true)}>
                                <div className={classes.stepName}>
                                    <div className={classes.addNew}>
                                        <AddCircleIcon color="inherit" />
                                    </div>
                                    {languageData?.AddNew}
                                </div>
                            </div>
                        </div>
                    )}
                    <div key={-1} className={classes.listElement}>
                        <div className={classes.title}>
                            <div className={classes.stepName}>{languageData?.Files}</div>
                            <div className={classes.status}>
                                {open !== -1 ? (
                                    <ExpandMoreIcon
                                        className={classes.pointerIcons}
                                        onClick={() => {
                                            setOpen(open === -1 ? null : -1);
                                        }}
                                    />
                                ) : (
                                    <ExpandLessIcon
                                        className={classes.pointerIcons}
                                        onClick={() => {
                                            setOpen(open === -1 ? null : -1);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <Collapse in={open === -1}>
                            <div className={classes.content}>
                                <MoldDesignFiles data={files} getMoldDesignData={getData} {...props} />
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MoldDesign;
