import React, { useContext, useEffect, useState } from "react";
import { FrappeGantt } from "frappe-gantt-react";
import Axios from "axios";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import { Button, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Switch from "@material-ui/core/Switch";

import WebsiteContext from "../../../utils/Website";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "auto",
        padding: 9,
        paddingBottom: 18,
    },
    table: {
        marginBottom: 16,
    },
    firstColumn: {
        width: 100,
    },
    viewButtonWrapper: {
        backgroundColor: "#FFFFFF",
        boxSizing: "border-box",
        borderRadius: "3.05172px",
        maxHeight: 32,
        width: 117,
        [theme.breakpoints.down(1250)]: {
            margin: "5px 5px 0 0",
        },
    },
    viewButtonText: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        margin: "0 5px",
    },

    viewButtonIcon: {
        color: "#757575",
        margin: "0 5px",
    },
    visibleSwitch: {
        display: "block",
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    ganttWrapper: {
        "& .gantt-container": {
            overflow: "scroll",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            wordWrap: "break-word",
            backgroundColor: "#fff",
            backgroundClip: "border-box",
            border: "1px solid rgba(0,0,0,0.125)",
            borderRadius: "4px",
        },
        "& .gantt": {
            overflow: "hidden",
            verticalAlign: "middle",
            height: (props) => (props?.ganttHeight > 0 ? props.ganttHeight : "auto"),
        },
        "&>div": {
            overflow: "hidden !important",
        },
        "& .bar-label": {
            fontWeight: "normal",
        },
        "& .handle-group": {
            display: "none",
        },
        "& .bar-wrapper.bar-milestone .bar-progress": {
            fill: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-milestone:hover .bar-progress": {
            fill: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-milestone.active .bar-progress": {
            fill: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        "& .bar-progress": {
            fill: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper:hover .bar-progress": {
            fill: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.active .bar-progress": {
            fill: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-in-time .bar-progress": {
            fill: "#0DC08A",
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-in-time:hover .bar-progress": {
            fill: "#0DC08A",
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-in-time.active .bar-progress": {
            fill: "#0DC08A",
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-out-of-time .bar-progress": {
            fill: "#C00D0E",
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-out-of-time:hover .bar-progress": {
            fill: "#C00D0E",
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-out-of-time.active .bar-progress": {
            fill: "#C00D0E",
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-completed .bar-progress": {
            fill: "#636362",
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-completed:hover .bar-progress": {
            fill: "#636362",
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-completed.active .bar-progress": {
            fill: "#636362",
            color: theme.palette.common.white,
        },
    },
}));

const ProjectSchedule = (props) => {
    const [tools, setTools] = useState([]);

    const classes = useStyles({
        ganttHeight: tools.length > 0 ? tools.length * 38 + 60 : 0,
    });
    const { languageData, role } = useContext(WebsiteContext);
    const [isVisible, setIsVisible] = useState(false);

    const getGantt = () => {
        const options = {
            url: `/projects/${props.match.params.id}` + (props.isCustomerSchedule ? `/customer-gantt-charts` : `/tool-maker-gantt-charts`),
            method: "GET",
        };
        Axios(options).then((response) => {
            let toolArray = [];
            response.data.data.ganttCharts.forEach((tool) => {
                toolArray.push({
                    id: tool.toolId,
                    name: tool.name,
                    start: tool.startDate,
                    end: tool.endDate,
                    progress: 100,
                    dependencies: "",
                    custom_class: tool.state.id === 2 ? "bar-in-time" : tool.state.id === 1 ? "bar-out-of-time" : "bar-completed",
                    state: tool.state,
                });
            });
            setTools(toolArray);
            setIsVisible(response.data.data.visibleGanttChart);
        });
    };

    useEffect(() => {
        getGantt();
    }, [props.isCustomerSchedule]);

    const handleOpenToolSchedule = (id) => {
        let scheduleDelimiter;
        if (props.isCustomerSchedule) {
            scheduleDelimiter = "customer";
        } else {
            scheduleDelimiter = "mold-maker";
        }
        window.open(
            role === 0
                ? `${window.location.origin}/customers/projects/tools/${id}/schedule/${scheduleDelimiter}`
                : `${window.location.origin}/project/tools/${id}/schedule/${scheduleDelimiter}`
        );
    };

    const handleToggleButton = () => {
        const data = {
            visibleGanttChart: !isVisible,
        };

        const options = {
            url: `/projects/${props.match.params.id}/customer-gantt-charts/visibility`,
            method: "PUT",
            data: data,
        };
        Axios(options).then((response) => {
            setIsVisible(data.visibleGanttChart);
        });
    };

    return (
        <div className={classes.wrapper}>
            {props.isCustomerSchedule && role === 0 && (
                <div className={classes.visibleSwitch}>
                    {languageData?.Visibility}
                    <Switch checked={isVisible} color="primary" onChange={handleToggleButton} />
                </div>
            )}

            <TableContainer className={classes.table} component={Paper}>
                <Table size="small" stickyHeader={true} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.ToolName}
                            </TableCell>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.StartDate}
                            </TableCell>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.EndDate}
                            </TableCell>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.Status}
                            </TableCell>
                            <TableCell className={classes.firstColumn}>{languageData?.ToolSchedule}</TableCell>
                        </TableRow>
                    </TableHead>
                    {(role === 0 || role === 2 || (role === 1 && isVisible)) && (
                        <TableBody>
                            {tools?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.start}</TableCell>
                                    <TableCell align="center">{row.end}</TableCell>
                                    <TableCell align="center" style={{ color: row.state.color }}>
                                        {row.state.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button onClick={() => handleOpenToolSchedule(row.id)} className={classes.viewButtonWrapper}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <VisibilityIcon fontSize="small" className={classes.viewButtonIcon} />
                                                <div className={classes.viewButtonText}>{languageData?.View}</div>
                                            </Grid>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {(role === 0 || role === 2 || (role === 1 && isVisible)) && (
                <Paper className={classes.ganttWrapper}>
                    {tools.length > 0 && (
                        <FrappeGantt tasks={tools} viewMode={"Day"} onClick={(task) => console.log(task)} onDateChange={(task, start, end) => getGantt()} />
                    )}
                </Paper>
            )}
        </div>
    );
};

ProjectSchedule.propTypes = {
    isCustomerSchedule: PropTypes.bool,
};

export default withRouter(ProjectSchedule);
