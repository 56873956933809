import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/tui-image-editor.css";
import "../../assets/css/tui-color-picker.css";
import ImageEditor from "@toast-ui/react-image-editor";
import img from "../../assets/img/loginImage.png";
import WebsiteContext from "../../utils/Website";
import StyledButton from "../../components/StyledButton/StyledButton";
import { makeStyles } from "@material-ui/core";
import Axios from "axios";

const myTheme = {
    // Theme object to extends default dark theme.
};

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: 18,
    },
    button: {
        marginBottom: 18,
    },
}));

const TestImageEditor = () => {
    const [imageSrc, setImageSrc] = useState(img);
    const [imageName, setImageName] = useState("basicImage");
    const [showEditor, setShowEditor] = useState(true);
    const imageEditor = React.createRef();
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles();

    const saveImage = () => {
        const imageEditorInst = imageEditor.current.imageEditorInst;
        const data = imageEditorInst.toDataURL();
        const file = DataURIToBlob(data);
        const formData = new FormData();
        formData.append("upload", file, "image.jpg");
    };

    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    const retrieveImageFromClipboardAsBlob = (pasteEvent, callback) => {
        if (pasteEvent.clipboardData == false) {
            if (typeof callback == "function") {
                callback(undefined);
            }
        }

        var items = pasteEvent.clipboardData.items;

        if (items == undefined) {
            if (typeof callback == "function") {
                callback(undefined);
            }
        }

        for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();

            if (typeof callback == "function") {
                callback(blob, imageEditor);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("paste", function (e) {
            retrieveImageFromClipboardAsBlob(e, function (imageBlob, test) {
                setShowEditor(false);
                const imageFormData = new FormData();
                imageFormData.append("files", imageBlob, imageBlob.name);
                const options = {
                    url: "/file-operations/image-editor/files",
                    method: "POST",
                    data: imageFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                };
                Axios(options).then((response) => {
                    setImageSrc(`${process.env.REACT_APP_URL}file-operations/image-editor/files/${response.data.data.files[0].id}`);
                    setImageName(response.data.data.files[0].name);
                    setShowEditor(true);
                });
            });
        });
    }, []);

    return (
        <div className={classes.wrapper}>
            <StyledButton className={classes.button} color="primary" variant="contained" onClick={saveImage}>
                {languageData?.Save.toUpperCase()}
            </StyledButton>
            {showEditor && (
                <ImageEditor
                    includeUI={{
                        loadImage: {
                            path: imageSrc,
                            name: imageName,
                        },
                        theme: myTheme,
                        menu: ["draw", "shape", "text", "icon"],
                        initMenu: "",
                        uiSize: {
                            height: `calc(100vh - 147px)`,
                        },
                        menuBarPosition: "bottom",
                    }}
                    cssMaxHeight={window.innerHeight}
                    cssMaxWidth={window.innerWidth}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70,
                    }}
                    usageStatistics={false}
                    ref={imageEditor}
                />
            )}
        </div>
    );
};

export default TestImageEditor;
