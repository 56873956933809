import React, { useContext } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Button, makeStyles, MenuItem, Select } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        marginTop: 10,
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    checkboxRowWrapper: {
        display: "flex",
        flexDirection: "row",
        marginTop: 10,
        marginLeft: 5,
    },
    checkboxWrapper: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
        margin: 5,
    },
    checked: {},
    disabled: {
        opacity: 0.5,
    },
    contentMargin: {
        marginTop: 4,
        [theme.breakpoints.down(500)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
        marginBottom: 4,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        width: 70,
    },
    checkboxComponent: {
        marginTop: 20,
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(785)]: {
            flexDirection: "column",
        },
    },
    columnWrapper: {
        marginRight: 40,
    },
    selectWrapper: {
        width: "210px",
        height: "33px",
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    inputWrapper: {
        padding: "10px 14px",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
}));

const Coating = (props) => {
    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const handleOpenCustomerGMS = () => {
        if (role === 0) {
            window.open(window.location.origin + "/customers/" + props.customerId + "/gts", "_blank", "noopener,noreferrer");
        } else if (role === 1) {
            window.open(window.location.origin + "/customer/gts", "_blank", "noopener,noreferrer");
        } else {
            window.open(window.location.origin + "/tool/" + props.toolId + "/gms", "_blank", "noopener,noreferrer");
        }
    };

    const handleChangeCheckbox = (section, value) => {
        const newValues = { ...props.coatingData[section] };
        newValues.checked = value;
        props.updateCoatingData("coating", section, newValues);
    };

    const handleChangeText = (section, value) => {
        const newValues = { ...props.coatingData[section] };
        newValues.value = value;
        props.updateCoatingData("coating", section, newValues);
    };

    const dropdownOptions = (options) => {
        return Object.keys(options).map((key, index) => {
            return (
                <div key={key} className={classes.columnWrapper}>
                    <InputLabel className={classes.labelWrapper}>{languageData[key]}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={options[key] && options[key] !== null ? options[key] : ""}
                            displayEmpty={options[key] === null}
                            renderValue={
                                options[key] === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateCoatingData("coating", key, e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props.gms12?.map((shot) => {
                                return (
                                    <MenuItem value={shot.id} key={shot.id}>
                                        <div className={classes.selectTextWrapper}>{shot.text}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            );
        });
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Coating.toUpperCase()}</div>
            <div className={classes.contentMargin}>
                <Button
                    variant="contained"
                    component="label"
                    className={classes.uploadFileButton}
                    startIcon={<OpenInNewIcon color="primary" />}
                    onClick={handleOpenCustomerGMS}
                >
                    {languageData?.OpenCustomerGMS}
                </Button>
            </div>
            <div className={classes.checkboxComponent}>{props.coatingData && dropdownOptions(props.coatingData)}</div>
        </div>
    );
};

export default Coating;
