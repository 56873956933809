import { createContext } from "react";

const WebsiteContext = createContext({
    language: "",
    setLanguage: () => {},
    languageData: null,

    loading: false,
    setLoading: () => {},

    isDefaultPassword: false,
    setIsDefaultPassword: () => {},

    role: null,
    setRole: () => {},
});

WebsiteContext.displayName = "WebsiteContext";

export default WebsiteContext;
