import React from "react";
import { alpha, FormControl, FormHelperText, InputAdornment, InputBase, InputLabel, makeStyles, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";

const Input = withStyles((theme) => ({
    root: {
        // "label + &": {
        //     marginTop: theme.spacing(3),
        // },
        fontSize: 10,
        lineHeight: "12px",
        width: "100%",
    },
    input: {
        color: theme.palette.dark.main,
        marginBottom: 0,
        borderRadius: 2.57,
        position: "relative",
        backgroundColor: theme.palette.common.white,
        border: "0.6425px solid #CCCCCC",
        fontSize: 10,
        lineHeight: "12px",
        width: "100%",
        padding: "11px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        position: "unset",
    },
    error: {
        fontSize: 10,
        lineHeight: "12px",
        fontWeight: 500,
        fontStyle: "normal",
        marginTop: 10,
    },
    formControl: {
        display: "flex",
        // padding: 4,
        // width: "calc(100% - 8px)",
    },
    labelFormControl: {
        transform: "unset",
        marginBottom: 4,
    },
    iconButton: {
        padding: 0,
        margin: "5px 10px 5px 0px",
        position: "absolute",
        right: 0,
    },
    wrapper: {
        width: "100%",
        position: "relative",
    },
    withIcon: {
        paddingRight: "40px",
    },
}));

const StyledInput = (props) => {
    const {
        defaultValue,
        onChange,
        label,
        className,
        type,
        helperText,
        error,
        required,
        placeholder,
        multiline,
        showLabel,
        disabled,
        form,
        inputClassName,
        showEndIcon,
        endIconTrigger,
    } = props;
    const classes = useStyles(useStyles);

    return (
        <FormControl className={className} classes={{ root: classes.formControl }}>
            {showLabel && (
                <InputLabel
                    required={required}
                    classes={{
                        root: classes.root,
                        shrink: classes.shrink,
                        formControl: classes.labelFormControl,
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <div className={classes.wrapper}>
                <Input
                    multiline={multiline}
                    placeholder={placeholder || ""}
                    required={required}
                    error={error}
                    value={defaultValue}
                    onChange={onChange}
                    type={type}
                    disabled={disabled}
                    inputProps={{
                        form: form,
                    }}
                    className={inputClassName}
                    classes={{ root: showEndIcon && classes.withIcon }}
                />
                {showEndIcon && (
                    <IconButton onClick={endIconTrigger} color="primary" className={classes.iconButton} aria-label="toggle password visibility">
                        <VisibilityIcon />
                    </IconButton>
                )}
            </div>
            {helperText && (
                <FormHelperText classes={{ error: classes.error }} error={error}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default StyledInput;
