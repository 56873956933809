import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Button, Grid, IconButton, makeStyles } from "@material-ui/core";
import StyledInput from "../../../StyledInput/StyledInput";
import CachedIcon from "@material-ui/icons/Cached";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
    },
    inputWrapper: {
        width: 210,
        marginTop: 20,
    },
    shrinkageWrapper: {
        width: 60,
        marginTop: 20,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        marginTop: 15,
    },
    shrinkagePercentageWrapper: {
        marginBottom: "15px",
        marginLeft: "5px",
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        height: 36,
    },
    linkWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        maxWidth: "190px",
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "12px",
        color: "#646363",
        marginRight: 10,
    },
    accessibleLink: {
        textDecorationLine: "underline",
        cursor: "pointer",
        color: "#00A7D1",
    },
    inputClassName: {
        height: 36,
    },
    root: {
        padding: 0,
        marginTop: 2,
    },
    addButton: {
        width: "fit-content",
    },
    fileInputWrapper: { width: 1, height: 1, opacity: 1 },
}));

const Material = (props) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);
    const [materialFiles, setMaterialFiles] = useState([]);
    const [insertFiles, setInsertFiles] = useState([]);
    const [filmFiles, setFilmFiles] = useState([]);
    const fileNameLength = 15;

    useEffect(() => {
        if (props.materialData) {
            const newMatFiles = [];
            if (props.materialData.names.length > 0) {
                props.materialData.names.forEach((matName, index) => {
                    if (matName.fileId && matName.filename) {
                        newMatFiles.push({
                            filename: createFileName(matName.filename),
                            fileId: matName.fileId,
                            accessible: true,
                            index: index,
                        });
                    }
                });
                setMaterialFiles(newMatFiles);
            } else {
                addInput(1);
            }

            const newInsertFiles = [];
            if (props.materialData.inserts.length > 0) {
                props.materialData.inserts.forEach((insert, index) => {
                    if (insert.fileId && insert.filename) {
                        newInsertFiles.push({
                            filename: createFileName(insert.filename),
                            fileId: insert.fileId,
                            accessible: true,
                            index: index,
                        });
                    }
                });
                setInsertFiles(newInsertFiles);
            } else {
                addInput(2);
            }

            const newFilmFiles = [];
            if (props.materialData.films.length > 0) {
                props.materialData.films.forEach((film, index) => {
                    if (film.fileId && film.filename) {
                        newFilmFiles.push({
                            filename: createFileName(film.filename),
                            fileId: film.fileId,
                            accessible: true,
                            index: index,
                        });
                    }
                });
                setFilmFiles(newFilmFiles);
            } else {
                addInput(3);
            }
        }
    }, [props.materialData]);

    const handleOpenFile = (id, accessible) => {
        if (accessible) {
            window.open(`${process.env.REACT_APP_URL}file-operations/${props.toolId}-specification/files/${id}`);
        }
    };

    const createFileName = (fileName) => {
        let filename;
        const sections = fileName.split(".");
        if (sections.length > 1) {
            let firstPart = sections.slice(0, sections.length - 1).join(".");
            if (firstPart.length > fileNameLength) {
                firstPart = firstPart.substr(0, fileNameLength) + "...";
            }
            const lastPart = "." + sections[sections.length - 1];
            filename = firstPart + lastPart;
        } else {
            filename = sections[0].length > fileNameLength ? sections[0].substr(0, fileNameLength) : sections[0];
        }
        return filename;
    };

    const uploadFile = (event, subsection, id, index) => {
        let file = event.target.files[0];

        if (file) {
            const filename = createFileName(file.name);

            switch (subsection) {
                case "names":
                    if (id) {
                        const materialFilesCopy = materialFiles.slice();
                        const fileIndex = materialFilesCopy.findIndex((el) => {
                            return el.fileId === id;
                        });
                        if (fileIndex > -1) {
                            materialFilesCopy[fileIndex].accessible = false;
                            materialFilesCopy[fileIndex].filename = filename;
                            materialFilesCopy[fileIndex].index = index;
                            setMaterialFiles(materialFilesCopy);
                            props.updateNewFiles("material", "names", id, file, file.name, index);
                        }
                    } else {
                        const materialFilesCopy = materialFiles.slice();
                        const fileIndex = materialFilesCopy.findIndex((el) => {
                            return el.index === index;
                        });
                        if (fileIndex > -1) {
                            materialFilesCopy[fileIndex].accessible = false;
                            materialFilesCopy[fileIndex].filename = filename;
                            materialFilesCopy[fileIndex].index = index;
                        } else {
                            materialFilesCopy.push({
                                id: null,
                                filename: filename,
                                accessible: false,
                                index: index,
                            });
                        }
                        setMaterialFiles(materialFilesCopy);
                        props.updateNewFiles("material", "names", null, file, file.name, index);
                    }
                    break;
                case "inserts":
                    if (id) {
                        const insertFilesCopy = insertFiles.slice();
                        const fileIndex = insertFilesCopy.findIndex((el) => {
                            return el.fileId === id;
                        });
                        if (fileIndex > -1) {
                            insertFilesCopy[fileIndex].accessible = false;
                            insertFilesCopy[fileIndex].filename = filename;
                            insertFilesCopy[fileIndex].index = index;
                            setInsertFiles(insertFilesCopy);
                            props.updateNewFiles("material", "inserts", id, file, file.name, index);
                        }
                    } else {
                        const insertFilesCopy = insertFiles.slice();
                        const fileIndex = insertFilesCopy.findIndex((el) => {
                            return el.index === index;
                        });
                        if (fileIndex > -1) {
                            insertFilesCopy[fileIndex].accessible = false;
                            insertFilesCopy[fileIndex].filename = filename;
                            insertFilesCopy[fileIndex].index = index;
                        } else {
                            insertFilesCopy.push({
                                id: null,
                                filename: filename,
                                accessible: false,
                                index: index,
                            });
                        }
                        setInsertFiles(insertFilesCopy);
                        props.updateNewFiles("material", "inserts", null, file, file.name, index);
                    }
                    break;
                case "films":
                    if (id) {
                        const filmFilesCopy = filmFiles.slice();
                        const fileIndex = filmFilesCopy.findIndex((el) => {
                            return el.fileId === id;
                        });
                        if (fileIndex > -1) {
                            filmFilesCopy[fileIndex].accessible = false;
                            filmFilesCopy[fileIndex].filename = filename;
                            filmFilesCopy[fileIndex].index = index;
                            setFilmFiles(filmFilesCopy);
                            props.updateNewFiles("material", "films", id, file, file.name, index);
                        }
                    } else {
                        const filmFilesCopy = filmFiles.slice();
                        const fileIndex = filmFilesCopy.findIndex((el) => {
                            return el.index === index;
                        });
                        if (fileIndex > -1) {
                            filmFilesCopy[fileIndex].accessible = false;
                            filmFilesCopy[fileIndex].filename = filename;
                            filmFilesCopy[fileIndex].index = index;
                        } else {
                            filmFilesCopy.push({
                                id: null,
                                filename: filename,
                                accessible: false,
                                index: index,
                            });
                        }
                        setFilmFiles(filmFilesCopy);
                        props.updateNewFiles("material", "films", null, file, file.name, index);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    const addInput = (type) => {
        switch (type) {
            case 1:
                props.addNewRow("material", "names", {
                    name: "",
                    shrinkage: 0,
                    filename: "",
                    fileId: "",
                });
                props.addNewRow("runnerSystem", null, {
                    runnerSystem: null,
                    runnerSystemOthers: [],
                    hotrunnerBrand: null,
                    cascadeControl: null,
                    gateSystem: "",
                    gateSystemOthers: [],
                });
                break;
            case 2:
                props.addNewRow("material", "inserts", {
                    name: "",
                    filename: "",
                    fileId: "",
                });
                break;
            case 3:
                props.addNewRow("material", "films", {
                    name: "",
                    filename: "",
                    fileId: "",
                });
                break;
            default:
                break;
        }
    };

    const removeInput = (index, info) => {
        props.removeRow("material", info, index, info === "names");
        switch (info) {
            case "names":
                const newMaterialFiles = materialFiles.slice();
                const indexToDelete = newMaterialFiles.findIndex((el) => el.index === index);

                if (indexToDelete > -1) {
                    newMaterialFiles.splice(indexToDelete, 1);
                    newMaterialFiles.forEach((matFile) => {
                        if (matFile.index > index) {
                            matFile.index--;
                        }
                    });
                }
                setMaterialFiles(newMaterialFiles);
                break;
            case "inserts":
                const newInsertFiles = insertFiles.slice();

                const indexToDeleteInsert = newInsertFiles.findIndex((el) => el.index === index);

                if (indexToDeleteInsert > -1) {
                    newInsertFiles.splice(indexToDeleteInsert, 1);
                    newInsertFiles.forEach((insertFile) => {
                        if (insertFile.index > index) {
                            insertFile.index--;
                        }
                    });
                }
                setInsertFiles(newInsertFiles);
                break;
            case "films":
                const newFilmFiles = filmFiles.slice();

                const indexToDeleteFilm = newFilmFiles.findIndex((el) => el.index === index);

                if (indexToDeleteFilm > -1) {
                    newFilmFiles.splice(indexToDeleteFilm, 1);
                    newFilmFiles.forEach((filmFile) => {
                        if (filmFile.index > index) {
                            filmFile.index--;
                        }
                    });
                }
                setFilmFiles(newFilmFiles);
                break;
            default:
                break;
        }
        props.deleteFromNewFiles("material", info, index);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Material.toUpperCase()}</div>
            <div>
                {props.materialData &&
                    props.materialData.names.map((materialName, index) => {
                        const currentFile = materialFiles.find((matFile) => matFile.index === index);
                        return (
                            <Grid key={index} container justifyContent={"flex-start"} alignItems={"flex-start"}>
                                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                    <StyledInput
                                        // required={props.materialData.names.length < 2}
                                        disabled={props.disabled}
                                        showLabel
                                        defaultValue={materialName.name}
                                        className={classes.inputWrapper}
                                        label={languageData?.MaterialName}
                                        inputClassName={classes.inputClassName}
                                        onChange={(e) => props.updateMaterialData("material", "names", e.target.value, "name", index)}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xl={1} lg={1} md={2} sm={6} xs={12}>
                                    <Grid container justifyContent={"flex-start"} alignItems={"flex-end"}>
                                        <StyledInput
                                            showLabel
                                            disabled={props.disabled}
                                            // required={props.materialData.names.length < 2}
                                            defaultValue={materialName.shrinkage}
                                            className={classes.shrinkageWrapper}
                                            label={languageData?.ShrinkageLabel}
                                            inputClassName={classes.inputClassName}
                                            onChange={(e) => props.updateMaterialData("material", "names", e.target.value, "shrinkage", index)}
                                            type="number"
                                        />
                                        <div className={classes.textWrapper + " " + classes.shrinkagePercentageWrapper}>%</div>
                                    </Grid>
                                </Grid>
                                <Grid item xl={9} lg={8} md={6} sm={12} xs={12}>
                                    <InputLabel
                                        // required={props.materialData.names.length < 2}
                                        className={classes.labelWrapper}
                                    >
                                        {languageData?.Filename}
                                    </InputLabel>
                                    <Grid style={{ marginTop: 4 }} container justifyContent={"flex-start"} alignItems={"center"}>
                                        {currentFile && (
                                            <span
                                                onClick={() => handleOpenFile(currentFile.fileId, currentFile.accessible)}
                                                className={classes.linkWrapper + " " + (currentFile.accessible && classes.accessibleLink)}
                                            >
                                                {currentFile.filename}
                                            </span>
                                        )}
                                        <Button
                                            disabled={props.disabled}
                                            variant="contained"
                                            component="label"
                                            className={classes.uploadFileButton}
                                            startIcon={currentFile ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                        >
                                            {currentFile ? languageData?.ChangeFile : languageData?.UploadFile}
                                            <input
                                                defaultValue={currentFile ? currentFile.name : ""}
                                                // required={
                                                //   props.materialData.names.length < 2 && !currentFile
                                                // }
                                                type="file"
                                                onChange={(e) => uploadFile(e, "names", currentFile ? currentFile.fileId : null, index)}
                                                className={classes.fileInputWrapper}
                                            />
                                        </Button>
                                        {props.materialData.names.length > 1 && (
                                            <Grid>
                                                <IconButton
                                                    disabled={props.disabled}
                                                    classes={{ root: classes.root }}
                                                    className={classes.addButton}
                                                    onClick={() => removeInput(index, "names")}
                                                >
                                                    <RemoveCircleIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                <IconButton
                    disabled={props.disabled}
                    classes={{ root: classes.root }}
                    color="primary"
                    className={classes.addButton}
                    onClick={() => addInput(1)}
                >
                    <AddCircleIcon />
                </IconButton>
            </div>

            <div>
                {props.materialData &&
                    props.materialData.inserts.map((insert, index) => {
                        const currentFile = insertFiles.find((insertFile) => insertFile.index === index);
                        return (
                            <Grid key={index} container justifyContent={"flex-start"} alignItems={"flex-start"}>
                                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                    <StyledInput
                                        disabled={props.disabled}
                                        showLabel
                                        defaultValue={insert.name}
                                        className={classes.inputWrapper}
                                        label={languageData?.Insert}
                                        inputClassName={classes.inputClassName}
                                        onChange={(e) => props.updateMaterialData("material", "inserts", e.target.value, "name", index)}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                                    <div className={classes.labelWrapper}>{languageData?.Filename}</div>
                                    <Grid style={{ marginTop: 4 }} container justifyContent={"flex-start"} alignItems={"center"}>
                                        {currentFile && (
                                            <span
                                                onClick={() => handleOpenFile(currentFile.fileId, currentFile.accessible)}
                                                className={classes.linkWrapper + " " + (currentFile.accessible && classes.accessibleLink)}
                                            >
                                                {currentFile.filename}
                                            </span>
                                        )}
                                        <Button
                                            disabled={props.disabled}
                                            variant="contained"
                                            component="label"
                                            className={classes.uploadFileButton}
                                            startIcon={currentFile ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                        >
                                            {currentFile ? languageData?.ChangeFile : languageData?.UploadFile}
                                            <input
                                                type="file"
                                                onChange={(e) => uploadFile(e, "inserts", currentFile ? currentFile.fileId : null, index)}
                                                style={{ display: "none" }}
                                            />
                                        </Button>
                                        {props.materialData.inserts.length > 1 && (
                                            <Grid>
                                                <IconButton
                                                    disabled={props.disabled}
                                                    classes={{ root: classes.root }}
                                                    className={classes.addButton}
                                                    onClick={() => removeInput(index, "inserts")}
                                                >
                                                    <RemoveCircleIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                <IconButton
                    disabled={props.disabled}
                    classes={{ root: classes.root }}
                    color="primary"
                    className={classes.addButton}
                    onClick={() => addInput(2)}
                >
                    <AddCircleIcon />
                </IconButton>
            </div>

            <div>
                {props.materialData &&
                    props.materialData.films.map((film, index) => {
                        const currentFile = filmFiles.find((filmFile) => filmFile.index === index);
                        return (
                            <Grid key={index} container justifyContent={"flex-start"} alignItems={"flex-start"}>
                                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                    <StyledInput
                                        disabled={props.disabled}
                                        showLabel
                                        defaultValue={film.name}
                                        className={classes.inputWrapper}
                                        label={languageData?.Film}
                                        inputClassName={classes.inputClassName}
                                        onChange={(e) => props.updateMaterialData("material", "films", e.target.value, "name", index)}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xl={3} lg={6} md={6} sm={6} xs={12}>
                                    <div className={classes.labelWrapper}>{languageData?.Filename}</div>
                                    <Grid style={{ marginTop: 4 }} container justifyContent={"flex-start"} alignItems={"center"}>
                                        {currentFile && (
                                            <span
                                                onClick={() => handleOpenFile(currentFile.fileId, currentFile.accessible)}
                                                className={classes.linkWrapper + " " + (currentFile.accessible && classes.accessibleLink)}
                                            >
                                                {currentFile.filename}
                                            </span>
                                        )}
                                        <Button
                                            disabled={props.disabled}
                                            variant="contained"
                                            component="label"
                                            className={classes.uploadFileButton}
                                            startIcon={currentFile ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                        >
                                            {currentFile ? languageData?.ChangeFile : languageData?.UploadFile}
                                            <input
                                                type="file"
                                                onChange={(e) => uploadFile(e, "films", currentFile ? currentFile.fileId : null, index)}
                                                style={{ display: "none" }}
                                            />
                                        </Button>
                                        {props.materialData.films.length > 1 && (
                                            <Grid>
                                                <IconButton
                                                    disabled={props.disabled}
                                                    classes={{ root: classes.root }}
                                                    className={classes.addButton}
                                                    onClick={() => removeInput(index, "films")}
                                                >
                                                    <RemoveCircleIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                <IconButton
                    disabled={props.disabled}
                    classes={{ root: classes.root }}
                    color="primary"
                    className={classes.addButton}
                    onClick={() => addInput(3)}
                >
                    <AddCircleIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default Material;
