import { Card, Divider, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import WorkIcon from "@material-ui/icons/Work";

const useStyles = makeStyles(() => ({
    wrapper: {},
    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    root: {
        width: 219,
        height: 147,
        display: "block",
        float: "left",
        cursor: "pointer",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "1.35951px",
    },
    name: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "15px",
        color: "#C00D0E",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    details: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "13px",
        display: "flex",
        color: "#191919",
        padding: "8px 8px",
    },
    detailsText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    nameWrapper: {
        padding: "16px 16px 10px 16px",
    },
    divider: {
        width: "100%",
    },
    icons: {
        color: "#C00D0E",
        height: "13px",
        weight: "13px",
    },
}));

const SimpleUserEntityCard = (props) => {
    const classes = useStyles(useStyles);
    const [id, setId] = useState("");

    useEffect(() => {
        setId(props.id);
    }, [props.id, id]);

    // const handleClickEvent = () => {
    //     props.history.push(props.match.path + "/" + id);
    // };

    return (
        <Card classes={{ root: classes.root }}>
            <div className={classes.cardWrapper}>
                <div className={classes.nameWrapper}>
                    <div className={classes.name}>{props.name}</div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.details}>
                    <PhoneIcon className={classes.icons} />
                    <div className={classes.detailsText}>{props.phoneNumber}</div>
                </div>
                <div className={classes.details}>
                    <EmailIcon className={classes.icons} />
                    <div className={classes.detailsText}>{props.email}</div>
                </div>
                <div className={classes.details}>
                    <WorkIcon className={classes.icons} />
                    <div className={classes.detailsText}>{props.department}</div>
                </div>
            </div>
        </Card>
    );
};

SimpleUserEntityCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    department: PropTypes.string,
};

export default SimpleUserEntityCard;
