const machineListStyle = {
    table: {
        width: "100%",
        height: "calc(100% - 44px)",
    },
    submitButton: {
        marginTop: "15px",
        marginBottom: "30px",
    },
    openFile: {
        color: "#00A7D1",
        cursor: "pointer",
    },
    uploadFile: {
        backgroundColor: "white",
        marginRight: "10px",
    },
    input: {
        padding: 2,
    },
    tile: {
        display: "flex",
        flexDirection: "column-reverse",
    },
    columnStyle: {
        minWidth: "200px",
        left: "unset",
    },
    editButton: {
        background: "green",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    deleteButton: {
        marginTop: "5px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    buttonWrapper: {
        width: "200px",
        height: "auto",
        marginBottom: "10px",
        background: "#C00D0E",
    },
    buttonAdd: {
        width: "200px",
        height: "auto",
        marginBottom: 18,
        marginRight: 8,
    },
    firstLastColumn: {
        left: "unset",
        minWidth: 90,
    },
    rowTable: {
        display: "table-row !important",
    },
    inputFile: {
        opacity: 0,
        width: 1,
    },
};

export default machineListStyle;
