import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import PropTypes from "prop-types";

import { Button, IconButton, makeStyles, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: "8px",
    },
    subtitleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginBottom: 4,
    },
    gridSpacing: {
        padding: 7,
        margin: "0 !important",
    },
    selectWrapper: {
        width: "210px",
    },
    userSelectWrapper: {
        height: 45,
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    emptySelectWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "45px",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
    },
    selectWithLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    inputWrapper: {
        padding: "10px 14px",
    },
    selectMoldMakerWrapper: {
        marginBottom: "10px",
    },
    horizontalInputButtonWrapper: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
    },
    deleteButton: {
        backgroundColor: "white",
        color: "#646363",
        boxShadow: "none",
        border: "1px solid #ECECEC",
        padding: 8,
        minWidth: 0,

        "&:hover": {
            boxShadow: "none",
        },
    },
    deleteIcon: {
        margin: 0,
    },
    addButtonRoot: {
        padding: 0,
        marginTop: 2,
    },
    addButton: {
        width: "fit-content",
    },
    contactsWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        gap: "10px",
        alignItems: "flex-end",
    },
}));

const defaultMakerContactPair = { moldMakerId: null, contactPersonId: null };

const QuotationRequestContacts = ({ moldMakers, contacts, addNewContact, updateContact, deleteContact }) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const selectMoldMaker = (index, value) => {
        updateContact(index, "moldMakerId", value);
        updateContact(index, "contactPersonId", null);
    };

    const selectUser = (index, value) => {
        updateContact(index, "contactPersonId", value);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Contacts.toUpperCase()}</div>
            <div className={classes.contactsWrapper}>
                {contacts?.map((pair, index) => (
                    <div key={index}>
                        <div className={classes.selectMoldMakerWrapper}>
                            <div className={classes.selectWithLabel}>
                                <InputLabel className={classes.subtitleWrapper}>{`${languageData?.MoldMaker} ${index + 1}`}</InputLabel>
                                <div className={classes.horizontalInputButtonWrapper}>
                                    <FormControl>
                                        <Select
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                            classes={{
                                                root: classes.rootWrapper,
                                                selectMenu: classes.selectMenuWrapper,
                                                outlined: classes.inputWrapper,
                                            }}
                                            className={classes.selectWrapper}
                                            variant={"outlined"}
                                            value={pair.moldMakerId || ""}
                                            displayEmpty={pair.moldMakerId === null || pair.moldMakerId === ""}
                                            renderValue={
                                                pair.moldMakerId === null || pair.moldMakerId === ""
                                                    ? () => {
                                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                                      }
                                                    : null
                                            }
                                            onChange={(e) => {
                                                selectMoldMaker(index, e.target.value);
                                            }}
                                        >
                                            <MenuItem value="" key={-1} disabled>
                                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                                            </MenuItem>
                                            {moldMakers?.map((moldMaker) => (
                                                <MenuItem value={moldMaker.id} key={moldMaker.id}>
                                                    <div className={classes.selectTextWrapper}>{moldMaker.name}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.deleteButton}
                                        classes={{ startIcon: classes.deleteIcon }}
                                        startIcon={<DeleteIcon color="primary" />}
                                        onClick={() => {
                                            deleteContact(index);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.contentWrapper}>
                            <div className={classes.selectWithLabel}>
                                <InputLabel className={classes.subtitleWrapper}>{languageData?.ContactPerson}</InputLabel>
                                <FormControl>
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        classes={{
                                            root: classes.rootWrapper + " " + classes.userSelectWrapper,
                                            selectMenu: classes.selectMenuWrapper,
                                        }}
                                        className={classes.selectWrapper}
                                        variant={"outlined"}
                                        value={pair.contactPersonId || ""}
                                        displayEmpty={pair.contactPersonId === null || pair.contactPersonId === ""}
                                        renderValue={
                                            pair.contactPersonId === null || pair.contactPersonId === ""
                                                ? () => {
                                                      return <div className={classes.emptySelectWrapper}>{languageData?.SelectPlaceholder}</div>;
                                                  }
                                                : null
                                        }
                                        onChange={(e) => {
                                            selectUser(index, e.target.value);
                                        }}
                                    >
                                        <MenuItem value="" key={-1} disabled>
                                            <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                                        </MenuItem>
                                        {moldMakers
                                            .find((el) => el.id === pair.moldMakerId)
                                            ?.users?.map((customerUser) => {
                                                return (
                                                    <MenuItem value={customerUser.id} key={customerUser.id}>
                                                        <div className={classes.selectTextWrapper}>
                                                            {customerUser.name + ", " + customerUser.email + ", " + customerUser.phone_number}
                                                        </div>
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                ))}
                <IconButton
                    classes={{ root: classes.addButtonRoot }}
                    color="primary"
                    className={classes.addButton}
                    onClick={() => {
                        addNewContact();
                    }}
                >
                    <AddCircleIcon />
                </IconButton>
            </div>
        </div>
    );
};

QuotationRequestContacts.propTypes = {
    moldMakers: PropTypes.array,
    contacts: PropTypes.array,
    addNewContact: PropTypes.func,
    updateContact: PropTypes.func,
    deleteContact: PropTypes.func,
};

export default QuotationRequestContacts;
