import React from "react";
import { Helmet } from "react-helmet";
import InjectionReportComponent from "../../components/ProcessSteps/MoldTrailFOT/InjectionReportComponent/InjectionReportComponent";

const InjectionReport = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Process</title>
            </Helmet>
            <InjectionReportComponent {...props} />
        </React.Fragment>
    );
};

export default InjectionReport;
