import React, { useContext } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Button, makeStyles } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
        marginBottom: 4,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "40px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        margin: "5px 10px 5px",
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    rowContentWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.down(750)]: {
            flexDirection: "column",
        },
    },
    componentWrapper: {
        display: "flex",
        flexDirection: "row",
    },
    checked: {},
}));

const Connector = (props) => {
    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const handleOpenCustomerGMS = () => {
        if (role === 0) {
            window.open(window.location.origin + "/customers/" + props.customerId + "/gts", "_blank", "noopener,noreferrer");
        } else if (role === 1) {
            window.open(window.location.origin + "/customer/gts", "_blank", "noopener,noreferrer");
        } else {
            window.open(window.location.origin + "/tool/" + props.toolId + "/gms", "_blank", "noopener,noreferrer");
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Connector.toUpperCase()}</div>
            <div>
                <div className={classes.rowContentWrapper}>
                    <div>
                        <div className={classes.labelWrapper}>{languageData?.ElectricConnector}</div>
                        <div className={classes.componentWrapper}>
                            <RadioGroup
                                className={classes.radioButtonsWrapper}
                                value={props.connectorData ? props.connectorData.electricConnector : false}
                                onChange={(e) => props.updateConnectorData("connector", "electricConnector", e.target.value === "true")}
                            >
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="electricConnector"
                                    value={true}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.Yes}
                                />
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="electricConnector"
                                    value={false}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.No}
                                />
                            </RadioGroup>
                            <Button
                                disabled={props.connectorData ? !props.connectorData.electricConnector : true}
                                variant="contained"
                                component="label"
                                className={classes.uploadFileButton}
                                startIcon={<OpenInNewIcon color="primary" />}
                                onClick={handleOpenCustomerGMS}
                            >
                                {languageData?.OpenCustomerGMS}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className={classes.labelWrapper}>{languageData?.HydraulicConnector}</div>
                        <div className={classes.componentWrapper}>
                            <RadioGroup
                                className={classes.radioButtonsWrapper}
                                value={props.connectorData ? props.connectorData.hydraulicConnector : false}
                                onChange={(e) => props.updateConnectorData("connector", "hydraulicConnector", e.target.value === "true")}
                            >
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="hydraulicConnector"
                                    value={true}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.Yes}
                                />
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="hydraulicConnector"
                                    value={false}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.No}
                                />
                            </RadioGroup>
                            <Button
                                disabled={props.connectorData ? !props.connectorData.hydraulicConnector : false}
                                variant="contained"
                                component="label"
                                className={classes.uploadFileButton}
                                startIcon={<OpenInNewIcon color="primary" />}
                                onClick={handleOpenCustomerGMS}
                            >
                                {languageData?.OpenCustomerGMS}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className={classes.labelWrapper}>{languageData?.AirConnector}</div>
                        <div className={classes.componentWrapper}>
                            <RadioGroup
                                className={classes.radioButtonsWrapper}
                                value={props.connectorData ? props.connectorData.airConnector : false}
                                onChange={(e) => props.updateConnectorData("connector", "airConnector", e.target.value === "true")}
                            >
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="airConnector"
                                    value={true}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.Yes}
                                />
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="airConnector"
                                    value={false}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.No}
                                />
                            </RadioGroup>
                            <Button
                                disabled={props.connectorData ? !props.connectorData.airConnector : true}
                                variant="contained"
                                component="label"
                                className={classes.uploadFileButton}
                                startIcon={<OpenInNewIcon color="primary" />}
                                onClick={handleOpenCustomerGMS}
                            >
                                {languageData?.OpenCustomerGMS}
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className={classes.labelWrapper}>{languageData?.Switches}</div>
                        <div className={classes.componentWrapper}>
                            <RadioGroup
                                className={classes.radioButtonsWrapper}
                                value={props.connectorData ? props.connectorData.switches : false}
                                onChange={(e) => props.updateConnectorData("connector", "switches", e.target.value === "true")}
                            >
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="switches"
                                    value={true}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.Yes}
                                />
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="switches"
                                    value={false}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.No}
                                />
                            </RadioGroup>
                            <Button
                                disabled={props.connectorData ? !props.connectorData.switches : true}
                                variant="contained"
                                component="label"
                                className={classes.uploadFileButton}
                                startIcon={<OpenInNewIcon color="primary" />}
                                onClick={handleOpenCustomerGMS}
                            >
                                {languageData?.OpenCustomerGMS}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Connector;
