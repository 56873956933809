import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";

import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "../routes";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";
import Navbar from "../components/Navbars/Navbar";
import WebsiteContext from "../utils/Website.js";
import { CircularProgress, Dialog } from "@material-ui/core";

import SnackbarProvider, { SnackbarContext } from "../utils/SnackbarContext";
import dashboardCustomerRoutes from "../customer_routes";
import dashboardToolMakerRoutes from "../tool_maker_routes";

const switchRoutes = (tmpRoutes, role) => (
    <Switch>
        {tmpRoutes.map((prop, key) => (
            <Route path={prop.path} render={(props) => <prop.component {...props} />} key={key} />
        ))}
        <Redirect from="/" to={role === 0 ? "/customers" : role === 1 ? "/customer/projects" : "/tools"} />
    </Switch>
);

const useStyles = makeStyles(styles);

const ErrorHandler = () => {
    const { setLoading, languageData, loading } = useContext(WebsiteContext);
    const Snackbar = useContext(SnackbarContext);

    Axios.interceptors.request.use(
        function (config) {
            if (!loading) {
                setLoading(true);
            }

            return config;
        },
        function (error) {
            setLoading(false);
            return Promise.reject(error);
        }
    );

    Axios.interceptors.response.use(
        function (response) {
            let loadingHeader = response?.config?.headers?.["X-Loading"];
            if (loadingHeader || loadingHeader === undefined) {
                setLoading(false);
            }
            if (response) {
                const { method } = response?.config;
                switch (method) {
                    case "put": {
                        if (response.config.url !== "/users/language") {
                            Snackbar.success(languageData.PutSuccess);
                        }
                        break;
                    }
                    case "post": {
                        if (!response.config.url.includes("tool-specification-resources") && !response.config.url.includes("quotation-request-resources")) {
                            if (response.config.url.includes("change-password")) {
                                Snackbar.success(languageData.PasswordSuccess);
                            } else {
                                Snackbar.success(languageData.PostSuccess);
                            }
                        }
                        break;
                    }
                    case "delete": {
                        Snackbar.success(languageData.DeleteSuccess);
                        break;
                    }
                    default:
                        break;
                }
            }
            return response;
        },
        function (error) {
            setLoading(false);

            const { response, config } = error;
            if (response) {
                const { data } = response;
                if (data?.error?.code) {
                    switch (data.error.code) {
                        case "E0010": {
                            Snackbar.error(languageData.Errors.E0010);
                            break;
                        }
                        case "E0011": {
                            Snackbar.error(languageData.Errors.E0011);
                            break;
                        }
                        case "E0012": {
                            Snackbar.error(languageData.Errors.E0012);
                            break;
                        }
                        case "E0013": {
                            if (localStorage.getItem("refresh_token")) {
                                Axios({
                                    url: "/auth/token-refresh",
                                    method: "POST",
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
                                    },
                                }).then((res) => {
                                    Axios.defaults.headers.Authorization = `Bearer ${res.data.data.accessToken}`;
                                    localStorage.setItem("accessToken", res.data.data.accessToken);
                                    localStorage.setItem("refreshToken", res.data.data.refreshToken);
                                    Axios({
                                        url: config.url,
                                        method: config.method,
                                        data: config.data,
                                        headers: {
                                            Authorization: `Bearer ${res.data.data.accessToken}`,
                                        },
                                    });
                                });
                            }

                            break;
                        }
                        case "E0014": {
                            Snackbar.error(languageData.Errors.E0014);
                            break;
                        }
                        case "E0015": {
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("refreshToken");
                            window.location.href = "/login";

                            break;
                        }
                        case "E0016": {
                            Snackbar.error(languageData.Errors.E0016);
                            break;
                        }
                        case "E0017": {
                            Snackbar.error(languageData.Errors.E0017);
                            break;
                        }
                        case "E0018": {
                            Snackbar.error(languageData.Errors.E0018);
                            break;
                        }
                        case "E0019": {
                            Snackbar.error(languageData.Errors.E0019);
                            break;
                        }
                        case "E0020": {
                            Snackbar.error(languageData.Errors.E0020);
                            break;
                        }

                        case "E0030": {
                            Snackbar.error(languageData.Errors.E0030);
                            break;
                        }
                        case "E0031": {
                            Snackbar.error(languageData.Errors.E0031);
                            break;
                        }
                        case "E0032": {
                            Snackbar.error(languageData.Errors.E0032);
                            break;
                        }
                        case "E0033": {
                            Snackbar.error(languageData.Errors.E0033);
                            break;
                        }
                        case "E0034": {
                            Snackbar.error(languageData.Errors.E0034);
                            break;
                        }

                        case "E0101": {
                            Snackbar.error(languageData.Errors.E0101);
                            break;
                        }
                        case "E0102": {
                            Snackbar.error(languageData.Errors.E0102);
                            break;
                        }
                        case "E0103": {
                            Snackbar.error(languageData.Errors.E0103);
                            break;
                        }
                        case "E0104": {
                            Snackbar.error(languageData.Errors.E0104);
                            break;
                        }

                        case "E0131": {
                            Snackbar.error(languageData.Errors.E0131);
                            break;
                        }
                        case "E0132": {
                            Snackbar.error(languageData.Errors.E0132);
                            break;
                        }
                        case "E0133": {
                            Snackbar.error(languageData.Errors.E0133);
                            break;
                        }
                        case "E0134": {
                            Snackbar.error(languageData.Errors.E0134);
                            break;
                        }
                        case "E0135": {
                            Snackbar.error(languageData.Errors.E0135);
                            break;
                        }
                        case "E0136": {
                            Snackbar.error(languageData.Errors.E0136);
                            break;
                        }
                        case "E0137": {
                            Snackbar.error(languageData.Errors.E0137);
                            break;
                        }
                        case "E0138": {
                            Snackbar.error(languageData.Errors.E0138);
                            break;
                        }
                        case "E0139": {
                            Snackbar.error(languageData.Errors.E0139);
                            break;
                        }
                        case "E0140": {
                            Snackbar.error(languageData.Errors.E0140);
                            break;
                        }
                        case "E0141": {
                            Snackbar.error(languageData.Errors.E0141);
                            break;
                        }
                        case "E0142": {
                            Snackbar.error(languageData.Errors.E0142);
                            break;
                        }
                        case "E0143": {
                            Snackbar.error(languageData.Errors.E0143);
                            break;
                        }
                        case "E0144": {
                            Snackbar.error(languageData.Errors.E0144);
                            break;
                        }
                        case "E0145": {
                            Snackbar.error(languageData.Errors.E0145);
                            break;
                        }
                        case "E0146": {
                            Snackbar.error(languageData.Errors.E0146);
                            break;
                        }
                        case "E0147": {
                            Snackbar.error(languageData.Errors.E0147);
                            break;
                        }
                        case "E0148": {
                            Snackbar.error(languageData.Errors.E0148);
                            break;
                        }
                        case "E0149": {
                            Snackbar.error(languageData.Errors.E0149);
                            break;
                        }
                        case "E0150": {
                            Snackbar.error(languageData.Errors.E0150);
                            break;
                        }

                        case "E0501": {
                            Snackbar.error(languageData.Errors.E0501);
                            break;
                        }
                        case "E0502": {
                            Snackbar.error(languageData.Errors.E0502);
                            break;
                        }
                        case "E0503": {
                            Snackbar.error(languageData.Errors.E0503);
                            break;
                        }
                        case "E0504": {
                            Snackbar.error(languageData.Errors.E0504);
                            break;
                        }
                        case "E0505": {
                            Snackbar.error(languageData.Errors.E0505);
                            break;
                        }
                        case "E0506": {
                            Snackbar.error(languageData.Errors.E0506);
                            break;
                        }
                        case "E0507": {
                            Snackbar.error(languageData.Errors.E0507);
                            break;
                        }
                        case "E0508": {
                            Snackbar.error(languageData.Errors.E0508);
                            break;
                        }
                        case "E0509": {
                            Snackbar.error(languageData.Errors.E0509);
                            break;
                        }
                        case "E0510": {
                            Snackbar.error(languageData.Errors.E0510);
                            break;
                        }
                        case "E0511": {
                            Snackbar.error(languageData.Errors.E0511);
                            break;
                        }

                        case "E9999": {
                            Snackbar.error(languageData.Errors.E9999);
                            break;
                        }

                        default:
                            Snackbar.error(languageData?.SomethingWentWrong);
                    }
                }
            }
            return Promise.reject(error);
        }
    );
    return <></>;
};

const Layout = ({ ...rest }) => {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);
    const { loading, role } = useContext(WebsiteContext);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return Axios.defaults.headers.Authorization ? (
        <div className={classes.wrapper}>
            <SnackbarProvider>
                <div className={classes.loading} style={{ display: loading === false && "none" }}>
                    <CircularProgress size={100} />
                </div>
                <ErrorHandler />
                <Sidebar
                    color={"blue"}
                    routes={role === 0 ? routes : role === 1 ? dashboardCustomerRoutes : role === 2 ? dashboardToolMakerRoutes : []}
                    handleDrawerToggle={handleDrawerToggle}
                    open={mobileOpen}
                    {...rest}
                />
                <div className={classes.mainPanel}>
                    <Navbar
                        routes={role === 0 ? routes : role === 1 ? dashboardCustomerRoutes : role === 2 ? dashboardToolMakerRoutes : []}
                        handleDrawerToggle={handleDrawerToggle}
                        {...rest}
                    />
                    <div className={classes.content}>
                        {switchRoutes(role === 0 ? routes : role === 1 ? dashboardCustomerRoutes : role === 2 ? dashboardToolMakerRoutes : [], role)}
                    </div>
                </div>
            </SnackbarProvider>
        </div>
    ) : (
        <></>
    );
};

export default Layout;
