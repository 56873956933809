import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import WebsiteContext from "../../../utils/Website";
import Axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import FormLayout from "../../../containers/FormLayout/FormLayout";
import StyledInput from "../../StyledInput/StyledInput";
import StyledButton from "../../StyledButton/StyledButton";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    dialogContentWrapper: {
        padding: 0,
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
    },
    shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        transformOrigin: "top left",
    },
    addRemoveButtons: {
        width: "100%",
    },
    saveButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    deleteButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    globalError: {
        color: "red",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    gridListWrapper: {
        height: "auto !important",
    },
}));

const UserModal = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);

    const [name, setName] = useState(props.addMode ? "" : props.name);
    const [email, setEmail] = useState(props.addMode ? "" : props.email);
    const [phoneNumber, setPhoneNumber] = useState(props.addMode ? "" : props.phoneNumber);
    const [department, setDepartment] = useState(props.addMode ? "" : props.department);
    const [nameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [departmentError] = useState("");
    const [globalError, setGlobalError] = useState("");
    const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);

    const handleCloseModal = () => {
        props.onClose(false);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(e.target.value)) {
            setPhoneNumberError("");
        } else {
            setPhoneNumberError(languageData?.PhoneNumberError);
        }
    };

    const handleAddUserEntity = () => {
        const data = {
            name: name,
            email: email,
            phoneNumber: phoneNumber,
            department: department,
        };
        const options = {
            url: props.match.url,
            method: "POST",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            props.onClose(true);
        });
    };

    const handleEditUserEntity = () => {
        const data = {
            name: name,
            email: email,
            phoneNumber: phoneNumber,
            department: department,
        };

        const options = {
            url: "/" + props.match.url.split("/")[1] + "/users/" + props.id,
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            props.onClose(true);
        });
    };

    const handleDeleteUserEntity = () => {
        if (!deleteButtonClicked) {
            setDeleteButtonClicked(true);
            setGlobalError(languageData?.DeleteWarningMessage);
        } else {
            const options = {
                url: "/" + props.match.url.split("/")[1] + "/users/" + props.id,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                props.onClose(true);
            });
        }
    };

    const handleCancelDeleteClicked = () => {
        setDeleteButtonClicked(false);
        setGlobalError("");
    };

    let buttons;

    if (props.addMode) {
        buttons = (
            <StyledButton variant="contained" type="submit" disabled={phoneNumberError !== ""} fullWidth className={classes.saveButton}>
                {languageData?.Save}
            </StyledButton>
        );
    } else {
        buttons = (
            <GridList cols={2}>
                <GridListTile style={{ height: "auto" }}>
                    <StyledButton variant="contained" fullWidth className={classes.deleteButton} onClick={handleDeleteUserEntity}>
                        {languageData?.Delete}
                    </StyledButton>
                </GridListTile>
                <GridListTile style={{ height: "auto" }}>
                    <StyledButton variant="contained" type="submit" fullWidth disabled={phoneNumberError !== ""} className={classes.saveButton}>
                        {deleteButtonClicked ? languageData?.Cancel : languageData?.Save}
                    </StyledButton>
                </GridListTile>
            </GridList>
        );
    }

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.addNew}>{props.title}</div>
            <div className={classes.formWrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (props.addMode) {
                            handleAddUserEntity();
                        } else if (deleteButtonClicked) {
                            handleCancelDeleteClicked();
                        } else {
                            handleEditUserEntity();
                        }
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={name}
                        label={languageData?.UserName}
                        className={classes.input}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        required={true}
                        helperText={nameError}
                        error={!!nameError}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={email}
                        label={languageData?.UserEmail}
                        className={classes.input}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required={true}
                        disabled={!props.addMode}
                        helperText={emailError}
                        error={!!emailError}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={phoneNumber}
                        label={languageData?.UserPhoneNumber}
                        className={classes.input}
                        onChange={(e) => handlePhoneNumberChange(e)}
                        type="text"
                        required={true}
                        helperText={phoneNumberError}
                        error={!!phoneNumberError}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={department}
                        label={languageData?.UserDepartment}
                        className={classes.input}
                        onChange={(e) => setDepartment(e.target.value)}
                        type="text"
                        required={true}
                        helperText={departmentError}
                        error={!!departmentError}
                    />
                    <div className={classes.globalError}>{globalError}</div>
                    {buttons}
                </FormLayout>
            </div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={() => props.onClose(false)}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

export default UserModal;
