import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, InputLabel } from "@material-ui/core";
import StyledInput from "./StyledInput/StyledInput";
import WebsiteContext from "../utils/Website";
import FormLayout from "../containers/FormLayout/FormLayout";
import StyledButton from "./StyledButton/StyledButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Axios from "axios";
import { Dialog, DialogContent } from "@material-ui/core";
import RoutingContext from "../utils/RoutingContext";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    dialogContentWrapper: {
        padding: 0,
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
        marginRight: 10,
    },
    logoWrapper: {
        marginBottom: 12,
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
    },
    shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        transformOrigin: "top left",
    },
    uploadButton: {
        width: "65%",
        marginTop: "10px",
        background: "#FFFFFF",
        font: "Montserrat",
        fontColor: "#646363",
        fontSize: "10px",
        fontWeight: 600,
        fontStyle: "normal",
        lineHeight: "15.42px",
        border: "0.6425px solid #CCCCCC",
        boxSizing: "border-box",
        borderRadius: "2.57px",
        boxShadow: "none",
    },
    saveButton: {
        backgroundColor: "#C00D0E",
        border: "0.6425px solid #CCCCCC",
        boxSizing: "border-box",
        borderRadius: "2.57px",
        width: "100%",
        font: "Montserrat",
        fortWeight: 700,
        fontStyle: "normal",
        fontSize: "10px",
        lineHeight: "15.42px",
    },
    logoName: {
        marginLeft: "10px",
        marginTop: "5px",
        fontSize: "10px",
        display: "inline",
    },
}));

const GenericModal = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const { entityPath } = useContext(RoutingContext);

    const [title, setTitle] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [logo, setLogo] = useState("");
    const [logoName, setLogoName] = useState("");
    const [nameError] = useState("");
    const [addressError] = useState("");
    const [globalError, setGlobalError] = useState("");

    const handleCloseModal = () => {
        props.onClose();
    };

    const handleAddEntity = () => {
        let options;
        if (props.addMode) {
            const data = {
                name: name,
                address: address,
                logo: logo,
            };
            options = {
                url: props.match.path,
                method: "POST",
                data: data,
                headers: {
                    "X-Loading": false,
                },
            };
        } else {
            const data = {
                name: name,
                address: address,
                logo: logo,
            };
            options = {
                url: entityPath,
                method: "PUT",
                data: data,
                headers: {
                    "X-Loading": false,
                },
            };
        }
        Axios(options).then((_) => {
            handleCloseModal();
            props.getData();
        });
    };

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const uploadFile = (event) => {
        let file = event.target.files[0];
        setLogoName(file.name);
        getBase64(file, (result) => {
            setLogo(result);
        });
    };

    useEffect(() => {
        if (props.addMode) {
            setName("");
            setAddress("");
            setLogo("");
            if (entityPath.includes("customer")) {
                setTitle(languageData?.AddNewClient);
            } else {
                setTitle(languageData?.AddNewToolMaker);
            }
        } else {
            setName(props.name);
            setAddress(props.address);
            setLogo(props.logo);
            if (entityPath.includes("customer")) {
                setTitle(languageData?.EditClient);
            } else {
                setTitle(languageData?.EditToolMaker);
            }
        }
    }, [props.open]);

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.addNew}>{title}</div>
            <div className={classes.formWrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddEntity();
                    }}
                >
                    <div>
                        <StyledInput
                            showLabel
                            defaultValue={name}
                            label={languageData?.Name}
                            className={classes.input}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            required={true}
                            helperText={nameError}
                            error={!!nameError}
                        />
                        <StyledInput
                            showLabel
                            defaultValue={address}
                            label={languageData?.Address}
                            className={classes.input}
                            onChange={(e) => setAddress(e.target.value)}
                            type="text"
                            required={true}
                            helperText={addressError}
                            error={!!addressError}
                        />
                        <div className={classes.logoWrapper}>
                            <InputLabel classes={{ root: classes.root, shrink: classes.shrink }} shrink>
                                {languageData?.Logo}
                            </InputLabel>
                            <Button
                                className={classes.uploadButton}
                                variant="contained"
                                component="label"
                                color="default"
                                startIcon={<CloudUploadIcon color="primary" />}
                            >
                                {logo !== "" ? languageData?.ChangeFile : languageData?.UploadFile}
                                <input type="file" accept="image/*" onChange={(e) => uploadFile(e)} style={{ display: "none" }} />
                            </Button>
                            <div className={classes.logoName}>{logoName.length < 15 ? logoName : logoName.substring(0, 13) + "..."}</div>
                        </div>
                    </div>
                    {globalError}
                    <StyledButton color="primary" variant="contained" type="submit" fullWidth>
                        {languageData?.Save}
                    </StyledButton>
                </FormLayout>
            </div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

GenericModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
};

export default GenericModal;
