import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WebsiteContext from "../../../utils/Website";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Axios from "axios";
import { Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import RoutingContext from "../../../utils/RoutingContext";
import SamplingFlowProcess from "./SamplingFlowProcess/SamplingFlowProcess";
import InjectionReport from "./InjectionReport/InjectionReport";
import CoolingLayout from "./CoolingLayout/CoolingLayout";

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    padding: {
        padding: 9,
        height: "calc(100% - 67px)",
    },
}));

const MoldTrailFOT = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role, language } = useContext(WebsiteContext);
    const { setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData, setStepData } = useContext(RoutingContext);

    useEffect(() => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}`,
            method: "GET",
            headers: {
                "X-Locale": language,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data.processes;
            setRoutingData();
            setStepData({
                visibleContent: true,
                id: props.match.params.stepNumber,
                toolId: props.match.params.id,
                name: data.name,
                state: data.state,
                canExport: false,
                canClose: false,
            });
            if (role === 0) {
                setBackPath(`/customers/projects/tools/${props.match.params.id}/process`);
            } else if (role === 1) {
                setBackPath(`/project/tools/${props.match.params.id}/process`);
            } else if (role === 2) {
                setBackPath(`/tool/${props.match.params.id}/process`);
            }
        });
    }, [language]);

    return (
        <React.Fragment>
            <Divider />
            {/* <Divider /> */}
            <Tabs value={props.location.pathname} className={classes.wrapper} variant="scrollable" indicatorColor={"primary"} aria-label="simple tabs example">
                <Tab
                    label={languageData?.SamplingFlowProcess.toLocaleUpperCase()}
                    component={Link}
                    to={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/sampling-flow-process`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/sampling-flow-process`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/sampling-flow-process`
                    }
                    value={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/sampling-flow-process`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/sampling-flow-process`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/sampling-flow-process`
                    }
                />
                <Tab
                    label={languageData?.CoolingLayout.toLocaleUpperCase()}
                    component={Link}
                    to={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/cooling-layout`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/cooling-layout`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/cooling-layout`
                    }
                    value={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/cooling-layout`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/cooling-layout`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/cooling-layout`
                    }
                />
                <Tab
                    label={languageData?.InjectionReport.toLocaleUpperCase()}
                    component={Link}
                    to={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/injection-report`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/injection-report`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/injection-report`
                    }
                    value={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/injection-report`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/injection-report`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/injection-report`
                    }
                />
            </Tabs>
            <div className={classes.padding}>
                <Switch>
                    <Route
                        path={
                            role === 0
                                ? `/customers/projects/tools/:id/process/step/:stepNumber/sampling-flow-process`
                                : role === 1
                                ? `/project/tools/:id/process/step/:stepNumber/sampling-flow-process`
                                : `/tool/:id/process/step/:stepNumber/sampling-flow-process`
                        }
                        render={(props) => <SamplingFlowProcess {...props} />}
                    />
                    <Route
                        path={
                            role === 0
                                ? `/customers/projects/tools/:id/process/step/:stepNumber/cooling-layout`
                                : role === 1
                                ? `/project/tools/:id/process/step/:stepNumber/cooling-layout`
                                : `/tool/:id/process/step/:stepNumber/cooling-layout`
                        }
                        render={(props) => <CoolingLayout {...props} />}
                    />
                    <Route
                        path={
                            role === 0
                                ? `/customers/projects/tools/:id/process/step/:stepNumber/injection-report`
                                : role === 1
                                ? `/project/tools/:id/process/step/:stepNumber/injection-report`
                                : `/tool/:id/process/step/:stepNumber/injection-report`
                        }
                        render={(props) => <InjectionReport {...props} />}
                    />
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default MoldTrailFOT;
