import { Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import RoutingContext from "../../../../utils/RoutingContext";
import Axios from "axios";
import FormLayout from "../../../../containers/FormLayout/FormLayout";
import StyledButton from "../../../StyledButton/StyledButton";
import StyledInput from "../../../StyledInput/StyledInput";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CavityParams from "./CavityParams/CavityParams";
import Evaluation from "./Evaluation/Evaluation";
import Comment from "../MoldChecklist/Comment/Comment";
import { Alert } from "@material-ui/lab";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100% - 18px)",
        padding: 9,
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        // marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginRight: 5,
    },
    divider: {
        width: "100%",
    },
    buttonWrapper: {
        padding: 18,
        background: "white",
    },
    sectionTitle: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        margin: "18px 0 18px 0",
    },
    sectionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    gridWrapper: {},
    questionWrapper: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        width: 210,
        marginBottom: 5,
        marginTop: 16,
    },
    answerWrapper: {
        width: 210,
    },
    copyButton: {
        borderRadius: "3px",
        width: "auto",
        // marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginRight: "10px",
    },
    textSectionWrapper: {
        marginTop: 40,
        marginBottom: 20,
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        margin: "5px 10px 5px",
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    checked: {},
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    messageGrid: {
        height: "100%",
        width: "100%",
        justifyContent: "start",
        alignItems: "flex-end",
        display: "flex",
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "12px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: 10,
        },
    },
    switchContent: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "10px",
        alignItems: "center",
    },
}));

const FinalRunReport = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role } = useContext(WebsiteContext);
    const { setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData, setStepData } = useContext(RoutingContext);
    const [data, setData] = useState(null);
    const [nrOfCavities, setNrOfCavities] = useState(null);
    const [currentNrOfCavities, setCurrentNrOfCavities] = useState(null);
    const [files, setFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);

    const getData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-release-process/final-run-report`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setFiles([]);
            setFilesToDelete([]);
            const rspData = response.data.data;
            setData(rspData);
            let qIndex = -1;
            const index = rspData?.overview.findIndex((el) => {
                const cavityIndex = el.questions.findIndex((q) => q.question === "Number of cavities");
                if (cavityIndex > -1) {
                    qIndex = cavityIndex;
                    return true;
                } else return false;
            });
            if (index > -1) {
                setNrOfCavities(rspData.overview[index].questions[qIndex].answer);
                setCurrentNrOfCavities(rspData.overview[index].questions[qIndex].answer);
            }
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const saveMedia = async () => {
        const tmpData = { ...data };
        filesToDelete.forEach((file) => {
            const options = {
                url: "/file-operations/" + `${props.match.params.id}-mold-process-release` + `/files/${file}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((response) => {});
        });

        if (files.length === 0) {
            handleSubmit(tmpData);
        } else {
            for (const file of files) {
                const idx = files.findIndex((el) => el === file);
                if (file.fileToBeDeleted !== "") {
                    const deleteOptions = {
                        url: "/file-operations/" + `${props.match.params.id}-mold-process-release` + `/files/${file.fileToBeDeleted}`,
                        headers: {
                            "X-Loading": false,
                        },
                        method: "DELETE",
                    };
                    await Axios(deleteOptions);
                    let filesFormatData = new FormData();
                    filesFormatData.append("files", file.file, file.file.name);

                    const options = {
                        url: "/file-operations/" + `${props.match.params.id}-mold-process-release` + "/files",
                        method: "POST",
                        data: filesFormatData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    const response = await Axios(options);

                    if (response.status === 200) {
                        tmpData.evaluation[file.index].fileId = response.data.data.files[0].id;
                        tmpData.evaluation[file.index].fileName = response.data.data.files[0].name;
                        if (idx === files.length - 1) {
                            handleSubmit(tmpData);
                        }
                    }
                } else {
                    let filesFormatData = new FormData();
                    filesFormatData.append("files", file.file, file.file.name);

                    const options = {
                        url: "/file-operations/" + `${props.match.params.id}-mold-process-release` + "/files",
                        method: "POST",
                        data: filesFormatData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    const response = await Axios(options);
                    if (response.status === 200) {
                        tmpData.evaluation[file.index].fileId = response.data.data.files[0].id;
                        tmpData.evaluation[file.index].fileName = response.data.data.files[0].name;
                        if (idx === files.length - 1) {
                            handleSubmit(tmpData);
                        }
                    }
                }
            }
        }
    };

    const handleSubmit = (tmpData) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-release-process/final-run-report`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: tmpData,
        };
        Axios(options).then((response) => {
            getData();
        });
    };

    const changeInput = (section, sectionIndex, questionIndex, value) => {
        const tmp = { ...data };
        tmp[section][sectionIndex].questions[questionIndex].answer = value;
        setData(tmp);
    };

    const handleCopy = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-release-process/final-run-report`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            getData();
        });
    };

    const defaultSection = (section, name) => {
        return (
            <div>
                {section?.map((section, sectionIndex) => {
                    if (section.name === "2. Component" && props.visible === false) {
                        return <div />;
                    }
                    return (
                        <div className={classes.sectionWrapper} key={sectionIndex}>
                            <div className={classes.sectionTitle}>{section.name}</div>
                            <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                {section.questions.map((question, questionIndex) => {
                                    if (question.question !== "Number of cavities") {
                                        if (question.question === "2Comp. Mold" && props.visible === false) {
                                            return <div />;
                                        }
                                        return (
                                            <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                    <StyledInput
                                                        disabled={role === 1}
                                                        defaultValue={question.answer}
                                                        onChange={(e) => changeInput(name, sectionIndex, questionIndex, e.target.value)}
                                                        className={classes.answerWrapper}
                                                        type="text"
                                                    />
                                                </div>
                                            </Grid>
                                        );
                                    } else {
                                        return (
                                            <Grid item container xs={12} sm={12} md={12} lg={12} key={questionIndex}>
                                                <Grid item container xs={12} sm={12} md={4} lg={3}>
                                                    <div className={classes.sectionWrapper}>
                                                        <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                        <StyledInput
                                                            disabled={role === 1 || (nrOfCavities !== "" && nrOfCavities !== null)}
                                                            defaultValue={question.answer}
                                                            onChange={(e) => {
                                                                changeInput(name, sectionIndex, questionIndex, e.target.value);
                                                                setCurrentNrOfCavities(e.target.value);
                                                            }}
                                                            className={classes.answerWrapper}
                                                            type="text"
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item container xs={12} sm={12} md={8} lg={9}>
                                                    <div className={classes.messageGrid}>
                                                        <Alert severity="warning">{languageData?.NumberOfCavitiesMessage}</Alert>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                })}
                            </Grid>
                        </div>
                    );
                })}
            </div>
        );
    };

    const saveData = (section, value) => {
        const tmpData = { ...data };
        tmpData[section] = value;
        setData(tmpData);
    };

    return (
        <div className={classes.wrapper}>
            <FormLayout
                onSubmit={async (e) => {
                    e.preventDefault();
                    await saveMedia();
                }}
                className={classes.layoutWrapper}
            >
                {(role === 0 || role === 2) && (
                    <div className={classes.buttonWrapper}>
                        <StyledButton className={classes.button} color="primary" variant="contained" type="submit">
                            {languageData?.Save.toUpperCase()}
                        </StyledButton>
                        <StyledButton
                            className={classes.copyButton}
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                handleCopy();
                            }}
                        >
                            {languageData?.CopyFromSamplingProcess.toUpperCase()}
                        </StyledButton>
                    </div>
                )}
                <div className={classes.switchContent}>
                    <Switch checked={props.visible} color="primary" onChange={(e) => props.changeVisibility(e.target.checked)} />
                    <div className={classes.textSectionWrapper} style={{ marginBottom: "10px", marginTop: "10px" }}>
                        {languageData?.EnableSecondComponentVisibility}
                    </div>
                </div>
                <div className={classes.contentWrapper}>
                    {defaultSection(data?.overview, "overview")}
                    {defaultSection(data?.componentParameters, "componentParameters")}
                    <div className={classes.textSectionWrapper}>
                        {data?.textList.map((text) => (
                            <div key={text}>{text}</div>
                        ))}
                    </div>
                    {data?.setupParams.params.map((section, sectionIndex) => {
                        return (
                            <div className={classes.sectionWrapper} key={sectionIndex}>
                                <div className={classes.sectionTitle}>{section.name}</div>
                                <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                    {section.questions.map((question, questionIndex) => {
                                        return (
                                            <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                    <StyledInput
                                                        disabled={role === 1}
                                                        defaultValue={question.answer}
                                                        onChange={(e) => {
                                                            const tmp = { ...data };
                                                            tmp.setupParams.params[sectionIndex].questions[questionIndex].answer = e.target.value;
                                                            setData(tmp);
                                                        }}
                                                        className={classes.answerWrapper}
                                                        type="text"
                                                    />
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        );
                    })}
                    <div className={classes.sectionWrapper}>
                        <div className={classes.questionWrapper}>{languageData && languageData[data?.setupParams.automaticRun.name]}</div>
                        <RadioGroup
                            className={classes.radioButtonsWrapper}
                            value={data && data?.setupParams.automaticRun.value}
                            onChange={(e) => {
                                const tmp = { ...data };
                                tmp.setupParams.automaticRun.value = e.target.value === "true";
                                setData(tmp);
                            }}
                        >
                            <FormControlLabel
                                disabled={role === 1}
                                classes={{ label: classes.textWrapper }}
                                name="switches"
                                value={true}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.Yes}
                            />
                            <FormControlLabel
                                disabled={role === 1}
                                classes={{ label: classes.textWrapper }}
                                name="switches"
                                value={false}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.No}
                            />
                        </RadioGroup>
                    </div>
                    <CavityParams {...props} data={data} nrOfCavities={nrOfCavities} saveData={saveData} currentNrOfCavities={currentNrOfCavities} />
                    <Evaluation
                        {...props}
                        changeObject={saveData}
                        data={data?.evaluation}
                        files={files}
                        setFiles={setFiles}
                        filesToDelete={filesToDelete}
                        setFilesToDelete={setFilesToDelete}
                    />
                </div>
            </FormLayout>
        </div>
    );
};

export default FinalRunReport;
