import React from "react";
import { Helmet } from "react-helmet";
import Project from "../../components/Projects/Project";

const ProjectView = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Project</title>
            </Helmet>
            <Project />
        </React.Fragment>
    );
};

export default ProjectView;
