const gridStyle = (theme) => ({
    wrapper: {
        padding: 18,
    },
    messageGrid: {
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "12px",
        textAlign: "center",
    },
    grid: {
        height: "100%",
        padding: 9,
    },
    gridAuto: {
        height: "auto",
        padding: 9,
        paddingBottom: 18,
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#A5A5A5",
        textAlign: "center",
    },
    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContext: "center",
        padding: "25px 0",
    },
    root: {
        minHeight: 147,
        minWidth: 219,
        float: "left",
        cursor: "pointer",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "1.35951px",
    },
    icon: {
        fontSize: 60,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },

    gridSpacing: {
        padding: 7,
        margin: "0 !important",
    },
    tile: {
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px",
    },
    tabCardsWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        height: "auto",
        paddingBottom: "16px",
    },
    cardsWrapper: {
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        padding: "16px",
    },
    toolCardWrapper: {
        display: "flex",
        flexWrap: "wrap",
        margin: "0px 0px",
        gap: 16,
    },
});

export default gridStyle;
