import React, { useEffect, useState } from "react";
// import { FrappeGantt } from "frappe-gantt-react";
import { makeStyles } from "@material-ui/core";
import Gantt from "./FrappeGantt";
import "../../assets/css/frappe-gantt.css";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        "& .gantt-target": {
            "& .gantt-container": {
                overflow: "scroll",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                minWidth: 0,
                wordWrap: "break-word",
                backgroundColor: "#fff",
                backgroundClip: "border-box",
                border: "1px solid rgba(0,0,0,0.125)",
                borderRadius: "4px",
            },
            "& .gantt": {
                overflow: "hidden",
                verticalAlign: "middle",
                height: (props) => (props?.ganttHeight > 0 ? props.ganttHeight : "auto"),
            },
            // "&>div": {
            //     overflow: "hidden !important",
            // },
            "& .bar-label": {
                fontWeight: "normal",
            },
            "& .handle-group": {
                display: "none",
            },
            "& .bar-wrapper.bar-milestone .bar-progress": {
                fill: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            "& .bar-wrapper.bar-milestone:hover .bar-progress": {
                fill: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            "& .bar-wrapper.bar-milestone.active .bar-progress": {
                fill: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            "& .bar-progress": {
                fill: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
            "& .bar-wrapper:hover .bar-progress": {
                fill: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
            "& .bar-wrapper.active .bar-progress": {
                fill: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
            // "& .bar-wrapper.bar-in-time .bar-progress": {
            //     fill: "#0DC08A",
            //     color: theme.palette.common.white,
            // },
            // "& .bar-wrapper.bar-in-time:hover .bar-progress": {
            //     fill: "#0DC08A",
            //     color: theme.palette.common.white,
            // },
            // "& .bar-wrapper.bar-in-time.active .bar-progress": {
            //     fill: "#0DC08A",
            //     color: theme.palette.common.white,
            // },
            // "& .bar-wrapper.bar-out-of-time .bar-progress": {
            //     fill: "#C00D0E",
            //     color: theme.palette.common.white,
            // },
            // "& .bar-wrapper.bar-out-of-time:hover .bar-progress": {
            //     fill: "#C00D0E",
            //     color: theme.palette.common.white,
            // },
            // "& .bar-wrapper.bar-out-of-time.active .bar-progress": {
            //     fill: "#C00D0E",
            //     color: theme.palette.common.white,
            // },
            // "& .bar-wrapper.bar-completed .bar-progress": {
            //     fill: "#636362",
            //     color: theme.palette.common.white,
            // },
            // "& .bar-wrapper.bar-completed:hover .bar-progress": {
            //     fill: "#636362",
            //     color: theme.palette.common.white,
            // },
            // "& .bar-wrapper.bar-completed.active .bar-progress": {
            //     fill: "#636362",
            //     color: theme.palette.common.white,
            // },
        },
    },
}));

const Chart = (props) => {
    const classes = useStyles({
        ganttHeight: props.tasks.length > 0 ? props.tasks.length * 38 + 60 : 0,
    });
    const [ganttChart, setGanttChart] = useState(null);

    useEffect(() => {
        if (ganttChart === null) {
            setGanttChart(
                new Gantt(".gantt-target", props.tasks, {
                    on_click: (task) => {
                        props.onClick();
                    },
                    on_date_change: (task, start, end) => {
                        props.onDateChange();
                    },
                    on_progress_change: function (task, progress) {},
                    on_view_change: function (mode) {},
                    view_mode: props.viewMode,
                    language: "en",
                })
            );
        }
    }, []);

    useEffect(() => {
        if (ganttChart) {
            ganttChart.refresh(props.tasks);
        }
    }, [props.tasks]);

    return (
        <div className={classes.wrapper}>
            <div className="gantt-target" />
        </div>
    );
};

export default Chart;
