import { container, drawerWidth, transition } from "../../material-dashboard-react";

const appStyle = (theme) => ({
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh",
        // maxWidth: "1366px",
        margin: "auto",
        backgroundColor: "#f8f8f8",
    },
    mainPanel: {
        [theme.breakpoints.up("md")]: {
            // maxWidth: `calc(1366px - ${drawerWidth}px)`,
            width: `calc(100% - ${drawerWidth}px)`,
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        height: "100%",
        width: "100%",
        overflowScrolling: "touch",
    },
    content: {
        marginTop: "60px",
        padding: 0,
        height: "calc(100% - 60px)",
        backgroundColor: "#f8f8f8",
    },
    container,
    map: {
        marginTop: "70px",
    },
    loading: {
        height: "100vh",
        width: "100vw",
        position: "absolute",
        backgroundColor: "#000000",
        opacity: 0.4,
        zIndex: 10000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});

export default appStyle;
