import React from "react";
import { Helmet } from "react-helmet";
import Tool from "../../components/Tools/Tool";

const ToolView = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Tool</title>
            </Helmet>
            <Tool />
        </React.Fragment>
    );
};

export default ToolView;
