import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import logo from "../../assets/img/logo.png";
import FormLayout from "../../containers/FormLayout/FormLayout";
import StyledInput from "../StyledInput/StyledInput";
import StyledButton from "../StyledButton/StyledButton";
import { Link } from "react-router-dom";
import Axios from "axios";
import WebsiteContext from "../../utils/Website";

const useStyles = makeStyles((theme) => ({
    form: {
        background: "rgba(255, 255, 255, 0.9)",
        boxShadow: "0px 1.9275px 3.2125px rgba(0, 0, 0, 0.05), 0px 3.2125px 5.14px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
    },
    wrapper: {
        padding: "73px 150px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    image: {
        margin: "0 auto 50px auto",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    forgotPassword: {
        textAlign: "right",
        fontSize: 10,
        lineHeight: "12px",
        color: "#00A7D1",
        marginBottom: 10,
        cursor: "pointer",
        fontWeight: 500,
        fontStyle: "normal",
    },
    error: {
        color: "red",
        fontSize: 10,
        lineHeight: "12px",
        fontWeight: 500,
        fontStyle: "normal",
    },
}));

const LoginForm = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [globalError, setGlobalError] = useState("");
    const { languageData, setIsDefaultPassword, setRole, role, setLanguage } = useContext(WebsiteContext);

    const classes = useStyles(useStyles);

    const handleLogin = () => {
        const data = {
            email: email,
            password: password,
        };
        const options = {
            url: "/auth/login",
            method: "POST",
            data: data,
        };
        Axios(options)
            .then((response) => {
                const { data } = response.data;
                Axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
                setEmailError("");
                setPasswordError("");
                setGlobalError("");
                localStorage.setItem("accessToken", data.accessToken);
                localStorage.setItem("refreshToken", data.refreshToken);
                localStorage.setItem("name", data.userName);
                localStorage.setItem("role", data.role);
                localStorage.setItem("language", data.language);
                localStorage.setItem("id", data.id);
                if (data.customerId === "None") {
                    localStorage.setItem("customerId", "");
                } else {
                    localStorage.setItem("customerId", data.customerId);
                }
                setLanguage(data.language);
                setRole(data.role);

                setIsDefaultPassword(data.defaultPassword);

                if (data.defaultPassword) {
                    props.history.push("/user/change-password");
                } else {
                    if (data.role === 0) {
                        props.history.push("/");
                    } else if (data.role === 1) {
                        props.history.push("/customer/projects");
                    } else if (data.role === 2) {
                        props.history.push("/tools");
                    }
                }
            })
            .catch((err) => {
                if (err?.response?.data?.error?.code === "E0010") {
                    setEmailError(err.response.data.error.message);
                    setPasswordError("");
                    setGlobalError("");
                } else if (err?.response?.data?.error?.code === "E0011") {
                    setEmailError("");
                    setPasswordError(err.response?.data?.error?.message);
                    setGlobalError("");
                } else {
                    setGlobalError(err?.response?.data?.error?.message || languageData?.SomethingWentWrong);
                }
            });
    };

    return (
        <div className={classes.form}>
            <div className={classes.wrapper}>
                <img src={logo} alt="Spannagl logo" className={classes.image} />
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleLogin();
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={email}
                        label={languageData?.Email}
                        className={classes.input}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required={true}
                        helperText={emailError}
                        error={!!emailError}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={password}
                        label={languageData?.Password}
                        className={classes.input}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        required={true}
                        helperText={passwordError}
                        error={!!passwordError}
                    />
                    <Link to="/forgot-password">
                        <div className={classes.forgotPassword}>{languageData?.ForgotPassword}?</div>
                    </Link>
                    {globalError && <div className={classes.error}>{globalError}</div>}
                    <StyledButton color="secondary" variant="contained" type="submit" fullWidth>
                        {languageData?.Login}
                    </StyledButton>
                </FormLayout>
            </div>
        </div>
    );
};

export default LoginForm;
