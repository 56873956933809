import React from "react";
import { Helmet } from "react-helmet";
import ToolMakers from "../../components/ToolMakers/ToolMakers";

const ToolMakersView = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Mold Makers</title>
            </Helmet>
            <ToolMakers {...props} />
        </React.Fragment>
    );
};

export default ToolMakersView;
