import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FormLayout from "../../../containers/FormLayout/FormLayout";
import StyledInput from "../../StyledInput/StyledInput";
import { makeStyles } from "@material-ui/core/styles";
import WebsiteContext from "../../../utils/Website";
import StyledButton from "../../StyledButton/StyledButton";
import Axios from "axios";
import PropTypes from "prop-types";
import RoutingContext from "../../../utils/RoutingContext";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    dialogContentWrapper: {
        padding: 0,
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    saveButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
}));

const ProjectModal = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);

    const [name, setName] = useState("");
    const [projectNo, setProjectNo] = useState("");
    const { entityPath } = useContext(RoutingContext);
    // const [nameError, setNameError] = useState("");

    const handleCloseModal = () => {
        props.onClose();
    };

    const handleAddProject = () => {
        const data = {
            name: name,
            project_number: projectNo,
        };
        let options;
        if (props.addMode) {
            options = {
                url: props.match.url,
                method: "POST",
                data: data,
                headers: {
                    "X-Loading": false,
                },
            };
        } else {
            options = {
                url: entityPath,
                method: "PUT",
                data: data,
                headers: {
                    "X-Loading": false,
                },
            };
        }
        Axios(options).then((_) => {
            props.onClose();
            props.getData();
        });
    };

    useEffect(() => {
        if (!props.addMode) {
            setName(props.name);
            setProjectNo(props.projectNo);
        }
    }, []);

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.addNew}>{props.addMode ? languageData?.AddNewProject : languageData?.EditProject}</div>
            <div className={classes.formWrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleAddProject();
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={name}
                        label={languageData?.Name}
                        className={classes.input}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={projectNo}
                        label={languageData?.ProjectNumber}
                        className={classes.input}
                        onChange={(e) => setProjectNo(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <StyledButton variant="contained" type="submit" fullWidth className={classes.saveButton}>
                        {languageData?.Save}
                    </StyledButton>
                </FormLayout>
            </div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

ProjectModal.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    customerId: PropTypes.string,
    onClose: PropTypes.func,
};

export default ProjectModal;
