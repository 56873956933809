import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";

import { makeStyles } from "@material-ui/core";

import Users from "./Users/Users";

import WebsiteContext from "../../utils/Website";
import RoutingContext from "../../utils/RoutingContext";

const useStyles = makeStyles((theme) => ({
    usersStyle: {
        padding: 16,
        paddingBottom: 0,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "15px",
        lineHeight: "15px",
        color: theme.palette.primary.main,
        marginBottom: "10px",
    },
}));

const Admin = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const { setCustomerData, setEntityPath, setProjectData, setBackPath, setToolData, setRoutingData } = useContext(RoutingContext);

    useEffect(() => {
        setRoutingData();
    }, []);

    return (
        <div className={classes.usersStyle}>
            <div className={classes.titleWrapper}>{languageData?.Admins}</div>
            <div>
                <Users {...props} />
            </div>
        </div>
    );
};

export default withRouter(Admin);
