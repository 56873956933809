import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card, makeStyles, Tooltip } from "@material-ui/core";

import LinearProgressWithLabel from "../../../LinearProgress/LinearProgressWithLabel";
import MoldProductionIcon from "../../../Icons/MoldProductionIcon";

import WebsiteContext from "../../../../utils/Website";

const useStyles = makeStyles(() => ({
    cardWrapper: {
        height: "100%",
        display: "grid",
        gridTemplateRows: "calc(50% - 2px) 4px calc(50% - 2px)",
    },
    root: {
        width: 219,
        height: 147,
        display: "block",
        float: "left",
        cursor: "pointer",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "1.35951px",
    },
    name: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "15px",
        overflow: "hidden",
        maxHeight: 30,
        lineClamp: 2,
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
    },
    state: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#B1B1B1",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    details: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        padding: "12px 16px 10px 16px",
        maxHeight: 39,
        overflow: "hidden",
        lineClamp: 4,
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
    },
    nameWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    titleWrapper: {
        padding: "8px 15px 12px",
        display: "grid",
        gridTemplateColumns: "27px 152px",
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
    },
    divider: {
        width: "100%",
    },
    icons: {
        color: "#C00D0E",
        height: "13px",
        weight: "13px",
    },
    cardIcon: {
        fill: "#C00D0E",
    },
}));

const ProductionToolEntityCard = (props) => {
    const classes = useStyles(useStyles);
    const [id, setId] = useState("");
    const { role } = useContext(WebsiteContext);

    useEffect(() => {
        setId(props.id);
    }, [props.id]);

    const handleClickEvent = () => {
        props.history.push((role === 0 ? "/customers/projects/tools/" + id : role === 1 ? "/project/tools/" + id : "/tool/" + id) + props.path);
    };

    return (
        <Card classes={{ root: classes.root }} onClick={handleClickEvent}>
            <div className={classes.cardWrapper}>
                <div className={classes.titleWrapper}>
                    <MoldProductionIcon className={classes.cardIcon} />
                    <div className={classes.nameWrapper}>
                        <Tooltip title={props.name}>
                            <div className={classes.name}>{props.name}</div>
                        </Tooltip>
                        <div className={classes.state}>{props.state}</div>
                    </div>
                </div>
                <LinearProgressWithLabel style={{ backgroundColor: "#ECECEC" }} width="100%" value={props.progress} statecolor={props.statecolor} />
                <Tooltip title={props.description}>
                    <div className={classes.details}>{props.description}</div>
                </Tooltip>
            </div>
        </Card>
    );
};

ProductionToolEntityCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
};

export default ProductionToolEntityCard;
