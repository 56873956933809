import StyledButton from "../../../../StyledButton/StyledButton";
import { Grid, makeStyles } from "@material-ui/core";
import StyledInput from "../../../../StyledInput/StyledInput";
import FormLayout from "../../../../../containers/FormLayout/FormLayout";
import React, { useContext, useState } from "react";
import styles from "../../../../../assets/jss/material-dashboard-react/components/DFMReviewListStyle";
import WebsiteContext from "../../../../../utils/Website";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ExpandMoreRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddMediaModal from "../../../Common/AddMediaModal";
import EditModal from "../../../Common/EditModal";
import DeleteModal from "../../../Common/DeleteModal";
import Axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(styles);

const InjectionParameters = (props) => {
    const classes = useStyles();
    const { role, languageData } = useContext(WebsiteContext);
    const [openUpload, setOpenUpload] = useState("");
    const [openEditor, setOpenEditor] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [elementToEdit, setElementToEdit] = useState(null);

    const handleSubmit = () => {
        props.submitData();
    };

    const changeInput = (section, sectionIndex, array, questionIndex, field, value) => {
        const tmpData = { ...props.data };
        if (questionIndex !== null) {
            tmpData[section][sectionIndex][array][questionIndex][field] = value;
        } else {
            tmpData[section][sectionIndex][array][field] = value;
        }
        props.changeObject("injectionParameters", tmpData);
    };

    const changeSimpleInput = (section, index, field, value) => {
        const tmpData = { ...props.data };
        tmpData[section][index][field] = value;
        props.changeObject("injectionParameters", tmpData);
    };

    const changeScrewBarrelInput = (index, section, sectionIndex, array, questionIndex, field, value) => {
        const tmpData = { ...props.data };
        if (sectionIndex !== null) {
            tmpData.screw_barrels[index][section][sectionIndex][array][questionIndex][field] = value;
            props.changeObject("injectionParameters", tmpData);
        } else {
            tmpData.screw_barrels[index][section][array][questionIndex][field] = value;
            props.changeObject("injectionParameters", tmpData);
        }
    };

    const handleDelete = () => {
        const options = {
            url:
                "/file-operations/" +
                `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` +
                `/files/${elementToDelete.fileId}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            const tmpData = { ...props.data };
            const index = tmpData.media.findIndex((el) => el.fileId === elementToDelete.fileId);
            tmpData.media.splice(index, 1);
            setElementToDelete(null);
            props.changeObject("injectionParameters", tmpData);
            handleSubmit();
        });
    };

    const handleCancelDelete = () => {
        setElementToDelete(null);
        setItemToDelete(null);
    };

    const uploadFile = (file) => {
        let filesFormatData = new FormData();
        filesFormatData.append("files", file, file.name);

        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` + "/files",
            method: "POST",
            data: filesFormatData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            const tmpData = { ...props.data };
            tmpData.media.push({
                fileId: response.data.data.files[0].id,
                type: 0,
            });
            props.changeObject("injectionParameters", tmpData);
            setOpenUpload("");
            handleSubmit();
        });
    };

    const saveMedia = (formData) => {
        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` + "/files",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            const tmpData = { ...props.data };
            tmpData.media.push({
                fileId: response.data.data.files[0].id,
                type: 0,
            });
            props.changeObject("injectionParameters", tmpData);
            setOpenEditor(false);
            setElementToEdit(null);
            setOpenUpload("");
            handleSubmit();
        });
    };

    const handleCopy = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${props.match.params.injectionNumber}/injection-parameters/overview`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            props.getData();
        });
    };

    const defaultSection = (list, sectionName) => {
        return (
            <div>
                {list?.map((section, sectionIndex) => {
                    if (section.name === "2. Component" && props.visible === false) {
                        return <div />;
                    }
                    if (sectionIndex === 2 && props.visible === false) {
                        return <div />;
                    }
                    return (
                        <div className={classes.sectionWrapper} key={sectionIndex}>
                            {section.name !== "" && <div className={classes.sectionTitle}>{section.name}</div>}
                            <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                {section.questions.map((question, questionIndex) => {
                                    if (question.question === "2Comp. Mold" && props.visible === false) {
                                        return <div />;
                                    }
                                    return (
                                        <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                            <div className={classes.sectionWrapper}>
                                                <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                <StyledInput
                                                    disabled={role === 1}
                                                    defaultValue={question.answer}
                                                    onChange={(e) =>
                                                        changeInput(sectionName, sectionIndex, "questions", questionIndex, "answer", e.target.value)
                                                    }
                                                    className={classes.answerWrapper}
                                                    type="text"
                                                />
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    );
                })}
            </div>
        );
    };

    const hotrunnerSection = () => {
        return (
            <div>
                {props.data?.hot_runners.map((section, sectionIndex) => {
                    if (section.name === "2. Hotrunner" && props.visible === false) {
                        return <div />;
                    }
                    return (
                        <div className={classes.sectionWrapper} key={sectionIndex}>
                            {section.name !== "" && <div className={classes.sectionTitle}>{section.name}</div>}
                            <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                {section.zone_temperatures.map((question, questionIndex) => {
                                    return (
                                        <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                            <div className={classes.sectionWrapper}>
                                                <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                <StyledInput
                                                    disabled={role === 1}
                                                    defaultValue={question.answer}
                                                    onChange={(e) =>
                                                        changeInput("hot_runners", sectionIndex, "zone_temperatures", questionIndex, "answer", e.target.value)
                                                    }
                                                    className={classes.answerWrapper}
                                                    type="text"
                                                />
                                            </div>
                                        </Grid>
                                    );
                                })}
                                <Grid item container xs={12} sm={12} md={12} lg={12}>
                                    <Grid item container xs={12} sm={12} md={4} lg={3}>
                                        <div className={classes.sectionWrapper}>
                                            <div className={classes.questionWrapper}>{languageData && languageData[section.number_of_air_circle.question]}</div>
                                            <StyledInput
                                                disabled={role === 1}
                                                defaultValue={section.number_of_air_circle.answer}
                                                onChange={(e) =>
                                                    changeInput("hot_runners", sectionIndex, "number_of_air_circle", null, "answer", e.target.value)
                                                }
                                                className={classes.answerWrapper}
                                                type="text"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item container xs={12} sm={12} md={4} lg={3}>
                                        <div className={classes.sectionWrapper}>
                                            <div className={classes.questionWrapper}>{languageData && languageData[section.valve_gate.name]}</div>
                                            <RadioGroup
                                                className={classes.radioButtonsWrapper}
                                                value={section.valve_gate.value}
                                                onChange={(e) => {
                                                    changeInput("hot_runners", sectionIndex, "valve_gate", null, "value", e.target.value === "true");
                                                }}
                                            >
                                                <FormControlLabel
                                                    classes={{ label: classes.textWrapper }}
                                                    name="switches"
                                                    value={true}
                                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                                    label={languageData?.Yes}
                                                />
                                                <FormControlLabel
                                                    classes={{ label: classes.textWrapper }}
                                                    name="switches"
                                                    value={false}
                                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                                    label={languageData?.No}
                                                />
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                    <Grid item container xs={12} sm={12} md={4} lg={3}>
                                        <div className={classes.sectionWrapper}>
                                            <div className={classes.questionWrapper}>{languageData && languageData[section.cascade_control.name]}</div>
                                            <RadioGroup
                                                className={classes.radioButtonsWrapper}
                                                value={section.cascade_control.value}
                                                onChange={(e) => {
                                                    changeInput("hot_runners", sectionIndex, "cascade_control", null, "value", e.target.value === "true");
                                                }}
                                            >
                                                <FormControlLabel
                                                    classes={{ label: classes.textWrapper }}
                                                    name="switches"
                                                    value={true}
                                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                                    label={languageData?.Yes}
                                                />
                                                <FormControlLabel
                                                    classes={{ label: classes.textWrapper }}
                                                    name="switches"
                                                    value={false}
                                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                                    label={languageData?.No}
                                                />
                                            </RadioGroup>
                                        </div>
                                    </Grid>
                                </Grid>
                                {section.open_close_time_table.map((question, questionIndex) => {
                                    return (
                                        <Grid item container xs={12} sm={12} md={12} lg={12} key={questionIndex}>
                                            <Grid item container xs={12} sm={12} md={4} lg={3}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.labelWrapper}>{languageData && languageData[question.name]}</div>
                                                </div>
                                            </Grid>
                                            <Grid item container xs={12} sm={12} md={4} lg={3}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.questionWrapper}>{languageData && languageData?.OpenTime}</div>
                                                    <StyledInput
                                                        disabled={role === 1}
                                                        defaultValue={question.open_time}
                                                        onChange={(e) =>
                                                            changeInput(
                                                                "hot_runners",
                                                                sectionIndex,
                                                                "open_close_time_table",
                                                                questionIndex,
                                                                "open_time",
                                                                e.target.value
                                                            )
                                                        }
                                                        className={classes.answerWrapper}
                                                        type="text"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item container xs={12} sm={12} md={4} lg={3}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.questionWrapper}>{languageData && languageData.CloseTime}</div>
                                                    <StyledInput
                                                        disabled={role === 1}
                                                        defaultValue={question.close_time}
                                                        onChange={(e) =>
                                                            changeInput(
                                                                "hot_runners",
                                                                sectionIndex,
                                                                "open_close_time_table",
                                                                questionIndex,
                                                                "close_time",
                                                                e.target.value
                                                            )
                                                        }
                                                        className={classes.answerWrapper}
                                                        type="text"
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    );
                })}
            </div>
        );
    };

    const screwBarrelSection = () => {
        return (
            <div>
                {props.data?.screw_barrels.map((section, sectionIndex) => {
                    if (section.name === "2. Screw barrel" && props.visible === false) {
                        return <div />;
                    }
                    return (
                        <div className={classes.sectionWrapper} key={sectionIndex}>
                            {section.name !== "" && <div className={classes.sectionTitle}>{section.name}</div>}
                            <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                {section.setup_params.map((subSection, subSectionIndex) => {
                                    return (
                                        <Grid item container xs={12} sm={12} md={12} key={subSectionIndex} className={classes.gridWrapper}>
                                            {subSection.questions.map((question, questionIndex) => {
                                                return (
                                                    <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                                        <div className={classes.sectionWrapper}>
                                                            <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                            <StyledInput
                                                                disabled={role === 1}
                                                                defaultValue={question.answer}
                                                                onChange={(e) =>
                                                                    changeScrewBarrelInput(
                                                                        sectionIndex,
                                                                        "setup_params",
                                                                        subSectionIndex,
                                                                        "questions",
                                                                        questionIndex,
                                                                        "answer",
                                                                        e.target.value
                                                                    )
                                                                }
                                                                className={classes.answerWrapper}
                                                                type="text"
                                                            />
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    );
                                })}
                                <div className={classes.sectionTitle}>{languageData?.Injection}</div>
                                {section.injection.step_table.map((row, rowIndex) => {
                                    return (
                                        <Grid item container xs={12} sm={12} md={12} key={rowIndex} className={classes.gridWrapper}>
                                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.tablePrimaryColumn}>{languageData && languageData[row.name]}</div>
                                                </div>
                                            </Grid>
                                            {Object.keys(row).map(
                                                (key, index) =>
                                                    key !== "name" && (
                                                        <Grid key={index} item container xs={12} sm={3} md={2} lg={2}>
                                                            <div className={classes.sectionWrapper}>
                                                                <div className={classes.questionWrapper}>{languageData && languageData[key]}</div>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={row[key]}
                                                                    onChange={(e) =>
                                                                        changeScrewBarrelInput(
                                                                            sectionIndex,
                                                                            "injection",
                                                                            null,
                                                                            "step_table",
                                                                            rowIndex,
                                                                            key,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </Grid>
                                                    )
                                            )}
                                        </Grid>
                                    );
                                })}
                                {section.injection.setup_params.map((params, paramsIndex) => {
                                    return (
                                        <Grid key={paramsIndex} item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                            {params.name !== "" && <div className={classes.sectionTitle}>{params.name}</div>}
                                            <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                                {params.questions.map((question, questionIndex) => {
                                                    return (
                                                        <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                                            <div className={classes.sectionWrapper}>
                                                                <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={question.answer}
                                                                    onChange={(e) => {
                                                                        const tmpData = { ...props.data };
                                                                        tmpData.screw_barrels[sectionIndex]["injection"]["setup_params"][paramsIndex][
                                                                            "questions"
                                                                        ][questionIndex]["answer"] = e.target.value;
                                                                        props.changeObject("injectionParameters", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                <div className={classes.sectionTitle}>{languageData?.HoldingPressure}</div>
                                {section.holding_pressure.step_table.map((row, rowIndex) => {
                                    return (
                                        <Grid item container xs={12} sm={12} md={12} key={rowIndex} className={classes.gridWrapper}>
                                            <Grid item container xs={12} sm={12} md={12} lg={12}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.tablePrimaryColumn}>{languageData && languageData[row.name]}</div>
                                                </div>
                                            </Grid>
                                            {Object.keys(row).map(
                                                (key, index) =>
                                                    key !== "name" && (
                                                        <Grid key={index} item container xs={12} sm={3} md={2} lg={2}>
                                                            <div className={classes.sectionWrapper}>
                                                                <div className={classes.questionWrapper}>{languageData && languageData[key]}</div>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={row[key]}
                                                                    onChange={(e) =>
                                                                        changeScrewBarrelInput(
                                                                            sectionIndex,
                                                                            "holding_pressure",
                                                                            null,
                                                                            "step_table",
                                                                            rowIndex,
                                                                            key,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </Grid>
                                                    )
                                            )}
                                        </Grid>
                                    );
                                })}
                                <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                    {section.holding_pressure.setup_params.map((question, questionIndex) => {
                                        return (
                                            <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                    <StyledInput
                                                        disabled={role === 1}
                                                        defaultValue={question.answer}
                                                        onChange={(e) =>
                                                            changeScrewBarrelInput(
                                                                sectionIndex,
                                                                "holding_pressure",
                                                                null,
                                                                "setup_params",
                                                                questionIndex,
                                                                "answer",
                                                                e.target.value
                                                            )
                                                        }
                                                        className={classes.answerWrapper}
                                                        type="text"
                                                    />
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}
            </div>
        );
    };

    const coolingParamsSection = () => {
        return (
            <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                {props.data?.cooling_params.map((question, questionIndex) => {
                    return (
                        <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                            <div className={classes.sectionWrapper}>
                                <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                <StyledInput
                                    disabled={role === 1}
                                    defaultValue={question.answer}
                                    onChange={(e) => changeSimpleInput("cooling_params", questionIndex, "answer", e.target.value)}
                                    className={classes.answerWrapper}
                                    type="text"
                                />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const hydraulicParamsSection = () => {
        return (
            <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                <Grid item container xs={12} sm={12} md={4} lg={3}>
                    <div className={classes.sectionWrapper}>
                        <div className={classes.questionWrapper}>{languageData && languageData[props.data?.hydraulic_params.core_pulling.name]}</div>
                        <RadioGroup
                            className={classes.radioButtonsWrapper}
                            value={props.data ? props.data.hydraulic_params.core_pulling.value : false}
                            onChange={(e) => {
                                const tmpData = { ...props.data };
                                tmpData.hydraulic_params.core_pulling.value = e.target.value === "true";
                                props.changeObject("injectionParameters", tmpData);
                            }}
                        >
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                name="switches"
                                value={true}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.Yes}
                            />
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                name="switches"
                                value={false}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.No}
                            />
                        </RadioGroup>
                    </div>
                </Grid>
                {props.data?.hydraulic_params.setup_params.map((question, questionIndex) => {
                    return (
                        <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                            <div className={classes.sectionWrapper}>
                                <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                <StyledInput
                                    disabled={role === 1}
                                    defaultValue={question.answer}
                                    onChange={(e) => {
                                        const tmpData = { ...props.data };
                                        tmpData.hydraulic_params.setup_params[questionIndex]["answer"] = e.target.value;
                                        props.changeObject("injectionParameters", tmpData);
                                    }}
                                    className={classes.answerWrapper}
                                    type="text"
                                />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const otherParamsSection = () => {
        return (
            <div>
                {props.data?.other_params.map((section, sectionIndex) => {
                    return (
                        <div className={classes.sectionWrapper} key={sectionIndex}>
                            {section.name !== "" && <div className={classes.sectionTitle}>{section.name}</div>}
                            <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                {section.questions.map((question, questionIndex) => {
                                    return (
                                        <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                            <div className={classes.sectionWrapper}>
                                                <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                <StyledInput
                                                    disabled={role === 1}
                                                    defaultValue={question.answer}
                                                    onChange={(e) =>
                                                        changeInput("other_params", sectionIndex, "questions", questionIndex, "answer", e.target.value)
                                                    }
                                                    className={classes.answerWrapper}
                                                    type="text"
                                                />
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            <AddMediaModal
                openEditor={() => {
                    setOpenEditor(true);
                }}
                open={openUpload !== "" && openEditor === false}
                onClose={() => setOpenUpload("")}
                uploadFile={uploadFile}
            />
            <EditModal
                open={elementToEdit || openEditor ? true : false}
                onClose={() => {
                    setOpenEditor(false);
                    setElementToEdit(null);
                    setOpenUpload("");
                }}
                saveMedia={saveMedia}
                src={
                    openEditor === false
                        ? `${process.env.REACT_APP_URL}file-operations/` +
                          `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}/files/` +
                          elementToEdit?.fileId
                        : ""
                }
            />
            <DeleteModal open={elementToDelete ? true : false} onCancel={() => handleCancelDelete()} onDelete={handleDelete} />
            <div>
                {(role === 0 || role === 2) && (
                    <div className={classes.buttonWrapper}>
                        <StyledButton
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            {languageData?.Save.toUpperCase()}
                        </StyledButton>
                        <StyledButton
                            className={classes.copyButton}
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                handleCopy();
                            }}
                        >
                            {languageData?.CopyFromSamplingProcess.toUpperCase()}
                        </StyledButton>
                    </div>
                )}
                <div className={classes.switchContent}>
                    <Switch checked={props.visible} color="primary" onChange={(e) => props.changeVisibility(e.target.checked)} />
                    <div className={classes.sectionTitle} style={{ marginBottom: "10px", marginTop: "10px" }}>
                        {languageData?.EnableSecondComponentVisibility}
                    </div>
                </div>
                <div className={classes.contentWrapper}>
                    {defaultSection(props.data?.overview, "overview")}

                    <div className={classes.divider} />

                    {defaultSection(props.data?.machine_parameters, "machine_parameters")}

                    <div className={classes.divider} />

                    {defaultSection(props.data?.component_parameters, "component_parameters")}

                    <div className={classes.divider} />

                    {defaultSection(props.data?.insert_parameters, "insert_parameters")}

                    <div className={classes.divider} />

                    {hotrunnerSection()}

                    <div className={classes.divider} />

                    {screwBarrelSection()}

                    <div className={classes.divider} />

                    {coolingParamsSection()}

                    <div className={classes.divider} />

                    {hydraulicParamsSection()}

                    <div className={classes.divider} />

                    {otherParamsSection()}
                </div>
            </div>
            <div className={classes.contentWrapper}>
                {props.data?.media?.length === 0 && (
                    <div className={classes.commentMessageText + " " + classes.commentsMessage}>{languageData?.NoMediaMessage}</div>
                )}
                {props.data?.media?.map((mediaElement, index) => (
                    <div key={index} className={classes.mediaElement}>
                        <div className={classes.flexCard}>
                            <img
                                src={`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}/files/${mediaElement.fileId}`}
                                alt={mediaElement.id}
                                className={classes.img}
                            />
                        </div>
                        {(role === 0 || role === 2) && (
                            <div className={classes.actions}>
                                <StyledButton
                                    onClick={() => setElementToDelete(mediaElement)}
                                    className={classes.actionButton}
                                    variant="outlined"
                                    startIcon={<DeleteIcon color="primary" />}
                                >
                                    {languageData?.Delete}
                                </StyledButton>
                            </div>
                        )}
                    </div>
                ))}
                {(role === 0 || role === 2) && (
                    <div className={classes.addMediaButton} onClick={() => setOpenUpload("0")}>
                        <AddCircleIcon className={classes.addMediaIcon} color="primary" />
                        {languageData?.AddMedia}
                    </div>
                )}
            </div>
        </div>
    );
};

export default InjectionParameters;
