import React from "react";
import { Helmet } from "react-helmet";
import Step from "../../components/ProcessSteps/DFM/Step";
import MoldDesign from "../../components/ProcessSteps/MoldDesign/MoldDesign";
import ManufactureStep from "../../components/ProcessSteps/Manufacture/ManufactureStep";
import MoldTrailFOT from "../../components/ProcessSteps/MoldTrailFOT/MoldTrailFOT";
import MoldReleaseProcess from "../../components/ProcessSteps/MoldReleaseProcess/MoldReleaseProcess";
import Acceptance from "../../components/ProcessSteps/Acceptance/Acceptance";

const ProcessStep = (props) => {
    const currentStep = () => {
        switch (props.match.params.stepNumber) {
            case "2": {
                return <Step {...props} />;
            }
            case "3": {
                return <MoldDesign {...props} />;
            }
            case "4": {
                return <ManufactureStep {...props} />;
            }
            case "5": {
                return <MoldTrailFOT {...props} />;
            }
            case "6": {
                return <MoldReleaseProcess {...props} />;
            }
            case "7": {
                return <Acceptance {...props} />;
            }
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Process</title>
            </Helmet>
            {currentStep()}
        </React.Fragment>
    );
};

export default ProcessStep;
