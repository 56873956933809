import React, { useContext } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Button, makeStyles, MenuItem, Select } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import StyledInput from "../../../StyledInput/StyledInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
        marginBottom: 4,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        marginTop: 15,
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    contentMargin: {
        marginTop: 4,
        [theme.breakpoints.down(500)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    rowContentWrapper: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(520)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    inputWrapper: {
        width: 210,
    },
    columnWrapper: {
        marginRight: 20,
    },
    selectWrapper: {
        width: "210px",
        height: "35px",
        marginBottom: 10,
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    selectInputWrapper: {
        padding: "10px 14px",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
}));

const Surface = (props) => {
    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const sides = [
        { id: 1, key: "LinePolished" },
        { id: 2, key: "HighglossPolished" },
        { id: 3, key: "EDM" },
        { id: 4, key: "Texture" },
        { id: 5, key: "SandBlasted" },
        { id: 6, key: "Milling" },
        { id: 7, key: "TechnicalSurface" },
    ];

    const handleOpenCustomerGMS = () => {
        if (role === 0) {
            window.open(window.location.origin + "/customers/" + props.customerId + "/gts", "_blank", "noopener,noreferrer");
        } else if (role === 1) {
            window.open(window.location.origin + "/customer/gts", "_blank", "noopener,noreferrer");
        } else {
            window.open(window.location.origin + "/tool/" + props.toolId + "/gms", "_blank", "noopener,noreferrer");
        }
    };

    const sideSelect = (section, value) => {
        return (
            <FormControl>
                <Select
                    disabled={props.disabled}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    classes={{
                        root: classes.rootWrapper,
                        selectMenu: classes.selectMenuWrapper,
                        outlined: classes.selectInputWrapper,
                    }}
                    className={classes.selectWrapper}
                    variant={"outlined"}
                    value={value}
                    displayEmpty={value === ""}
                    renderValue={
                        value === ""
                            ? () => {
                                  return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                              }
                            : null
                    }
                    onChange={(e) => {
                        props.updateSurfaceData("surface", section, {
                            id: e.target.value,
                            value: props.surfaceData[section].value,
                        });
                    }}
                >
                    <MenuItem value="" key={-1} disabled>
                        <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                    </MenuItem>
                    {sides.map((option) => {
                        return (
                            <MenuItem key={option.id} value={option.id}>
                                <div className={classes.selectTextWrapper}>{languageData[option.key]}</div>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    const onTextInputChanged = (section, value) => {
        props.updateSurfaceData("surface", section, {
            id: props.surfaceData[section].id,
            value: value,
        });
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Surface.toUpperCase()}</div>
            <div>
                <div className={classes.labelWrapper}>{languageData?.CustomerGMS}</div>
                <div className={classes.contentMargin}>
                    <Button
                        variant="contained"
                        component="label"
                        className={classes.uploadFileButton}
                        startIcon={<OpenInNewIcon color="primary" />}
                        onClick={handleOpenCustomerGMS}
                    >
                        {languageData?.OpenCustomerGMS}
                    </Button>
                </div>
            </div>
            <div className={classes.rowContentWrapper}>
                <div className={classes.columnWrapper}>
                    <InputLabel required className={classes.labelWrapper}>
                        {languageData?.cavitySide}
                    </InputLabel>
                    {sideSelect(
                        "cavitySide",
                        props.surfaceData && props.surfaceData.cavitySide && props.surfaceData.cavitySide.id !== null ? props.surfaceData.cavitySide.id : ""
                    )}
                    <StyledInput
                        // required
                        disabled={props.disabled}
                        placeholder={languageData?.InputTextPlaceholder}
                        className={classes.inputWrapper}
                        defaultValue={props.surfaceData && props.surfaceData.cavitySide.value ? props.surfaceData.cavitySide.value : ""}
                        onChange={(e) => onTextInputChanged("cavitySide", e.target.value)}
                        type="text"
                    />
                </div>
                <div className={classes.columnWrapper}>
                    <InputLabel className={classes.labelWrapper}>{languageData?.coreSide}</InputLabel>
                    {sideSelect(
                        "coreSide",
                        props.surfaceData && props.surfaceData.coreSide && props.surfaceData.coreSide.id ? props.surfaceData.coreSide.id : ""
                    )}
                    <StyledInput
                        disabled={props.disabled}
                        // required
                        placeholder={languageData?.InputTextPlaceholder}
                        className={classes.inputWrapper}
                        defaultValue={props.surfaceData && props.surfaceData.coreSide.value ? props.surfaceData.coreSide.value : ""}
                        onChange={(e) => onTextInputChanged("coreSide", e.target.value)}
                        type="text"
                    />
                </div>
            </div>
        </div>
    );
};

export default Surface;
