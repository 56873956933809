const DFMReviewListStyle = (theme) => ({
    wrapper: {
        height: "calc(100% - 18px)",
        padding: 9,
    },
    switchContent: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "10px",
        alignItems: "center",
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        // marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginRight: "10px",
    },
    copyButton: {
        borderRadius: "3px",
        width: "auto",
        // marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginRight: "10px",
    },
    divider: {
        width: "100%",
        height: 1,
        margin: "20px 0 10px 0",
        backgroundColor: "#191919",
        opacity: 0.1,
    },
    buttonWrapper: {
        padding: 18,
        background: "white",
    },
    sectionTitle: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        margin: "18px 0 0px 0",
    },
    sectionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    gridWrapper: {
        marginTop: 15,
    },
    questionWrapper: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        width: 210,
        marginBottom: 5,
        marginTop: 16,
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "10px",
        lineHeight: "15px",
        width: 210,
        marginBottom: 5,
        textAlign: "center",
        marginTop: 45,
    },
    tablePrimaryColumn: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "10px",
        lineHeight: "15px",
        width: 150,
        marginBottom: 5,
        marginTop: 15,
        textAlign: "center",
    },
    answerWrapper: {
        width: 210,
    },
    listWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "default",
        // overflow: "auto",
    },
    innerWrapper: {
        height: "100%",
        overflow: "auto",
    },
    listElement: {
        [theme.breakpoints.down("md")]: {
            width: "calc(100% - 2px)",
        },
        width: "70%",
        backgroundColor: theme.palette.common.white,
        margin: "0 auto 10px auto",
        padding: "10px 0",
        border: "1px solid #ECECEC",
        borderRadius: "2px",
    },
    injectionTitle: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginBottom: 15,
    },
    addNewItem: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    title: {
        display: "flex",
        padding: "0 16px",
        width: "calc(100% - 32px)",
        justifyContent: "space-between",
        // cursor: "pointer",
    },
    stepName: {
        lineHeight: "24px",
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
    },
    emptyState: {
        width: 24,
        marginRight: 12,
    },
    iconCompleted: (props) => ({
        display: "flex",
        color: props.completedColor,
        marginRight: 12,
    }),
    iconNeedsReview: (props) => ({
        display: "flex",
        color: props.needsReviewColor,
        marginRight: 12,
    }),
    iconUnderReview: (props) => ({
        display: "flex",
        color: props.underReviewColor,
        marginRight: 12,
    }),
    textCompleted: (props) => ({
        color: props.completedColor,
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 500,
    }),
    textNeedsReview: (props) => ({
        color: props.needsReviewColor,
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 500,
    }),
    textUnderReview: (props) => ({
        color: props.underReviewColor,
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 500,
    }),
    stateLabelWrapper: {
        fontFamily: "Montserrat",
        fontSize: 10,
        fontWeight: 500,
    },
    commentsMessage: {
        width: "100%",
    },
    stateTextWrapper: {
        marginTop: 6,
    },
    userStatesWrapper: {
        display: "flex",
        flexDirection: "row",
    },
    adminStateWrapper: {
        marginRight: 15,
    },
    customerStateWrapper: {
        marginLeft: 15,
    },
    content: {
        padding: "0 16px",
        // width: "calc(100% - 32px)",
        marginTop: 28,
        display: "flex",
        flexDirection: "column",
    },
    commentContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    textContainer: {
        width: "100%",
    },
    sendButton: {
        backgroundColor: theme.palette.common.white,
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        justifyContent: "left",
        height: 28,
        width: "fit-content",
        padding: "0 10px",
        marginBottom: 40,
    },
    upDownButton: {
        backgroundColor: theme.palette.common.white,
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        height: 28,
        width: 28,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderRadius: "3.05172px",
        marginRight: 16,
        marginBottom: 16,
    },
    mediaElement: {
        display: "flex",
        marginBottom: 24,
        [theme.breakpoints.down("800")]: {
            flexDirection: "column",
        },
    },
    actions: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("800")]: {
            marginTop: 16,
        },
    },
    actionButton: {
        backgroundColor: theme.palette.common.white,
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        justifyContent: "left",
        height: 28,
        width: 120,
        padding: "0 10px",
        marginBottom: 10,
        marginLeft: 16,
        [theme.breakpoints.down("800")]: {
            marginLeft: 0,
        },
    },
    commentSaveButton: {
        marginTop: 10,
        backgroundColor: "#C00D0E",
        border: "1.01724px solid #ECECEC",
        color: "white",
        height: 28,
        width: 80,
        marginBottom: 10,
        marginLeft: 16,
        textAlign: "center",
        "&:hover,&:focus": {
            backgroundColor: "#C00D0E",
            color: "white",
        },
        [theme.breakpoints.down("800")]: {
            marginLeft: 0,
        },
    },
    positionButtons: {
        display: "flex",
        flexDirection: "column",
    },
    addMediaButton: {
        margin: "40px 0 20px",
        display: "flex",
        lineHeight: "24px",
        justifyContent: "center",
        padding: 6,
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        cursor: "pointer",
        fontSize: 10,
        color: "#A5A5A5",
        fontWeight: "600",
    },
    addMediaIcon: {
        marginRight: 10,
    },
    acceptButtons: {
        display: "flex",
        justifyContent: "center",
        padding: "20px 0",
    },
    acceptButtonAgree: {
        backgroundColor: "#0DC08A",
        color: theme.palette.common.white,
        height: 33,
        width: 110,
        padding: "0 10px",
        "&:hover": {
            backgroundColor: "#0DC08A",
        },
        border: "none",
        marginRight: 96,
    },
    acceptButtonDisagree: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: 33,
        padding: "0 10px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            opacity: "50%",
        },
        border: "none",
    },
    comments: {
        padding: "20px 16px 0",
    },
    nameIcon: {
        height: 30,
        width: 30,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: "#C00D0E",
        borderRadius: "50%",
        lineHeight: "21px",
        fontSize: "10px",
        fontWeight: "500",
        color: theme.palette.common.white,
        marginRight: 16,
    },
    commentAuthor: {
        display: "flex",
        marginBottom: 10,
    },
    name: {
        lineHeight: "30px",
        fontSize: "12px",
        fontWeight: "500",
        color: "#191919F",
        marginRight: 16,
    },
    date: {
        lineHeight: "30px",
        fontSize: "8px",
        fontWeight: "500",
        color: "rgba(31, 31, 31, 0.5)",
    },
    commentTranslation: {
        display: "flex",
        marginBottom: 2,
    },
    language: {
        width: 30,
        textAlign: "right",
        lineHeight: "24px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#A5A5A5",
        marginRight: 16,
    },
    commentDescription: {
        lineHeight: "24px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#A5A5A5",
        marginRight: 16,
    },
    editButton: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #ECECEC",
        color: theme.palette.primary.main,
        height: 22,
        width: 22,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderRadius: "3.05172px",
    },
    comment: {
        marginBottom: 15,
    },
    commentArea: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    textarea: {
        minHeight: 70,
        width: "100%",
        padding: 10,
        lineHeight: "12px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#191919",
        fontFamily: "Montserrat",
        background: "#FFFFFF",
        border: "1px solid #ECECEC",
        boxSizing: "border-box",
        borderRadius: 2.57,
    },
    commentButton: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
    },
    flex: {
        display: "flex",
    },
    flexCard: {
        display: "flex",
        flex: 1,
    },
    img: {
        minWidth: 1,
        flex: 1,
        maxWidth: "100%",
        height: "auto",
    },
    status: {
        display: "flex",
    },
    needsReview: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#C00D0E",
        marginRight: 20,
    },
    underReview: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#E39600",
        marginRight: 20,
    },
    textareaEdit: {
        marginRight: 16,
    },
    margin: {
        marginTop: 4,
    },
    addNew: {
        display: "flex",
        marginRight: 12,
    },
    pointerIcons: {
        cursor: "pointer",
    },
    padding: {
        height: "calc(100% - 85px)",
        padding: 18,
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        color: "#191919",
    },
    commentMessageText: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        color: "#191919",
    },
    rootWrapper: {
        padding: 0,
        "&:focus": {
            backgroundColor: "white",
        },
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
        padding: 14,
    },
    selectWrapper: {
        width: "150px",
        height: 35,
        padding: 0,
        marginBottom: 20,
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        margin: "5px 10px 5px",
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    checked: {},
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
});

export default DFMReviewListStyle;
