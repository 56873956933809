const ReleaseProcessIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 14 14" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M10 6.4V10H4V6.4L6.1 5.5V6.1L7.6 5.5V6.4H10ZM8.56 5.95L8.8 4H9.7L9.94 5.95H8.56ZM6.7 8.8H7.3V7.6H6.7V8.8ZM5.5 8.8H6.1V7.6H5.5V8.8ZM8.5 7.6H7.9V8.8H8.5V7.6Z" />
            <path d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.906 12.6 1.4 10.094 1.4 7C1.4 3.906 3.906 1.4 7 1.4C10.094 1.4 12.6 3.906 12.6 7C12.6 10.094 10.094 12.6 7 12.6Z" />
        </svg>
    );
};

export default ReleaseProcessIcon;
