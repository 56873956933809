import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/components/gridStyle";
import WebsiteContext from "../../../utils/Website";
import StepCard from "./Step/StepCard";
import StepModal from "./Step/StepModal";
import { useHistory } from "react-router";
import Axios from "axios";
import RoutingContext from "../../../utils/RoutingContext";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(styles);

const Process = (props) => {
    const [steps, setSteps] = useState(null);
    const { languageData, role, language } = useContext(WebsiteContext);
    const { setRoutingData } = useContext(RoutingContext);
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [stepNumber, setStepNumber] = useState(null);
    const history = useHistory();

    const getProcessSteps = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes`,
            method: "GET",
            headers: {
                "X-Locale": language,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setSteps(data);
        });
    };

    useEffect(() => {
        getProcessSteps();
    }, [language]);

    const handleOpenModal = (stepNumber) => {
        setStepNumber(stepNumber);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setStepNumber(null);
        getProcessSteps();
    };

    const handleClickCard = (step) => {
        if (!step.disabled) {
            if (step.state.id === 0) {
                if (role === 0) {
                    handleOpenModal(step.stepNo);
                }
            } else {
                switch (step.stepNo) {
                    case 2: {
                        history.push(
                            role === 0
                                ? "/customers/projects/tools/" + props.toolId + "/process/step/" + step.stepNo + "/info"
                                : role === 1
                                ? "/project/tools/" + props.toolId + "/process/step/" + step.stepNo + "/info"
                                : "/tool/" + props.toolId + "/process/step/" + step.stepNo + "/info"
                        );
                        break;
                    }
                    case 3: {
                        history.push(
                            role === 0
                                ? "/customers/projects/tools/" + props.toolId + "/process/step/" + step.stepNo
                                : role === 1
                                ? "/project/tools/" + props.toolId + "/process/step/" + step.stepNo
                                : "/tool/" + props.toolId + "/process/step/" + step.stepNo
                        );
                        break;
                    }
                    case 4: {
                        history.push(
                            role === 0
                                ? "/customers/projects/tools/" + props.toolId + "/process/step/" + step.stepNo + "/manufacturing"
                                : role === 1
                                ? "/project/tools/" + props.toolId + "/process/step/" + step.stepNo + "/manufacturing"
                                : "/tool/" + props.toolId + "/process/step/" + step.stepNo + "/manufacturing"
                        );
                        break;
                    }
                    case 5: {
                        history.push(
                            role === 0
                                ? "/customers/projects/tools/" + props.toolId + "/process/step/" + step.stepNo + "/sampling-flow-process"
                                : role === 1
                                ? "/project/tools/" + props.toolId + "/process/step/" + step.stepNo + "/sampling-flow-process"
                                : "/tool/" + props.toolId + "/process/step/" + step.stepNo + "/sampling-flow-process"
                        );
                        break;
                    }
                    case 6: {
                        history.push(
                            role === 0
                                ? "/customers/projects/tools/" + props.toolId + "/process/step/" + step.stepNo + "/final-run-report"
                                : role === 1
                                ? "/project/tools/" + props.toolId + "/process/step/" + step.stepNo + "/final-run-report"
                                : "/tool/" + props.toolId + "/process/step/" + step.stepNo + "/final-run-report"
                        );
                        break;
                    }
                    case 7: {
                        history.push(
                            role === 0
                                ? "/customers/projects/tools/" + props.toolId + "/process/step/" + step.stepNo
                                : role === 1
                                ? "/project/tools/" + props.toolId + "/process/step/" + step.stepNo
                                : "/tool/" + props.toolId + "/process/step/" + step.stepNo
                        );
                        break;
                    }
                    default:
                        break;
                }
            }
        }
    };

    const stepCards = steps?.map((step) => {
        return <StepCard {...props} key={step.stepNo} step={step} handleClickEvent={handleClickCard} />;
    });

    let modal;
    if (openModal) {
        modal = <StepModal {...props} open={openModal} stepNumber={stepNumber} onClose={handleCloseModal} />;
    }

    return (
        steps &&
        (steps?.length === 0 ? (
            <div className={classes.messageGrid}>
                <Alert severity="warning">Please fill the Mold Specifications form before starting the process.</Alert>
            </div>
        ) : (
            <div className={classes.grid}>
                <div className={role === 2 ? classes.toolCardWrapper : classes.tabCardsWrapper}>{stepCards}</div>
                {modal}
            </div>
        ))
    );
};

export default Process;
