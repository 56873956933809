import React, { useContext } from "react";
import { Dialog, DialogContent, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import WebsiteContext from "../../../utils/Website";
import ToastImageEditor from "../../ImageEditor/ImageEditor";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: "90vw",
        maxWidth: "unset",
        height: "auto",
        borderRadius: "1.4px",
        [theme.breakpoints.down(700)]: {
            width: "80vw",
        },
    },
    dialog: {
        maxWidth: "unset",
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "16px",
        zIndex: 1,
        color: theme.palette.common.white,
    },
    dialogContentWrapper: {
        "&:first-child": {
            padding: 0,
        },
        padding: 0,
    },
    title: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "16px",
        textAlign: "center",
        marginTop: "24px",
        color: "#1F1F1F",
    },
}));

const EditModal = ({ open, onClose, src, saveMedia }) => {
    const classes = useStyles();
    const { languageData } = useContext(WebsiteContext);

    return (
        <Dialog open={open} onClose={onClose} classes={{ paperWidthSm: classes.dialog }} disableEnforceFocus={true}>
            <DialogContent className={classes.dialogContentWrapper}>
                <div className={classes.modalWrapper}>
                    <IconButton className={classes.icon} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <ToastImageEditor saveMedia={saveMedia} src={src} />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditModal;
