const de = {
    // GENERAL
    Email: "E-Mail",
    UserEmail: "E-Mail",
    Password: "Passwort",
    Actions: "Aktionen",
    Edit: "Bearbeiten",
    Delete: "Löschen",
    Address: "Adresse",
    Cancel: "Abbrechen",
    ChangeFile: "Datei ändern",
    Login: "Anmeldung",
    ChangePassword: "Passwort ändern",
    ConfirmPassword: "Passwort bestätigen",
    CustomerName: "Kundenname",
    Copy: "Kopieren",
    Contacts: "Kontakte",
    CurrentPassword: "aktuelles Passwort",
    Date: "Datum",
    Exit: "Verlassen",
    ExitWithoutSaving: "verlassen ohne speichern",
    ForgotPassword: "Passwort vergessen",
    Logo: "Logo",
    Logout: "Abmelden",
    Name: "Name",
    UserName: "Benutzername",
    NewPassword: "neues Passwort",
    No: "Nein",
    Ok: "Ok",
    OpenFile: "Datei öffnen",
    Picture: "Bilder",
    ResetPassword: "Passwort zurücksetzen",
    Role: "Rolle",
    Save: "Speichern",
    SelectPlaceholder: "Wählen Sie ",
    Start: "START",
    Status: "Status",
    Step: "Schritt",
    Users: "Nutzer",
    View: "Ansicht",
    Yes: "Ja",
    Language: "Sprache",
    English: "Englisch",
    Deutsch: "Deutsch",
    DeleteMessage: "Sind Sie sich sicher dass Sie dieses Objekt löschen wollen?",
    NameEn: "Name EN",
    NameDe: "Name DE",
    DeleteGeneral: "Sind Sie sich sicher dass Sie löschen möchten?",
    StartDate: "Startdatum",
    EndDate: "Enddatum",
    Description: "Beschreibung",
    CloseStep: "Schritt schließen",
    Chart: "Karte",
    Filename: "Dateiname",
    PasswordMessage: "Das Passwort muss: ",
    UpperCaseMessage: "Mindestens ein Großbuchstabe",
    LowerCaseMessage: "Mindestens ein Kleinbuchstabe",
    DigitMessage: "Mindestens eine Ziffer",
    SpecialCharMessage: "Mindestens ein Sonderzeichen [~`!@#$%^&*()_-+={[}]|:;\"'<,>.?/]",
    LengthMessage: "Länge von mindestens 8 Zeichen",
    FirstChangePasswordMessage: "Bitte ädnern Sie Ihr automatisch generiertes Passwort",
    ViewHistory: "Historie anzeigen",
    DeleteToolMaker: "Formenbauer löschen",
    EmailInUse: "Die E-Mail wird bereits verwendet",
    DataDrawing2D: "2D Zeichnung",
    FilmTakeByRobot: "Folie einlegen mit Handling",
    FilmTakeByHand: "Folie einlegen von Hand",
    InsertTakeByRobot: "Einlegeteile mit Handling einlegen",
    InsertTakeByHand: "Einlegeteilevon Hand einlegen",
    MoveByRobot: "Entnehmen mit Handling",
    MoveByHand: "Entnehmen von Hand",
    PreseriesMold: "Vorserienspritzwerkzeug",
    ProductionMold: "Serienspritzwerkzeug",
    PrototypeMold: "Prototypenspritzwerkzeug",
    WithTurntable: "mit Drehteller",
    WithIndexPlate: "mit Indexplatte",
    Actual3DFileForTheTool: "Aktuelle 3D-Daten für das Spritzwerkzeug",
    ResetPasswordEmailSentConfirm: "If the email was found in the database, an email with reset instructions was sent to specified email.",
    ResetPasswordConfirmation: "Das Passwort wurde erfolgreich aktualisiert.",
    SomethingWentWrong: "Serverfehler. Bitte versuchen Sie es später noch einmal!",
    DiscussionWith: "Diskussion mit:",
    EnableSecondComponentVisibility: "Zweite Komponente einblenden",
    InputTextPlaceholder: "Eingabefeld...",

    // Kundenübersicht
    AddNewClient: "Neuen Kunden hinzufügen",
    DeleteClient: "Kunde löschen",
    EditClient: "Kunde bearbeiten",
    Projects: "Projekte",

    // Projektübersicht
    AddNewProject: "Neue Projekte hinzufügen",
    Members: "Mitglieder",
    Tools: "Spritzwerkzeuge",
    MoldStates: {
        QUOTATION_REQUEST: "Quotation request",
        PRODUCTION: "Production",
    },

    // GMS VIEW
    EditMode: "Bearbeitungsmodus",
    EditModeExitAlert: "Wenn Sie den Bearbeitungsmodus verlassen, ohne zu speichern, gehen alle Änderungen verloren.",
    ExpandAll: "Alle öffnen",
    CollapseAll: "Alle schliessen",
    GeneralToolStandard: "Allgemeine Spritzwerkzeugspezifikation",

    "1. Guideline": "1. Richtlinien",
    "2. Steel": "2. Staehle",
    "3. Standard parts": "3. Normteile",
    "4. Moldbase": "4. Formaufbau",
    "5. Mold inserts": "5. Formeinsaetze",
    "6. Runner/gate system": "6. Angusssystem",
    "7. Cooling": "7. Kuehlung",
    "8. Hydraulic": "8. Hydraulik",
    "9. Electric": "9. Elektrik",
    "10. Air": "10. Luft",
    "11. Surface": "11. Oberflaechen",
    "12. Coating": "12. Beschichtungen",
    "13. Labeling": "13. Kennzeichnung",
    "14. Handling by robot": "14. Teileentnahme mit Roboter",
    "15. Welding": "15. Schweißen",
    "16. Transfer preparation": "16. Transfer Standard",

    gms: {
        "1. Guideline": {
            "Data format": "Datenformat",
            "Data transfer": "Datenübertragung",
            "Mold standard": "Werkzeugstandard",
            "Approval Process": "Freigabeprozess",
            Documentation: "Documentation",
            Others: "Sonstiges",
        },
        "2. Steel": {
            Moldbase: "Werkzeugaufbau ",
            Inserts: "Formeinsätze",
            "Special applications": "Spezialanwendungen",
            Others: "Sonstiges",
        },
        "3. Standard parts": {
            Flange: "Zentierring",
            "KO insert": "Auswerferstangeneinsatz ",
            "Ejector pins": "Auswerferstifte",
            "Data stamp": "Datumstempel",
            "Shot counter": "Schusszähler",
            "Inter locks": "Zentriereinheiten",
            Others: "Sonstiges",
        },
        "4. Moldbase": {
            "Standard supplier": "Standard Werkzeugaufbau",
            "Clamping system": "Werkzeugspannsystem",
            "Transport bar": "Transportbalken",
            "Storage bar": "Werkzeuglagerung",
            "Isolation plate": "Isolierplatten",
            "Ejector system": "Auswerfersystem",
            "Mould labeling": "Werkzeugkennzeichnung",
            "Guiding strip": "Fuehrungssystem",
            Others: "Sonstiges",
        },
        "5. Mold inserts": {
            "Interlock system": "Einsatzzentrierung",
            Fitting: "Einbauvorgaben",
            "Parting line shut off": "Einsatztrennungen",
            Spotting: "Tuschierungen",
            Venting: "Entlüftungen ",
            "Poka Yoke": "Poka Yoke (Kein Vertauschen)",
            "Critical area": "Kritische Bereiche",
            Centering: "Zentrierung",
            "Insert labeling": "Einsatzkennzeichnung",
            Slider: "Schieber",
            Lifter: "Innenschieber",
            Others: "Sonstiges",
        },
        "6. Runner/gate system": {
            Coldrunner: "Kaltkanalsystem",
            "Gate Typ": "Angussart",
            "Hotrunner Gate Typ": "Heisskanalausführung",
            "Hotrunner Brand": "Heisskanalhersteller",
            Others: "Sonstiges",
        },
        "7. Cooling": {
            "Cooling lines": "Kühlbohrungen",
            Connector: "Kühlanschlüsse",
            Tubes: "Kühlrohre",
            Baffles: "Trennbleche",
            "O-Ring": "O-Ringe",
            "Cooling balance": "Vorgaben Kühlungsauslegung",
            Others: "Sonstiges",
        },
        "8. Hydraulic": {
            Cylinder: "Zylinder",
            Connector: "Anschlüsse  ",
            Others: "Sonstiges",
        },
        "9. Electric": {
            Connector: "Stecker",
            "Conncetion plan": "Steckerbelegung",
            "Limit switches": "Endschalter",
            "Thermo Sensor": "Temperaturfühler",
            "Pressure Sensor": "Druckfühler",
            Others: "Sonstiges",
        },
        "10. Air": {
            Connector: "Anschlüsse",
            Others: "Sonstiges",
        },
        "11. Surface": {
            "Polish surface": "Standard polierte Oberflächen",
            "EDM surface": "Erodierstruktur",
            Texture: "Ätzstrukturen",
            Others: "Sonstiges",
        },
        "12. Coating": {
            "Highgloss Coating": "Hochglanzoberflächenbeschichtung",
            "Demoulding Coating": "Entformungsbeschichtung",
            "Sliding Coating": "Trockenlaufbeschichtung",
            Others: "Sonstiges",
        },
        "13. Labeling": {
            Label: "Standardschilder",
            Others: "Sonstiges",
        },
        "14. Handling by robot": {
            Basic: "Standards",
            Centering: "Zentrierungen für Roboter",
            Others: "Sonstiges",
        },
        "15. Welding": {
            Process: "Freigebene Schweissprozesse",
            "Kind of welding": "Schweißbedingungen",
            Others: "Sonstiges",
        },
        "16. Transfer preparation": {
            Document: "Dokumente",
            Protection: "Korrossionsschutz/Verpackung",
            Others: "Sonstiges",
        },
    },

    //MOLDS Zustand
    ProductionState: "PRODUCTION",
    QuotationRequestState: "QUOTATION_REQUEST",

    // Maschinenliste
    MachineList: "Maschinenliste",
    AddNewMachineListSuccessMessage: "Erfolgreich neue Maschinenliste hinzugefügt!",
    EditCustomLabels: "Kundenspezifische Felder bearbeiten",
    CustomLabel1: " Informationsfeld 1",
    CustomLabel2: " Informationsfeld 2",
    CustomLabel3: " Infomationsfeld 3",
    CustomLabel4: " Infomationsfeld 4",
    CustomLabel5: " Infomationsfeld 5",
    AddNewMachine: "Neue Maschine hinzufügen",
    MachineListHeader: [
        { name: "Maschinen-Nummer" },
        { name: "Hersteller" },
        { name: "Maschinentyp" },
        { name: "Herstellermaschinennummer" },
        { name: "Holmabstand (mm)" },
        { name: "Feste Seite Flansch / Bewegliche Seite Flansch (mm)" },
        { name: "Werkzeughöhe min/max (mm)" },
        { name: "Maschinenöffnung min/max (mm)" },
        { name: "Auswerferhub (mm)" },
        { name: "Aufspannplatte Abmessung (mm)" },
        { name: "Maschinenzuhaltung (KN)" },
        { name: "Schneckendurchmesser (mm)" },
        { name: "Maximale Schussvolumen (ccm)" },
        { name: "Maximaler spezifischer Einspritzdruck (bar)" },
        { name: "2. Schneckendurchmesser (mm)" },
        { name: "Maximale Schussvolumen (ccm)" },
        { name: "Maximaler spezifischer Einspritzdruck (bar)" },
        { name: "Düsenradius in mm" },
        { name: "Drehtisch Durchmesser (mm)" },
        { name: "Baujahr" },
        { name: "Interne Temperaturregelkreisläufe für Heißkanäle" },
        { name: "Interne Luftkreisläufe  für Nadelverschluss" },
        { name: "Hydraulischer Kernzüge Feste Seite/ Bewegliche Seite" },
        { name: "Handlinghersteller / 3-Achse oder 6-Achse / max. Belastung kg" },
        { name: "Sonstiges" },
        { name: "Zeichnung/Bild pdf" },
    ],
    DeleteMachineMessage: "Sind Sie sicher, dass Sie dieses Maschinendaten löschen möchten?",

    // QUOTATION REQUEST VIEW
    QuotationRequest: "Anfrage",
    ChooseMoldMaker: "Werkzeugmacher auswählen",
    ContactPerson: "Kontaktperson",

    // Benutzeransicht
    AddNewUser: "Neuen Benutzer hinzufügen",
    EditUser: "Benutzer bearbeiten",
    UserPhoneNumber: "Telefonnummer",
    UserDepartment: "Abteilung",
    DeleteWarningMessage: "Klicken Sie erneut auf Löschen, um den Benutzer zu löschen, oder klicken Sie auf Abbrechen",
    PhoneNumberError: "Die Rufnummer ist ungültig!",

    // Mitgliederansicht
    AssignMemberToProject: "Diesem Projekt zuordnen",
    MemberAssigned: "Diesem Projekt zugewiesen",
    Unassign: "Zuweisung aufheben",
    UnassignWarningMessage:
        "Klicken Sie erneut auf Zuweisung aufheben, um die Zuweisung dieses Benutzers aus dem Projekt aufzuheben, oder klicken Sie auf Abbrechen",
    UnassignedMembers: "Nicht zugewiesene Mitglieder",
    AssignedMembers: "Zugewiesene Mitglieder",
    ProjectNumber: "Projektnummer",
    EditProject: "Projekt bearbeiten",

    // Zeitplanansicht
    ToolName: "Werkzeugbezeichnung",
    Visibility: "Sichtbar",
    ToolSchedule: "Terminplan",
    CopyTasks: "Terminplan kopieren",
    CopyTasksMessage:
        "Sind Sie sicher, dass Sie alle Aufgaben in den Kundenterminplan kopieren möchten? Alle vorhandenen Aufgaben im Kundenterminplan werden gelöscht!",
    CustomerSchedule: "Kundenterminplan",
    MoldMakerSchedule: "Werkzeugmacher Terminplan",
    SchedulerStatesMessage: "Zustände sollten entsprechend dem tatsächlichen Terminplan des Werkzeugs geändert werden.",
    Schedule: "Terminplan",
    ViewProjectSchedule: "Projektterminplan anzeigen",
    AddNewMilestone: "Neuen Meilenstein hinzufügen",
    AddNewTask: "Neuen Arbeitsschritt hinzufügen",
    DeleteMilestoneMessage: "Sind Sie sicher, dass Sie diesen Meilenstein löschen wollen?",
    DeleteTaskMessage: "Sind Sie sicher, dass Sie diesen Arbeitschritt löschen möchten?",
    MilestoneName: "Meilenstein Name",
    TaskName: "Arbeitschrittbezeichnung",

    // Form ANSICHT
    AddNewTool: "Neues Spritzwerkzeug hinzufügen",
    ToolMaker: "Werkzeugmacher",
    EditTool: "Spritzwerkzeugdaten bearbeiten",
    ToolSpecification: "Spritzwerkzeugspezifikation",
    MoldsCheckboxLabel: "Anfrage",

    // MOLD INFO ANSICHT
    MoldInfo: "Mold info",
    MoldMakerUsers: "Anwender des Werkzeugmacher",
    MoldMakerInfo: "Informationen zum Werkzeugmacher",

    // ANSICHT DER WERKZEUGSPEZIFIKATION
    MoldSpecWarning:
        "Dieses Formular übernimmt Daten aus der Allgemeinen Spritzwerkzeugspezifikation und der Maschinenliste, daher stellen Sie bitte sicher, dass diese Abschnitte ausgefüllt sind.",
    MoldSpecRequiredFields:
        "Die weiteren Schritte auf der Registerkarte Prozess werden erst aktiviert, wenn alle Pflichtfelder (*) ausgefüllt sind. Beachten Sie, dass das Formular mit Teilinformationen gespeichert werden kann.",
    ContactPersonCustomer: "Kontaktperson (Kunde)",
    // contacts
    ContactPersonSws: "Kontaktperson (SWS)",
    ContactPersonMoldMaker: "Kontaktperson (Werkzeugmacher)",
    // project data
    ProjectData: "Projektdaten",
    ProjectName: "Projektname",
    FileName3D: "Name der 3D-Daten",
    FileName2D: "Name der 2D-Daten",
    EstimatedInjectionMA: "Geplante Spritzgiessmaschine",
    CustomerGMS: "Allgemeine Kundenwerkzeugspezifikation",
    OpenCustomerGMS: "Öffnen der Allg.Kundenwerkzeugspezifikation",
    ShowEstimatedInjection: "Geschätzte Spritzgiessmaschine anzeigen",
    // mold type
    MoldStandard: "Spritzwerkzeugstandard",
    MoldType: "Spritzwerkzeugtyp",
    ShotWarranty: "Schuss Garantie",
    Process: "Prozess",
    estimatedCycleTime: "Geplante Zykluszeit",
    calculatedClampingForce: "Berechnete Schliesskraft",
    calculatedPartWeight: "Berechnetes Teilegewicht",
    calculatedRunnerWeight: "Berechnetes Angussgewicht",
    calculatedShotWeight: "Berechnetes Schussgewicht",
    // material
    Material: "Material",
    MaterialName: "Material Name",
    ShrinkageLabel: "Schwindung",
    Insert: "Einlegeteile",
    Film: "Folie",
    // mold layout
    MoldLayout: "Spritzwerkzeuglayout",
    NumberOfCavity: "Kavitätenzahl",
    KindOfTool: "Spritzwerkzeugausführung",
    PartTakeOut: "Teileentnahme",
    FallAutomatically: "automatisch fallend",
    ByRobot: "durch Handling",
    ByHand: "von Hand",
    OneKMold: "1K Spritzwerkzeug",
    OneKInsertMold: "1K Spritzwerkzeug mit Einlegeteilen",
    OneKIMLMold: "1K IML Spritzwerkzeug",
    TwoKMold: "2K Spritzwerkzeug",
    TwoKInsertMold: "2K Spritzwerkzeug mit Einlegeteilen",
    TwoKIMLMold: "2K IML Spritzwerkzeug",
    // runner system
    RunnerSystem: "Angusssystem",
    CascadeControl: "Kaskadensteuerung",
    GateSystem: "Angusstyp",
    HotrunnerBrand: "Heißkanalhersteller",
    Coldrunner: "Kaltkanal Spritzwerkzeug",
    "3-Plate Mold": "3-Platten Spritzwerkzeug",
    "Single nozzle": "Einzeldüse",
    "Single Nozzle subgate": "Einzeldüse mit Kaltkanalunterverteiler",
    Hotrunner: "Heißkanalsystem",
    "Hotrunner subgate": "Heißkanal mit Kaltkanalunterverteiler",
    "Hotrunner valve gate": "Heißkanal mit Nadelverschlussdüsen",
    "Hotrunner valve gate subgate": "Heißkanal mit Nadelverschlussdüsen und Kaltkanalunterverteiler",
    // demolding
    Demolding: "Entformen",
    slider: "Schieber",
    lifter: "Innenschiebr",
    coreSide: "Auswerferseite",
    MechanicalFunction: "Mechanische Funktion",
    HydraulicFunction: "Hydraulische Funktion",
    cavitySide: "Düsenseite",
    EjectorDesign: "Auswerferausführung",
    EjectorFunction: "Auswerferbetätigung",
    Unscrewing: "Gewindeentspindelung",
    EjectorPin: "Rundauswerfer",
    BladeEjector: "Flachauswerfer",
    EjectorBlock: "Auswerferblock",
    EjectorSleeve: "Auswerfhülse",
    StripperSegment: "Abstreifsegmente",
    stripperPlate: "Abstreifplatte",
    ForceEjection: "Zwangsauswerfen",
    CollapsableCore: "Faltkern",
    WithMachineEjector: "Mit Maschinenauswerfer",
    WithHydraulicCylinder: "Mit Hydraulikzylinder",
    Mechanical: "Mechanisch",
    WithHydraulicCylinderAndGearRack: "Mit Hydraulikzylinder und Zahnstange",
    WithHydraulicMotor: "Mit Hydraulikmotor",
    WithElServoMotor: "Mit el. Servomotor",
    // cooling
    Cooling: "Kühlung",
    CoolingMedium: "Kühlmedium",
    TemperatureCavitySide: "Temperatur Düsenseite",
    TemperatureCoreSide: "Temperatur Auswerferseite",
    IsolationPlate: "Isolierplatte",
    Water: "Wasser",
    Oil: "Öl",
    Others: "anderes",
    HighTemperatureTool: "Hochtemperatur-Werkzeug",
    // surface
    Surface: "Oberfläche",
    LinePolished: "Strichpoliert",
    HighglossPolished: "hochglanzpoliert",
    EDM: " Erodiert",
    Texture: "Geätzt",
    SandBlasted: "Sandgestrahlt",
    Milling: "Gefräst",
    TechnicalSurface: "Technische Oberfläche",
    // moldbase
    MoldBase: "Spritzwerkzeugaufbau",
    guidingSystem: "Führungssystem",
    ejectorSystem: "Auswerferführungssystem",
    interlockSystem: "Feinzentrierung",
    clampingSystem: "Aufspannsystem",
    // steel
    Steel: "Stahl",
    InsertSteel: "Formeinsatzstahl",
    Mouldbase: "Formaufbau",
    aPlate: "Fomplatte Düsenseite",
    bPlate: "Fomplatte Auswerferseite",
    backupPlate: "Zwischenplatte",
    hotrunnerPlate: "Heißkanalplatte",
    clampingPlate: "Aufspannplatte",
    ejectorBasePlate: "Auswerfer-Grundplatte",
    ejectorHoldingPlate: "Auswerfer-Halteplatte",
    // coating
    Coating: "Beschichtung",
    movingParts: "Bewegliche Teile",
    // connector
    Connector: "Anschlüsse",
    ElectricConnector: "Elektrischer Anschluss",
    HydraulicConnector: "Hydraulischer Anschluss",
    AirConnector: "Luftanschluss",
    Switches: "Endschalter",
    // documents for delivery
    DocumentsForDelivery: "Dokumente für die Lieferung",
    steelCertification: "Stahl-Zertifikat",
    hardnessCertification: "Härteprotokoll",
    injectionParameter: "Spritzgiessparameter",
    measurementReport: "Messbericht",
    maintenanceManual: "Wartungshandbuch",
    hydraulicConnecting: "Hydraulischer Anschlussplan",
    hotrunnerData: "Heißkanaldaten und Zeichnungen",
    coolingLayout: "Kühlunganschlussplan",
    weeklyReport: "Wöchentlicher Bericht",
    updated2dAssembly: "Aktualisierte 2D-Zusammenstellzeichnung",
    updated2dDetail: "Aktualisiertes 2D-Zeichnungen",
    updated3dAssembly: "Aktualisierte 3D-Daten",
    updated3dDetail: "Aktualisierte 3D-Einzelteildaten",
    sparePartList: "Ersatzteilliste",
    electricConnectingLayout: "Elektrische Anschlussplan",
    functionFlowchart: "Funktionsablaufdiagramm",
    Sketch: "Skizze",

    // PROCESS - DFM
    StartingDFMStep: "DFM-Schritt starten",
    ProcessAlertMessage: "Bitte füllen Sie das Formular Werkzeugspezifikation komplett aus, bevor Sie den Prozess starten.",
    ChooseDFMTemplate: "DFM-Vorlage auswählen",
    INFO: "INFO",
    ChangeHistory: "HISTORIE ÄNDERN",
    DFMReviewList: "DFM-PRÜFUNGSLISTE",
    ExportDFM: "DFM exportieren",
    ViewDFM: "DFM ansehen",
    // info
    ProjectInformationDatabase: "PROJEKTINFORMATIONSDATENBANK",
    projectName: "Projektname",
    partName: "Teilename",
    partNumber: "Teilenummer",
    moldNumber: "Spritzwerkzeugnummer",
    Actual3DFile: "Aktuelle 3D-Daten",
    Actual2DPartDrawing: "Aktuelle 2D-Teilezeichnung",
    GeneralInformation: "ALLGEMEINE INFORMATIONEN",
    plasticMaterial1Component: "Kunststoffmaterial  1.Komponente",
    plasticMaterial2Component: "Kunststoffmaterial 2.Komponente",
    shrinkageRate1Component: "Schwindung 1.Komponente",
    shrinkageRate2Component: "Schwindung 2.Komponente",
    cavityNumber: "Kavitätenzahl",
    kindOfMold: "Spritzwerkzeugausführung",
    surfaceRequirement: "Oberflächenanforderungen",
    calculatedCycleTime: "Kalkulierte Zykluszeit",
    estimatedInjectionMachine: "Geplante Spritzgiessmaschine",
    kindOfProcess: "Prozessablauf",
    // change history
    AddNewChange: "Neue Änderung hinzufügen",
    ChangedProperty: "Änderungsgrund",
    ChangedInfo: "geänderte Informationen",
    Company: "Unternehmen",
    UploadFile: "Datei hochladen",
    UploadDate: "Hochladedatum",
    CreatorName: "Ersteller Name",
    HistoryFiles: "Änderungshistorie",
    // dfm review list
    ToCustomer: "Zum Kunden",
    ToMoldMaker: "Zum Werkzeugacher",
    Agree: "EINVERSTANDEN",
    Disagree: "ABLEHNEN",
    SendForReview: "ZUR ÜBERPRÜFUNG SENDEN",
    DisagreeMessage: "Um diesem Aspekt nicht zuzustimmen, müssen Sie einen Kommentar hinzufügen.",
    Comment: "KOMMENTAR SPEICHERN",
    Duplicate: "DUPLIZIEREN",
    AddMedia: "Medien hinzufügen",
    OpenEditor: "Editor öffnen",
    AddNew: "Neu hinzufügen",
    Files: "Dateien",
    NeedsReview: "zu überprüfen",

    // PROCESS - MOLD DESIGN
    FileVersions: "3D-Dateiversion",
    FileDiscussions: "3D-Datei-Diskussion",
    Mold3DFile: "3D-Datei Spritzwerkzeug",
    ChangeDate: "Datum ändern",
    MoldMaker: "Werkzeugmacher",
    Customer: "Kunde",
    Transfer: "Übertragen",
    AddNewFile: "Neue Datei hinzufügen",
    DeleteChangeFileMessage: "Sind Sie sicher, dass Sie diese Dateihistorie löschen wollen?",

    // PROCESS - MANUFACTURING
    Manufacturing: "Fertigung",

    // PROCESS - MOLD TRAIL PROCESS
    SamplingFlowProcess: "Bemusterungsablauf",
    InjectionReport: "Spritzgiessparameter",
    CoolingLayout: "Kühlungplan",

    "Project name": "Projektname",
    "Part number": "Teilenummer",
    "Actual 3D file": "Aktuelles 3-D file",
    "Plastic Material": "Material",
    "Mold Layout": "Spritzwerkzeugauslegung",
    "Mold Status": "Werkzeugstatus",
    "Part name": "Teile Name",
    "Mold number": "Spritzwerkzeugnummer",
    "Actual 2D Part drawing": "Aktuelle 2D Teilezeichnung",
    "Cavity number": "Anzahl der Kavitäten",
    Shrinkage: "Schwindung [%]",

    "Necessary documents for start sampling process": "Notwendige Dokumente für die Bemusterung",
    "2D-part drawing": "2D Teilezeichnung",
    "2D/3D Mold drawing": "2D/3D Zeichnung / Spritzwerkzeugdaten ",
    "Plastic material process data sheet": "Materialverarbeitungsdatenblatt",
    "Mold cooling layout": "Anschlussplan  Kühlkreisläufe",
    "Temperature measuring device": "Temperaturmessgerät",
    "Scale for check the part weight": "Feinwaage zum überprüfen des Teilegewichts (0,01g)",
    "Caliper for check dimension": "Messschieber zum prüfen",
    "Magnifier for part check": "Lupe zum kontrollieren",
    "Camera for documentation": "Kamera zum dokumentieren",
    "After T0 documentation last sampling process parameter": "Nach Erstbemusterung (T0) Dokumentation der letzten Bemusterung vorhanden",

    "Mold and maschine check before start sampling": "Überprüfen von Spritzwerkzeug und Maschine vor der Bemusterung",
    "Maschine size and mold size matching": "Spritzmaschine und Werkzeuggröße zueinander passend",
    "Cooling connection like cooling layout": "Kühlung nach Kühlplan angeschlossen",
    "Mold clamping without interference with the cooling connection": "Werkzeugaufspannung ohne Kollision mit den Kühlanschlüssen",
    "Check waterflow in the mold": "Durchfluss und Funktion der Kühlkreisläufe prüfen ",
    "Flange diameter and nozzle radius checked no leakage": "Zentrierring Durchmesser und Düsenradius geprüft",
    "Check mold temperature": "Werkzeugtemperaturen prüfen",
    "Check electrical connections": "Elektrischen Anschlüsse geprüft",
    "Moving parts run smoothly (Slider,Lifter, Ejector)": "Bewegliche Teile leichtgänig ( Schieber, Auswerfer, Innenschieber)",
    "Check hydraulic moving parts function ( cylinder, slider, plates)": "Hydraulische Schieber und Kernzüge auf Funktion geprüft",
    "Injection parameter like material data sheet or last sampling process": "Spritzparameter nach Datenblatt oder wie bei der letzten Bemusterung",
    "Material drying like data sheet": "Material nach Datenblatt getrocknet",
    "Check Screw barrel diameter temperature (temperature like data sheet or documentation form last sampling)":
        "Schneckenzylindertemperaturen geprüft (Temperaturen nach Datenblatt oder Dokumentation der letzten Bemusterung)",
    "Check injection volume": "Einspritzvolumen ermittelt",
    "Enough temperature heater units": "Ausreichend Heizgeräte für die Kühlkreisläufe",
    "Check Screw barrel diameter (max. Shot weight) with mold shot weight and check the residence time of the plastic material":
        "Schneckendurchmesser des Spritzaggregats (max. Schussgewicht) mit Schussgewicht des Spritzwerkzeugs überprüfen, Verweilzeit im Zylinder prüfen.",
    "Compare maschine clamping force with calculated mold clamping force":
        "Machinenschließkraft mit der berechneten Schließkraft für das Spritzwerkzeug prüfen.",
    "Check the gate points": "Anspritzpunkte geprüft",

    "Start sampling process": "Start des Bemusterungsprozess",
    "Process Guideline": "Prozess  Leitfaden",
    "Injection 95 to 98% of the part volume till switch point for Holding pressure":
        "Einspritzen  95-98% des Teilevolumens bis zum Umschaltpunkt auf Nachdruck (Standard)",
    "2 to 5% of the volume filling with Holding pressure": "2-5% des Volumens mit Nachdruck füllen (Standard)",
    "Cushion between 5 to 10mm or 5 to 20cm³": "Massenpolster 5-10mm oder 5-20 cm³",
    "Holding pressure around 60%  of the injection pressure": "Nachdruck ca.60% vom Einspritzdruck (Standard)",
    "Start with filling study": "Starten mit einer Füllstudie",
    "Filling study documentation": "Dokumentieren der Füllstudie",
    "Check if the filling even in all cavity (2 or more cavity)": "Überprüfen auf gleichmässiges füllen bei 2 oder mehr Kavitäten",
    "Check the part ejection (must be smoothly)": "Auswerfen des Teils überprüfen",
    "Injection speed follow the material data sheet": "Einspritzgeschwindigkeit nach Datenblatt",
    "Check Material dosing and dosing time": "Dosiervolumen und Weg eingestellt",
    "Check Backpressure and screw compression": "Staudruck und Schneckenkompresion eingestellt",
    "Injection profil adjust": "Einspritzprofil ermitteln",
    "Holding pressure adjust": "Nachdruckprofil ermitteln",
    "Sealing point adjust": "Siegelpunkt ermitteln",
    "Check the part weight (volume x density)": "Teilegewicht ermitteln (Volumen x Materialdichte)",
    "Check the venting and the venting position of the mold": "Überprüfen der Entlüftungen und Entlüftungspositionen im Werkzeug",
    "Start sampling": "Start der Teilebemusterung",
    "Parts for check the dimension after 20 complete shots": "Teile zum vermessen nach 20 kompletten Schuss entnehmen",
    "Check the important dimension (2D drawing)": "Überprüfen der wichtigen Dimensionen aus der 2D Teilezeichnung",
    "Check the surface requirements for the part": "Überprüfen der geforderten Oberflächen am Teil",
    "Check of cavity number, part identification and data stamp": "Überprüfen von Nestnummer, Teilenummer und Datumstempel",
    "In the case of follow-up sampling, check whether all optimizations and modifications done":
        "Überprüfen bei Folgebemusterungen ob alle Optimierungen und Änderungen im Spritzwerkzeug umgesetzt sind.",

    Item: "Kühlkreislauf Nr.",
    WaterOrOilTemp: "Wasser/Öl-Temperatur",
    MoldTemp: "Spritzwerkzeugtemperatur",
    FlowLMin: "Durchfluss l/min",
    LengthOrDiameter: "Kühlkreislauf Länge/Durchmesser (mm)",
    CoolingUnit: "Heizgerät",
    AddNewItem: "Neues Element hinzufügen",
    DeleteCoolingLayoutTableRowMessage: "Sind Sie sicher, dass Sie diese Zeile im Kühlplan löschen möchten?",
    OverviewCoolingLayout: "Übersicht Kühlplan",
    ItemName: "Bezeichnung",
    CopyFromSamplingProcess: "Kopieren aus der vorhergehenden Bemusterung",

    AddNewInjectionReport: "Neuen Spritzparameterreport hinzufügen",
    DuplicateInjectionReport: "Spritzparameterreport kopieren",
    DeleteInjectionReportMessage: "Sind Sie sicher, dass Sie diesen Spritzparameterreport löschen möchten?",

    InjectionParameters: "Spritzparameter",
    Comments: "Kommentare",
    Pictures: "Bilder",
    NoMediaMessage: "Noch keine Bilder hinzugefügt.",
    noCommentMessage: "Noch keine Kommentare hinzugefügt.",

    // Injection report page
    "1Comp. Mold": "1K Werkzeug",
    "2Comp. Mold": "2K Werkzeug",
    Media: "Media",
    MeasurementReport: "Messbericht",

    // injection report page - injection parameters - machine parameters
    Machine: "Maschine",
    "Max. Closing Force": "Max.Zuhaltung [kN/t]",
    "1.Screw barrel diameter": "1.Schneckendurchmesser [mm]",
    "2.Screw barrel diameter": "2.Schneckendurchmesser [mm]",
    "Max. Shot weight": "Max. Schussgewicht [g/cm³]",
    "Max. spec. Injection pressure": "Max. spez. Einspritzdruck [bar]",
    "Max. injection speed": "Max. Einspritzgeschwindigkeit [mm/s, cm³/s]",
    "Max. Maschine system pressure": "Max. Maschinensystemdruck [bar]",
    "Proportion  Maschine system pressure/spec.Injection pressure": "Verhältnis Systemdruck/spez.Druck [bar]",
    "Maximum Dosing": "Maximaler Dosierweg [mm/cm³]",
    "Volume per mm screw move": "Volumen pro mm [cm³]",

    // injection report page - injection parameters - component parameters
    "1. Component": "1.Komponente",
    "2. Component": "2.Komponente",
    Density: "Spez.Gewicht [g/cm³]",
    "Processing temperature": "Verarbeitungstemperatur [C°]",
    "Material drying temperature": "Materialvortrocknung [C°]",
    "Drying time": "Trockenzeit [h]",
    Colour: "Farbe",
    Batch: "Batch [%]",
    "Part Weight": "Teilegewicht [g]",
    "Shot weight": "Schussgewicht [g]",

    // injection report page - injection parameters - insert parameters
    "Metal insert": "Metall",

    // injection report page - injection parameters - hot runner parameters
    HotrunnerTemperature: "Heisskanaltemperaturen",
    "1.Hotrunner": "1.Heisskanal",
    "2.Hotrunner": "2.Heisskanal",
    "Zone 1": "Zone 1 [C°]",
    "Zone 2": "Zone 2 [C°]",
    "Zone 3": "Zone 3 [C°]",
    "Zone 4": "Zone 4 [C°]",
    "Zone 5": "Zone 5 [C°]",
    "Zone 6": "Zone 6 [C°]",
    "Zone 7": "Zone 7 [C°]",
    "Zone 8": "Zone 8 [C°]",
    "Zone 9": "Zone 9 [C°]",
    "Zone 10": "Zone 10 [C°]",
    "Zone 11": "Zone 11 [C°]",
    "Zone 12": "Zone 12 [C°]",
    "Zone 13": "Zone 13 [C°]",
    "Zone 14": "Zone 14 [C°]",
    "Zone 15": "Zone 15 [C°]",
    "Zone 16": "Zone 16 [C°]",
    "Circle 1": "Kreislauf 1",
    "Circle 2": "Kreislauf 2",
    "Circle 3": "Kreislauf 3",
    "Circle 4": "Kreislauf 4",
    "Circle 5": "Kreislauf 5",
    "Circle 6": "Kreislauf 6",
    "Circle 7": "Kreislauf 7",
    "Circle 8": "Kreislauf 8",
    "Valve gate": "Nadelverschlussdüsen",
    "Cascade control": "Kaskadensteuerung",
    OpenTime: "AUF Zeit [s]/ Weg [mm]",
    CloseTime: "ZU Zeit [s]/ Weg [mm]",
    "Number of air circles": "Anzahl Luftkreisläufe",

    // injection report page - injection parameters - screw barrel parameters
    "1.Screw barrel": "1.Spritzeinheit",
    "2.Screw barrel": "2.Spritzeinheit",
    Nozzle: "Düse [C°]",
    Einzug: "Einzug [C°]",
    Dosing: "Plastifizierweg [mm/cm³]",
    Position: "Position [mm/cm³]",
    "Dosing speed": "Dosiergeschw. [mm/cm³]",
    "Dosing time": "Dosierzeit [s]",
    "Residence time": "Verweilzeit [s]",
    "Screw retraction": "Schneckenrückzug [mm/cm³]",
    Backpressure: "Staudruck [bar]",
    "Screw RMP": "Schneckendrehzahl [1/min]",
    Decompression: "Druckentspannung [bar]",
    Injection: "Einspritzen",
    Pressure: "Druck [bar]",
    "Spec. Pressure": "spez. Druck [bar]",
    "Injection Speed": "Einspritzgeschw. [%,cm³/s, mm/s]",
    "Injection Volume": "Einspritzvolumen [cm³]",
    step1: "Stufe 1",
    step2: "Stufe 2",
    step3: "Stufe 3",
    step4: "Stufe 4",
    step5: "Stufe 5",
    step6: "Stufe 6",
    "Max. spec. injection pressure": "Max. benötigter spezifischer Einspritzdruck [bar]",
    "Switch position/volume": "Umschaltpunkt",
    "Position[mm]": "Position [mm]",
    Volume: "Volumen [cm³]",
    Time: "Zeit [s]",
    HoldingPressure: "Nachdruck",
    Cushion: "Massepolster [mm/cm³]",
    "Injection time": "Einspritzzeit [s]",

    // injection report page - injection parameters - cooling parameters
    "Cooling time": "Kühlzeit [s]",
    "Cycle time": "Zykluszeit [s]",

    // injection report page - injection parameters - hydraulic parameters
    "Hydraulic Core pulling": "Hydraulische Kernzüge",
    "Hydraulic circle 1": "Kernzug 1 [bar]",
    "Hydraulic circle 2": "Kernzug 2 [bar]",
    "Hydraulic circle 3": "Kernzug 3 [bar]",
    "Hydraulic circle 4": "Kernzug 4 [bar]",
    "Hydraulic circle 5": "Kernzug 5 [bar]",

    // injection report page - injection parameters - other parameters
    OtherParameters: "Maschinenparameter",
    "Current closing force": "Aktuelle Zuhaltung [kn/t]",
    "Ejector stroke": "Auswerferweg [mm]",
    "Calculated closing force": "Benötigte Zuhaltung [kn/t]",
    "Ejector speed": "Auswerfer Geschwindigkeit [mm/s]",
    "Mold opening stroke": "Werkzeugöffnungsweg",
    "Mold open/closing time": "Öffnungs/Schliesszeit [s]",
    "Venting in the Mold checked": "Werkzeugentlüftung geprüft und ausreichend",
    "Current part weight": "aktuelles Teilegewicht [g]",
    "Runner weight": "Angussgewicht [g]",

    // PROCESS - MOLD RELEASE PROCESS
    // Final run report
    FinalRunReport: "Finale Spritzwerkzeugfreigabe",
    MoldChecklist: "Werkzeugcheckliste",
    "Number of cavities": "Anzahl der Kavitäten",
    NumberOfColumns: "Anzahl der Spalten",

    NumberOfCavitiesMessage: "Die Anzahl der Kavitäten kann nach dem ersten Speichern nicht mehr geändert werden",
    CavitiesMessage: "Kavitätenformulare werden nach dem Speichern des Feldes Anzahl der Kavitäten verfügbar sein.",
    ModifyDimensions: "Dimensionen ändern",
    AddNewShotTime: "Schusszahl/Zeit hinzufügen",
    VisibleForCustomer: "Sichtbar für den Kunden",
    UnavailableStep6Info: "Diese Information ist zur Zeit nicht verfügbar.",
    "Prepare Final Run": "Vorbereitung für finale Bemusterung",
    "Start with Parameter from last Injection Report": "Spritzparameter und Temperatureinstellungen der letzten Bemusterung",
    "Use same Maschine as in the last Injection Report": "Spritzgiessen auf der gleichen Maschine mit dem gleichen Equipment wie bei der letzten Bemusterung",
    "Fix time or shot number for report": "Festlegen ob nach Schusszahl oder auf Zeit Teile zur Kontrolle entnommen werden",
    "Fix the important Dimension for the run": "Festlegen der wichtigen Dimensionen die jeweils geprüft werden",
    "Fix the important surface requirements": "Festlegen welche Oberflächenanforderungen geprüft werden",

    "Estimated Cycle time": "Geplante Zykluszeit [s]",
    "Current Cycle time": "Aktuelle Zykluszeit [s]",
    "Start time": "Start Zeit",
    "Automatic run": "Vollautomatisch",
    CavityNumber: "Kavitäten Nummer",
    Dimension: "Dimension [mm]",
    Tolerance: "Toleranz [+/-]",
    ShotTime: "Schusszahl / Zeit min",
    PartWeight: "Teilgewicht [g]",
    SurfaceCheck: "Oberflächenprüfung",
    Temperature: "Temperatur [C°]",
    CavityInsertTemperature: "DS Einsatz Temp. [C°]",
    CoreInsertTemperature: "AS Einsatz Temp. [C°]",
    SliderTemperature: "Schieber Temp. [C°]",
    MoldbaseTemperature: "Formplatten Temp. [C°]",
    Evaluation: "Auswertung",

    // PROCESS - ACCEPTANCE
    UnavailableStep7Info: "Diese Information ist zur Zeit nicht verfügbar.",

    ReleaseProcess: "Freigabe",

    // Mold Check list

    Maschine: "Maschine",
    "Max Closing Force": "Max.Zuhaltung  [kn/t]",
    "1. Screw barrel diameter": "1. Schneckendurchmesser [mm]",
    "2. Screw barrel diameter": "2. Schneckendurchmesser [mm]",
    "Mold Dimension": "Werkzeugmaße / Maschinengröße",
    "width x length x height": "Länge x Breite x Höhe [mm]",
    "Flange diameter": "Zentrierring Durchmesser [mm]",
    "KO insert screw diameter": "Gewinde Auswerferbolzen",
    "Sprue bushing radius": "Augussbuchsenradius",
    "Min. Mold opening": "mindest Werkzeugöffnung",
    "Mold weight": "Werkzeuggewicht",
    "Maschine Tie bar distance": "MA-Säulenabstand [mm]",
    "Maschine Flange diameter": "MA-Zentriering [mm]",
    "Maschine KO screw diameter": "MA-Auswerferstangengewinde",
    "Maschine nozzle radius": "MA-Düsenradius [mm]",
    "Max. Maschine Opening": "max Maschinenöffnung",
    "Maschine min. mold height": "minimale Werkzeughöhe",
    "Max. Ejector stroke": "Max. Auswerferweg",
    "Sprue bushing radius 2.Comp.": "Angussbuchsenradius 2.Komp",
    "Turning diameter core side": "Drehdurchmesser des Drehteller",
    "Check the locks for the turnatble": "Zentrierung für Drehteller passend",
    "Cooling connection match with the turntable": "Kühlanschlüsse im Werkzeug passend zum Drehteller",
    "Maschine nozzle radius 2.Comp.": "MA-Düsenradius 2.Komp.",
    "Turntable diameter": "Drehtellerdurchmesser",
    "Check the position for the ejector rod": "Ausswerferbolzen Position passend",

    "Cooling connection": "Kühlanschlüsse",
    "Connector screw size like GMS": "Kühlanschlussgewinde nach Werkzeugspezifkation",
    "Connector counterbore": "Kühlanschlusssenkungen nach Werkzeugspezifikation",
    "No interference between the Cooling connector": "Keine Interferenz zwischen  den Kühlanschlüssen",
    "Number of cooling line Cavity side": "Anzahl der Kühlkreisläufe DS",
    "Number of cooling line core side": "Anzahl der Kühlkreisläufe AS",
    "Cooling line labeling like GMS": "Kühlkreislaufkennzeichnung nach Werkzeugspezifikation",
    "Cooling line compare to Cooling layout": "Kühlkreislauf identisch mit dem Kühlplan",
    "Leakage check from the cooling lines": "Dichtigkeit der Kühlkreisläufe geprüft",
    "Check the cooling lines flow rate": "Durchfluss der Kühlkreisläufe geprüft",
    "Completely documented cooling plan": "Kühlplan vollständig dokumentiert",

    Moldbase: "Formaufbau",
    "Clamping according mold specification": "Aufspannung nach Werkzeugspezifikation",
    "No cooling connections  in the clamping area or latch lock area": "Keine Kühlanschlüsse oder Klinkenzüge im Aufspannbereich",
    "Lifting grooves for disassembly between the plates present": "Abhebenuten zwischen den Platten vorhanden",
    "Mold labels according GMS": "Plattenkennzeichnung nach Werkzeugspezifikation",
    "Mold plate numbering according GMS": "Werkzeugschilder nach Werkzeugspezifikation",
    "Hot runner connector according GMS": "Heisskanalstecker nach Werkzeugspezifiation",
    "Hot runner labels according GMS": "Heisskanalschilder nach Werkzeugspezifikation",
    "Check hot runner heating and temperature sensor for function": "Heisskanalheizung und Temperaturfühler auf Funktion prüfen",
    "Hot runner connecting according to GMS": "Heisskanalkontaktierung nach Werkzeugspezifikation",
    "Check the hot runner about leakage": "Heisskanal auf Überspritzung prüfen",
    "Air connection according to GMS": "Luftanschlüsse nach Werkzeugspezifikation",
    "Check labeling for air connection for valve gate": "Luftanschlüsse für Nadelverschluss gekennzeichnet",
    "Hydraulic cylinder supplier according to GMS": "Hersteller Hydraulikzylinder nach Werkzeugspezifikation",
    "Hydraulic cylinder type": "Bauart Hydraulikzylinder",
    "Limit switch for cylinder present and set": "Endschalter für Zylinder vorhanden und eingestellt",
    "Check the travel of the hydraulic cylinders": "Fahrweg der Hydraulikzylinder überprüfen",
    "Hydraulik circuits marked according GMS": "Hydraulikkreisläufe nach Werkzeugspezifikation gekennzeichnet",
    "Hydraulic connector according to GMS": "Hydraulikanschlüsse nach Werkzeugspezifikation",
    "Guides lubricated and lubrication grooves inserted": "Führungen geschmiert und Schmiernuten eingebracht",
    "Guide columns longer than inclined columns": "Führungssäulen länger als Schrägsäulen ",
    "Mounting guide columns for hot runner mounting": "Montagesäule für Heisskanalmontage",
    "Guide system according to GMS": "Durchgehendes Führungssystem nach Werkzeugspezifikation",
    "Inclined columns with chamfer or radius according to GMS": "Schrägsäulen mit Fase oder Radius nach Werkzeugspezifikation",
    "Inclined column inlet on slide rounded off": "Säuleneinlauf an Schieberrundet",
    "Slider on the top mechanically secured position": "Schieber Oben (Top) mechanisch gesicherte Position",
    "Slider detent elements according to GMS": "Schieberrastelemente nach Werkzeugspezifikation",
    "Slider position secured with ball grid": "Schieberposition mit Kugelraster gesichert",
    "Ball grid position checked and adjusted": "Kugelraster Position geprüft und eingestellt ",
    "Slider move easily in the slide guides Lubrication grooves present in the slide guides":
        "Schieber lassen sich leicht bewegen in den Schieberführungen Schmiernuten in den Schieberführungen vorhanden",
    "Lubrication grooves present in the wear plates": "Schmiernuten in den Druckplatten vorhanden",
    "Mechanical slide lock for ejector positions under the slide contour": "Mechanische Schiebersicherung bei Auswerferpositionen unter der Schieberkontur",
    "Elements with spring movement smooth, spring travel tested": "Elemente mit Federbewegung leichtgängig, Federweg geprüft",
    "Ejector system smooth can be moved by hand": "Auswerfersystem leichtgängig lässt sich von Hand bewegen",
    "Ejector system Guides according to GMS": " Auswerfersystem Führungen nach Werkzeugspezifikation",
    "Ejector pin moves easily": "Auswerfer bewegen sich leicht",
    "Push back pins are easy to move": "Rückdrückstifte sind leicht zu bewegen",
    "Ejector pin for runner fixed runner can drop down": "Auswerfer für Kaltkanal sind fixiert Anguss kann fallen",
    "Ejector stroke marked on mold": "Auswerferweg auf Werkzeug gekennzeichnet",
    "Limit switch for ejector plates according to GMS": "Endschalter für Auswerferplatten Werkzeugspezifikation",
    "Ejector positions marked on the ejector plate": "Auswerferpositionen auf der Auswerferplatte gekennzeichnet",
    "No sharp edges, all parts with chamfers or rounded": "Keine Scharfen Kanten, alle Teile mit Fasen oder verrundet",
    "Support bar with eyebolt present": "Tragbalken mit Ringschraube vorhanden",
    "Weight and tool number on the support bar": "Gewicht und Werkzeugnummer auf dem Tragbalken",
    "Single transport possible and balanced": "Einzeltransport möglich und ausbalanciert",
    "Eyebolt size according to GMS": "Ringschraubengröße nach Werkzeugspezifikation",

    "Mold inserts and gate": "Formeinsätze und Angusskanäle",
    "Sprue bush polished in demolding direction": "Angussbuchse in Entformrichtung poliert",
    "Connection to the cold runner rounded": "Übergang zum Kaltkanal verrundet",
    "Runner polished and rounded": "Angusskanal poliert und verrundet",
    "Injection points balanced and measured": "Anspritzpunkte ausbalanciert und vermessen",
    "Filling equally in the filling study": "Füllung in der Füllstudie gleichmässig",
    "Runner falls automatically during demolding": "Anguss fällt automatisch beim entformen",
    "Mold surfaces according to drawing approved by customer": "Formoberflächen nach Zeichnung vom Kunden freigegeben",
    "Inspect surfaces for damage": "Oberflächen überprüfen auf Beschädigung",
    "Cavity number according drawing": "Formnestkennzeichnung nach Zeichnung",
    "Recycling engraving according drawing": "Recyclingzeichen nach Zeichnung",
    "Date stamp according drawing": "Datumstempel nach Zeichnung",
    "Date stamp set to current date": "Datumstempel auf aktuelles Datum eingestellt",
    "Shut off contour sharp and not damaged": "Trennkanten scharf und nicht beschädigt",
    "Shut off contour according to GMS": "Trennungen freigestellt nach Werkzeugspezfikation",
    "Spotting image even": "Tuschierbild gleichmässig",
    "Venting slots inserted and tested": "Entlüftungskanäle eingebracht und geprüft",
    "Check mold inserts for abrasions": "Formeinsätze auf Abschabungen prüfen",
    "Check centering elements for abrasions": "Zentrierelemente auf Abschabungen prüfen",
    "Slider shut off contour sharp and not damaged": "Schiebertuschierung geprüft Kanten nicht beschädigt",
    "Slider lock tested": "Schieberverriegelung geprüft",
    "Lifter smooth-running no abrasion": "Innenschieber leichtgängig kein Abrieb",
    "Lubrication grooves on the Lifter": "Schmiernuten an den Innenschiebern",
    "Lifter fix in the end position": "Innenschieber federn nicht in Endlage",
    "Lifter guiding  in ejector plate according to GMS": "Innenschieberaufnahme in Auswerferplatte nach Werkzeugspezifikation",
    "Check Lifter function no collision with ejector": "Innenschieberfunktion prüfen keine Kollision mit Auswerfer",
    "No abrasions on the plastic part due to the Lifter": "Keine Abschabungen am Teil durch den Innenschieber",
    "Guide elements without run-in marks": "Führungselemente ohne Einlaufspuren",
    "Plastic parts fall automaticly": "Teile frei fallend",
    "Plastic part taking out with robot": "Teileentnahme mit Roboter möglich",
    "Inserts numbered according to parts list": "Einsätze mit Nummerierung nach Stückliste",

    Delivery: "Auslieferung",
    "Packing according to GMS": "Verpackung nach Werkzeugspezifikation",
    "All required documents provided": "Alle geforderten Dokumente bereitgestellt",
    "3D data updated": "3D Daten des Werkzeugs auf aktuellem Stand",
    "2D drawing updated ": "2D Zeichnungen des Werkzeugs auf aktuellem Stand",
    "Spare parts provided upon agreement": "Ersatzteile nach Absprache bereitgestellt",
    "Sent out by": "Versandt per",

    "Checked by:": "Geprüft von:",
    "Moldmaker:": "Werkzeugmacher:",
    "Released:": "Freigegeben:",
    "Revised till:": "Überarbeitet bis:",

    // MOLD MAKERS VIEW
    AddNewToolMaker: "Neuen Werkzeugmacher hinzufügen",
    EditToolMaker: "Werkzeugmacher bearbeiten",

    // ADMIN VIEW
    Admins: "Admins",

    // SUCCESS MESAGES
    PutSuccess: "Erfolgreich gespeichert",
    PostSuccess: "Erfolgreich erstellt",
    PasswordSuccess: "Passwort geändert",
    DeleteSuccess: "Erfolgreich erstellt",

    // REMINDERS
    Schedule: "Schedule",
    ReminderSettings: "Reminder Settings",
    ReminderTitle: "DFM and Mold Design Email Reminders Settings",
    NewReminder: "Create a new reminder",
    ReminderEmail: "Reminder email",
    Repeats: "Repeats",
    Daily: "Daily",
    Weekly: "Weekly",
    SendDate: "Send date",
    SendTime: "Send time",
    StartDate: "Start date",
    EndDate: "End date",
    NoEndDate: "No end date",
    Timezone: "Timezone",
    DailyReminder: "Daily Reminder",
    WeeklyReminder: "Weekly Reminder",
    Days: {
        mon: "Monday",
        tue: "Tuesday",
        wed: "Wednesday",
        thu: "Thursday",
        fri: "Friday",
        sat: "Saturday",
        sun: "Sunday",
    },
    DailySat: "Mon-Sat",
    DailyFri: "Mon-Fri",
    DeleteReminder: "Are you sure you want to delete this reminder?",
    TooEarlyReminder: "The reminder must be scheduled for a date and time in the future",

    // ERRORS
    Errors: {
        E0010: "Ungültige E-Mail Adresse.",
        E0011: "Ungültiges Passwort.",
        E0012: "Benutzer bereits vorhanden.",
        E0013: "Das Token ist abgelaufen.",
        E0014: "Token wurde entzogen.",
        E0015: "Signaturprüfung fehlgeschlagen.",
        E0016: "Die Anfrage enthält kein Token.",
        E0017: "Token nicht frisch.",
        E0018: "Benutzer ist bereits abgemeldet.",
        E0019: "Benutzerberechtigung verweigert.",
        E0020: "Benutzer nicht gefunden.",

        E0030: "Ungültiges JSON-Format.",
        E0031: "E-Mail kann nicht gesendet werden, die Anmeldeinformationen sind ungültig!",
        E0032: "Datei ist nicht vorhanden",
        E0033: "Server-Fehler",
        E0034: "Ordner ist nicht vorhanden",

        E0101: "Objekt konnte nicht in die Datenbank eingefügt werden.",
        E0102: "Zugriff auf Datenbank fehlgeschlagen.",
        E0103: "Entität existiert bereits",
        E0104: "Datenbankmodell ist nicht vorhanden",

        E0131: "Werkzeugmacher nicht gefunden.",
        E0132: "Werkzeugmacher existiert bereits.",
        E0133: "Kunde nicht gefunden.",
        E0134: "Kunde ist bereits vorhanden.",
        E0135: "Projekt existiert bereits.",
        E0136: "Projekt nicht gefunden.",
        E0137: "Benutzer nicht diesem Projekt zugewiesen.",
        E0138: "Diesem Projekt bereits zugewiesener Benutzer.",
        E0139: "Werkzeug nicht gefunden.",
        E0140: "Werkzeug existiert bereits.",
        E0141: "Maschine nicht gefunden.",
        E0142: "Gantt-Diagramm nicht gefunden.",
        E0143: "Meilenstein nicht gefunden",
        E0144: "Prozess nicht gefunden",
        E0145: "Ungültiger neuer Zustand für Prozessschritt",
        E0146: "Schritt mit angegebener Schrittnummer nicht gefunden",
        E0147: "Sie haben für diesen Überprüfungsaspekt nicht die Kontrolle, um den Status zu ändern",
        E0148: "Nur der Kundenbenutzer kann diese Operation durchführen",
        E0149: "Nur Kunden- oder Admin-Benutzer können diese Operation durchführen",
        E0150: "Nur der Benutzer admin kann diese Operation durchführen",

        E0501: "DFM-Prüfaspekt mit angegebener ID wurde nicht gefunden",
        E0502: "Fertigungsartikel mit angegebener ID wurde nicht gefunden",
        E0503: "Elemente, die Medien oder Kommentare enthalten, können nicht gelöscht werden",
        E0504: "Kommentar nicht gefunden",
        E0505: "Änderungshistorie nicht gefunden",
        E0506: "Dateiversion nicht gefunden",
        E0507: "Spritzgiessparameterreport nicht gefunden",
        E0508: "Kühlplan nicht gefunden",
        E0509: "Tabellenzeilen-Layout nicht gefunden",
        E0510: "Spritzgiessparameter media mit angegebener id wurde nicht gefunden",
        E0511: "Spritzgiessparameter mit angegebener ID wurde nicht gefunden",

        PasswordMatch: "Passwörter stimmen nicht überein...",

        E9999: "Unbekannter Fehler!",
    },
};

export default de;
