import React from "react";
import { Helmet } from "react-helmet";

import Projects from "../../components/Projects/Projects";

const ProjectsView = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Projects</title>
            </Helmet>
            <Projects {...props} />
        </React.Fragment>
    );
};

export default ProjectsView;
