import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import RoutingContext from "../../../../utils/RoutingContext";
import { Collapse, Divider, makeStyles } from "@material-ui/core";
import Axios from "axios";
import styles from "../../../../assets/jss/material-dashboard-react/components/DFMReviewListStyle";
import AddCommentModal from "../../Common/AddCommentModal";
import ChangeControlModal from "../../Common/ChangeControlModal";
import AddMediaModal from "../../Common/AddMediaModal";
import EditModal from "../../Common/EditModal";
import DeleteModal from "../../Common/DeleteModal";
import AddNew from "../../Common/AddNew";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import StyledButton from "../../../StyledButton/StyledButton";
import SendIcon from "@material-ui/icons/Send";
import { ExpandMoreRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import RichTextEditor from "../../../RichTextEditor/RichTextEditor";
import InjectionParameters from "./InjectionParameters/InjectionParameters";
import Media from "./Media/Media";
import MeasurementReport from "./MeasurementReport/MeasurementReport";

const useStyles = makeStyles(styles);

const InjectionReportComponent = (props) => {
    const [completedColor, setCompletedColor] = useState("#0DC08A");
    const [underReviewColor, setUnderReviewColor] = useState("#E39600");
    const [needsReviewColor, setNeedsReviewColor] = useState("#C00D0E");
    const [data, setData] = useState({});
    const [open, setOpen] = useState(null);
    const [openUpload, setOpenUpload] = useState("");
    const [openEditor, setOpenEditor] = useState(false);
    const [openAddNew, setOpenAddNew] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [elementToEdit, setElementToEdit] = useState(null);
    const [commentToEdit, setCommentToEdit] = useState(null);
    const [accountNameInitials, setAccountNameInitials] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    const { languageData, role } = useContext(WebsiteContext);
    const { stepData, setBackPath, setRoutingData, setStepData } = useContext(RoutingContext);

    const classes = useStyles({
        completedColor: completedColor,
        underReviewColor: underReviewColor,
        needsReviewColor: needsReviewColor,
        role: role,
    });

    useEffect(() => {
        const name = localStorage.getItem("name");
        setAccountNameInitials(
            name
                .match(/\b(\w)/g)
                .join("")
                .substring(0, 2)
        );
        getStepData();
        getInjectionParameters();
    }, []);

    const getStepData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}`,
            method: "GET",
        };
        Axios(options).then((response) => {
            const data = response.data.data.processes;
            setIsVisible(response.data.data.isSecondCompsVisible);
            setRoutingData();
            setStepData({
                visibleContent: true,
                id: props.match.params.stepNumber,
                toolId: props.match.params.id,
                name: data.name,
                state: data.state,
                canExport: false,
                canClose: false,
            });
            if (role === 0) {
                setBackPath(`/customers/projects/tools/${props.match.params.id}/process/step/5/injection-report`);
            } else if (role === 1) {
                setBackPath(`/project/tools/${props.match.params.id}/process/step/5/injection-report`);
            } else if (role === 2) {
                setBackPath(`/tool/${props.match.params.id}/process/step/5/injection-report`);
            }
        });
    };

    const changeSecondCompVisibility = (value) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: {
                isSecondCompsVisible: value,
            },
        };
        Axios(options).then((_) => {
            getStepData();
        });
    };

    const getInjectionParameters = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${props.match.params.injectionNumber}`,
            method: "GET",
        };
        Axios(options).then((response) => {
            const responseData = response.data.data;
            setData(responseData);
        });
    };

    const handleDelete = () => {
        const deleteOptions = {
            url:
                "/file-operations/" +
                `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` +
                `/files/${elementToDelete.fileId}`,
            headers: {
                "X-Loading": false,
            },
            method: "DELETE",
        };
        Axios(deleteOptions).then((response) => {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${props.match.params.injectionNumber}/pictures/${elementToDelete.fileId}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                setElementToDelete(null);
                getInjectionParameters();
            });
        });
    };

    const handleCancelDelete = () => {
        setElementToDelete(null);
    };

    const saveComment = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${props.match.params.injectionNumber}/comment`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: {
                text: commentToEdit,
            },
        };
        Axios(options).then((_) => {
            getInjectionParameters();
            setCommentToEdit(null);
        });
    };

    const moveMedia = (up, pictureId) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${props.match.params.injectionNumber}/pictures/${pictureId}`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                up: up,
            },
        };
        Axios(options).then((_) => {
            getInjectionParameters();
        });
    };

    const uploadFile = (file) => {
        let filesFormatData = new FormData();
        filesFormatData.append("files", file, file.name);

        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` + "/files",
            method: "POST",
            data: filesFormatData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            const mediaOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-report/${props.match.params.injectionNumber}/pictures`,
                method: "POST",
                headers: {
                    "X-Loading": false,
                },
                data: {
                    fileId: response.data.data.files[0].id,
                    type: 0,
                },
            };
            Axios(mediaOptions).then((response) => {
                setOpenUpload("");
                getInjectionParameters();
            });
        });
    };

    const saveNewMedia = (formData, edit, fileId) => {
        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` + "/files",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            let mediaOptions;
            if (edit === true) {
                mediaOptions = {
                    url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${props.match.params.injectionNumber}/pictures/${fileId}`,
                    method: "PUT",
                    headers: {
                        "X-Loading": false,
                    },
                    data: {
                        newFileId: response.data.data.files[0].id,
                    },
                };
            } else {
                mediaOptions = {
                    url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-report/${props.match.params.injectionNumber}/pictures`,
                    method: "POST",
                    headers: {
                        "X-Loading": false,
                    },
                    data: {
                        fileId: response.data.data.files[0].id,
                        type: 0,
                    },
                };
            }
            Axios(mediaOptions).then((response) => {
                setOpenEditor(false);
                setElementToEdit(null);
                setOpenUpload("");
                getInjectionParameters();
            });
        });
    };

    const saveMedia = (formData) => {
        if (!openEditor) {
            const deleteOptions = {
                url:
                    "/file-operations/" +
                    `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` +
                    "/files/" +
                    elementToEdit?.fileId,
                headers: {
                    "X-Loading": false,
                },
                method: "DELETE",
            };
            Axios(deleteOptions).then((_) => {
                saveNewMedia(formData, true, elementToEdit?.fileId);
            });
        } else {
            saveNewMedia(formData, false, elementToEdit?.fileId);
        }
    };

    const changeObject = (section, newData) => {
        const tempData = { ...data };
        tempData[section] = newData;
        setData(tempData);
    };

    const saveInjectionParameters = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${props.match.params.injectionNumber}/injection-parameters`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: data.injectionParameters,
        };
        Axios(options).then((_) => {
            getInjectionParameters();
        });
    };

    return (
        <div className={classes.listWrapper + " " + classes.padding}>
            <div className={classes.innerWrapper}>
                <AddMediaModal
                    openEditor={() => {
                        setOpenEditor(true);
                    }}
                    open={openUpload !== "" && openEditor === false}
                    onClose={() => setOpenUpload("")}
                    uploadFile={uploadFile}
                />
                <EditModal
                    open={elementToEdit || openEditor ? true : false}
                    onClose={() => {
                        setOpenEditor(false);
                        setElementToEdit(null);
                        setOpenUpload("");
                    }}
                    saveMedia={saveMedia}
                    src={
                        openEditor === false
                            ? `${process.env.REACT_APP_URL}file-operations/` +
                              `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}/files/` +
                              elementToEdit?.fileId
                            : ""
                    }
                />
                <DeleteModal open={elementToDelete ? true : false} onCancel={() => handleCancelDelete()} onDelete={handleDelete} />
                <div className={classes.injectionTitle}>{data.name}</div>
                <div className={classes.listElement}>
                    <div className={classes.title}>
                        <div className={classes.stepName}>{languageData?.InjectionParameters}</div>
                        <div className={classes.status}>
                            {open !== 0 ? (
                                <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === 0 ? null : 0)} />
                            ) : (
                                <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === 0 ? null : 0)} />
                            )}
                        </div>
                    </div>
                    <Collapse in={open === 0}>
                        <div className={classes.content} style={{ padding: 0 }}>
                            <InjectionParameters
                                data={data?.injectionParameters}
                                changeObject={changeObject}
                                submitData={saveInjectionParameters}
                                getData={getInjectionParameters}
                                {...props}
                                visible={isVisible}
                                changeVisibility={changeSecondCompVisibility}
                            />
                        </div>
                    </Collapse>
                </div>
                <div className={classes.listElement}>
                    <div className={classes.title}>
                        <div className={classes.stepName}>{languageData?.Comments}</div>
                        <div className={classes.status}>
                            {open !== 1 ? (
                                <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === 1 ? null : 1)} />
                            ) : (
                                <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === 1 ? null : 1)} />
                            )}
                        </div>
                    </div>
                    <Collapse in={open === 1}>
                        <div className={classes.content + " " + classes.commentContent}>
                            {(role === 0 || role === 1 || role === 2) && (
                                <StyledButton
                                    onClick={() => {
                                        if (commentToEdit !== null) {
                                            saveComment();
                                        } else {
                                            setCommentToEdit(data?.comment);
                                        }
                                    }}
                                    className={classes.commentSaveButton}
                                    variant="outlined"
                                >
                                    {commentToEdit !== null ? languageData?.Save : languageData?.Edit}
                                </StyledButton>
                            )}
                            {commentToEdit === null && data?.comment !== "" && (
                                <div className={classes.textContainer} dangerouslySetInnerHTML={{ __html: data?.comment }} />
                            )}
                            {commentToEdit === null && data?.comment === "" && (
                                <div className={classes.commentMessageText + " " + classes.commentsMessage}>{languageData?.noCommentMessage}</div>
                            )}
                            {commentToEdit !== null && <RichTextEditor content={commentToEdit} onChange={(content) => setCommentToEdit(content)} />}
                            {(role === 0 || role === 1 || role === 2) && (
                                <StyledButton
                                    onClick={() => {
                                        if (commentToEdit !== null) {
                                            saveComment();
                                        } else {
                                            setCommentToEdit(data?.comment);
                                        }
                                    }}
                                    className={classes.commentSaveButton}
                                    variant="outlined"
                                >
                                    {commentToEdit !== null ? languageData?.Save : languageData?.Edit}
                                </StyledButton>
                            )}
                        </div>
                    </Collapse>
                </div>
                <div className={classes.listElement}>
                    <div className={classes.title}>
                        <div className={classes.stepName}>{languageData?.Pictures}</div>
                        <div className={classes.status}>
                            {open !== 2 ? (
                                <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === 2 ? null : 2)} />
                            ) : (
                                <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === 2 ? null : 2)} />
                            )}
                        </div>
                    </div>
                    <Collapse in={open === 2}>
                        <div className={classes.content}>
                            {data?.pictures?.length === 0 && (
                                <div className={classes.commentMessageText + " " + classes.commentsMessage}>{languageData?.NoMediaMessage}</div>
                            )}
                            {data?.pictures?.map((mediaElement, index) => (
                                <div key={index} className={classes.mediaElement}>
                                    <div className={classes.flexCard}>
                                        {(role === 0 || role === 1 || role === 2) && (
                                            <div className={classes.positionButtons}>
                                                {index > 0 && (
                                                    <div className={classes.upDownButton} onClick={() => moveMedia(true, mediaElement.fileId)}>
                                                        <ExpandLessIcon color="inherit" />
                                                    </div>
                                                )}
                                                {index >= 0 && index < data?.pictures?.length && index !== data?.pictures?.length - 1 && (
                                                    <div className={classes.upDownButton} onClick={() => moveMedia(false, mediaElement.fileId)}>
                                                        <ExpandMoreRounded color="inherit" />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <img
                                            src={`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}/files/${mediaElement.fileId}`}
                                            alt={mediaElement.id}
                                            className={classes.img}
                                        />
                                    </div>
                                    {(role === 0 || role === 2) && (
                                        <div className={classes.actions}>
                                            <StyledButton
                                                onClick={() => {
                                                    setOpenUpload("2");
                                                    setElementToEdit(mediaElement);
                                                }}
                                                className={classes.actionButton}
                                                variant="outlined"
                                                startIcon={<EditIcon color="primary" />}
                                            >
                                                {languageData?.Edit}
                                            </StyledButton>
                                            <StyledButton
                                                onClick={() => setElementToDelete(mediaElement)}
                                                className={classes.actionButton}
                                                variant="outlined"
                                                startIcon={<DeleteIcon color="primary" />}
                                            >
                                                {languageData?.Delete}
                                            </StyledButton>
                                        </div>
                                    )}
                                </div>
                            ))}
                            {(role === 0 || role === 1 || role === 2) && (
                                <div className={classes.addMediaButton} onClick={() => setOpenUpload("2")}>
                                    <AddCircleIcon className={classes.addMediaIcon} color="primary" />
                                    {languageData?.AddMedia}
                                </div>
                            )}
                        </div>
                    </Collapse>
                </div>
                <div className={classes.listElement}>
                    <div className={classes.title}>
                        <div className={classes.stepName}>{languageData?.Media}</div>
                        <div className={classes.status}>
                            {open !== 3 ? (
                                <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === 3 ? null : 3)} />
                            ) : (
                                <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === 3 ? null : 3)} />
                            )}
                        </div>
                    </div>
                    <Collapse in={open === 3}>
                        <div className={classes.content} style={{ padding: 0 }}>
                            <Media data={data?.media} {...props} changeObject={changeObject} getData={getInjectionParameters} />
                        </div>
                    </Collapse>
                </div>
                <div className={classes.listElement}>
                    <div className={classes.title}>
                        <div className={classes.stepName}>{languageData?.MeasurementReport}</div>
                        <div className={classes.status}>
                            {open !== 4 ? (
                                <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === 4 ? null : 4)} />
                            ) : (
                                <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === 4 ? null : 4)} />
                            )}
                        </div>
                    </div>
                    <Collapse in={open === 4}>
                        <div className={classes.content} style={{ padding: 0 }}>
                            <MeasurementReport data={data?.measurement_report} {...props} changeObject={changeObject} getData={getInjectionParameters} />
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    );
};

export default InjectionReportComponent;
