import { Button, Dialog, DialogContent, IconButton, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import WebsiteContext from "../../../utils/Website";
import CloseIcon from "@material-ui/icons/Close";
import BackupIcon from "@material-ui/icons/Backup";
import StyledButton from "../../StyledButton/StyledButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: "500px",
        maxWidth: "unset",
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(700)]: {
            width: "80vw",
        },
    },
    dialog: {
        maxWidth: "unset",
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    dialogContentWrapper: {},
    title: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "16px",
        textAlign: "center",
        marginTop: "24px",
        color: "#1F1F1F",
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        padding: "40px 0",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        boxShadow: "none",
        textTransform: "none",
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: "14px",
        borderRadius: 2.57,

        backgroundColor: theme.palette.primary.main,
        border: "none",
        color: theme.palette.common.white,
        justifyContent: "center",
        height: 33,
        width: 210,
        padding: "0 10px",
        margin: "16px 0",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    checked: {},
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
}));

const ChangeControlModal = (props) => {
    const classes = useStyles();
    const { languageData } = useContext(WebsiteContext);
    const [toCustomer, setToCustomer] = useState(false);

    const changeControl = () => {
        props.changeControl(toCustomer, setToCustomer);
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} classes={{ paperWidthSm: classes.dialog }}>
            <DialogContent className={classes.dialogContentWrapper}>
                <div className={classes.modalWrapper}>
                    <IconButton className={classes.icon} onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                    <div className={classes.titleWrapper}>{languageData?.SendForReview}</div>
                    <div className={classes.buttons}>
                        {/* <StyledButton className={classes.button} variant="outlined" startIcon={<BackupIcon color="inherit" />}>
                            UPLOAD FILE
                        </StyledButton> */}
                        <RadioGroup className={classes.radioButtonsWrapper} value={toCustomer} onChange={(e) => setToCustomer(e.target.value === "true")}>
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                name="control"
                                value={true}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.ToCustomer}
                            />
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                name="control"
                                value={false}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.ToMoldMaker}
                            />
                        </RadioGroup>
                        <StyledButton onClick={changeControl} className={classes.button} variant="outlined" startIcon={<OpenInNewIcon color="inherit" />}>
                            {languageData?.SendForReview}
                        </StyledButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeControlModal;
