import React, { useContext } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Button, makeStyles } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    selectWithLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginRight: "20px",
        marginBottom: "20px",
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    subtitleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginBottom: 4,
    },
    selectWrapper: {
        width: "210px",
        height: "33px",
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    inputWrapper: {
        padding: "10px 14px",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    contentMargin: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down(500)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        margin: "5px 10px 5px",
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    rowContentWrapper: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(750)]: {
            flexDirection: "column",
        },
    },
    componentWrapper: {
        display: "flex",
        flexDirection: "row",
    },
    checked: {},
}));

const DocumentsForDelivery = (props) => {
    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const handleOpenCustomerGMS = () => {
        if (role === 0) {
            window.open(window.location.origin + "/customers/" + props.customerId + "/gts", "_blank", "noopener,noreferrer");
        } else if (role === 1) {
            window.open(window.location.origin + "/customer/gts", "_blank", "noopener,noreferrer");
        } else {
            window.open(window.location.origin + "/tool/" + props.toolId + "/gms", "_blank", "noopener,noreferrer");
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.DocumentsForDelivery.toUpperCase()}</div>
            <div className={classes.contentMargin}>
                <Button
                    variant="contained"
                    component="label"
                    className={classes.uploadFileButton}
                    startIcon={<OpenInNewIcon color="primary" />}
                    onClick={handleOpenCustomerGMS}
                >
                    {languageData?.OpenCustomerGMS}
                </Button>
            </div>
            <div className={classes.contentWrapper}>
                {Object.keys(props?.data ? props.data : {}).map((key, index) => (
                    <div className={classes.selectWithLabel} key={key}>
                        <InputLabel
                            // required={index < Object.keys(props.data).length - 4}
                            className={classes.subtitleWrapper}
                        >
                            {languageData[key]}
                        </InputLabel>
                        <div className={classes.componentWrapper}>
                            <RadioGroup
                                className={classes.radioButtonsWrapper}
                                value={props.data[key] !== null ? props.data[key] : ""}
                                onChange={(e) => props.updateDocumentsForDelivery("documentsForDelivery", key, e.target.value === "true")}
                            >
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="documentsForDelivery"
                                    value={true}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.Yes}
                                />
                                <FormControlLabel
                                    disabled={props.disabled}
                                    classes={{ label: classes.textWrapper }}
                                    name="documentsForDelivery"
                                    value={false}
                                    control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                    label={languageData?.No}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

DocumentsForDelivery.propTypes = {
    data: PropTypes.any,
    updateDocumentsForDelivery: PropTypes.func,
    values: PropTypes.array,
};

export default DocumentsForDelivery;
