import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import Axios from "axios";
import { Link, Route, Switch } from "react-router-dom";

import { Divider, makeStyles, Tab, Tabs } from "@material-ui/core";

import RoutingContext from "../../../utils/RoutingContext";
import WebsiteContext from "../../../utils/Website";
import Info from "./Info/Info";
import DFMReviewList from "./DFMReviewList/DFMReviewList";
import ChangeHistory from "./ChangeHistory/ChangeHistory";

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    tabStyle: {
        padding: "16px",
        overflowX: "hidden",
    },
    boxStyle: {
        padding: 0,
    },
    padding: {
        height: "calc(100% - 85px)",
        padding: 18,
    },
}));

const Step = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role, language } = useContext(WebsiteContext);
    const { setRoutingData, setStepData, setBackPath, stepData } = useContext(RoutingContext);

    useEffect(() => {
        getStepData();
    }, [language]);

    const getStepData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}`,
            method: "GET",
            headers: {
                "X-Locale": language,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data.processes;
            setRoutingData();
            setStepData({
                visibleContent: true,
                id: props.match.params.stepNumber,
                toolId: props.match.params.id,
                name: data.name,
                state: data.state,
                canClose: data.canClose,
                canExport: true,
                exportFileId: data.exportFileId,
                closeStep,
                exportDFM,
                states: response.data.data.state_resources,
                viewDFM: () => viewDFM(data.exportFileId),
            });
            setBackPath(
                role === 0
                    ? `/customers/projects/tools/${props.match.params.id}/process`
                    : role === 1
                    ? `/project/tools/${props.match.params.id}/process`
                    : `/tool/${props.match.params.id}/process`
            );
        });
    };

    const closeStep = (state) => {
        if (state === 2) {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}/close`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                getStepData();
            });
        } else {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}/start`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                getStepData();
            });
        }
    };

    const exportDFM = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/dfm/export`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            window.open(`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-dfm-media/files/${response.data.data}`);
            getStepData();
        });
    };

    const viewDFM = (exportFileId) => {
        window.open(`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-dfm-media/files/${exportFileId}`);
    };

    return (
        <React.Fragment>
            <Divider />
            {/* <Divider /> */}
            <Tabs value={props.location.pathname} className={classes.wrapper} variant="scrollable" indicatorColor={"primary"} aria-label="simple tabs example">
                <Tab
                    label={languageData.INFO}
                    component={Link}
                    to={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/info`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/info`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/info`
                    }
                    value={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/info`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/info`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/info`
                    }
                />
                <Tab
                    label={languageData.ChangeHistory}
                    component={Link}
                    to={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/change-history`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/change-history`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/change-history`
                    }
                    value={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/change-history`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/change-history`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/change-history`
                    }
                />
                <Tab
                    label={languageData.DFMReviewList}
                    component={Link}
                    to={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/dfm-review-list`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/dfm-review-list`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/dfm-review-list`
                    }
                    value={
                        role === 0
                            ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/dfm-review-list`
                            : role === 1
                            ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/dfm-review-list`
                            : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/dfm-review-list`
                    }
                />
            </Tabs>
            <div className={classes.padding}>
                <Switch>
                    <Route
                        path={
                            role === 0
                                ? `/customers/projects/tools/:id/process/step/:stepNumber/info`
                                : role === 1
                                ? `/project/tools/:id/process/step/:stepNumber/info`
                                : `/tool/:id/process/step/:stepNumber/info`
                        }
                        render={(props) => <Info {...props} />}
                    />
                    <Route
                        path={
                            role === 0
                                ? `/customers/projects/tools/:id/process/step/:stepNumber/change-history`
                                : role === 1
                                ? `/project/tools/:id/process/step/:stepNumber/change-history`
                                : `/tool/:id/process/step/:stepNumber/change-history`
                        }
                        render={(props) => <ChangeHistory {...props} />}
                    />
                    <Route
                        path={
                            role === 0
                                ? `/customers/projects/tools/:id/process/step/:stepNumber/dfm-review-list`
                                : role === 1
                                ? `/project/tools/:id/process/step/:stepNumber/dfm-review-list`
                                : `/tool/:id/process/step/:stepNumber/dfm-review-list`
                        }
                        render={(props) => <DFMReviewList {...props} getData={getStepData} />}
                    />
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Step);
