import React, { useContext, useState } from "react";
import Axios from "axios";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, makeStyles, TextField } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import WebsiteContext from "../../../../utils/Website";
import StyledButton from "../../../StyledButton/StyledButton";
import RoutingContext from "../../../../utils/RoutingContext";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        width: "160px",
        marginRight: 16,
        height: "33px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    buttonWrapper: {
        background: "white",
        padding: 18,
    },
    header: {
        background: "#FAFAFA",
        border: "1px solid #ECECEC",
    },
    row: {
        background: "#FFFFFF",
        border: "1px solid #ECECEC",
    },
    cell: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        left: "unset",
    },
    headerCell: {
        color: "#777777",
    },
    tableContainer: {
        height: "100%",
    },
    inputFile: {
        opacity: 0,
        width: 1,
    },
    openFile: {
        color: "#00A7D1",
        cursor: "pointer",
    },
    uploadFile: {
        backgroundColor: "white",
        marginRight: "10px",
    },
    filenameEditModeWrapper: {
        marginLeft: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
        height: "15p",
        lineHeight: "15px",
        textOverflow: "ellipsis",
    },
}));

const FileVersions = (props) => {
    const classes = useStyles();
    const { languageData, role } = useContext(WebsiteContext);
    const { stepData } = useContext(RoutingContext);

    const [showNewRow, setShowNewRow] = useState(false);
    const [newFile, setNewFile] = useState({});
    const [editFile, setEditFile] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(null);

    const onSave = () => {
        const fileData = new FormData();
        fileData.append("files", uploadedFile, uploadedFile.name);
        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-design-media` + "/files",
            method: "POST",
            data: fileData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            const moldDesignOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/file-version`,
                method: "POST",
                headers: {
                    "X-Loading": false,
                },
                data: {
                    fileId: response.data.data.files[0].id,
                    filename: response.data.data.files[0].name,
                    date: newFile.date,
                },
            };
            Axios(moldDesignOptions).then((rsp) => {
                setUploadedFile(null);
                setNewFile({});
                setShowNewRow(false);
                props.getData();
            });
        });
    };

    const updateFile = () => {
        if (uploadedFile !== null) {
            const options = {
                url: `/file-operations/${props.match.params.id}-mold-design-media/files/${editFile.fileId}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((rsp) => {
                const fileData = new FormData();
                fileData.append("files", uploadedFile, uploadedFile.name);
                const options = {
                    url: "/file-operations/" + `${props.match.params.id}-mold-design-media` + "/files",
                    method: "POST",
                    data: fileData,
                    headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                };
                Axios(options).then((response) => {
                    const moldDesignOptions = {
                        url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/file-version/${editFile.id}`,
                        method: "PUT",
                        headers: {
                            "X-Loading": false,
                        },
                        data: {
                            filename: response.data.data.files[0].name,
                            date: editFile.date.split(" ")[0],
                            fileId: response.data.data.files[0].id,
                        },
                    };
                    Axios(moldDesignOptions).then((rsp) => {
                        setUploadedFile(null);
                        setEditFile(null);
                        props.getData();
                    });
                });
            });
        } else {
            const moldDesignOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/file-version/${editFile.id}`,
                method: "PUT",
                headers: {
                    "X-Loading": false,
                },
                data: {
                    filename: editFile.fileName,
                    date: editFile.date.split(" ")[0],
                    fileId: editFile.id,
                },
            };
            Axios(moldDesignOptions).then((rsp) => {
                setUploadedFile(null);
                setEditFile(null);
                props.getData();
            });
        }
    };

    const handleDeleteChangeFile = (id) => {
        setShowDeleteDialog(id);
    };

    const deleteChangeFile = () => {
        const options = {
            url: `/file-operations/${props.match.params.id}-mold-design-media/files/${showDeleteDialog.fileId}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((rsp) => {
            const moldDesignOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-design/file-version/${showDeleteDialog.id}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(moldDesignOptions).then((rsp) => {
                setShowDeleteDialog(null);
                props.getData();
            });
        });
    };

    const uploadFile = (event) => {
        let file = event?.target?.files[0];
        if (file) {
            setUploadedFile(file);
        }
    };

    // const uploadNewFile = (event) => {
    //     let file = event?.target?.files[0];
    //     if (file) {
    //         const fileData = new FormData();
    //         fileData.append("files", file, file.name);
    //         setNewFile(fileData);
    //         setNewFilename(file.name);
    //     }
    // };

    const handleFileOpen = (id) => {
        window.open(`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-mold-design-media/files/${id}`);
    };

    return (
        <div>
            <div className={classes.wrapper}>
                <div className={classes.layoutWrapper}>
                    {stepData.state.id === 1 && (role === 0 || role === 2) && (
                        <div className={classes.buttonWrapper}>
                            <StyledButton onClick={() => setShowNewRow(!showNewRow)} className={classes.button} color="primary" variant="contained">
                                {showNewRow ? languageData?.Cancel.toLocaleUpperCase() : languageData?.AddNewFile.toLocaleUpperCase()}
                            </StyledButton>
                            {showNewRow && (
                                <StyledButton form="newFile" type="submit" className={classes.button} color="secondary" variant="contained">
                                    {languageData?.Save.toLocaleUpperCase()}
                                </StyledButton>
                            )}
                        </div>
                    )}
                    <div className={classes.contentWrapper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow className={classes.header}>
                                        <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Mold3DFile}</TableCell>
                                        <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.ChangeDate}</TableCell>
                                        {role === 0 && <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Actions}</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {showNewRow && (
                                        <TableRow className={classes.row} key="new">
                                            {/*<TableCell component="th" scope="row">*/}
                                            {/*    */}
                                            {/*    /!*<IconButton type="submit" form="newChange">*!/*/}
                                            {/*    /!*    <SaveIcon fontSize="small" />*!/*/}
                                            {/*    /!*</IconButton>*!/*/}
                                            {/*    /!*<IconButton*!/*/}
                                            {/*    /!*    onClick={() => {*!/*/}
                                            {/*    /!*        setShowNewRow(false);*!/*/}
                                            {/*    /!*    }}*!/*/}
                                            {/*    /!*>*!/*/}
                                            {/*    /!*    <CancelIcon fontSize="small" />*!/*/}
                                            {/*    /!*</IconButton>*!/*/}
                                            {/*</TableCell>*/}
                                            <TableCell>
                                                <form
                                                    id="newFile"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        onSave();
                                                    }}
                                                />
                                                <input
                                                    className={classes.inputFile}
                                                    id="contained-button-file"
                                                    form="newFile"
                                                    type="file"
                                                    onChange={(e) => uploadFile(e)}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button
                                                        variant="contained"
                                                        component="span"
                                                        className={classes.uploadFile}
                                                        startIcon={<CloudUploadIcon color="primary" />}
                                                    >
                                                        {uploadedFile ? languageData?.ChangeFile : languageData?.UploadFile}
                                                    </Button>
                                                </label>
                                                {uploadedFile && <span className={classes.filenameEditModeWrapper}>{uploadedFile.name}</span>}
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="date"
                                                    defaultValue={newFile?.date ? newFile?.date : ""}
                                                    onChange={(e) => setNewFile({ ...newFile, date: e.target.value })}
                                                    required
                                                    inputProps={{
                                                        form: "newFile",
                                                        // min: new Date().toISOString().split("T")[0],
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell></TableCell>
                                            {/* <TableCell component="th" scope="row" align="right">
                                            <IconButton type="submit" form="newChange">
                                                <SaveIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setShowNewRow(false);
                                                }}
                                            >
                                                <CancelIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell> */}
                                        </TableRow>
                                    )}
                                    {props.fileVersions?.map((element) => (
                                        <TableRow className={classes.row} key={element.id}>
                                            {editFile && editFile.id === element.id ? (
                                                <TableCell className={classes.cell}>
                                                    <React.Fragment>
                                                        <input
                                                            className={classes.inputFile}
                                                            id={"contained-button-file-" + element.id}
                                                            form={"editFile-" + element.id}
                                                            type="file"
                                                            onChange={(e) => uploadFile(e)}
                                                        />
                                                        <label htmlFor={"contained-button-file-" + element.id}>
                                                            <Button
                                                                variant="contained"
                                                                component="span"
                                                                className={classes.uploadFile}
                                                                startIcon={<CloudUploadIcon color="primary" />}
                                                            >
                                                                {element?.fileName ? languageData?.ChangeFile : languageData?.UploadFile}
                                                            </Button>
                                                        </label>
                                                        {element?.fileName && uploadedFile !== null && (
                                                            <span className={classes.filenameEditModeWrapper}>{uploadedFile.name}</span>
                                                        )}
                                                        {element?.fileName && uploadedFile === null && (
                                                            <span className={classes.filenameEditModeWrapper}>{element?.fileName}</span>
                                                        )}
                                                    </React.Fragment>
                                                </TableCell>
                                            ) : (
                                                <TableCell className={classes.cell}>
                                                    <div className={classes.openFile} onClick={() => handleFileOpen(element?.fileId)}>
                                                        {element.fileName}
                                                    </div>
                                                </TableCell>
                                            )}
                                            {editFile && editFile.id === element.id ? (
                                                <TableCell className={classes.cell}>
                                                    <TextField
                                                        type="date"
                                                        defaultValue={editFile?.date ? element.date.split(" ")[0] : ""}
                                                        onChange={(e) => setEditFile({ ...editFile, date: e.target.value })}
                                                        required
                                                        inputProps={{
                                                            form: "editFile-" + element.id,
                                                        }}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell className={classes.cell}>{element.date.split(" ")[0]}</TableCell>
                                            )}
                                            {role === 0 && (
                                                <TableCell className={classes.cell}>
                                                    {element.id === editFile?.id ? (
                                                        <React.Fragment>
                                                            <form
                                                                id={"editFile-" + element.id}
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    updateFile();
                                                                }}
                                                            />
                                                            <IconButton size="small" type="submit" form={"editFile-" + element.id}>
                                                                <SaveIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton size="small" onClick={() => setEditFile(null)}>
                                                                <CancelIcon fontSize="small" />
                                                            </IconButton>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {stepData.state.id === 1 && (
                                                                <IconButton size="small" onClick={() => setEditFile(element)}>
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton>
                                                            )}
                                                            {stepData.state.id === 1 && (
                                                                <IconButton size="small" onClick={() => handleDeleteChangeFile(element)}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <Dialog
                open={showDeleteDialog !== null}
                onClose={() => {
                    setShowDeleteDialog(null);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{languageData?.DeleteChangeFileMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setShowDeleteDialog(null);
                        }}
                        color="primary"
                        autoFocus
                    >
                        {languageData?.No}
                    </Button>
                    <Button onClick={deleteChangeFile} color="primary" autoFocus>
                        {languageData?.Yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default FileVersions;
