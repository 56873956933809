import React, { useContext } from "react";

import { makeStyles } from "@material-ui/core";

import WebsiteContext from "../../utils/Website";

import StyledModal from "../StyledModal/StyledModal";
import StyledButton from "../StyledButton/StyledButton";

const useStyles = makeStyles(
    (theme) => ({
        modalBody: {
            width: "450px",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "15px",
            color: theme.palette.grey.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        deleteCancelButton: {
            width: "100px",
            backgroundColor: "white",
            border: "1px solid #CCCCCC",
            color: "#C00D0E",
            height: "40px",
            marginRight: "5px",
            marginBottom: "15px",
            borderRadius: "3px",
            marginTop: "10px",
            fontFamily: "Montserrat",
            fontSize: 12,
            fontWeight: 700,
            "&:hover": {
                backgroundColor: "white",
            },
        },
        deleteConfirmButton: {
            width: "100px",
            backgroundColor: "#C00D0E",
            border: "1px solid #9a9a9a",
            color: "white",
            height: "40px",
            marginLeft: "5px",
            marginBottom: "15px",
            borderRadius: "3px",
            marginTop: "10px",
            fontFamily: "Montserrat",
            fontSize: 12,
            fontWeight: 700,
        },
        deleteModalActions: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            marginTop: 10,
        },
        error: {
            fontSize: 10,
            lineHeight: "12px",
            fontWeight: 500,
            fontStyle: "normal",
            color: "red",
        },
        children: {
            padding: 0,
            margin: 0,
        },
        topWrapper: {
            padding: 0,
        },
    }),
    { name: "DeleteModalStyles" }
);

const DeleteModal = ({ isOpen, onClose, onDelete, title, message, error }) => {
    const classes = useStyles();
    const { languageData } = useContext(WebsiteContext);

    return (
        <StyledModal isOpen={isOpen} onClose={onClose} title={title} childrenClassName={classes.children} topWrapperClassName={classes.topWrapper}>
            <div className={classes.modalBody}>
                {message}
                <div className={classes.error}>{languageData && languageData[error]}</div>
                <div className={classes.deleteModalActions}>
                    <StyledButton color="primary" variant="contained" className={classes.deleteConfirmButton} onClick={onDelete}>
                        {languageData?.Yes.toLocaleUpperCase()}
                    </StyledButton>
                    <StyledButton color="primary" variant="contained" className={classes.deleteCancelButton} onClick={onClose}>
                        {languageData?.No.toLocaleUpperCase()}
                    </StyledButton>
                </div>
            </div>
        </StyledModal>
    );
};

export default DeleteModal;
