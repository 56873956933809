export const dailySendDates = [
    { id: 100, name: "Monday - Friday", value: "mon,tue,wed,thu,fri" },
    { id: 101, name: "Monday - Saturday", value: "mon,tue,wed,thu,fri,sat" },
];

export const weeklySendDates = [
    {
        id: 100,
        name: "Every Monday",
        value: "mon",
    },
    {
        id: 101,
        name: "Every Tuesday",
        value: "tue",
    },
    {
        id: 102,
        name: "Every Wednesday",
        value: "wed",
    },
    {
        id: 103,
        name: "Every Thursday",
        value: "thu",
    },
    {
        id: 104,
        name: "Every Friday",
        value: "fri",
    },
    {
        id: 105,
        name: "Every Saturday",
        value: "sat",
    },
    {
        id: 106,
        name: "Every Sunday",
        value: "sun",
    },
];

export const reminderTypes = {
    Daily: "daily",
    Weekly: "weekly",
};
