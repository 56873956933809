import { createContext } from "react";

const RoutingContext = createContext({
    customerData: {
        visibleContent: false,
        name: "",
        address: "",
        logo: "",
    },
    setCustomerData: () => {},

    projectData: {
        visibleContent: false,
        number: "",
        name: "",
        progress: "",
        customerName: "",
        stateColor: "",
    },
    setProjectData: () => {},

    toolData: {
        visibleContent: false,
        name: "",
        tool_maker_name: "",
        tool_maker_id: "",
        progress: "",
        description: "",
        stateColor: "",
    },
    setToolData: () => {},

    entityPath: "",
    setEntityPath: () => {},

    backPath: "",
    setBackPath: () => {},

    setRoutingData: () => {},

    stepData: {
        visibleContent: false,
        name: "",
        state: "",
        id: "",
        canClose: "",
        toolId: "",
        exportFileId: "",
    },
    setStepData: () => {},
});

RoutingContext.displayName = "RoutingContext";

export default RoutingContext;
