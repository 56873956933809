import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import Axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";

import WebsiteContext from "../../../utils/Website";
import StyledButton from "../../StyledButton/StyledButton";
import StyledInput from "../../StyledInput/StyledInput";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import Chart from "../../Chart/Chart";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100% - 18px)",
        padding: 9,
        overflow: "auto",
    },
    table: {
        marginBottom: 16,
    },
    cell: {
        left: "unset",
    },
    firstColumn: {
        width: 100,
        left: "unset",
    },
    buttonAdd: {
        width: "185px",
        height: "auto",
        marginRight: 8,
        marginBottom: 8,
    },
    buttonCopy: {
        height: "auto",
        width: "185px",
        marginBottom: 8,
    },
    ganttWrapper: (props) => ({
        "& .gantt-container": {
            overflow: "scroll",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            wordWrap: "break-word",
            backgroundColor: "#fff",
            backgroundClip: "border-box",
            border: "1px solid rgba(0,0,0,0.125)",
            borderRadius: "4px",
        },
        "& .gantt": {
            overflow: "hidden",
            verticalAlign: "middle",
            height: (props) => (props?.ganttHeight > 0 ? props.ganttHeight : "auto"),
        },
        "&>div": {
            overflow: "hidden !important",
        },
        "& .bar-label": {
            fontWeight: "normal",
        },
        "& .handle-group": {
            display: "none",
        },
        "& .bar-wrapper.bar-milestone .bar-progress": {
            fill: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-milestone:hover .bar-progress": {
            fill: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-milestone.active .bar-progress": {
            fill: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        "& .bar-progress": {
            fill: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper:hover .bar-progress": {
            fill: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.active .bar-progress": {
            fill: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-in-time .bar-progress": {
            fill: props.inTimeColor,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-in-time:hover .bar-progress": {
            fill: props.inTimeColor,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-in-time.active .bar-progress": {
            fill: props.inTimeColor,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-out-of-time .bar-progress": {
            fill: props.outOfTimeColor,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-out-of-time:hover .bar-progress": {
            fill: props.outOfTimeColor,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-out-of-time.active .bar-progress": {
            fill: props.outOfTimeColor,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-completed .bar-progress": {
            fill: props.completedColor,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-completed:hover .bar-progress": {
            fill: props.completedColor,
            color: theme.palette.common.white,
        },
        "& .bar-wrapper.bar-completed.active .bar-progress": {
            fill: props.completedColor,
            color: theme.palette.common.white,
        },

        "& .milestone-in-time": {
            fill: props.milestoneInTimeColor,
            color: theme.palette.common.white,
        },
        "& .milestone-in-time:hover": {
            fill: props.milestoneInTimeColor,
            color: theme.palette.common.white,
        },
        "& .milestone-in-time.active": {
            fill: props.milestoneInTimeColor,
            color: theme.palette.common.white,
        },
        "& .milestone-out-of-time": {
            fill: props.milestoneOutOfTimeColor,
            color: theme.palette.common.white,
        },
        "& .milestone-out-of-time:hover": {
            fill: props.milestoneOutOfTimeColor,
            color: theme.palette.common.white,
        },
        "& .milestone-out-of-time.active": {
            fill: props.milestoneOutOfTimeColor,
            color: theme.palette.common.white,
        },
        "& .milestone-completed": {
            fill: props.milestoneCompletedColor,
            color: theme.palette.common.white,
        },
        "& .milestone-completed:hover": {
            fill: props.milestoneCompletedColor,
            color: theme.palette.common.white,
        },
        "& .milestone-completed.active": {
            fill: props.milestoneCompletedColor,
            color: theme.palette.common.white,
        },
    }),
    viewButtonWrapper: {
        backgroundColor: "#f8f8f8",
        boxSizing: "border-box",
        borderRadius: "3.05172px",
        width: "200px",
        height: "auto",
        marginBottom: 8,
        cursor: "pointer",
        // marginRight: 8,
    },
    viewButtonText: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        // margin: "0 5px",
    },

    viewButtonIcon: {
        color: "#757575",
        margin: "0 5px",
    },
    buttonsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    leftButtonsWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    rightButtonsWrapper: {
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            alignSelf: "flex-end",
        },
    },
    visibleSwitch: {
        display: "block",
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginLeft: 8,
    },
    selectWrapper: {
        height: "33px",
    },
    messageGrid: {
        marginBottom: 8,
    },
}));

const ToolSchedule = (props) => {
    const [tasks, setTasks] = useState([]);
    const [newTask, setNewTask] = useState(null);
    const [editTask, setEditTask] = useState(null);
    const [showNewRow, setShowNewRow] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [states, setStates] = useState([]);
    const [schedulerTasks, setSchedulerTasks] = useState([]);

    const [milestones, setMilestones] = useState([]);
    const [newMilestone, setNewMilestone] = useState(null);
    const [editMilestone, setEditMilestone] = useState(null);
    const [showNewMilestoneRow, setShowNewMilestoneRow] = useState(false);
    const [showMilestoneDeleteDialog, setShowMilestoneDeleteDialog] = useState(false);
    const [selectedMilestoneId, setSelectedMilestoneId] = useState("");
    const [milestoneStates, setMilestoneStates] = useState([]);

    const [showCopyTasksDialog, setShowCopyTasksDialog] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const [inTimeColor, setInTimeColor] = useState("#FFFFFF");
    const [completedColor, setCompletedColor] = useState("#FFFFFF");
    const [outOfTimeColor, setOutOfTimeColor] = useState("#FFFFFF");

    const [milestoneInTimeColor, setMilestoneInTimeColor] = useState("#FFFFFF");
    const [milestoneCompletedColor, setMilestoneCompletedColor] = useState("#FFFFFF");
    const [milestoneOutOfTimeColor, setMilestoneOutOfTimeColor] = useState("#FFFFFF");

    const classes = useStyles({
        ganttHeight: tasks.length > 0 ? tasks.length * 38 + 60 : 0,
        inTimeColor: inTimeColor,
        completedColor: completedColor,
        outOfTimeColor: outOfTimeColor,
        milestoneCompletedColor: milestoneCompletedColor,
        milestoneInTimeColor: milestoneInTimeColor,
        milestoneOutOfTimeColor: milestoneOutOfTimeColor,
    });
    const { languageData, role } = useContext(WebsiteContext);

    const getGantt = () => {
        const options = {
            url: `/tools/${props.match.params.id}` + (props.isCustomerSchedule ? `/customer-gantt-charts` : `/tool-maker-gantt-charts`),
            method: "GET",
        };
        Axios(options).then((response) => {
            let taskArray = [];
            response.data.data.ganttCharts.forEach((task) => {
                switch (task.state.id) {
                    case 1:
                        setOutOfTimeColor(task.state.color);
                        break;
                    case 2:
                        setInTimeColor(task.state.color);
                        break;
                    case 3:
                        setCompletedColor(task.state.color);
                        break;
                    default:
                        break;
                }
                taskArray.push({
                    id: String(task.id),
                    name: task.name,
                    start: task.startDate,
                    end: task.endDate,
                    progress: 100,
                    dependencies: "",
                    custom_class: task.state.id === 2 ? "bar-in-time" : task.state.id === 1 ? "bar-out-of-time" : "bar-completed",
                    state: task.state,
                });
            });
            response.data.data.milestones.forEach((milestone) => {
                switch (milestone.state.id) {
                    case 1:
                        setMilestoneOutOfTimeColor(milestone.state.color);
                        break;
                    case 2:
                        setMilestoneInTimeColor(milestone.state.color);
                        break;
                    case 3:
                        setMilestoneCompletedColor(milestone.state.color);
                        break;
                    default:
                        break;
                }
                taskArray.push({
                    id: String(milestone.id),
                    name: milestone.name,
                    start: milestone.date,
                    end: milestone.date,
                    progress: 100,
                    isMilestone: true,
                    dependencies: "",
                    milestone_class:
                        milestone.state.id === 2 ? "milestone-in-time" : milestone.state.id === 1 ? "milestone-out-of-time" : "milestone-completed",
                    state: milestone.state,
                });
            });
            const taskData = taskArray.filter((el) => !el.isMilestone || el.isMilestone === false);
            const milestoneData = taskArray.filter((el) => el.isMilestone !== undefined && el.isMilestone === true);
            setSchedulerTasks(taskArray);
            setTasks(taskData);
            setMilestones(milestoneData);
            setIsVisible(response.data.data.visibleGanttChart ? response.data.data.visibleGanttChart : false);
        });
    };

    const createGantt = () => {
        const data = {
            endDate: newTask.end,
            name: newTask.name,
            startDate: newTask.start,
        };

        const options = {
            url: `/tools/${props.match.params.id}` + (props.isCustomerSchedule ? `/customer-gantt-charts` : `/tool-maker-gantt-charts`),
            method: "POST",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            if (props.getData) {
                props.getData();
            }
            getGantt();
            setShowNewRow(false);
            setNewTask(null);
        });
    };

    const createMilestone = () => {
        const data = {
            date: newMilestone.date,
            name: newMilestone.name,
        };

        const options = {
            url: `/tools/${props.match.params.id}` + (props.isCustomerSchedule ? `/customer-milestones` : `/tool-maker-milestones`),
            method: "POST",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            getGantt();
            setShowNewMilestoneRow(false);
            setNewMilestone(null);
        });
    };

    const updateGantt = () => {
        const data = {
            endDate: editTask.end,
            name: editTask.name,
            startDate: editTask.start,
            stateId: editTask.state.id,
        };

        const options = {
            url: `/tools` + (props.isCustomerSchedule ? `/customer-gantt-charts` : `/tool-maker-gantt-charts`) + `/${editTask.id}`,
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            if (props.getData) {
                props.getData();
            }
            getGantt();
            setEditTask(null);
        });
    };

    const updateMilestone = () => {
        const data = {
            date: editMilestone.end,
            name: editMilestone.name,
            stateId: editMilestone.state.id,
        };

        const options = {
            url: `/tools` + (props.isCustomerSchedule ? `/customer-milestones` : `/tool-maker-milestones`) + `/${editMilestone.id}`,
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            if (props.getData) {
                props.getData();
            }
            getGantt();
            setEditMilestone(null);
        });
    };

    const deleteGantt = () => {
        const options = {
            url: `/tools` + (props.isCustomerSchedule ? `/customer-gantt-charts` : `/tool-maker-gantt-charts`) + `/${selectedId}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            if (props.getData) {
                props.getData();
            }
            getGantt();
            handleCloseDeleteDialog();
        });
    };

    const deleteMilestone = () => {
        const options = {
            url: `/tools` + (props.isCustomerSchedule ? `/customer-milestones` : `/tool-maker-milestones`) + `/${selectedMilestoneId}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            if (props.getData) {
                props.getData();
            }
            getGantt();
            handleCloseMilestoneDeleteDialog();
        });
    };

    const handleDeleteTask = (id) => {
        setShowDeleteDialog(true);
        setSelectedId(id);
    };

    const handleDeleteMilestone = (id) => {
        setShowMilestoneDeleteDialog(true);
        setSelectedMilestoneId(id);
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
        setSelectedId("");
    };

    const handleCloseMilestoneDeleteDialog = () => {
        setShowMilestoneDeleteDialog(false);
        setSelectedMilestoneId("");
    };

    const handleChangeInput = (value, object, key) => {
        if (object === "newTask") {
            setNewTask({ ...newTask, [key]: value });
        } else {
            setEditTask({ ...editTask, [key]: value });
        }
    };

    const handleChangeMilestoneInput = (value, object, key) => {
        if (object === "newMilestone") {
            setNewMilestone({ ...newMilestone, [key]: value });
        } else {
            setEditMilestone({ ...editMilestone, [key]: value });
        }
    };

    const handleOpenToolSchedule = () => {
        let scheduleDelimiter;
        if (props.isCustomerSchedule) {
            scheduleDelimiter = "customer";
        } else {
            scheduleDelimiter = "mold-maker";
        }
        window.open(
            role === 0
                ? `${window.location.origin}/customers/projects/${props.projectId}/schedule/${scheduleDelimiter}`
                : `${window.location.origin}/project/${props.projectId}/schedule/${scheduleDelimiter}`
        );
    };

    const handleCopyTasks = () => {
        const options = {
            url: `/tools/${props.match.params.id}/tool-maker-gantt-charts/copy`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            setShowCopyTasksDialog(false);
            if (props.getData) {
                props.getData();
            }
            getGantt();
        });
    };

    const handleToggleButton = () => {
        const data = {
            visibleGanttChart: !isVisible,
        };

        const options = {
            url: `/tools/${props.match.params.id}/customer-gantt-charts/visibility`,
            method: "PUT",
            data: data,
        };
        Axios(options).then((response) => {
            setIsVisible(data.visibleGanttChart);
        });
    };

    useEffect(() => {
        getGantt();
    }, [props.isCustomerSchedule]);

    const handleEditTask = (task) => {
        const options = {
            url: `/tools/gantt-charts/states`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setStates(response.data.data);
            setEditTask(task);
        });
    };

    const handleEditMilestone = (milestone) => {
        const options = {
            url: `/tools/gantt-charts/states`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setMilestoneStates(response.data.data);
            setEditMilestone(milestone);
        });
    };

    const handleStateChange = (event) => {
        const newState = states.find((t) => t.id === event.target.value);
        setEditTask({ ...editTask, ["state"]: newState });
    };

    const handleMilestoneStateChange = (event) => {
        const newState = milestoneStates.find((t) => t.id === event.target.value);
        setEditMilestone({ ...editMilestone, ["state"]: newState });
    };

    const handleAddTask = () => {
        const options = {
            url: `/tools/gantt-charts/states`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setStates(response.data.data);
            setShowNewRow(true);
        });
    };

    const handleAddMilestone = () => {
        const options = {
            url: `/tools/milestones/states`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setMilestoneStates(response.data.data);
            setShowNewMilestoneRow(true);
        });
    };

    const stateComponent = (
        <Select
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                getContentAnchorEl: null,
            }}
            disabled={showNewRow}
            variant={"outlined"}
            className={classes.selectWrapper}
            value={showNewRow ? states[1].id : editTask !== null ? editTask.state.id : 1}
            onChange={handleStateChange}
        >
            {states.map((state) => (
                <MenuItem key={state.id} value={state.id} style={{ color: state.color }}>
                    {state.name.split("_").join(" ")}
                </MenuItem>
            ))}
        </Select>
    );

    const milestoneStateComponent = (
        <Select
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                getContentAnchorEl: null,
            }}
            disabled={showNewMilestoneRow}
            variant={"outlined"}
            className={classes.selectWrapper}
            value={showNewMilestoneRow ? milestoneStates[1].id : editMilestone !== null ? editMilestone.state.id : 1}
            onChange={handleMilestoneStateChange}
        >
            {milestoneStates.map((state) => (
                <MenuItem key={state.id} value={state.id} style={{ color: state.color }}>
                    {state.name.split("_").join(" ")}
                </MenuItem>
            ))}
        </Select>
    );

    return (
        <div className={classes.wrapper}>
            <div className={classes.buttonsWrapper}>
                <div className={classes.leftButtonsWrapper}>
                    {role === 2 && (
                        <div className={classes.messageGrid}>
                            <Alert severity="error">{languageData?.SchedulerStatesMessage}</Alert>
                        </div>
                    )}
                    {(role === 0 || role === 2) && (
                        <StyledButton variant="contained" color="primary" fullWidth className={classes.buttonAdd} onClick={() => handleAddTask()}>
                            {languageData?.AddNewTask}
                        </StyledButton>
                    )}
                    {role === 0 && (
                        <div>
                            {!props.isCustomerSchedule ? (
                                <StyledButton
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    className={classes.buttonCopy}
                                    onClick={() => setShowCopyTasksDialog(true)}
                                >
                                    {languageData?.CopyTasks}
                                </StyledButton>
                            ) : (
                                <div className={classes.visibleSwitch}>
                                    {languageData?.Visibility}
                                    <Switch checked={isVisible} color="primary" onChange={handleToggleButton} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {(role === 0 || role === 1) && props.disableProjSchedule === false && (
                    <div className={classes.rightButtonsWrapper}>
                        <div onClick={handleOpenToolSchedule} className={classes.viewButtonWrapper}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                <VisibilityIcon fontSize="small" className={classes.viewButtonIcon} />
                                <div className={classes.viewButtonText}>{languageData?.ViewProjectSchedule}</div>
                            </Grid>
                        </div>
                    </div>
                )}
            </div>
            <TableContainer className={classes.table} component={Paper}>
                <Table size="small" stickyHeader={true} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.TaskName}
                            </TableCell>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.StartDate}
                            </TableCell>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.EndDate}
                            </TableCell>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.Status}
                            </TableCell>
                            {(role === 0 || role === 2) && <TableCell className={classes.firstColumn}>{languageData?.Actions}</TableCell>}
                        </TableRow>
                    </TableHead>
                    {(role === 0 || role === 2 || (role === 1 && isVisible)) && (
                        <TableBody>
                            {showNewRow && (
                                <TableRow className={classes.rowTable} key="new">
                                    <TableCell align="center">
                                        <StyledInput
                                            defaultValue={newTask?.name ? newTask.name : ""}
                                            onChange={(e) => handleChangeInput(e.target.value, "newTask", "name")}
                                            type="text"
                                            required={true}
                                            form="newTaskForm"
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            type="date"
                                            defaultValue={newTask?.start ? newTask.start : ""}
                                            onChange={(e) => handleChangeInput(e.target.value, "newTask", "start")}
                                            required
                                            inputProps={{
                                                form: "newTaskForm",
                                                min: new Date().toISOString().split("T")[0],
                                                max: newTask?.end,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            type="date"
                                            defaultValue={newTask?.end ? newTask.end : ""}
                                            onChange={(e) => handleChangeInput(e.target.value, "newTask", "end")}
                                            required
                                            inputProps={{
                                                form: "newTaskForm",
                                                min: newTask?.start ? newTask.start : new Date().toISOString().split("T")[0],
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">{stateComponent}</TableCell>
                                    {(role === 0 || role === 2) && (
                                        <TableCell component="th" scope="row">
                                            <form
                                                id="newTaskForm"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    createGantt();
                                                }}
                                            />
                                            <IconButton size="small" type="submit" form="newTaskForm">
                                                <SaveIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setShowNewRow(false);
                                                    setNewTask(null);
                                                }}
                                            >
                                                <CancelIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            )}
                            {tasks?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">
                                        {row.id === editTask?.id ? (
                                            <StyledInput
                                                defaultValue={editTask.name}
                                                onChange={(e) => handleChangeInput(e.target.value, "editTask", "name")}
                                                type="text"
                                                required={true}
                                                form={"editTaskForm-" + row.id}
                                            />
                                        ) : (
                                            row.name
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.id === editTask?.id ? (
                                            <TextField
                                                type="date"
                                                defaultValue={editTask?.start ? editTask.start : ""}
                                                onChange={(e) => handleChangeInput(e.target.value, "editTask", "start")}
                                                required
                                                inputProps={{
                                                    form: "editTaskForm-" + row.id,
                                                }}
                                            />
                                        ) : (
                                            row.start
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.id === editTask?.id ? (
                                            <TextField
                                                type="date"
                                                defaultValue={editTask?.end ? editTask.end : ""}
                                                onChange={(e) => handleChangeInput(e.target.value, "editTask", "end")}
                                                required
                                                inputProps={{
                                                    form: "editTaskForm-" + row.id,
                                                }}
                                            />
                                        ) : (
                                            row.end
                                        )}
                                    </TableCell>
                                    <TableCell align="center" style={{ color: row.state.color }}>
                                        {row.id === editTask?.id ? stateComponent : row.state.name}
                                    </TableCell>
                                    {(role === 0 || role === 2) && (
                                        <TableCell component="th" scope="row">
                                            {row.id === editTask?.id ? (
                                                <React.Fragment>
                                                    <form
                                                        id={"editTaskForm-" + row.id}
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            updateGantt();
                                                        }}
                                                    />
                                                    <IconButton size="small" type="submit" form={"editTaskForm-" + row.id}>
                                                        <SaveIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" onClick={() => setEditTask(null)}>
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <IconButton size="small" onClick={() => handleEditTask(row)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" onClick={() => handleDeleteTask(row.id)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </React.Fragment>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {(role === 0 || role === 2) && (
                <StyledButton variant="contained" color="primary" fullWidth className={classes.buttonAdd} onClick={() => handleAddMilestone()}>
                    {languageData?.AddNewMilestone}
                </StyledButton>
            )}
            <TableContainer className={classes.table} component={Paper}>
                <Table size="small" stickyHeader={true} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.MilestoneName}
                            </TableCell>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.Date}
                            </TableCell>
                            <TableCell align="center" style={{ left: "unset" }}>
                                {languageData?.Status}
                            </TableCell>
                            {(role === 0 || role === 2) && <TableCell className={classes.firstColumn}>{languageData?.Actions}</TableCell>}
                        </TableRow>
                    </TableHead>
                    {(role === 0 || role === 2 || (role === 1 && isVisible)) && (
                        <TableBody>
                            {showNewMilestoneRow && (
                                <TableRow className={classes.rowTable} key="new">
                                    <TableCell align="center">
                                        <StyledInput
                                            defaultValue={newMilestone?.name ? newMilestone.name : ""}
                                            onChange={(e) => handleChangeMilestoneInput(e.target.value, "newMilestone", "name")}
                                            type="text"
                                            required={true}
                                            form="newMilestoneForm"
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            type="date"
                                            defaultValue={newMilestone?.date ? newMilestone.date : ""}
                                            onChange={(e) => handleChangeMilestoneInput(e.target.value, "newMilestone", "date")}
                                            required
                                            inputProps={{
                                                form: "newMilestoneForm",
                                                min: new Date().toISOString().split("T")[0],
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">{milestoneStateComponent}</TableCell>
                                    {(role === 0 || role === 2) && (
                                        <TableCell component="th" scope="row">
                                            <form
                                                id="newMilestoneForm"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    createMilestone();
                                                }}
                                            />
                                            <IconButton size="small" type="submit" form="newMilestoneForm">
                                                <SaveIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setShowNewMilestoneRow(false);
                                                    setNewMilestone(null);
                                                }}
                                            >
                                                <CancelIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            )}
                            {milestones?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">
                                        {row.id === editMilestone?.id ? (
                                            <StyledInput
                                                defaultValue={editMilestone.name}
                                                onChange={(e) => handleChangeMilestoneInput(e.target.value, "editMilestone", "name")}
                                                type="text"
                                                required={true}
                                                form={"editMilestoneForm-" + row.id}
                                            />
                                        ) : (
                                            row.name
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.id === editMilestone?.id ? (
                                            <TextField
                                                type="date"
                                                defaultValue={editMilestone?.start ? editMilestone.start : ""}
                                                onChange={(e) => {
                                                    handleChangeMilestoneInput(e.target.value, "editMilestone", "start");
                                                    handleChangeMilestoneInput(e.target.value, "editMilestone", "end");
                                                }}
                                                required
                                                inputProps={{
                                                    form: "editMilestoneForm-" + row.id,
                                                }}
                                            />
                                        ) : (
                                            row.start
                                        )}
                                    </TableCell>
                                    <TableCell align="center" style={{ color: row.state.color }}>
                                        {row.id === editMilestone?.id ? milestoneStateComponent : row.state.name}
                                    </TableCell>
                                    {(role === 0 || role === 2) && (
                                        <TableCell component="th" scope="row">
                                            {row.id === editMilestone?.id ? (
                                                <React.Fragment>
                                                    <form
                                                        id={"editMilestoneForm-" + row.id}
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            updateMilestone();
                                                        }}
                                                    />
                                                    <IconButton size="small" type="submit" form={"editMilestoneForm-" + row.id}>
                                                        <SaveIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" onClick={() => setEditMilestone(null)}>
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <IconButton size="small" onClick={() => handleEditMilestone(row)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton size="small" onClick={() => handleDeleteMilestone(row.id)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </React.Fragment>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <Dialog
                open={showDeleteDialog || showMilestoneDeleteDialog}
                onClose={showDeleteDialog ? handleCloseDeleteDialog : handleCloseMilestoneDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {showDeleteDialog ? languageData?.DeleteTaskMessage : languageData?.DeleteMilestoneMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={showDeleteDialog ? handleCloseDeleteDialog : handleCloseMilestoneDeleteDialog} color="primary" autoFocus>
                        {languageData?.No}
                    </Button>
                    <Button onClick={showDeleteDialog ? deleteGantt : deleteMilestone} color="primary" autoFocus>
                        {languageData?.Yes}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showCopyTasksDialog}
                onClose={() => setShowCopyTasksDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{languageData?.CopyTasksMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowCopyTasksDialog(false)} color="primary" autoFocus>
                        {languageData?.Cancel}
                    </Button>
                    <Button onClick={handleCopyTasks} color="primary" autoFocus>
                        {languageData?.Copy}
                    </Button>
                </DialogActions>
            </Dialog>
            {(role === 0 || role === 2 || (role === 1 && isVisible)) && (
                <Paper className={classes.ganttWrapper}>
                    {schedulerTasks.length > 0 && (
                        <Chart tasks={schedulerTasks} viewMode={"Day"} onClick={(task) => console.log(task)} onDateChange={getGantt} />
                    )}
                </Paper>
            )}
        </div>
    );
};

ToolSchedule.propTypes = {
    isCustomerSchedule: PropTypes.bool,
};

export default ToolSchedule;
