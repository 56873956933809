import React, { useContext } from "react";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link, Route, Switch } from "react-router-dom";
import Projects from "../Clients/Projects/Projects";
import TreelikeForm from "../TreelikeForm/TreelikeForm";
import MachineList from "../Clients/MachineList/MachineList";
import Users from "../Clients/Users/Users";
import { makeStyles } from "@material-ui/core";
import WebsiteContext from "../../utils/Website";

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    tabStyle: {
        padding: "16px",
        overflowX: "hidden",
    },
    boxStyle: {
        padding: 0,
    },
    padding: {
        height: "calc(100% - 85px)",
        padding: 18,
    },
}));

const CustomerPage = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role } = useContext(WebsiteContext);

    return (
        <React.Fragment>
            <Divider />
            {/* <Divider /> */}
            <Tabs value={props.location.pathname} className={classes.wrapper} variant="scrollable" indicatorColor={"primary"} aria-label="simple tabs example">
                {(role === 0 || role === 1) && <Tab label={languageData.Projects} component={Link} to={`/customer/projects`} value={`/customer/projects`} />}
                {(role === 0 || role === 1) && <Tab label={languageData.GeneralToolStandard} component={Link} to={`/customer/gts`} value={`/customer/gts`} />}
                {(role === 0 || role === 1 || role === 2) && (
                    <Tab label={languageData.MachineList} component={Link} to={`/customer/machine-list`} value={`/customer/machine-list`} />
                )}
            </Tabs>
            <div className={classes.padding}>
                <Switch>
                    <Route path={`/customer/projects`} render={(props) => <Projects {...props} id={props.match.params.id} />} />
                    <Route path={`/customer/gts`} render={(props) => <TreelikeForm {...props} />} />
                    <Route path={`/customer/machine-list`} render={(props) => <MachineList {...props} id={props.match.params.id} />} />
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default CustomerPage;
