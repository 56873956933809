import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../utils/Website";
import { Divider, GridList, GridListTile, makeStyles } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MemberCard from "./MemberCard";
import MemberRoleModal from "./MemberRoleModal";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Axios from "axios";

const useStyles = makeStyles((_) => ({
    assignedUsersBackgroundCard: {
        height: "calc(100% - 40px)",
        padding: "20px 20px",
    },
    unassignedUsersBackgroundCard: {
        backgroundColor: "white",
        height: "calc(100% - 40px)",
        padding: "20px 0px",
    },
    unassignedMemberTitle: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: "20px",
        padding: "0px 20px",
    },
    assignedMemberTitle: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: "20px",
    },
    icon: {
        float: "right",
        cursor: "pointer",
        marginLeft: "auto",
    },
    unassignedUserWrapper: {
        padding: "0px 20px",
    },
    divider: {
        width: "100%",
        marginTop: "8px",
        marginBottom: "8px",
    },
    alignElements: {
        display: "flex",
        alignItems: "center",
        fontSize: "9px",
        fontWeight: "500",
        fontFamily: "Montserrat",
    },
    accountIconWrapper: {
        height: "27px",
        width: "27px",
        backgroundColor: "#777777",
        borderRadius: "50%",
        // display: "inline-block",
        color: "white",
        fontWeight: "500",
        fontSize: "8.5px",
        lineHeight: "11px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        margin: "0 5px",
        textTransform: "uppercase",
    },
    wrapper: {
        padding: 18,
    },
    tile: {
        background: "#FFFFFF",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px",
        overflow: "auto",
    },
    grid: {
        height: "100%",
        padding: 9,
    },
    tileRoot: {
        height: "100% !important",
    },
    nameWrapper: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

const Members = (props) => {
    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const [assignedUsers, setAssignedUsers] = useState([]);
    const [unassignedUsers, setUnassignedUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [memberToBeAssigned, setMemberToBeAssigned] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const getMembers = () => {
        const options = {
            url: `/customers/projects/${props.match.params.id}/members`,
            method: "GET",
        };

        Axios(options).then((response) => {
            setRoles(response.data.data.roles);
            setAssignedUsers(response.data.data.assignedUsers);
            setUnassignedUsers(response.data.data.unassignedUsers);
        });
    };

    const getBiggerGridListCols = () => {
        if (isWidthUp("xl", props.width)) {
            return 4;
        }

        if (isWidthUp("lg", props.width)) {
            return 4;
        }

        if (isWidthUp("md", props.width)) {
            return 4;
        }
        if (isWidthUp("sm", props.width)) {
            return 4;
        }
        return 1;
    };

    const getRightGridListCols = () => {
        if (isWidthUp("xl", props.width)) {
            return 3;
        }

        if (isWidthUp("lg", props.width)) {
            return 3;
        }

        if (isWidthUp("md", props.width)) {
            return 3;
        }
        if (isWidthUp("sm", props.width)) {
            return 3;
        }
        return 1;
    };

    const getCardsGridListCols = () => {
        if (isWidthUp("xl", props.width)) {
            return 4;
        }

        if (isWidthUp("lg", props.width)) {
            return 4;
        }

        if (isWidthUp("md", props.width)) {
            return 3;
        }
        if (isWidthUp("sm", props.width)) {
            return 2;
        }
        return 1;
    };

    useEffect(() => {
        getMembers();
    }, []);

    const handleOpenModal = (member) => {
        setOpenModal(true);
        setMemberToBeAssigned({ ...member });
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const unassignedUsersBox = unassignedUsers.map((unassignedUser) => {
        const userNameComponents = unassignedUser.name.split(" ");
        return (
            <div className={classes.unassignedUserWrapper} key={unassignedUser.id}>
                <div className={classes.alignElements}>
                    <div className={classes.accountIconWrapper}>
                        <p>{userNameComponents[0] ? userNameComponents[0][0] + (userNameComponents[1] ? userNameComponents[1][0] : "") : ""}</p>
                    </div>
                    <div className={classes.nameWrapper}>{unassignedUser.name}</div>
                    {role === 0 && <AssignmentIcon className={classes.icon} color={"primary"} onClick={() => handleOpenModal(unassignedUser)} />}
                </div>
                <Divider className={classes.divider} />
            </div>
        );
    });
    const assignedUsersBox = assignedUsers.map((assignedUser) => {
        return (
            <GridListTile style={{ height: "auto" }} key={assignedUser.id} cols={1} onClick={() => handleOpenModal(assignedUser)}>
                <MemberCard
                    id={assignedUser.id}
                    name={assignedUser.name}
                    email={assignedUser.email}
                    roleId={assignedUser.roleId}
                    roleName={assignedUser.roleName}
                />
            </GridListTile>
        );
    });

    return (
        <React.Fragment>
            <GridList classes={{ root: classes.grid }} cols={getBiggerGridListCols()} spacing={18} cellHeight={"auto"}>
                <GridListTile classes={{ tile: classes.tile, root: classes.tileRoot }} cols={1}>
                    <div className={classes.unassignedUsersBackgroundCard}>
                        <div className={classes.unassignedMemberTitle}>{languageData?.UnassignedMembers}</div>
                        {unassignedUsersBox}
                    </div>
                </GridListTile>
                <GridListTile classes={{ tile: classes.tile, root: classes.tileRoot }} cols={getRightGridListCols()}>
                    <div className={classes.assignedUsersBackgroundCard}>
                        <div className={classes.assignedMemberTitle}>{languageData?.AssignedMembers}</div>
                        <GridList cols={getCardsGridListCols()} spacing={16}>
                            {assignedUsersBox}
                        </GridList>
                    </div>
                </GridListTile>
            </GridList>
            <MemberRoleModal
                {...props}
                open={openModal}
                onClose={handleCloseModal}
                title={languageData?.AssignMemberToProject}
                id={memberToBeAssigned?.id}
                name={memberToBeAssigned?.name}
                email={memberToBeAssigned?.email}
                selectedRoleId={memberToBeAssigned?.roleId}
                selectedRoleName={memberToBeAssigned?.roleName}
                roles={roles}
                getData={getMembers}
            />
        </React.Fragment>
    );
};

export default withWidth()(Members);
