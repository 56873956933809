import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import FormLayout from "../../containers/FormLayout/FormLayout";
import StyledInput from "../StyledInput/StyledInput";
import StyledButton from "../StyledButton/StyledButton";
import Axios from "axios";
import WebsiteContext from "../../utils/Website";
import RoutingContext from "../../utils/RoutingContext";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import SnackbarProvider, { SnackbarContext } from "../../utils/SnackbarContext";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: "73px 150px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
        width: 210,
    },
    image: {
        margin: "0 auto 50px auto",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    lastInput: {
        width: "100%",
        marginBottom: 32,
    },
    forgotPassword: {
        textAlign: "right",
        fontSize: 10,
        lineHeight: "12px",
        color: theme.palette.secondary.main,
        marginBottom: 10,
        cursor: "pointer",
        fontWeight: 500,
        fontStyle: "normal",
    },
    error: {
        color: "red",
        fontSize: 10,
        lineHeight: "12px",
        fontWeight: 500,
        fontStyle: "normal",
    },
    confirmed: {
        color: "green",
        fontSize: 10,
        fontWeight: 500,
        fontStyle: "normal",
    },
    unconfirmed: {
        color: "gray",
        fontSize: 10,
        fontWeight: 500,
        fontStyle: "normal",
    },
    titleWithoutSubtitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18.2857px",
        lineHeight: "20px",
        fontFamily: "Montserrat",
        textAlign: "start",
        marginBottom: 32,
    },
    titleWithSubtitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18.2857px",
        lineHeight: "20px",
        fontFamily: "Montserrat",
        textAlign: "start",
        marginBottom: 16,
    },
    subtitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "10px",
        lineHeight: "15px",
        fontFamily: "Montserrat",
        color: "#A5A5A5",
        marginBottom: 16,
    },
    button: {
        backgroundColor: "#636362",
    },
    parent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const ChangePassword = (props) => {
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [error, setError] = useState("");
    const [responseError, setResponseError] = useState("");
    const [validation, setValidation] = useState(null);
    const Snackbar = useContext(SnackbarContext);
    const router = useHistory();

    const { languageData, isDefaultPassword, setIsDefaultPassword } = useContext(WebsiteContext);
    const { setCustomerData, setToolData, setProjectData, setRoutingData } = useContext(RoutingContext);

    const upperCaseRegex = new RegExp("(?=.*[A-Z])");
    const lowerCaseRegex = new RegExp("(?=.*[a-z])");
    const digitRegex = new RegExp("(?=.*[0-9])");
    const specialCharRegex = new RegExp("(?=.[~`!@#$%^&*()_\\-+={\\[}\\]|:;\"'<,>.?/])");

    const classes = useStyles(useStyles);

    useEffect(() => {
        const checkPasswords = () => {
            setResponseError("");
            if (password !== confirmPassword && password !== "" && confirmPassword !== "") setError(languageData.Errors.PasswordMatch);
            else setError("");
        };

        if (confirmPassword) {
            checkPasswords();
        }
    }, [password, confirmPassword]);

    useEffect(() => {
        setRoutingData();

        return function cleanup() {
            setIsDefaultPassword(false);
        };
    }, []);

    const handleSubmit = () => {
        if (
            validation &&
            validation.upperCaseValidation.valid &&
            validation.lowerCaseValidation.valid &&
            validation.digitValidation.valid &&
            validation.specialCharValidation.valid &&
            validation.lengthValidation.valid
        ) {
            const data = {
                current_password: oldPassword,
                new_password: password,
            };
            const options = {
                url: "/user/change-password",
                method: "POST",
                data: data,
            };
            Axios(options).then((response) => {
                if (response.status === 200) {
                    router.push("/");
                }
                setValidation(null);
                setPassword("");
                setConfirmPassword("");
                setOldPassword("");
            });
        }
    };

    const onPasswordChange = (value) => {
        setPassword(value);

        let upperCaseState = false;
        let lowerCaseState = false;
        let digitState = false;
        let specialCharState = false;
        let lengthState = true;

        if (upperCaseRegex.test(value)) {
            upperCaseState = true;
        }
        if (lowerCaseRegex.test(value)) {
            lowerCaseState = true;
        }
        if (digitRegex.test(value)) {
            digitState = true;
        }
        if (specialCharRegex.test(value)) {
            specialCharState = true;
        }
        if (value.length < 8) {
            lengthState = false;
        }

        setValidation({
            upperCaseValidation: {
                valid: upperCaseState,
                message: languageData?.UpperCaseMessage,
            },
            lowerCaseValidation: {
                valid: lowerCaseState,
                message: languageData?.LowerCaseMessage,
            },
            digitValidation: {
                valid: digitState,
                message: languageData?.DigitMessage,
            },
            specialCharValidation: {
                valid: specialCharState,
                message: languageData?.SpecialCharMessage,
            },
            lengthValidation: {
                valid: lengthState,
                message: languageData?.LengthMessage,
            },
        });
    };

    return (
        <div className={classes.parent}>
            <div className={classes.wrapper}>
                <div className={isDefaultPassword ? classes.titleWithSubtitle : classes.titleWithoutSubtitle}>{languageData?.ChangePassword}</div>
                {isDefaultPassword && <div className={classes.subtitle}>{languageData?.FirstChangePasswordMessage}</div>}
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={oldPassword}
                        label={languageData?.CurrentPassword}
                        className={classes.input}
                        onChange={(e) => setOldPassword(e.target.value)}
                        type="password"
                        required={true}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={password}
                        label={languageData?.NewPassword}
                        className={classes.input}
                        onChange={(e) => onPasswordChange(e.target.value)}
                        type="password"
                        required={true}
                    />
                    {validation && (
                        <div style={{ marginBottom: 10 }}>
                            {Object.keys(validation).map((key, value) => {
                                return (
                                    <Grid key={key} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                        <div style={{ width: "10%" }}>
                                            {validation[key].valid === true ? (
                                                <CheckIcon classes={{ root: classes.confirmed }}></CheckIcon>
                                            ) : (
                                                <ClearIcon classes={{ root: classes.unconfirmed }}></ClearIcon>
                                            )}
                                        </div>
                                        <div className={validation[key].valid === true ? classes.confirmed : classes.unconfirmed} style={{ width: "90%" }}>
                                            {validation[key].message}
                                        </div>
                                    </Grid>
                                );
                            })}
                        </div>
                    )}
                    <StyledInput
                        showLabel
                        defaultValue={confirmPassword}
                        label={languageData?.ConfirmPassword}
                        className={!error ? classes.lastInput : classes.input}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        helperText={error}
                        error={!!error}
                        required={true}
                    />
                    {responseError && <div className={classes.error}>{responseError}</div>}
                    <StyledButton className={classes.button} variant="contained" type="submit" fullWidth>
                        {languageData?.ChangePassword}
                    </StyledButton>
                </FormLayout>
            </div>
        </div>
    );
};

export default ChangePassword;
