import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import WebsiteContext from "../../../../../utils/Website";
import Axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import FormLayout from "../../../../../containers/FormLayout/FormLayout";
import StyledInput from "../../../../StyledInput/StyledInput";
import StyledButton from "../../../../StyledButton/StyledButton";
import RoutingContext from "../../../../../utils/RoutingContext";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    dialogContentWrapper: {
        padding: 0,
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
    },
    shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        transformOrigin: "top left",
    },
    addRemoveButtons: {
        width: "100%",
    },
    saveButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    deleteButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    globalError: {
        color: "red",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    gridListWrapper: {
        height: "auto !important",
    },
    selectWrapper: {
        marginBottom: "10px",
        height: "33px",
        width: "100%",
    },
    textareaWrapper: {
        width: "89%",
        border: "0.6425px solid #CCCCCC",
        marginBottom: "5px",
        borderRadius: "2.57px",
        minHeight: "60px",
        height: "auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
        position: "unset",
        padding: "10px",
        resize: "none",
    },
    datePickerWrapper: {
        width: "89%",
        border: "0.6425px solid #CCCCCC",
        marginBottom: "5px",
        borderRadius: "2.57px",
        height: "auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
        position: "unset",
        padding: "0 10px 0 10px",
        resize: "none",
    },
}));

const InjectionReportModal = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role } = useContext(WebsiteContext);
    const { entityPath, toolData } = useContext(RoutingContext);

    const [name, setName] = useState(props.duplicateMode ? "" : props.addMode ? "" : props.name);
    const [nameError] = useState("");

    const handleCloseModal = () => {
        props.onClose();
    };

    const handleAddToolEntity = () => {
        const data = {
            name: name,
        };
        const options = {
            url: `/business-logic/tools/${props.toolId}/processes/mold-trail-fot/injection-reports`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: data,
        };
        Axios(options).then((_) => {
            props.onClose();
        });
    };

    const handleEditToolEntity = () => {
        const data = {
            name: name,
        };

        const options = {
            url: `/business-logic/tools/${props.toolId}/processes/mold-trail-fot/injection-reports/${props.injectionId}`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: data,
        };
        Axios(options).then((_) => {
            props.onClose();
        });
    };

    let buttons = (
        <StyledButton variant="contained" type="submit" fullWidth className={classes.saveButton}>
            {props.duplicateMode === false ? languageData?.Save : languageData?.Duplicate}
        </StyledButton>
    );

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.addNew}>
                {props.duplicateMode
                    ? languageData?.DuplicateInjectionReport
                    : props.addMode
                    ? languageData?.AddNewInjectionReport
                    : languageData?.AddNewInjectionReport}
            </div>
            <div className={classes.formWrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (props.duplicateMode) {
                            props.duplicate(name);
                            setName("");
                        } else {
                            if (props.addMode) {
                                handleAddToolEntity();
                            } else {
                                handleEditToolEntity();
                            }
                        }
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={name}
                        label={languageData?.Name}
                        className={classes.input}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        required={true}
                        helperText={nameError}
                        error={!!nameError}
                    />
                    {buttons}
                </FormLayout>
            </div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

export default InjectionReportModal;
