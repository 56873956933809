const MoldProductionIcon = (props) => {
    return (
        <svg {...props} width="27" height="27" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.2 10.8V8.74799C16.2 7.78949 15.2415 7.14149 14.3505 7.49249L9.45 9.44999V8.80199C9.45 7.82999 8.4645 7.16849 7.5735 7.55999L1.6335 10.098C0.648 10.53 0 11.502 0 12.582V24.3C0 25.785 1.215 27 2.7 27H24.3C25.785 27 27 25.785 27 24.3V10.8H16.2ZM9.45 20.25C9.45 20.9925 8.8425 21.6 8.1 21.6C7.3575 21.6 6.75 20.9925 6.75 20.25V17.55C6.75 16.8075 7.3575 16.2 8.1 16.2C8.8425 16.2 9.45 16.8075 9.45 17.55V20.25ZM14.85 20.25C14.85 20.9925 14.2425 21.6 13.5 21.6C12.7575 21.6 12.15 20.9925 12.15 20.25V17.55C12.15 16.8075 12.7575 16.2 13.5 16.2C14.2425 16.2 14.85 16.8075 14.85 17.55V20.25ZM20.25 20.25C20.25 20.9925 19.6425 21.6 18.9 21.6C18.1575 21.6 17.55 20.9925 17.55 20.25V17.55C17.55 16.8075 18.1575 16.2 18.9 16.2C19.6425 16.2 20.25 16.8075 20.25 17.55V20.25Z" />
            <path d="M24.4615 0H22.801C22.1125 0 21.5455 0.513 21.4645 1.188L20.5195 8.775H26.7295L25.798 1.188C25.717 0.513 25.1365 0 24.4615 0Z" />
        </svg>
    );
};

export default MoldProductionIcon;
