import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { Link, Route, Switch } from "react-router-dom";

import { makeStyles, Divider, Tabs, Tab } from "@material-ui/core";

import WebsiteContext from "../../utils/Website";
import RoutingContext from "../../utils/RoutingContext";

import Schedule from "./Schedule";
import RemindersAddEdit from "./RemindersAddEdit";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    tabStyle: {
        padding: "16px",
        overflowX: "hidden",
    },
    boxStyle: {
        padding: 0,
    },
    padding: {
        height: "calc(100% - 85px)",
        padding: 18,
    },
}));

const Reminders = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const { setRoutingData, setToolData } = useContext(RoutingContext);

    useEffect(() => {
        setRoutingData();
        setToolData({});
    }, []);

    return (
        <React.Fragment>
            <Divider />
            <Tabs value={props.location.pathname} className={classes.wrapper} variant="scrollable" indicatorColor={"primary"} aria-label="simple tabs example">
                {props.location.pathname === "/reminders/schedule" && (
                    <Tab label={languageData.Schedule} component={Link} to={`/reminders/schedule`} value={`/reminders/schedule`} />
                )}
                {props.location.pathname === "/reminders/schedule/add" && (
                    <Tab label={languageData.Schedule} component={Link} to={`/reminders/schedule/add`} value={`/reminders/schedule/add`} />
                )}
                {RegExp("^/" + "reminders/schedule" + "/.*[0-9]").test(props.location?.pathname) && (
                    <Tab label={languageData.Schedule} component={Link} to={props.location?.pathname} value={props.location?.pathname} />
                )}
            </Tabs>
            <div className={classes.padding}>
                <Switch>
                    <Route path={`/reminders/schedule/add`} render={(props) => <RemindersAddEdit {...props} />} />
                    <Route path={`/reminders/schedule/edit/:id`} render={(props) => <RemindersAddEdit {...props} />} />
                    <Route path={`/reminders/schedule`} render={(props) => <Schedule {...props} />} />
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Reminders);
