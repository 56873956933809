import BuildIcon from "@material-ui/icons/Build";
import LockIcon from "@material-ui/icons/Lock";
import ChangePasswordView from "./views/ChangePassword/ChangePassword";
import CustomerProjectsView from "./views/CustomerProjects/CustomerProjects";
import NoteIcon from "@material-ui/icons/Note";
import ProjectsView from "./views/Projects/Projects";
import ToolsView from "./views/Tools/Tools";
import ToolMakerToolsView from "./views/ToolMakerTools/ToolMakerTools";
import ProcessStep from "./views/ProcessSteps/ProcessSteps";
import InjectionReport from "./views/InjectionReport/InjectionReport";

const dashboardToolMakerRoutes = [
    {
        path: "/tool/:id/process/step/:stepNumber/injection-report/:injectionNumber",
        name: "Molds",
        icon: NoteIcon,
        component: InjectionReport,
        isOnMenu: false,
    },
    {
        path: "/tool/:id/process/step/:stepNumber",
        name: "Molds",
        icon: NoteIcon,
        component: ProcessStep,
        isOnMenu: false,
    },
    {
        path: "/tools",
        name: "Tools",
        icon: BuildIcon,
        component: ToolMakerToolsView,
        isOnMenu: true,
    },
    {
        path: "/user/change-password",
        name: "Change Password",
        icon: LockIcon,
        component: ChangePasswordView,
        isOnMenu: false,
    },
    {
        path: "/tool",
        name: "Mold",
        icon: NoteIcon,
        component: ToolsView,
        isOnMenu: false,
    },
];

export default dashboardToolMakerRoutes;
