import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import WebsiteContext from "../../../utils/Website";
import Axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import FormLayout from "../../../containers/FormLayout/FormLayout";
import StyledInput from "../../StyledInput/StyledInput";
import StyledButton from "../../StyledButton/StyledButton";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "12%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
    },
    shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        transformOrigin: "top left",
    },
    addRemoveButtons: {
        width: "100%",
    },
    saveButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    deleteButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    globalError: {
        color: "red",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    dialogContentWrapper: {
        padding: 0,
    },
}));

const CustomLabelsModal = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);

    const [custom1, setCustom1] = useState(props.labels[0].name);
    const [custom2, setCustom2] = useState(props.labels[1].name);
    const [custom3, setCustom3] = useState(props.labels[2].name);
    const [custom4, setCustom4] = useState(props.labels[3].name);
    const [custom5, setCustom5] = useState(props.labels[4].name);

    const handleCloseModal = () => {
        props.onClose();
    };

    const handleSubmit = () => {
        const data = {
            custom1: custom1,
            custom2: custom2,
            custom3: custom3,
            custom4: custom4,
            custom5: custom5,
        };
        const options = {
            url: "/business-logic/customers/" + props.customerId + "/customized-labels",
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            props.onClose();
        });
    };

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.addNew}>{props.title}</div>
            <div className={classes.formWrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={custom1}
                        label={languageData?.CustomLabel1}
                        className={classes.input}
                        onChange={(e) => setCustom1(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={custom2}
                        label={languageData?.CustomLabel2}
                        className={classes.input}
                        onChange={(e) => setCustom2(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={custom3}
                        label={languageData?.CustomLabel3}
                        className={classes.input}
                        onChange={(e) => setCustom3(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={custom4}
                        label={languageData?.CustomLabel4}
                        className={classes.input}
                        onChange={(e) => setCustom4(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={custom5}
                        label={languageData?.CustomLabel5}
                        className={classes.input}
                        onChange={(e) => setCustom5(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <StyledButton variant="contained" type="submit" fullWidth className={classes.saveButton}>
                        {languageData?.Save}
                    </StyledButton>
                </FormLayout>
            </div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

export default CustomLabelsModal;
