import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { makeStyles } from "@material-ui/core";
import WebsiteContext from "../../../../utils/Website";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: "11px 11px",
        height: "calc(100% - 22px)",
    },
    layoutWrapper: {
        height: "calc(100% - 40px)",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
        padding: "20px",
        overflow: "auto",
    },
    title: {
        fontWeight: "700",
    },
    infoGrid: {
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateRows: "repeat(10, 48px)",
        alignContent: "flex-start",
        marginTop: "20px",
        gap: "20px",
        columnGap: "100px",
        gridAutoColumns: "215px",

        [theme.breakpoints.down(1075)]: {
            gridTemplateRows: "repeat(13, 48px)",
        },
        [theme.breakpoints.down(630)]: {
            gridTemplateRows: "repeat(25, 48px)",
        },
    },
    infoBundle: {
        height: "fit-content",
        fontSize: "10px",
    },
    info: {
        color: "#191919",
    },
    subtitle: {
        color: "#777777",
    },
    openFile: {
        color: "#00A7D1",
        cursor: "pointer",
    },
}));

const MachineSpecification = (props) => {
    const classes = useStyles(useStyles);
    const { id, machineId } = useParams();
    const [machine, setMachine] = useState(null);
    const { languageData } = useContext(WebsiteContext);

    useEffect(() => {
        const options = {
            url: `/business-logic/customers/machine-list/${machineId}`,
            method: "GET",
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setMachine(data);
        });
    }, [machineId]);

    const handleFileOpen = () => {
        window.open(`${process.env.REACT_APP_URL}file-operations/${props.customerId}/files/${machine?.fileId}`);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.layoutWrapper}>
                <div className={classes.title}>{languageData?.estimatedInjectionMachine}</div>
                <div className={classes.infoGrid}>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[0].name}</div>
                        <div className={classes.info}>{machine?.textFields.factoryMachineNumber || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[1].name}</div>
                        <div className={classes.info}>{machine?.textFields.machineBrand || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[2].name}</div>
                        <div className={classes.info}>{machine?.textFields.machineType || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[3].name}</div>
                        <div className={classes.info}>{machine?.textFields.machineNr || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[4].name}</div>
                        <div className={classes.info}>{machine?.textFields.tieBarDIstance || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[5].name}</div>
                        <div className={classes.info}>{machine?.textFields.fixSideFlange || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[6].name}</div>
                        <div className={classes.info}>{machine?.textFields.mouldHeight || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[7].name}</div>
                        <div className={classes.info}>{machine?.textFields.machineOpening || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[8].name}</div>
                        <div className={classes.info}>{machine?.textFields.ejectorStroke || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[9].name}</div>
                        <div className={classes.info}>{machine?.textFields.clampingPlateDimension || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[10].name}</div>
                        <div className={classes.info}>{machine?.textFields.clampingForce || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[11].name}</div>
                        <div className={classes.info}>{machine?.textFields.injectionUnittDiameter || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[12].name}</div>
                        <div className={classes.info}>{machine?.textFields.maximumShotSize || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[13].name}</div>
                        <div className={classes.info}>{machine?.textFields.injectionUnitMax || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[14].name}</div>
                        <div className={classes.info}>{machine?.textFields.injectionUnittDiameter2 || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[15].name}</div>
                        <div className={classes.info}>{machine?.textFields.maximumShotSize2 || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[16].name}</div>
                        <div className={classes.info}>{machine?.textFields.injectionUnitMax2 || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[17].name}</div>
                        <div className={classes.info}>{machine?.textFields.nozzleRadius || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[24].name}</div>
                        <div className={classes.info}>{machine?.textFields.miscellaneous || "-"}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.MachineListHeader[25].name}</div>
                        <div className={classNames(classes.info, classes.openFile)} onClick={() => handleFileOpen()}>
                            {machine?.fileName}
                        </div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.CustomLabel1}</div>
                        <div className={classes.info}>{machine?.customFields.custom1}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.CustomLabel2}</div>
                        <div className={classes.info}>{machine?.customFields.custom2}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.CustomLabel3}</div>
                        <div className={classes.info}>{machine?.customFields.custom3}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.CustomLabel4}</div>
                        <div className={classes.info}>{machine?.customFields.custom4}</div>
                    </div>
                    <div className={classes.infoBundle}>
                        <div className={classes.subtitle}>{languageData?.CustomLabel5}</div>
                        <div className={classes.info}>{machine?.customFields.custom5}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MachineSpecification;
