import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Axios from "axios";

import Layout from "./containers/Layout";
import Login from "./views/Login/Login";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/ResetPassword/ResetPassword";

import WebsiteContext from "./utils/Website";
import "./assets/css/material-dashboard-react.css?v=1.9.0";
import de from "./assets/language/de";
import en from "./assets/language/en";
import RoutingContext from "./utils/RoutingContext";
import "./index.css";

Axios.defaults.baseURL = process.env.REACT_APP_URL;
Axios.defaults.headers.common["X-Loading"] = true;

const hist = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (localStorage.getItem("accessToken") ? <Component {...props} /> : <Redirect to="/login" />)} />
);

const theme = createTheme({
    palette: {
        primary: {
            main: "#C00D0E",
        },
        secondary: {
            main: "#636362",
        },
        error: {
            main: "#ff0000",
        },
        dark: {
            main: "#191919",
        },
        gray: {
            main: "#646363",
        },
    },
    typography: {
        fontFamily: "'Montserrat', sans-serif",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1366,
            xl: 1920,
        },
    },
});

const App = () => {
    const [language, setLanguage] = useState("");
    const [languageData, setLanguageData] = useState(null);
    const [customerData, setCustomerData] = useState({
        visibleContent: false,
        name: "",
        address: "",
        logo: "",
    });
    const [projectData, setProjectData] = useState({
        visibleContent: false,
        number: "",
        name: "",
        progress: "",
        customerName: "",
        stateColor: "",
    });
    const [toolData, setToolData] = useState({
        visibleContent: false,
        name: "",
        tool_maker_name: "",
        tool_maker_id: "",
        progress: "",
        description: "",
        stateColor: "",
        startDate: "",
    });
    const [stepData, setStepData] = useState({
        visibleContent: false,
        id: "",
        name: "",
        canClose: "",
        state: "",
        toolId: "",
    });
    const [entityPath, setEntityPath] = useState("");
    const [loading, setLoading] = useState(false);
    const [isDefaultPassword, setIsDefaultPassword] = useState(false);
    const [backPath, setBackPath] = useState("");
    const [role, setRole] = useState(null);

    useEffect(() => {
        const savedLanguage = localStorage.getItem("language");
        const accessToken = localStorage.getItem("accessToken");

        const platformRole = localStorage.getItem("role");

        if (platformRole) {
            setRole(Number(platformRole));
        }

        if (savedLanguage) {
            setLanguage(savedLanguage);
            if (savedLanguage === "en") setLanguageData(en);
            else setLanguageData(de);
        } else {
            localStorage.setItem("language", "en");
            setLanguage("en");
            setLanguageData(en);
        }

        if (accessToken) Axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    }, []);

    useEffect(() => {
        if (language) {
            if (language === "en") setLanguageData(en);
            else setLanguageData(de);
        }
    }, [language]);

    const setRoutingValues = () => {
        setCustomerData({
            visibleContent: false,
            name: "",
            logo: "",
            address: "",
        });
        setProjectData({
            visibleContent: false,
            number: "",
            name: "",
            progress: "",
            customerName: "",
            stateColor: "",
        });
        setToolData({
            visibleContent: false,
            name: "",
            tool_maker_name: "",
            tool_maker_id: "",
            progress: "",
            description: "",
            stateColor: "",
            startDate: "",
            state: "",
        });
        setStepData({
            visibleContent: false,
            id: "",
            name: "",
            canClose: "",
            state: "",
            toolId: "",
        });
        setEntityPath("");
        setBackPath("");
    };

    return (
        <WebsiteContext.Provider
            value={{
                language: language,
                setLanguage: setLanguage,
                languageData: languageData,
                loading: loading,
                setLoading: setLoading,
                isDefaultPassword: isDefaultPassword,
                setIsDefaultPassword: setIsDefaultPassword,
                role: role,
                setRole: setRole,
            }}
        >
            <RoutingContext.Provider
                value={{
                    customerData: customerData,
                    setCustomerData: setCustomerData,
                    entityPath: entityPath,
                    setEntityPath: setEntityPath,
                    projectData: projectData,
                    setProjectData: setProjectData,
                    toolData: toolData,
                    setToolData: setToolData,
                    backPath: backPath,
                    setBackPath: setBackPath,
                    stepData: stepData,
                    setStepData: setStepData,
                    setRoutingData: setRoutingValues,
                }}
            >
                <ThemeProvider theme={theme}>
                    <Helmet>
                        <title>HANNES</title>
                    </Helmet>
                    <Router history={hist}>
                        <Switch>
                            <Route path="/login" exact component={Login} />
                            <Route path="/forgot-password" exact component={ForgotPassword} />
                            <Route path="/user/reset-password" exact component={ResetPassword} />
                            <PrivateRoute path="/" component={Layout} />
                        </Switch>
                    </Router>
                </ThemeProvider>
            </RoutingContext.Provider>
        </WebsiteContext.Provider>
    );
};

ReactDOM.render(<App />, document.getElementById("root"));
