import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
    progressWrapper: {
        backgroundColor: (props) => props.statecolor,
    },
    percentageWrapper: {
        fontSize: "8px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    boxWrapper: {
        marginRight: "0px",
    },
    parentBoxWrapper: {
        position: "relative",
    },
    childBoxWrapper: {
        position: "absolute",
    },
});

export default function LinearProgressWithLabel(props) {
    const classes = useStyles(props);
    const valueToMove = () => {
        if (props.width === "100%") {
            if (props.value === 100 || props.value === 0) {
                return "50%";
            }
            return ((100 * (props.value < 95 ? props.value : 94)) / 100).toString() + "%";
        } else {
            if (props.value === 100 || props.value === 0) {
                return (props.width / 2).toString() + "px";
            }
            return ((props.width * (props.value < 95 ? props.value : 94)) / 100).toString() + "px";
        }
    };
    return (
        <Box display="flex" alignItems="center" className={classes.parentBoxWrapper}>
            <Box classes={{ root: classes.boxWrapper }} width={props.width} mr={1}>
                <LinearProgress
                    classes={{
                        barColorPrimary: classes.progressWrapper,
                    }}
                    variant="determinate"
                    {...props}
                />
            </Box>
            <Box
                classes={{ root: classes.childBoxWrapper }}
                style={{
                    transform: "translate(-50%,-10px)",
                    marginLeft: valueToMove(),
                }}
            >
                <Typography className={classes.percentageWrapper} variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};
