import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, makeStyles, Paper } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import WorkIcon from "@material-ui/icons/Work";
import RoomIcon from "@material-ui/icons/Room";
import Axios from "axios";
import WebsiteContext from "../../../utils/Website";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
        padding: 9,
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
    },
    paper: {
        background: "#FFFFFF",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px",
        padding: 18,
        marginBottom: 18,
    },
    paperInfo: {
        background: "#FFFFFF",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px",
        padding: 18,
        marginBottom: 18,
        height: "calc(100% - 18px)",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
        },
    },
    paperUsers: {
        background: "#FFFFFF",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px",
        padding: 18,
        marginBottom: 18,
        overflow: "auto",
        height: "calc(100% - 182px)",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 2,
            height: "auto",
        },
    },
    title: {
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: "bold",
        color: "#A5A5A5",
        marginBottom: "30px",
    },
    name: {
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: "bold",
        color: "#191919",
        marginBottom: "20px",
    },
    description: {
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    usersWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    user: {
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        width: "auto",
    },
    info: {
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "20px",
        color: "#191919",
        marginBottom: "10px",
        marginLeft: "10px",
    },
    infoWrapper: {
        display: "flex",
    },
    grid: {
        height: "100%",
    },
}));

const Info = (props) => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [info, setInfo] = useState(null);
    const [makerInfo, setMakerInfo] = useState(null);
    const { setLoading, languageData } = useContext(WebsiteContext);

    const getUsers = () => {
        if (props.tool?.tool_maker_id) {
            const options = {
                url: "/tool-makers/" + props.tool?.tool_maker_id + "/users",
                method: "GET",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((response) => {
                setUsers(response.data.data);
                getInfo();
                getMakerInfo();
            });
        } else {
            setLoading(false);
        }
    };

    const getInfo = () => {
        setInfo(props.tool.description);
    };

    const getMakerInfo = () => {
        const options = {
            url: "/tool-makers/" + props.tool?.tool_maker_id,
            method: "GET",
        };
        Axios(options).then((response) => {
            setMakerInfo(response.data.data);
        });
    };

    useEffect(() => {
        if (props.tool && props.state !== languageData?.QuotationRequestState) {
            getUsers();
        }
    }, [props.tool]);

    return (
        <Grid className={classes.wrapper} container spacing={2}>
            <Grid className={classes.grid} item xs={12} md={4}>
                <Paper className={classes.paper}>
                    <div className={classes.title}>{languageData?.MoldMakerInfo}</div>
                    <div className={classes.name}>{makerInfo?.name}</div>
                    <div className={classes.infoWrapper}>
                        <RoomIcon color="primary" fontSize="small" />
                        <div className={classes.info}>{makerInfo?.address}</div>
                    </div>
                </Paper>
                <Paper className={classes.paperUsers}>
                    <div className={classes.title}>{languageData?.MoldMakerUsers}</div>
                    <div className={classes.usersWrapper}>
                        {users.map((user) => (
                            <div className={classes.user} key={user.id}>
                                <div className={classes.name}>{user.name}</div>
                                <div className={classes.infoWrapper}>
                                    <PhoneIcon color="primary" fontSize="small" />
                                    <div className={classes.info}>{user.phone_number}</div>
                                </div>
                                <div className={classes.infoWrapper}>
                                    <MailIcon color="primary" fontSize="small" />
                                    <div className={classes.info}>{user.email}</div>
                                </div>
                                <div className={classes.infoWrapper}>
                                    <WorkIcon color="primary" fontSize="small" />
                                    <div className={classes.info}>{user.department}</div>
                                </div>
                                <Divider />
                            </div>
                        ))}
                    </div>
                </Paper>
            </Grid>
            <Grid className={classes.grid} item xs={12} md={8}>
                <Paper className={classes.paperInfo}>
                    <div className={classes.title}>{languageData?.MoldInfo}</div>
                    <div className={classes.description}>{info}</div>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Info;
