import React, { useContext } from "react";

import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import WebsiteContext from "../../utils/Website";

const useStyles = makeStyles(
    (theme) => ({
        modalTitle: {
            color: theme.palette.text.primary,
            textAlign: "center",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "0.01em",
        },
        closeButton: {
            position: "absolute",
            top: "5px",
            right: "5px",
            color: theme.palette.grey["550"],
        },
        children: {
            padding: "0px 50px 30px 50px",
            width: "100%",
            margin: "20px auto",
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.down(650)]: {
                padding: "0px 20px 10px 20px",
            },
        },
        topWrapper: {
            paddingTop: "50px",
            backgroundColor: "#fff",
        },
        paperScrollPaper: {
            maxHeight: "calc(100% - 20px)",
            boxShadow: "0px 2.84488px 4.74146px rgba(0, 0, 0, 0.05), 0px 4.74146px 7.58634px rgba(0, 0, 0, 0.05)",
        },
    }),
    { name: "CustomModalStyles" }
);

const StyledModal = ({ isOpen, onClose, title, children, noTitle, paperClassName, contentClassName, childrenClassName, topWrapperClassName }) => {
    const classes = useStyles();

    const { languageData } = useContext(WebsiteContext);

    return (
        <Dialog classes={{ paperScrollPaper: `${classes.paperScrollPaper} ${paperClassName}` }} open={isOpen} onClose={onClose}>
            <DialogTitle>
                {!noTitle ? (
                    <div className={`${classes.topWrapper} ${topWrapperClassName}`}>
                        <IconButton className={classes.closeButton} onClick={onClose}>
                            <Close />
                        </IconButton>
                        <div className={classes.modalTitle}>{languageData && languageData[title]?.toLocaleUpperCase()}</div>
                    </div>
                ) : (
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <Close />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent className={contentClassName}>
                <div className={`${classes.children} ${childrenClassName}`}>{children}</div>
            </DialogContent>
        </Dialog>
    );
};

export default StyledModal;
