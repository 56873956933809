import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import RoutingContext from "../../../../utils/RoutingContext";
import { Grid, makeStyles } from "@material-ui/core";
import FormLayout from "../../../../containers/FormLayout/FormLayout";
import StyledButton from "../../../StyledButton/StyledButton";
import StyledInput from "../../../StyledInput/StyledInput";
import Comment from "./Comment/Comment";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100% - 18px)",
        padding: 9,
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        // marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginRight: 5,
    },
    divider: {
        width: "100%",
    },
    buttonWrapper: {
        padding: 18,
        background: "white",
    },
    sectionTitle: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        margin: "18px 0 18px 0",
    },
    sectionWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    gridWrapper: {},
    questionWrapper: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        width: 210,
        marginBottom: 5,
        marginTop: 16,
    },
    answerWrapper: {
        width: 210,
    },
    copyButton: {
        borderRadius: "3px",
        width: "auto",
        // marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginRight: "10px",
    },
    switchContent: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "10px",
        alignItems: "center",
    },
}));

const MoldChecklist = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role } = useContext(WebsiteContext);
    const { setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData, setStepData } = useContext(RoutingContext);
    const [data, setData] = useState(null);
    const [files, setFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);

    const getData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-release-process/mold-check-list`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setFiles([]);
            setFilesToDelete([]);
            const rspData = response.data.data;
            setData(rspData);
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const saveMedia = async () => {
        const tmpData = { ...data };
        filesToDelete.forEach((file) => {
            const options = {
                url: "/file-operations/" + `${props.match.params.id}-mold-process-release` + `/files/${file}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((response) => {});
        });

        if (files.length === 0) {
            handleSubmit(data);
        } else {
            for (const file of files) {
                const idx = files.findIndex((el) => el === file);
                if (file.fileToBeDeleted !== "") {
                    const deleteOptions = {
                        url: "/file-operations/" + `${props.match.params.id}-mold-process-release` + `/files/${file.fileToBeDeleted}`,
                        method: "DELETE",
                        headers: {
                            "X-Loading": false,
                        },
                    };
                    await Axios(deleteOptions);
                    let filesFormatData = new FormData();
                    filesFormatData.append("files", file.file, file.file.name);

                    const options = {
                        url: "/file-operations/" + `${props.match.params.id}-mold-process-release` + "/files",
                        method: "POST",
                        data: filesFormatData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    const response = await Axios(options);

                    if (response.status === 200) {
                        tmpData.comments[file.index].fileId = response.data.data.files[0].id;
                        tmpData.comments[file.index].fileName = response.data.data.files[0].name;
                        if (idx === files.length - 1) {
                            handleSubmit(tmpData);
                        }
                    }
                } else {
                    let filesFormatData = new FormData();
                    filesFormatData.append("files", file.file, file.file.name);

                    const options = {
                        url: "/file-operations/" + `${props.match.params.id}-mold-process-release` + "/files",
                        method: "POST",
                        data: filesFormatData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    const response = await Axios(options);
                    if (response.status === 200) {
                        tmpData.comments[file.index].fileId = response.data.data.files[0].id;
                        tmpData.comments[file.index].fileName = response.data.data.files[0].name;
                        if (idx === files.length - 1) {
                            handleSubmit(tmpData);
                        }
                    }
                }
            }
        }
    };

    const handleSubmit = (tmpData) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-release-process/mold-check-list`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: tmpData,
        };
        Axios(options).then((response) => {
            getData();
        });
    };

    const changeInput = (sectionIndex, questionIndex, value) => {
        const tmp = { ...data };
        tmp.params[sectionIndex].questions[questionIndex].answer = value;
        setData(tmp);
    };

    const handleCopy = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-release-process/mold-check-list`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            getData();
        });
    };

    const saveData = (field, value) => {
        const tmpData = { ...data };
        tmpData[field] = value;
        setData(tmpData);
    };

    return (
        <div className={classes.wrapper}>
            <FormLayout
                onSubmit={async (e) => {
                    e.preventDefault();
                    await saveMedia();
                }}
                className={classes.layoutWrapper}
            >
                {(role === 0 || role === 2) && (
                    <div className={classes.buttonWrapper}>
                        <StyledButton className={classes.button} color="primary" variant="contained" type="submit">
                            {languageData?.Save.toUpperCase()}
                        </StyledButton>
                        <StyledButton
                            className={classes.copyButton}
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                handleCopy();
                            }}
                        >
                            {languageData?.CopyFromSamplingProcess.toUpperCase()}
                        </StyledButton>
                    </div>
                )}
                <div className={classes.switchContent}>
                    <Switch checked={props.visible} color="primary" onChange={(e) => props.changeVisibility(e.target.checked)} />
                    <div className={classes.sectionTitle} style={{ marginBottom: "10px", marginTop: "10px" }}>
                        {languageData?.EnableSecondComponentVisibility}
                    </div>
                </div>
                <div className={classes.contentWrapper}>
                    {data?.params.map((section, sectionIndex) => {
                        return (
                            <div className={classes.sectionWrapper} key={sectionIndex}>
                                <div className={classes.sectionTitle}>{section.name}</div>
                                <Grid item container xs={12} sm={12} md={12} className={classes.gridWrapper}>
                                    {section.questions.map((question, questionIndex) => {
                                        if (
                                            props.visible === false &&
                                            (question.question === "2Comp. Mold" ||
                                                question.question === "2. Screw barrel diameter" ||
                                                (question.question === "Max. Shot weight" &&
                                                    section.questions[questionIndex - 1].question === "2. Screw barrel diameter") ||
                                                question.question === "Sprue bushing radius 2.Comp." ||
                                                question.question === "Maschine nozzle radius 2.Comp.")
                                        ) {
                                            return <div />;
                                        }
                                        return (
                                            <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                    <StyledInput
                                                        disabled={role === 1}
                                                        defaultValue={question.answer}
                                                        onChange={(e) => changeInput(sectionIndex, questionIndex, e.target.value)}
                                                        className={classes.answerWrapper}
                                                        type="text"
                                                    />
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        );
                    })}
                    <Comment
                        {...props}
                        changeObject={saveData}
                        data={data?.comments}
                        files={files}
                        setFiles={setFiles}
                        filesToDelete={filesToDelete}
                        setFilesToDelete={setFilesToDelete}
                    />
                </div>
            </FormLayout>
        </div>
    );
};

export default MoldChecklist;
