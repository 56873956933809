import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton, InputLabel, MenuItem, Select } from "@material-ui/core";
import WebsiteContext from "../../../utils/Website";
import StyledButton from "../../StyledButton/StyledButton";
import Axios from "axios";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    formWrapper: {
        padding: "5% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "12%",
        color: "#1F1F1F",
    },
    nameWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "14,63px",
        textAlign: "center",
        marginTop: "36px",
        color: "#646363",
    },
    emailWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        textAlign: "center",
        color: "#646363",
    },
    roleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#646363",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    closeButtonWrapper: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    selectWrapper: {
        marginBottom: "35px",
        height: "33px",
    },
    globalError: {
        color: "red",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    dialogContentWrapper: {
        padding: 0,
    },
}));

const MemberRoleModal = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const [unassignButtonClicked, setUnassignButtonClicked] = useState(false);
    const [globalError, setGlobalError] = useState("");

    const [selectedRoleName, setSelectedRoleName] = useState("");

    useEffect(() => {
        setSelectedRoleName(props.selectedRoleName ? props.selectedRoleName : props.roles[0]);
        setUnassignButtonClicked(false);
        setGlobalError("");
    }, [props.open]);

    const handleRoleChange = (event) => {
        setSelectedRoleName(event.target.value);
    };

    const handleCloseModal = () => {
        props.onClose();
    };

    const handleAssignMember = () => {
        const data = {
            userId: props.id,
            role: selectedRoleName,
        };

        const options = {
            url: props.match.url,
            method: "POST",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };

        Axios(options).then((_) => {
            props.onClose();
            props.getData();
        });
    };

    const handleSaveOrCancelButtonClick = () => {
        if (!unassignButtonClicked) {
            editAssignedMemberRole();
        } else {
            handleCancelDeleteClicked();
        }
    };

    const handleCancelDeleteClicked = () => {
        setUnassignButtonClicked(false);
        setGlobalError("");
    };

    const editAssignedMemberRole = () => {
        const data = {
            role: selectedRoleName,
        };

        const options = {
            url: props.match.url + "/" + props.id,
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };

        Axios(options).then((_) => {
            props.onClose();
            props.getData();
        });
    };

    const handleUnassignMember = () => {
        if (!unassignButtonClicked) {
            setUnassignButtonClicked(true);
            setGlobalError(languageData?.UnassignWarningMessage);
        } else {
            const options = {
                url: props.match.url + "/" + props.id,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                props.onClose();
                props.getData();
            });
        }
    };

    const roleItems = props.roles.map((role) => {
        return (
            <MenuItem value={role} key={role}>
                {role}
            </MenuItem>
        );
    });

    let buttons;

    if (props.selectedRoleId) {
        buttons = (
            <GridList cols={2}>
                <GridListTile style={{ height: "auto" }}>
                    <StyledButton color="primary" variant="contained" fullWidth onClick={handleUnassignMember}>
                        {languageData?.Unassign}
                    </StyledButton>
                </GridListTile>
                <GridListTile style={{ height: "auto" }}>
                    <StyledButton color="primary" variant="contained" fullWidth onClick={handleSaveOrCancelButtonClick}>
                        {unassignButtonClicked ? languageData?.Cancel : languageData?.Save}
                    </StyledButton>
                </GridListTile>
            </GridList>
        );
    } else {
        buttons = (
            <StyledButton color="primary" variant="contained" onClick={handleAssignMember} fullWidth>
                {languageData?.Save}
            </StyledButton>
        );
    }

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.closeButtonWrapper} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.titleWrapper}>{props.title}</div>
            <div className={classes.nameWrapper}>{props.name}</div>
            <div className={classes.emailWrapper}>{props.email}</div>
            <div className={classes.formWrapper}>
                <InputLabel className={classes.roleWrapper}>{languageData?.Role}</InputLabel>
                <Select
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    variant={"outlined"}
                    className={classes.selectWrapper}
                    value={selectedRoleName}
                    onChange={handleRoleChange}
                >
                    {roleItems}
                </Select>
                {buttons}
                <div className={classes.globalError}>{globalError}</div>
            </div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={handleCloseModal}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

MemberRoleModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.array,
    add: PropTypes.bool,
};

export default MemberRoleModal;
