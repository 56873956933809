import React, { useContext } from "react";
import { Button, Dialog, DialogContent, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import BackupIcon from "@material-ui/icons/Backup";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import WebsiteContext from "../../../utils/Website";
import StyledButton from "../../StyledButton/StyledButton";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: "500px",
        maxWidth: "unset",
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(700)]: {
            width: "80vw",
        },
    },
    dialog: {
        maxWidth: "unset",
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    dialogContentWrapper: {
        "&:first-child": {
            padding: 0,
        },
        padding: 0,
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        padding: "120px 0",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        boxShadow: "none",
        textTransform: "none",
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: "14px",
        borderRadius: 2.57,

        backgroundColor: theme.palette.primary.main,
        border: "none",
        color: theme.palette.common.white,
        justifyContent: "center",
        height: 33,
        width: 210,
        padding: "0 10px",
        margin: "16px 0",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    inputFile: {
        opacity: 0,
        width: 1,
    },
}));

const AddMediaModal = ({ open, onClose, openEditor, uploadFile }) => {
    const classes = useStyles();
    const { languageData } = useContext(WebsiteContext);

    const upload = (event) => {
        let file = event.target.files[0];

        uploadFile(file);
    };

    return (
        <Dialog open={open} onClose={onClose} classes={{ paperWidthSm: classes.dialog }}>
            <DialogContent className={classes.dialogContentWrapper}>
                <div className={classes.modalWrapper}>
                    <IconButton className={classes.icon} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <div className={classes.buttons}>
                        <input className={classes.inputFile} id="contained-button-file" type="file" onChange={(e) => upload(e)} />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" component="span" className={classes.button} startIcon={<BackupIcon color="inherit" />}>
                                {languageData?.UploadFile.toLocaleUpperCase()}
                            </Button>
                        </label>

                        {/* <StyledButton className={classes.button} variant="outlined" startIcon={<BackupIcon color="inherit" />}>
                            UPLOAD FILE
                        </StyledButton> */}
                        <StyledButton onClick={openEditor} className={classes.button} variant="outlined" startIcon={<OpenInNewIcon color="inherit" />}>
                            {languageData?.OpenEditor.toUpperCase()}
                        </StyledButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AddMediaModal;
