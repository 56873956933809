import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Button, FormControlLabel, IconButton, makeStyles, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import StyledInput from "../../../StyledInput/StyledInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { v4 as uuidv4 } from "uuid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CachedIcon from "@material-ui/icons/Cached";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: "8px",
    },
    subtitleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginBottom: 4,
    },
    selectWrapper: {
        width: "210px",
        height: "33px",
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    inputWrapper: {
        padding: "10px 14px",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    input: {
        width: "210px",
        marginRight: 10,
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    selectWithLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginRight: "20px",
        marginBottom: "4px",
    },
    root: {
        padding: 0,
        marginTop: 2,
    },
    addButton: {
        width: "fit-content",
        marginLeft: 16,
    },
    removeButton: {
        width: "fit-content",
    },
    inputOthers: {
        display: "flex",
        marginTop: 8,
    },
    fullWidth: {
        width: "100%",
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        margin: 5,
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    checked: {},
    gateSystemWrapper: {
        marginLeft: 7,
    },
    gateSystemElement: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    linkWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        maxWidth: "190px",
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "12px",
        color: "#646363",
        marginRight: 5,
    },
    accessibleLink: {
        textDecorationLine: "underline",
        cursor: "pointer",
        color: "#00A7D1",
    },
    radioButtonWrapper: {
        width: 150,
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    fileInputWrapper: { width: 1, height: 1, opacity: 1 },
}));

const runnerSystem = [
    {
        id: 1,
        name: "Coldrunner",
    },
    {
        id: 2,
        name: "3-Plate Mold",
    },
    {
        id: 3,
        name: "Single nozzle",
    },
    {
        id: 4,
        name: "Single Nozzle subgate",
    },
    {
        id: 5,
        name: "Hotrunner",
    },
    {
        id: 6,
        name: "Hotrunner subgate",
    },
    {
        id: 7,
        name: "Hotrunner valve gate",
    },
    {
        id: 8,
        name: "Hotrunner valve gate subgate",
    },
];

const RunnerSystem = (props) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);
    const fileNameLength = 15;
    const [otherFiles, setOtherFiles] = useState([]);

    useEffect(() => {
        if (props?.data?.runnerSystemOthers.length === 0) {
            addOthersInput();
        }
    }, [props?.data?.runnerSystemOthers]);

    useEffect(() => {
        if (props?.data?.gateSystemOthers.length === 0) {
            addGateOthersInput();
        } else {
            const auxGateFiles = [];
            props?.data?.gateSystemOthers.map((gate) => {
                if (gate.fileId !== "" && gate.fileId !== null) {
                    auxGateFiles.push({
                        id: gate.id,
                        fileId: gate.fileId,
                        filename: gate.filename,
                        accessible: true,
                    });
                }
            });
            setOtherFiles(auxGateFiles);
        }
        if (props.files && props.files.length !== 0) {
            setOtherFiles(
                props.files.map((el) => {
                    return {
                        id: props?.data?.gateSystemOthers[el.index].id,
                        fileId: null,
                        filename: el.newFileName,
                        accessible: false,
                    };
                })
            );
        }
    }, [props?.data]);

    const handleSimpleSelectChange = (event, selectedColumn) => {
        props.update("runnerSystem", selectedColumn, event.target.value, props.index);
    };

    const updateOthersInput = (event, index) => {
        let arrayCopy = JSON.parse(JSON.stringify(props.data.runnerSystemOthers));
        arrayCopy[index].value = event.target.value;
        props.update("runnerSystem", "runnerSystemOthers", arrayCopy, props.index);
    };

    const updateGateOthersInput = (event, index) => {
        let arrayCopy = JSON.parse(JSON.stringify(props.data.gateSystemOthers));
        arrayCopy[index].value = event.target.value;
        props.update("runnerSystem", "gateSystemOthers", arrayCopy, props.index);
    };

    const removeOthersInput = (index) => {
        let arrayCopy = JSON.parse(JSON.stringify(props.data.runnerSystemOthers));
        arrayCopy.splice(index, 1);
        props.update("runnerSystem", "runnerSystemOthers", arrayCopy, props.index);
    };

    const removeGateOthersInput = (index, id) => {
        props.deleteFromNewFiles("runnerSystem", "gateSystemOthers", index, props.index);

        let arrayCopy = JSON.parse(JSON.stringify(props.data.gateSystemOthers));
        arrayCopy.splice(index, 1);
        props.update("runnerSystem", "gateSystemOthers", arrayCopy, props.index);

        const auxFiles = otherFiles.slice();
        const indexToDelete = auxFiles.findIndex((el) => el.id === id);
        if (indexToDelete > -1) {
            auxFiles.splice(indexToDelete, 1);
        }

        setOtherFiles(auxFiles);
    };

    const addOthersInput = () => {
        let arrayCopy = JSON.parse(JSON.stringify(props.data.runnerSystemOthers));
        arrayCopy.push({
            id: uuidv4(),
            value: "",
        });
        props.update("runnerSystem", "runnerSystemOthers", arrayCopy, props.index);
    };

    const addGateOthersInput = () => {
        let arrayCopy = JSON.parse(JSON.stringify(props.data.gateSystemOthers));
        arrayCopy.push({
            id: uuidv4(),
            value: "",
            filename: "",
            fileId: "",
        });
        props.update("runnerSystem", "gateSystemOthers", arrayCopy, props.index);
    };

    const computeFilename = (file) => {
        if (file) {
            let filename;
            const sections = file.split(".");
            if (sections.length > 1) {
                let firstPart = sections.slice(0, sections.length - 1).join(".");
                if (firstPart.length > fileNameLength) {
                    firstPart = firstPart.substr(0, fileNameLength) + "...";
                }
                const lastPart = "." + sections[sections.length - 1];
                filename = firstPart + lastPart;
            } else {
                filename = sections[0].length > fileNameLength ? sections[0].substr(0, fileNameLength) : sections[0];
            }
            return filename;
        } else {
            return "";
        }
    };

    const handleOpenFile = (id, folder, accessible) => {
        if (accessible) {
            window.open(`${process.env.REACT_APP_URL}file-operations/${folder}/files/${id}`);
        }
    };

    const uploadFile = (event, id, indexToInsert) => {
        let file = event.target.files[0];

        if (file) {
            const auxFiles = otherFiles.slice();
            const index = otherFiles.findIndex((el) => el.id === id);

            if (index > -1) {
                auxFiles[index].accessible = false;
                auxFiles[index].filename = file.name;
            } else {
                auxFiles.push({
                    id: id,
                    fileId: null,
                    filename: file.name,
                    accessible: false,
                });
            }
            setOtherFiles(auxFiles);
            props.updateNewFiles("runnerSystem", "gateSystemOthers", index > -1 ? auxFiles[index].fileId : null, file, file.name, indexToInsert, props.index);
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.RunnerSystem.toUpperCase()}</div>
            <div className={classes.contentWrapper}>
                <div className={classes.selectWithLabel}>
                    <InputLabel className={classes.subtitleWrapper}>{languageData?.RunnerSystem}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={props?.data?.runnerSystem && props?.data?.runnerSystem > -1 ? props?.data?.runnerSystem : ""}
                            displayEmpty={!(props?.data?.runnerSystem && props?.data?.runnerSystem > -1)}
                            renderValue={
                                !(props?.data?.runnerSystem && props?.data?.runnerSystem > -1)
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                handleSimpleSelectChange(e, "runnerSystem");
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {runnerSystem.map((runner) => {
                                return (
                                    <MenuItem value={runner.id} key={runner.id}>
                                        <div className={classes.selectTextWrapper}>{languageData && languageData[runner.name]}</div>
                                    </MenuItem>
                                );
                            })}
                            <MenuItem value={0} key={0}>
                                <div className={classes.selectTextWrapper}>{languageData?.Others}</div>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {props?.data?.runnerSystem === 0 && (
                        <React.Fragment>
                            {props?.data?.runnerSystemOthers?.length > 0 &&
                                props?.data?.runnerSystemOthers?.map((input, index) => (
                                    <div key={input.id} className={classes.inputOthers}>
                                        <StyledInput
                                            disabled={props.disabled}
                                            className={classes.fullWidth}
                                            defaultValue={input.value}
                                            onChange={(e) => updateOthersInput(e, index)}
                                            type="text"
                                            // required={true}
                                        />
                                        {props?.data?.runnerSystemOthers?.length > 1 && (
                                            <IconButton
                                                disabled={props.disabled}
                                                classes={{ root: classes.root }}
                                                className={classes.addButton}
                                                onClick={() => removeOthersInput(index)}
                                            >
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                ))}
                            <IconButton
                                disabled={props.disabled}
                                classes={{ root: classes.root }}
                                color="primary"
                                className={classes.removeButton}
                                onClick={() => addOthersInput()}
                            >
                                <AddCircleIcon />
                            </IconButton>
                        </React.Fragment>
                    )}
                </div>
                <div className={classes.selectWithLabel}>
                    <InputLabel className={classes.subtitleWrapper}>{languageData?.HotrunnerBrand}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={props.data?.hotrunnerBrand ? props.data.hotrunnerBrand : ""}
                            displayEmpty={props.data?.hotrunnerBrand === null}
                            renderValue={
                                props.data?.hotrunnerBrand === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                handleSimpleSelectChange(e, "hotrunnerBrand");
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props?.resources?.hotrunnerBrand?.map((runner) => {
                                return (
                                    <MenuItem value={runner.id} key={runner.id}>
                                        <div className={classes.selectTextWrapper}>{runner.text}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                {(props?.data?.runnerSystem === 6 || props?.data?.runnerSystem === 7) && (
                    <div className={classes.selectWithLabel}>
                        <div className={classes.subtitleWrapper}>{languageData?.CascadeControl}</div>
                        <RadioGroup
                            className={classes.radioButtonsWrapper}
                            value={props?.data?.cascadeControl ? props?.data?.cascadeControl : false}
                            onChange={(e) => props.update("runnerSystem", "cascadeControl", e.target.value === "true", props.index)}
                        >
                            <FormControlLabel
                                disabled={props.disabled}
                                classes={{ label: classes.textWrapper }}
                                name="slider"
                                value={true}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.Yes}
                            />
                            <FormControlLabel
                                disabled={props.disabled}
                                classes={{ label: classes.textWrapper }}
                                name="slider"
                                value={false}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.No}
                            />
                        </RadioGroup>
                    </div>
                )}
                <div className={classes.selectWithLabel}>
                    <InputLabel className={classes.subtitleWrapper}>{languageData?.GateSystem}</InputLabel>
                    <RadioGroup
                        className={classes.gateSystemWrapper}
                        value={props?.data?.gateSystem ? props?.data?.gateSystem : ""}
                        onChange={(e) => props.update("runnerSystem", "gateSystem", e.target.value, props.index)}
                    >
                        {props?.resources?.gateSystem?.map((runner) => {
                            return (
                                <div key={runner.id} className={classes.gateSystemElement}>
                                    <FormControlLabel
                                        disabled={props.disabled}
                                        classes={{ label: classes.textWrapper }}
                                        className={classes.radioButtonWrapper}
                                        name="slider"
                                        value={runner.id}
                                        control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                        label={runner.text}
                                    />
                                    {runner.fileId && (
                                        <span
                                            onClick={() => handleOpenFile(runner.fileId, props.customerId, true)}
                                            className={classes.linkWrapper + " " + classes.accessibleLink}
                                        >
                                            {computeFilename(runner.fileName)}
                                        </span>
                                    )}
                                </div>
                            );
                        })}
                        <FormControlLabel
                            disabled={props.disabled}
                            classes={{ label: classes.textWrapper }}
                            name="slider"
                            value={"0"}
                            control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                            label={languageData?.Others}
                        />
                    </RadioGroup>
                    {props?.data?.gateSystem === "0" && (
                        <div>
                            {props.data.gateSystemOthers.map((gate, index) => {
                                const currentFile = otherFiles.find((el) => el.id === gate.id);
                                return (
                                    <div key={gate.id} className={classes.inputOthers}>
                                        <StyledInput
                                            disabled={props.disabled}
                                            className={classes.input}
                                            defaultValue={gate.value}
                                            onChange={(e) => updateGateOthersInput(e, index)}
                                            type="text"
                                            required={true}
                                        />
                                        <div>
                                            {currentFile && (
                                                <span
                                                    onClick={() => handleOpenFile(currentFile.fileId, props.toolId + "-specification", currentFile.accessible)}
                                                    className={classes.linkWrapper + " " + (currentFile.accessible && classes.accessibleLink)}
                                                >
                                                    {computeFilename(currentFile.filename)}
                                                </span>
                                            )}{" "}
                                            <Button
                                                disabled={props.disabled}
                                                variant="contained"
                                                component="label"
                                                className={classes.uploadFileButton}
                                                startIcon={currentFile ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                            >
                                                {currentFile ? languageData?.ChangeFile : languageData?.UploadFile}
                                                <input
                                                    type="file"
                                                    required={!currentFile}
                                                    onChange={(e) => uploadFile(e, gate.id, index)}
                                                    className={classes.fileInputWrapper}
                                                />
                                            </Button>
                                        </div>
                                        {props?.data?.gateSystemOthers?.length > 1 && (
                                            <IconButton
                                                disabled={props.disabled}
                                                classes={{ root: classes.root }}
                                                className={classes.addButton}
                                                onClick={() => removeGateOthersInput(index, gate.id)}
                                            >
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                );
                            })}
                            <IconButton
                                disabled={props.disabled}
                                classes={{ root: classes.root }}
                                color="primary"
                                className={classes.removeButton}
                                onClick={() => addGateOthersInput()}
                            >
                                <AddCircleIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RunnerSystem;
