import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import logo from "../../assets/img/logo.png";
import FormLayout from "../../containers/FormLayout/FormLayout";
import StyledInput from "../StyledInput/StyledInput";
import StyledButton from "../StyledButton/StyledButton";
import { Link, useLocation } from "react-router-dom";
import Axios from "axios";
import WebsiteContext from "../../utils/Website";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    form: {
        background: "rgba(255, 255, 255, 0.9)",
        boxShadow: "0px 1.9275px 3.2125px rgba(0, 0, 0, 0.05), 0px 3.2125px 5.14px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
    },
    wrapper: {
        padding: "73px 150px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    image: {
        margin: "0 auto 50px auto",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    lastInput: {
        width: "100%",
        marginBottom: 10,
    },
    forgotPassword: {
        textAlign: "right",
        fontSize: 10,
        lineHeight: "12px",
        color: theme.palette.secondary.main,
        marginBottom: 10,
        cursor: "pointer",
        fontWeight: 500,
        fontStyle: "normal",
    },
    error: {
        color: "red",
        fontSize: 10,
        lineHeight: "12px",
        fontWeight: 500,
        fontStyle: "normal",
    },
    confirmed: {
        color: "green",
        fontSize: 10,
        fontWeight: 500,
        fontStyle: "normal",
    },
    unconfirmed: {
        color: "gray",
        fontSize: 10,
        fontWeight: 500,
        fontStyle: "normal",
    },
    successful: {
        color: "#00A7D1",
        fontSize: 10,
        lineHeight: "12px",
        fontWeight: 500,
        fontStyle: "normal",
    },
}));

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ResetPasswordForm = (props) => {
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [responseError, setResponseError] = useState("");
    const [validation, setValidation] = useState(null);
    const [okResponse, setOkResponse] = useState("");
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

    const changeViewMode = (type) => {
        if (type === "password") {
            setViewPassword(!viewPassword);
        } else {
            setViewConfirmPassword(!viewConfirmPassword);
        }
    };

    const { languageData } = useContext(WebsiteContext);

    const classes = useStyles(useStyles);
    const query = useQuery();

    const upperCaseRegex = new RegExp("(?=.*[A-Z])");
    const lowerCaseRegex = new RegExp("(?=.*[a-z])");
    const digitRegex = new RegExp("(?=.*[0-9])");
    const specialCharRegex = new RegExp("(?=.[~`!@#$%^&*()_\\-+={\\[}\\]|:;\"'<,>.?/])");

    const checkPasswords = () => {
        setResponseError("");
        if (password !== confirmPassword) setError("Passwords don't match...");
        else setError("");
    };

    useEffect(() => {
        if (confirmPassword) {
            checkPasswords();
        }
    }, [password, confirmPassword]);

    const handleSubmit = () => {
        if (
            validation &&
            validation.upperCaseValidation.valid &&
            validation.lowerCaseValidation.valid &&
            validation.digitValidation.valid &&
            validation.specialCharValidation.valid &&
            validation.lengthValidation.valid
        ) {
            const data = {
                email: query.get("email").replace(" ", "+"),
                code: query.get("code"),
                new_password: password,
            };
            const options = {
                url: "/user/reset-password",
                method: "PUT",
                data: data,
            };
            Axios(options)
                .then(() => {
                    setOkResponse(languageData.ResetPasswordConfirmation);
                    // Axios({
                    //   url: "/auth/login",
                    //   method: "POST",
                    //   data: {
                    //     email: query.get("email"),
                    //     password: password,
                    //   },
                    // }).then((response) => {
                    //   Axios.defaults.headers.Authorization = `Bearer ${response.data.data.accessToken}`;
                    //   localStorage.setItem("accessToken", response.data.data.accessToken);
                    //   localStorage.setItem(
                    //     "refreshToken",
                    //     response.data.data.refreshToken
                    //   );
                    //   localStorage.setItem("name", response.data.data.userName);
                    //   localStorage.setItem("role", data.role);
                    //   localStorage.setItem("language", data.language);
                    //   if (response.data.data.role === 0) {
                    //     props.history.push("/");
                    //   } else if (response.data.data.role === 1) {
                    //     props.history.push("/customer/projects");
                    //   } else if (response.data.data.role === 2) {
                    //     props.history.push("/tools");
                    //   }
                    // });
                })
                .catch((err) => {
                    if (err?.response?.data?.error?.code === "E9999") {
                        setResponseError("Reset password code has expired!");
                    } else if (err?.response?.data?.error?.code === "E0010") {
                        setResponseError(languageData?.Errors.E0010);
                    } else {
                        setResponseError(languageData?.SomethingWentWrong);
                    }
                });
        }
    };

    const onPasswordChange = (value) => {
        setPassword(value);

        let upperCaseState = false;
        let lowerCaseState = false;
        let digitState = false;
        let specialCharState = false;
        let lengthState = true;

        if (upperCaseRegex.test(value)) {
            upperCaseState = true;
        }
        if (lowerCaseRegex.test(value)) {
            lowerCaseState = true;
        }
        if (digitRegex.test(value)) {
            digitState = true;
        }
        if (specialCharRegex.test(value)) {
            specialCharState = true;
        }
        if (value.length < 8) {
            lengthState = false;
        }

        setValidation({
            upperCaseValidation: {
                valid: upperCaseState,
                message: languageData?.UpperCaseMessage,
            },
            lowerCaseValidation: {
                valid: lowerCaseState,
                message: languageData?.LowerCaseMessage,
            },
            digitValidation: {
                valid: digitState,
                message: languageData?.DigitMessage,
            },
            specialCharValidation: {
                valid: specialCharState,
                message: languageData?.SpecialCharMessage,
            },
            lengthValidation: {
                valid: lengthState,
                message: languageData?.LengthMessage,
            },
        });
    };

    return (
        <div className={classes.form}>
            <div className={classes.wrapper}>
                <img src={logo} alt="Spannagl logo" className={classes.image} />
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={password}
                        label={languageData?.NewPassword}
                        className={classes.input}
                        onChange={(e) => onPasswordChange(e.target.value)}
                        type={viewPassword === false ? "password" : "text"}
                        required={true}
                        showEndIcon={true}
                        endIconTrigger={() => {
                            changeViewMode("password");
                        }}
                    />
                    {validation && (
                        <div style={{ marginBottom: 10 }}>
                            {Object.keys(validation).map((key, value) => {
                                return (
                                    <Grid key={key} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                        <div style={{ width: "10%" }}>
                                            {validation[key].valid === true ? (
                                                <CheckIcon classes={{ root: classes.confirmed }}></CheckIcon>
                                            ) : (
                                                <ClearIcon classes={{ root: classes.unconfirmed }}></ClearIcon>
                                            )}
                                        </div>
                                        <div className={validation[key].valid === true ? classes.confirmed : classes.unconfirmed} style={{ width: "90%" }}>
                                            {validation[key].message}
                                        </div>
                                    </Grid>
                                );
                            })}
                        </div>
                    )}
                    <StyledInput
                        showLabel
                        defaultValue={confirmPassword}
                        label={languageData?.ConfirmPassword}
                        className={!error ? classes.lastInput : classes.input}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type={viewConfirmPassword === false ? "password" : "text"}
                        helperText={error}
                        error={!!error}
                        required={true}
                        showEndIcon={true}
                        endIconTrigger={() => {
                            changeViewMode("confirmPassword");
                        }}
                    />
                    <Link to="/login">
                        <div className={classes.forgotPassword}>Go to login</div>
                    </Link>
                    {responseError && <div className={classes.error}>{responseError}</div>}
                    {okResponse && <div className={classes.successful}>{okResponse}</div>}
                    <StyledButton variant="contained" type="submit" fullWidth>
                        SUBMIT
                    </StyledButton>
                </FormLayout>
            </div>
        </div>
    );
};

export default ResetPasswordForm;
