import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import WebsiteContext from "../../../../utils/Website";
import Axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import StyledButton from "../../../StyledButton/StyledButton";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    dialogContentWrapper: {
        padding: 0,
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    selectTemplate: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
    },
    shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        transformOrigin: "top left",
    },
    saveButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    deleteButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    globalError: {
        color: "red",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    gridListWrapper: {
        height: "auto !important",
    },
    selectWrapper: {
        marginBottom: "10px",
        height: "33px",
        width: "100%",
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
}));

const ToolModal = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role } = useContext(WebsiteContext);

    const handleCloseModal = () => {
        props.onClose();
    };

    const handleStartStep = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.stepNumber}/start`,
            method: "PUT",
        };
        Axios(options).then((response) => {
            props.onClose();
        });
    };

    let buttons = (
        <StyledButton variant="contained" onClick={handleStartStep} fullWidth className={classes.saveButton}>
            {languageData?.Start}
        </StyledButton>
    );

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.selectTemplate}>{languageData?.StartingDFMStep + " " + props.stepNumber}</div>
            <div className={classes.formWrapper}>{buttons}</div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

export default ToolModal;
