import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";

import { Collapse, makeStyles } from "@material-ui/core";

import WebsiteContext from "../../../utils/Website";
import RoutingContext from "../../../utils/RoutingContext";
import AcceptanceExchange from "./AcceptanceExchange";

import { Alert } from "@material-ui/lab";
import ReleaseProcess from "./ReleaseProcess/ReleaseProcess";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import ReleaseProcessIcon from "../../Icons/ReleaseProcessIcon";
import ExtraFilesAcceptance from "./ExtraFilesAcceptance/ExtraFilesAcceptance";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FilesIcon from "../../Icons/FilesIcon";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    padding: {
        padding: 18,
        height: "calc(100% - 67px)",
    },
    messageGrid: {
        height: "80vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "12px",
        textAlign: "center",
    },
    listWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "default",
    },
    innerWrapper: {
        height: "100%",
        overflow: "auto",
    },
    content: {
        marginTop: 28,
        display: "flex",
        padding: "0 16px",
        flexDirection: "column",
    },
    pointerIcons: {
        cursor: "pointer",
    },
    title: {
        display: "flex",
        padding: "0 16px",
        width: "calc(100% - 32px)",
        justifyContent: "space-between",
        padding: "0 16px",
        // cursor: "pointer",
    },
    stepNameWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 10,
    },
    stepName: {
        lineHeight: "24px",
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
    },
    status: {
        display: "flex",
    },
    card: {
        width: "70%",
        margin: "0 auto",
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        padding: "9px 0",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
            width: "calc(100% - 2px)",
        },
    },
    pointerIcons: {
        cursor: "pointer",
    },
    content: {
        padding: "0 16px",
        // width: "calc(100% - 32px)",
        marginTop: 28,
        display: "flex",
        flexDirection: "column",
    },
}));

const Acceptance = (props) => {
    const classes = useStyles();
    const { languageData, role, language } = useContext(WebsiteContext);
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);

    const [releaseProcessOpen, setReleaseProcessOpen] = useState(true);
    const { setRoutingData, setBackPath, setStepData, stepData } = useContext(RoutingContext);

    const [items, setItems] = useState([]);
    const [releaseProcess, setReleaseProcess] = useState([]);

    const getData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}`,
            method: "GET",
            headers: {
                "X-Locale": language,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data.processes;
            setRoutingData();
            setStepData({
                visibleContent: true,
                id: props.match.params.stepNumber,
                toolId: props.match.params.id,
                name: data.name,
                state: data.state,
                canExport: false,
                canClose: false,
            });
            if (role === 0) {
                setBackPath(`/customers/projects/tools/${props.match.params.id}/process`);
            } else if (role === 1) {
                setBackPath(`/project/tools/${props.match.params.id}/process`);
            } else if (role === 2) {
                setBackPath(`/tool/${props.match.params.id}/process`);
            }
        });
        const optionsTwo = {
            url: `/business-logic/tools/${props.match.params.id}/processes/customer-acceptance`,
            method: "GET",
            headers: {
                "X-Locale": language,
            },
        };
        Axios(optionsTwo).then((response) => {
            setItems(response.data.data.items);
            setFiles(response.data.data.files);
            setReleaseProcess(response.data.data.releaseProcess);
        });
    };

    useEffect(() => {
        getData();
    }, [language]);

    return (
        <div>
            {/* {role !== 2 ? ( */}
            <React.Fragment>
                <div className={classes.padding}>
                    <div className={classes.listWrapper}>
                        <div className={classes.innerWrapper}>
                            <AcceptanceExchange
                                {...props}
                                data={items}
                                open={open}
                                setOpen={setOpen}
                                stepData={stepData}
                                setData={setItems}
                                id={props.match.params.id}
                            />
                        </div>
                    </div>
                    <div className={classes.listWrapper}>
                        <div className={classes.innerWrapper}>
                            <div className={classes.card}>
                                <div className={classes.title}>
                                    <div className={classes.stepNameWrapper}>
                                        <FilesIcon fill="#C00D0E" />
                                        <div className={classes.stepName}>{languageData?.Files}</div>
                                    </div>
                                    <div className={classes.status}>
                                        {open !== "files" ? (
                                            <ExpandMoreIcon
                                                className={classes.pointerIcons}
                                                onClick={() => {
                                                    setOpen("files");
                                                }}
                                            />
                                        ) : (
                                            <ExpandLessIcon
                                                className={classes.pointerIcons}
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <Collapse in={open === "files"}>
                                    <div className={classes.content}>
                                        <ExtraFilesAcceptance data={files} setFiles={setFiles} {...props} />
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.title}>
                            <div className={classes.stepNameWrapper}>
                                <ReleaseProcessIcon fill="#0DC08A" />
                                <div className={classes.stepName}>{languageData?.ReleaseProcess}</div>
                            </div>
                            <div className={classes.status}>
                                {open !== "releaseProcess" ? (
                                    <ExpandMoreIcon
                                        className={classes.pointerIcons}
                                        onClick={() => {
                                            setOpen("releaseProcess");
                                        }}
                                    />
                                ) : (
                                    <ExpandLessIcon
                                        className={classes.pointerIcons}
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <Collapse in={open === "releaseProcess"}>
                            <div className={classes.content}>
                                <ReleaseProcess
                                    id={props.match.params.id}
                                    role={role}
                                    setReleaseProcess={setReleaseProcess}
                                    data={releaseProcess}
                                    disabled={role === 2}
                                />
                            </div>
                        </Collapse>
                    </div>
                </div>
            </React.Fragment>
            {/* // ) : (
            //     <div className={classes.messageGrid}>
            //         <Alert severity="warning">{languageData?.UnavailableStep7Info}</Alert>
            //     </div>
            // )} */}
        </div>
    );
};

export default Acceptance;
