import React, { useContext, useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import SaveIcon from "@material-ui/icons/Save";
// import CancelIcon from "@material-ui/icons/Cancel";
import StyledButton from "../../../StyledButton/StyledButton";
import WebsiteContext from "../../../../utils/Website";
import StyledInput from "../../../StyledInput/StyledInput";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        width: "160px",
        marginRight: 16,
        height: "33px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    buttonWrapper: {
        background: "white",
        padding: 18,
    },
    header: {
        background: "#FAFAFA",
        border: "1px solid #ECECEC",
    },
    row: {
        background: "#FFFFFF",
        border: "1px solid #ECECEC",
    },
    cell: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        left: "unset",
    },
    headerCell: {
        color: "#777777",
    },
    tableContainer: {
        height: "100%",
    },
}));

const ChangeHistory = (props) => {
    const classes = useStyles();
    const { languageData } = useContext(WebsiteContext);
    const [newChange, setNewChange] = useState({});
    const [showNewRow, setShowNewRow] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getChangeHistory();
    }, []);

    const getChangeHistory = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/dfm/change-history`,
            method: "GET",
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setData(data);
        });
    };

    const onSave = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/dfm/change-history`,
            method: "POST",
            data: newChange,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            setNewChange({});
            setShowNewRow(!showNewRow);
            getChangeHistory();
        });
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.layoutWrapper}>
                <div className={classes.buttonWrapper}>
                    <StyledButton onClick={() => setShowNewRow(!showNewRow)} className={classes.button} color="primary" variant="contained">
                        {showNewRow ? languageData?.Cancel : languageData?.AddNewChange}
                    </StyledButton>
                    {showNewRow && (
                        <StyledButton form="newChange" type="submit" className={classes.button} color="secondary" variant="contained">
                            {languageData?.Save.toLocaleUpperCase()}
                        </StyledButton>
                    )}
                </div>
                <div className={classes.contentWrapper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.header}>
                                    <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.ChangedProperty}</TableCell>
                                    <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Date}</TableCell>
                                    <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.ChangedInfo}</TableCell>
                                    <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Company}</TableCell>
                                    <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Name}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showNewRow && (
                                    <TableRow className={classes.row} key="new">
                                        {/*<TableCell component="th" scope="row">*/}
                                        {/*    */}
                                        {/*    /!*<IconButton type="submit" form="newChange">*!/*/}
                                        {/*    /!*    <SaveIcon fontSize="small" />*!/*/}
                                        {/*    /!*</IconButton>*!/*/}
                                        {/*    /!*<IconButton*!/*/}
                                        {/*    /!*    onClick={() => {*!/*/}
                                        {/*    /!*        setShowNewRow(false);*!/*/}
                                        {/*    /!*    }}*!/*/}
                                        {/*    /!*>*!/*/}
                                        {/*    /!*    <CancelIcon fontSize="small" />*!/*/}
                                        {/*    /!*</IconButton>*!/*/}
                                        {/*</TableCell>*/}
                                        <TableCell>
                                            <form
                                                id="newChange"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    onSave();
                                                }}
                                            />
                                            <StyledInput
                                                required
                                                defaultValue={newChange?.changedProperty ? newChange?.changedProperty : ""}
                                                onChange={(e) =>
                                                    setNewChange({
                                                        ...newChange,
                                                        changedProperty: e.target.value,
                                                    })
                                                }
                                                type="text"
                                                form="newChange"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="date"
                                                defaultValue={newChange?.date ? newChange?.date : ""}
                                                onChange={(e) => setNewChange({ ...newChange, date: e.target.value })}
                                                required
                                                inputProps={{
                                                    form: "newChange",
                                                    // min: new Date().toISOString().split("T")[0],
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <StyledInput
                                                required
                                                defaultValue={newChange?.changedInfo ? newChange?.changedInfo : ""}
                                                onChange={(e) =>
                                                    setNewChange({
                                                        ...newChange,
                                                        changedInfo: e.target.value,
                                                    })
                                                }
                                                type="text"
                                                form="newChange"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <StyledInput
                                                required
                                                defaultValue={newChange?.company ? newChange?.company : ""}
                                                onChange={(e) =>
                                                    setNewChange({
                                                        ...newChange,
                                                        company: e.target.value,
                                                    })
                                                }
                                                type="text"
                                                form="newChange"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <StyledInput
                                                required
                                                defaultValue={newChange?.name ? newChange?.name : ""}
                                                onChange={(e) => setNewChange({ ...newChange, name: e.target.value })}
                                                type="text"
                                                form="newChange"
                                            />
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row" align="right">
                                            <IconButton type="submit" form="newChange">
                                                <SaveIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setShowNewRow(false);
                                                }}
                                            >
                                                <CancelIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell> */}
                                    </TableRow>
                                )}
                                {data?.map((element) => (
                                    <TableRow className={classes.row} key={element.id}>
                                        <TableCell className={classes.cell}>{element.changedProperty}</TableCell>
                                        <TableCell className={classes.cell}>{element.date.split(" ")[0]}</TableCell>
                                        <TableCell className={classes.cell}>{element.changedInfo}</TableCell>
                                        <TableCell className={classes.cell}>{element.company}</TableCell>
                                        <TableCell className={classes.cell}>{element.name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
};

export default ChangeHistory;
