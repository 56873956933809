import PeopleIcon from "@material-ui/icons/People";
import BuildIcon from "@material-ui/icons/Build";
import NoteIcon from "@material-ui/icons/Note";
import LockIcon from "@material-ui/icons/Lock";
import SecurityIcon from "@material-ui/icons/Security";
import AddAlertIcon from "@material-ui/icons/AddAlert";

import ClientsView from "./views/Clients/Clients";
import ToolMakersView from "./views/ToolMaker/ToolMakers";
import ProjectsView from "./views/Projects/Projects";
import ToolsView from "./views/Tools/Tools";
import ChangePasswordView from "./views/ChangePassword/ChangePassword";
import TestImageEditor from "./views/TestImageEditor/TestImageEditor";
import AdminView from "./views/Admin/Admin";
import Chart from "./components/Chart/Chart";
import ProcessStep from "./views/ProcessSteps/ProcessSteps";
import InjectionReport from "./views/InjectionReport/InjectionReport";
import RemindersView from "./views/Reminders/Reminders";

const dashboardRoutes = [
    {
        path: "/customers/projects/tools/:id/process/step/:stepNumber/injection-report/:injectionNumber",
        name: "Molds",
        icon: NoteIcon,
        component: InjectionReport,
        isOnMenu: false,
    },
    {
        path: "/customers/projects/tools/:id/process/step/:stepNumber",
        name: "Molds",
        icon: NoteIcon,
        component: ProcessStep,
        isOnMenu: false,
    },
    {
        path: "/customers/projects/tools",
        name: "Molds",
        icon: NoteIcon,
        component: ToolsView,
        isOnMenu: false,
    },
    {
        path: "/customers/projects",
        name: "Projects",
        icon: NoteIcon,
        component: ProjectsView,
        isOnMenu: false,
    },
    {
        path: "/customers",
        name: "Clients",
        icon: PeopleIcon,
        component: ClientsView,
        isOnMenu: true,
    },
    {
        path: "/tool-makers",
        name: "Mold Makers",
        icon: BuildIcon,
        component: ToolMakersView,
        isOnMenu: true,
    },
    {
        path: "/user/change-password",
        name: "Change Password",
        icon: LockIcon,
        component: ChangePasswordView,
        isOnMenu: false,
    },
    {
        path: "/test-image",
        name: "Mold Makers",
        icon: BuildIcon,
        component: TestImageEditor,
        isOnMenu: false,
    },
    {
        path: "/admin",
        name: "Admin",
        icon: SecurityIcon,
        component: AdminView,
        isOnMenu: true,
    },
    {
        path: "/test-chart",
        name: "Mold Makers",
        icon: BuildIcon,
        component: Chart,
        isOnMenu: false,
    },
    {
        path: "/reminders/schedule",
        name: "Reminder Settings",
        icon: AddAlertIcon,
        component: RemindersView,
        isOnMenu: true,
    },
];

export default dashboardRoutes;
