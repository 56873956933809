import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import Axios from "axios";
import { useHistory } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import { Button, Grid, Select, Tooltip } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { MoreVert } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import Switch from "@material-ui/core/Switch";

import RegularButton from "../CustomButtons/Button";
import WebsiteContext from "../../utils/Website";
import GenericModal from "../GenericModal";
import LinearProgressWithLabel from "../LinearProgress/LinearProgressWithLabel";
import ProjectModal from "../Clients/Projects/ProjectModal";
import ToolModal from "../Projects/Tools/ToolModal/ToolModal";
import RoutingContext from "../../utils/RoutingContext";

import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export function AccountData(props) {
    const classes = useStyles();

    const [accountNameInitials, setAccountNameInitials] = useState("");
    const [openProfile, setOpenProfile] = React.useState(null);
    const [dropdownClosed, setDropdownClosed] = React.useState(true);
    const { languageData } = useContext(WebsiteContext);
    const history = useHistory();
    const [width, setWidth] = useState(null);

    useEffect(() => {
        const name = localStorage.getItem("name");
        const nameItems = name.split(" ");
        setAccountNameInitials(nameItems[0] ? nameItems[0][0] + (nameItems[1] ? nameItems[1][0] : " ") : "");
    }, []);

    const handleClickProfile = (event) => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
        setDropdownClosed(true);
    };
    const handleCloseProfile = () => {
        if (dropdownClosed) {
            setDropdownClosed(false);
        } else {
            setOpenProfile(null);
        }
    };

    const handleLogout = () => {
        const options = {
            url: "/auth/logout",
            method: "POST",
        };

        Axios(options)
            .then((_) => {
                localStorage.clear();
                history.push("/login");
            })
            .catch((_) => {
                localStorage.clear();
                history.push("/login");
            });
    };

    const handleChangePassword = () => {
        history.push("/user/change-password");
        setOpenProfile(null);
    };

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
    });

    return (
        <div>
            <RegularButton
                color={window.innerWidth > 1249 ? "transparent" : "white"}
                justIcon={window.innerWidth > 1249}
                simple={!(window.innerWidth > 1249)}
                aria-owns={openProfile ? "profile-menu-list-grow" : null}
                aria-haspopup="true"
                onClick={handleClickProfile}
                muiClasses={{ root: classes.accountButtonWrapper }}
                className={classes.buttonLink}
            >
                <div className={classes.accountIcon}>
                    <p>{accountNameInitials}</p>
                    <div className={classes.dot}></div>
                </div>
                <Tooltip title={localStorage.getItem("name")} className={classes.tooltipWrapper}>
                    <p className={classes.linkText}>{localStorage.getItem("name")}</p>
                </Tooltip>
            </RegularButton>
            <Poppers
                open={Boolean(openProfile)}
                anchorEl={openProfile}
                transition
                disablePortal
                className={classNames({ [classes.popperClose]: !openProfile }) + " " + classes.popperNav}
            >
                {({ TransitionProps, placement }) => (
                    <ClickAwayListener onClickAway={handleCloseProfile}>
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list-grow"
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <MenuList role="menu">
                                    {/*<MenuItem*/}
                                    {/*    onClick={handleCloseProfile}*/}
                                    {/*    className={classes.dropdownItem}*/}
                                    {/*>*/}
                                    {/*    Profile*/}
                                    {/*</MenuItem>*/}
                                    {/*<MenuItem*/}
                                    {/*    onClick={handleCloseProfile}*/}
                                    {/*    className={classes.dropdownItem}*/}
                                    {/*>*/}
                                    {/*    Settings*/}
                                    {/*</MenuItem>*/}
                                    {/*<Divider light/>*/}
                                    <MenuItem onClick={handleChangePassword} className={classes.dropdownItem}>
                                        {languageData?.ChangePassword}
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout} className={classes.dropdownItem}>
                                        {languageData?.Logout}
                                    </MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Poppers>
        </div>
    );
}

export default function AdminNavbarLinks(props) {
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { languageData, role } = useContext(WebsiteContext);
    const { customerData, projectData, toolData, entityPath, backPath, stepData, setStepData } = useContext(RoutingContext);
    const location = useLocation();

    const handleBack = () => {
        props.history.push(backPath);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleEditMode = () => {
        handleOpenModal();
    };

    const handleCloseStep = () => {
        stepData.closeStep();
    };

    const handleDeleteButton = () => {
        setDeleteModal(true);
    };

    const handleAccept = () => {
        const options = {
            url: entityPath,
            method: "DELETE",
        };

        Axios(options).then((_) => {
            setDeleteModal(false);
            props.history.push(backPath);
            // window.location.reload();
        });
    };

    const handleDecline = () => {
        setDeleteModal(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const refresh = () => {
        window.location.reload();
    };

    return (
        <div className={classes.navbarGrid + (window.innerWidth > 1249 ? " " + classes.navbarShadow : "")}>
            <div className={classes.manager}>
                <div className={classes.extendedNavData} implementation="css">
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.navbarDataWrapper}>
                        <div className={classes.gridStyle}>
                            {customerData.visibleContent || projectData.visibleContent || toolData.visibleContent || stepData.visibleContent ? (
                                <Grid container direction="row" alignItems="center" className={classes.gridStyle}>
                                    <RegularButton className={classes.backButton} onClick={handleBack}>
                                        <ArrowBackIosIcon className={classes.backIcon} />
                                    </RegularButton>
                                    {customerData.visibleContent ? (
                                        <div>{customerData.logo !== "" ? <img className={classes.logo} src={customerData.logo} alt="" /> : null}</div>
                                    ) : projectData.visibleContent ? (
                                        <Tooltip title={languageData?.ProjectNumber + ": " + projectData.number} className={classes.tooltipWrapper}>
                                            <div className={classes.headerTitle}>
                                                {languageData?.ProjectNumber}: {projectData.number}
                                            </div>
                                        </Tooltip>
                                    ) : toolData.visibleContent ? (
                                        <Tooltip title={toolData.name} className={classes.tooltipWrapper}>
                                            <div className={classes.headerTitle}>{toolData.name}</div>
                                        </Tooltip>
                                    ) : stepData.visibleContent ? (
                                        <Tooltip title={stepData.name} className={classes.tooltipWrapper}>
                                            <div className={classes.headerTitle}>{stepData.name}</div>
                                        </Tooltip>
                                    ) : null}
                                    {(customerData.visibleContent && customerData.logo !== "") ||
                                    projectData.visibleContent ||
                                    (toolData.visibleContent && toolData.tool_maker_name !== "") ||
                                    stepData.visibleContent ? (
                                        <Divider orientation="vertical" className={classes.divider} />
                                    ) : null}
                                    <div
                                        style={{
                                            marginLeft:
                                                (customerData.visibleContent && customerData.logo !== "") ||
                                                projectData.visibleContent ||
                                                toolData.visibleContent ||
                                                stepData.visibleContent
                                                    ? 0
                                                    : 20,
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                customerData.visibleContent
                                                    ? customerData.name
                                                    : projectData.visibleContent
                                                    ? projectData.name
                                                    : toolData.visibleContent
                                                    ? toolData.tool_maker_name
                                                    : stepData.visibleContent && stepData.canClose === false
                                                    ? stepData.state.name
                                                    : ""
                                            }
                                            className={classes.tooltipWrapper}
                                        >
                                            <div
                                                className={customerData.visibleContent ? classes.nameStyle : classes.headerText}
                                                style={{
                                                    color: stepData.visibleContent && stepData.state.color,
                                                }}
                                            >
                                                {customerData.visibleContent
                                                    ? customerData.name
                                                    : projectData.visibleContent
                                                    ? projectData.name
                                                    : toolData.visibleContent
                                                    ? toolData.tool_maker_name
                                                    : stepData.visibleContent && stepData.canClose === false
                                                    ? stepData.state.name
                                                    : null}
                                            </div>
                                        </Tooltip>
                                        <Tooltip title={customerData.visibleContent ? customerData.address : ""} className={classes.tooltipWrapper}>
                                            <div className={classes.addressStyle}>{customerData.visibleContent ? customerData.address : null}</div>
                                        </Tooltip>
                                    </div>
                                    {projectData.visibleContent || toolData.visibleContent || customerData.visibleContent ? (
                                        <Divider orientation="vertical" className={classes.divider} />
                                    ) : null}
                                    {projectData.visibleContent ? (
                                        <Tooltip title={languageData?.CustomerName + ": " + projectData.customerName} className={classes.tooltipWrapper}>
                                            <div className={classes.headerText}>
                                                {languageData?.CustomerName}: {projectData.customerName}
                                            </div>
                                        </Tooltip>
                                    ) : null}
                                    {projectData.visibleContent ? <Divider orientation="vertical" className={classes.divider} /> : null}
                                    {projectData.visibleContent || toolData.visibleContent ? (
                                        <LinearProgressWithLabel
                                            style={{ backgroundColor: "#ECECEC" }}
                                            width={100}
                                            value={projectData.visibleContent ? projectData.progress : toolData.visibleContent ? toolData.progress : null}
                                            statecolor={
                                                projectData.visibleContent ? projectData.stateColor : toolData.visibleContent ? toolData.stateColor : null
                                            }
                                        />
                                    ) : null}
                                    {projectData.visibleContent || toolData.visibleContent ? (
                                        <Divider orientation="vertical" className={classes.divider} />
                                    ) : null}
                                    {role === 0 && (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            {projectData.visibleContent || toolData.visibleContent || customerData.visibleContent ? (
                                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                    <Button onClick={handleEditMode} className={classes.changeEntityButton}>
                                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                                            <EditIcon className={classes.buttonIcon} />
                                                            <div className={classes.buttonText}>{languageData?.Edit}</div>
                                                        </Grid>
                                                    </Button>
                                                    <Button onClick={handleDeleteButton} className={classes.changeEntityButton}>
                                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                                            <DeleteIcon className={classes.buttonIcon} />
                                                            <div className={classes.buttonText}>{languageData?.Delete}</div>
                                                        </Grid>
                                                    </Button>
                                                </Grid>
                                            ) : null}
                                            {stepData.visibleContent && stepData.canClose === true && role === 0 ? (
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                    }}
                                                    classes={{
                                                        root: classes.rootWrapper,
                                                        selectMenu: classes.selectMenuWrapper,
                                                    }}
                                                    className={classes.selectWrapper}
                                                    variant={"outlined"}
                                                    value={stepData.state.id}
                                                    onChange={(e) => stepData.closeStep(e.target.value)}
                                                >
                                                    {stepData.states.map((state) => {
                                                        return (
                                                            <MenuItem disabled={state.id === 0} key={state.id} value={state.id}>
                                                                <div className={classes.selectTextWrapper} style={{ color: state.color }}>
                                                                    {state.name}
                                                                </div>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            ) : null}
                                            {stepData.visibleContent && (stepData.canClose === true || stepData.visible !== undefined) && (
                                                <Divider orientation="vertical" className={classes.divider} />
                                            )}
                                            {stepData.visibleContent && stepData.canExport === true ? (
                                                <Button onClick={stepData.exportDFM} className={classes.changeEntityButton}>
                                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                                        <div className={classes.buttonText}>{languageData?.ExportDFM}</div>
                                                    </Grid>
                                                </Button>
                                            ) : null}
                                            {stepData.visibleContent && stepData.exportFileId && stepData.canExport === true ? (
                                                <Button onClick={stepData.viewDFM} className={classes.changeEntityButton}>
                                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                                        <div className={classes.buttonText}>View DFM</div>
                                                    </Grid>
                                                </Button>
                                            ) : null}
                                            {stepData.visibleContent && stepData.visible !== undefined && stepData.visible !== null && (
                                                <div className={classes.textWrapper}>{languageData?.VisibleForCustomer}</div>
                                            )}
                                            {stepData.visibleContent && stepData.visible !== undefined && stepData.visible !== null && (
                                                <div className={classes.visibleSwitch}>
                                                    <Switch
                                                        checked={stepData.visible === true}
                                                        color="primary"
                                                        onChange={(e) => stepData.changeVisibility(e.target.checked)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Grid>
                            ) : location?.pathname.match() === "/reminders" ||
                              location?.pathname === "/reminders/schedule" ||
                              RegExp("^/" + "reminders/schedule" + "/.*[0-9]").test(location?.pathname) ||
                              location?.pathname === "/reminders/schedule/add" ? (
                                <div className={classes.title}>{languageData?.ReminderTitle}</div>
                            ) : null}
                        </div>
                        <div className={classes.gridStyle}>
                            <AccountData></AccountData>
                        </div>
                    </Grid>
                </div>
                <Hidden className={classes.collapsedNavData} implementation="css">
                    {customerData.visibleContent || projectData.visibleContent || toolData.visibleContent || stepData.visibleContent ? (
                        <Grid container direction="row" alignItems="center" className={classes.moreVertWrapper}>
                            <div>
                                <RegularButton className={classes.smallBackButton} onClick={handleBack}>
                                    <ArrowBackIosIcon className={classes.backIcon} />
                                </RegularButton>
                            </div>
                            <div className={classes.headerTitleWrapper}>
                                <Tooltip
                                    title={
                                        customerData.visibleContent
                                            ? customerData.name
                                            : projectData.visibleContent
                                            ? projectData.name
                                            : toolData.visibleContent
                                            ? toolData.name
                                            : stepData.visibleContent
                                            ? stepData.name
                                            : ""
                                    }
                                    className={classes.tooltipWrapper}
                                >
                                    <div className={customerData.visibleContent ? classes.nameStyle : classes.headerText}>
                                        {customerData.visibleContent
                                            ? customerData.name
                                            : projectData.visibleContent
                                            ? projectData.name
                                            : toolData.visibleContent
                                            ? toolData.name
                                            : stepData.visibleContent
                                            ? stepData.name
                                            : null}
                                    </div>
                                </Tooltip>
                                <Tooltip title={customerData.visibleContent ? customerData.address : ""} className={classes.tooltipWrapper}>
                                    <div className={classes.addressStyle}>{customerData.visibleContent ? customerData.address : null}</div>
                                </Tooltip>
                            </div>
                            <div>
                                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                    <MoreVert></MoreVert>
                                </Button>
                                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                                    {projectData.visibleContent ? (
                                        <MenuItem onClick={handleClose}>
                                            <Tooltip title={languageData?.ProjectNumber + ": " + projectData.number} className={classes.tooltipWrapper}>
                                                <div className={classes.moreVertHeaderTitle}>
                                                    {languageData?.ProjectNumber}: {projectData.number}
                                                </div>
                                            </Tooltip>
                                        </MenuItem>
                                    ) : null}
                                    <MenuItem onClick={handleClose}>
                                        <div>
                                            <Tooltip
                                                title={
                                                    customerData.visibleContent
                                                        ? customerData.name
                                                        : projectData.visibleContent
                                                        ? projectData.name
                                                        : toolData.visibleContent
                                                        ? toolData.tool_maker_name
                                                        : stepData.visibleContent && stepData.canClose === false
                                                        ? stepData.state.name
                                                        : ""
                                                }
                                                className={classes.tooltipWrapper}
                                            >
                                                <div
                                                    className={customerData.visibleContent ? classes.nameStyle : classes.moreVertHeaderText}
                                                    style={{
                                                        color: stepData.visibleContent && stepData.state.color,
                                                    }}
                                                >
                                                    {customerData.visibleContent
                                                        ? customerData.name
                                                        : projectData.visibleContent
                                                        ? projectData.name
                                                        : toolData.visibleContent
                                                        ? toolData.tool_maker_name
                                                        : stepData.visibleContent && stepData.canClose === false
                                                        ? stepData.state.name
                                                        : null}
                                                </div>
                                            </Tooltip>
                                            <Tooltip title={customerData.visibleContent ? customerData.address : ""} className={classes.tooltipWrapper}>
                                                <div className={classes.addressStyle}>{customerData.visibleContent ? customerData.address : null}</div>
                                            </Tooltip>
                                        </div>
                                    </MenuItem>
                                    {projectData.visibleContent ? (
                                        <MenuItem onClick={handleClose}>
                                            <Tooltip title={languageData?.CustomerName + ": " + projectData.customerName} className={classes.tooltipWrapper}>
                                                <div className={classes.moreVertHeaderText}>
                                                    {languageData?.CustomerName}: {projectData.customerName}
                                                </div>
                                            </Tooltip>
                                        </MenuItem>
                                    ) : null}
                                    {projectData.visibleContent || toolData.visibleContent ? (
                                        <MenuItem onClick={handleClose}>
                                            {projectData.visibleContent || toolData.visibleContent ? (
                                                <LinearProgressWithLabel
                                                    style={{
                                                        backgroundColor: "#ECECEC",
                                                        marginTop: "10px",
                                                    }}
                                                    width={100}
                                                    value={
                                                        projectData.visibleContent ? projectData.progress : toolData.visibleContent ? toolData.progress : null
                                                    }
                                                    statecolor={
                                                        projectData.visibleContent
                                                            ? projectData.stateColor
                                                            : toolData.visibleContent
                                                            ? toolData.stateColor
                                                            : null
                                                    }
                                                />
                                            ) : null}
                                        </MenuItem>
                                    ) : null}
                                    {role === 0 && (
                                        <MenuItem onClick={handleClose}>
                                            <div>
                                                {projectData.visibleContent || toolData.visibleContent || customerData.visibleContent ? (
                                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                        <Button onClick={handleEditMode} className={classes.changeEntityButton}>
                                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                <EditIcon className={classes.buttonIcon} />
                                                                <div className={classes.buttonText}>{languageData?.Edit}</div>
                                                            </Grid>
                                                        </Button>
                                                        <Button onClick={handleDeleteButton} className={classes.changeEntityButton}>
                                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                <DeleteIcon className={classes.buttonIcon} />
                                                                <div className={classes.buttonText}>{languageData?.Delete}</div>
                                                            </Grid>
                                                        </Button>
                                                    </Grid>
                                                ) : null}
                                                {stepData.visibleContent && stepData.canClose && role === 0 ? (
                                                    <Select
                                                        MenuProps={{
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "left",
                                                            },
                                                            getContentAnchorEl: null,
                                                        }}
                                                        classes={{
                                                            root: classes.rootWrapper,
                                                            selectMenu: classes.selectMenuWrapper,
                                                        }}
                                                        className={classes.selectWrapper}
                                                        variant={"outlined"}
                                                        value={stepData.state.id}
                                                        onChange={(e) => stepData.closeStep(e.target.value)}
                                                    >
                                                        {stepData.states.map((state) => {
                                                            return (
                                                                <MenuItem disabled={state.id === 0} key={state.id} value={state.id}>
                                                                    <div className={classes.selectTextWrapper} style={{ color: state.color }}>
                                                                        {state.name}
                                                                    </div>
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                ) : null}
                                                {stepData.visibleContent && stepData.canExport === true ? (
                                                    <Button onClick={stepData.exportDFM} className={classes.changeEntityButton}>
                                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                                            <div className={classes.buttonText}>{languageData?.ExportDFM}</div>
                                                        </Grid>
                                                    </Button>
                                                ) : null}
                                                {stepData.visibleContent && stepData.exportFileId && stepData.canExport === true ? (
                                                    <Button onClick={stepData.viewDFM} className={classes.changeEntityButton}>
                                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                                            <div className={classes.buttonText}>{languageData?.ViewDFM}</div>
                                                        </Grid>
                                                    </Button>
                                                ) : null}
                                                {stepData.visibleContent && stepData.visible !== undefined && stepData.visible !== null && (
                                                    <div className={classes.textWrapper}>{languageData?.VisibleForCustomer}</div>
                                                )}
                                                {stepData.visibleContent && stepData.visible !== undefined && stepData.visible !== null && (
                                                    <div className={classes.visibleSwitch}>
                                                        <Switch
                                                            checked={stepData.visible === true}
                                                            color="primary"
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                stepData.changeVisibility(e.target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </MenuItem>
                                    )}
                                </Menu>
                            </div>
                        </Grid>
                    ) : null}
                </Hidden>
            </div>
            {customerData.visibleContent ? (
                <GenericModal
                    {...props}
                    addMode={false}
                    open={openModal}
                    name={customerData.name}
                    address={customerData.address}
                    logo={customerData.logo}
                    onClose={handleCloseModal}
                    getData={refresh}
                />
            ) : null}
            {projectData.visibleContent ? (
                <ProjectModal
                    {...props}
                    addMode={false}
                    open={openModal}
                    name={projectData.name}
                    projectNo={projectData.number}
                    onClose={handleCloseModal}
                    getData={refresh}
                />
            ) : null}
            {toolData.visibleContent ? (
                <ToolModal
                    {...props}
                    name={toolData.name}
                    description={toolData.description}
                    startDate={toolData.startDate}
                    addMode={false}
                    open={openModal}
                    onClose={handleCloseModal}
                />
            ) : null}
            <Dialog open={deleteModal} onClose={handleDecline} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{languageData?.DeleteMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDecline} color="primary">
                        {languageData?.No}
                    </Button>
                    <Button onClick={handleAccept} color="primary" autoFocus>
                        {languageData?.Yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
