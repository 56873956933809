import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import WebsiteContext from "../../../utils/Website";

const DeleteModal = ({ open, onCancel, onDelete }) => {
    const { languageData } = useContext(WebsiteContext);

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{languageData?.DeleteGeneral}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {languageData?.No}
                </Button>
                <Button onClick={onDelete} color="primary">
                    {languageData?.Yes}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteModal;
