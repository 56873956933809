import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import { Button, makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import StyledInput from "../../../StyledInput/StyledInput";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: 15,
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    textFieldWrapper: {
        width: 130,
        marginTop: 20,
    },
    inputClassName: {
        width: 60,
        height: 36,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    degreeWrapper: {
        marginLeft: "8px",
        marginRight: "80px",
    },
    rowWrapper: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("sm")]: {},
    },
    selectWithLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginRight: "10px",
        marginTop: "4px",
    },
    subtitleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginBottom: 4,
    },
    selectWrapper: {
        width: "210px",
        height: "33px",
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    inputWrapper: {
        padding: "10px 14px",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
        marginBottom: 4,
    },
    contentMargin: {
        marginTop: 4,
        [theme.breakpoints.down(500)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    checkboxWrapper: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
        margin: 5,
    },
    checked: {},
    textWithUnitOfMeasurement: {
        display: "flex",
        alignItems: "center",
    },
    lastItem: {
        marginBottom: 15,
    },
}));

const Cooling = (props) => {
    const [isolationPlate, setIsolationPlate] = useState([
        {
            id: 1,
            key: "coreSide",
            checked: false,
        },
        {
            id: 2,
            key: "cavitySide",
            checked: false,
        },
    ]);

    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    useEffect(() => {
        if (props.data) {
            const dataToView = [...isolationPlate];
            props.data.isolationPlate.forEach((option) => {
                dataToView.find((el) => el.id === Number(option)).checked = true;
            });
            setIsolationPlate(dataToView);
        }
    }, [props.data]);

    const handleChangeCheckbox = (id, value) => {
        const data = [...isolationPlate];
        data.find((el) => el.id === id).checked = value;
        props.updateCooling(
            "cooling",
            "isolationPlate",
            isolationPlate.filter((el) => el.checked === true).map((el) => el.id)
        );
        setIsolationPlate(data);
    };

    const handleOpenGeneralGMS = () => {
        if (role === 0) {
            window.open(window.location.origin + "/customers/" + props.customerId + "/gts", "_blank", "noopener,noreferrer");
        } else if (role === 1) {
            window.open(window.location.origin + "/customer/gts", "_blank", "noopener,noreferrer");
        } else {
            window.open(window.location.origin + "/tool/" + props.toolId + "/gms", "_blank", "noopener,noreferrer");
        }
    };

    const handleOpenHighTemperatureToolGMS = () => {
        if (role === 0) {
            window.open(window.location.origin + "/customers/" + props.customerId + "/gts", "_blank", "noopener,noreferrer");
        } else if (role === 1) {
            window.open(window.location.origin + "/customer/gts", "_blank", "noopener,noreferrer");
        } else {
            window.open(window.location.origin + "/tool/" + props.toolId + "/gms", "_blank", "noopener,noreferrer");
        }
    };

    const coolingMediumTypes = [
        {
            id: 1,
            key: "Oil",
        },
        {
            id: 2,
            key: "Water",
        },
    ];

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Cooling.toUpperCase()}</div>
            <div className={classes.contentWrapper}>
                <div className={classes.contentMargin}>
                    <Button
                        variant="contained"
                        component="label"
                        className={classes.uploadFileButton}
                        startIcon={<OpenInNewIcon color="primary" />}
                        onClick={handleOpenGeneralGMS}
                    >
                        {languageData?.OpenCustomerGMS}
                    </Button>
                </div>
                <div className={classes.rowWrapper}>
                    <div>
                        <InputLabel className={classes.labelWrapper}>{languageData?.TemperatureCavitySide}</InputLabel>
                        <div className={classes.textWithUnitOfMeasurement}>
                            <StyledInput
                                disabled={props.disabled}
                                // required
                                defaultValue={props?.data?.temperatureCavitySide ? props.data.temperatureCavitySide : ""}
                                inputClassName={classes.inputClassName}
                                onChange={(e) => props.updateCooling("cooling", "temperatureCavitySide", e.target.value)}
                                type="number"
                            />
                            <div className={classes.textWrapper + " " + classes.degreeWrapper}>°C</div>
                        </div>
                    </div>
                    <div>
                        <InputLabel className={classes.labelWrapper}>{languageData?.TemperatureCoreSide}</InputLabel>
                        <div className={classes.textWithUnitOfMeasurement}>
                            <StyledInput
                                disabled={props.disabled}
                                // required
                                defaultValue={props?.data?.temperatureCoreSide ? props.data.temperatureCoreSide : ""}
                                inputClassName={classes.inputClassName}
                                onChange={(e) => props.updateCooling("cooling", "temperatureCoreSide", e.target.value)}
                                type="number"
                            />
                            <div className={classes.textWrapper + " " + classes.degreeWrapper}>°C</div>
                        </div>
                    </div>
                    <div>
                        <InputLabel className={classes.labelWrapper}>{languageData?.IsolationPlate}</InputLabel>
                        <div className={classes.contentWrapper}>
                            {isolationPlate.map((option) => (
                                <FormControlLabel
                                    disabled={props.disabled}
                                    key={option.id}
                                    classes={{ label: classes.textWrapper }}
                                    control={
                                        <Checkbox
                                            checked={option.checked}
                                            className={classes.checkboxWrapper}
                                            classes={{ checked: classes.checked }}
                                            onChange={(e) => handleChangeCheckbox(option.id, e.target.checked)}
                                        />
                                    }
                                    label={languageData[option.key]}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className={classes.selectWithLabel}>
                    <InputLabel className={classes.subtitleWrapper}>{languageData?.CoolingMedium}</InputLabel>
                    <FormControl required>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={props?.data?.coolingMediumId ? props.data.coolingMediumId : 2}
                            displayEmpty={props?.data?.coolingMediumId === ""}
                            renderValue={
                                props?.data?.coolingMediumId === ""
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateCooling("cooling", "coolingMediumId", e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {coolingMediumTypes.map((type) => {
                                return (
                                    <MenuItem value={type.id} key={type.id}>
                                        <div className={classes.selectTextWrapper}>{languageData[type.key]}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.lastItem}>
                    <div className={classes.labelWrapper}>{languageData?.HighTemperatureTool}</div>
                    <div className={classes.contentMargin}>
                        <Button
                            variant="contained"
                            component="label"
                            className={classes.uploadFileButton}
                            startIcon={<OpenInNewIcon color="primary" />}
                            onClick={handleOpenHighTemperatureToolGMS}
                        >
                            {languageData?.OpenCustomerGMS}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

Cooling.propTypes = {
    updateCooling: PropTypes.func,
    data: PropTypes.any,
};

export default Cooling;
