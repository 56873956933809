import React from "react";
import { Helmet } from "react-helmet";
import ToolMaker from "../../components/ToolMakers/ToolMaker";

const ToolMakerView = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | ToolMaker</title>
            </Helmet>
            <ToolMaker />
        </React.Fragment>
    );
};

export default ToolMakerView;
