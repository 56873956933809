import React, { useCallback, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Card, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import WebsiteContext from "../../../utils/Website";
import UserModal from "./UserModal";
import Axios from "axios";
import styles from "../../../assets/jss/material-dashboard-react/components/gridStyle";
import SimpleUserEntityCard from "./SimpleUserEntityCard";

const useStyles = makeStyles(styles);

const Users = (props) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [isAddActive, setIsAddActive] = useState(false);
    const [userToEdit, setUserToEdit] = useState("");
    const { languageData } = useContext(WebsiteContext);

    const getUsers = useCallback(() => {
        const options = {
            url: `/users`,
            method: "GET",
            headers: { "X-ROLE": 0 },
        };
        Axios(options).then((response) => {
            setUsers(response.data.data);
        });
    }, [props.match.url]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const handleOpenModal = () => {
        setIsAddActive(true);
        setOpenModal(true);
    };

    const handleCloseModal = (changedUser) => {
        setOpenModal(false);
        if (changedUser) {
            getUsers();
        }
    };

    const HandleOpenEditModal = (user) => {
        setUserToEdit({ ...user });
        setOpenModal(true);
        setIsAddActive(false);
    };

    const userCards = users.map((user) => {
        return (
            <div key={user.id} onClick={() => HandleOpenEditModal(user)}>
                <SimpleUserEntityCard
                    {...props}
                    id={user.id}
                    key={user.id}
                    name={user.name}
                    email={user.email}
                    phoneNumber={user.phone_number}
                    department={user.department}
                />
            </div>
        );
    });

    let modal;
    if (openModal) {
        if (isAddActive) {
            modal = <UserModal {...props} addMode={isAddActive} title={languageData?.AddNewUser} open={openModal} onClose={handleCloseModal} />;
        } else {
            modal = (
                <UserModal
                    {...props}
                    id={userToEdit.id}
                    name={userToEdit.name}
                    email={userToEdit.email}
                    phoneNumber={userToEdit.phone_number}
                    department={userToEdit.department}
                    addMode={isAddActive}
                    title={languageData?.EditUser}
                    open={openModal}
                    onClose={handleCloseModal}
                />
            );
        }
    }

    return (
        <React.Fragment>
            <div className={classes.tabCardsWrapper}>
                <Card classes={{ root: classes.root }}>
                    <div className={classes.cardWrapper}>
                        <div className={classes.addNew}>{languageData?.AddNewUser}</div>
                        <IconButton className={classes.icon} onClick={handleOpenModal}>
                            <AddCircleIcon color="primary" fontSize="inherit" />
                        </IconButton>
                    </div>
                </Card>
                {userCards}
            </div>
            {modal}
        </React.Fragment>
    );
};

export default withWidth()(Users);
