import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Card, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import ToolModal from "./ToolModal/ToolModal";
import ProductionToolEntityCard from "./ToolCard/ProductionToolEntityCard";
import QuotationRequestEntityCard from "./ToolCard/QuotationRequestEntityCard";

import WebsiteContext from "../../../utils/Website";
import RoutingContext from "../../../utils/RoutingContext";

import styles from "../../../assets/jss/material-dashboard-react/components/gridStyle";

const useStyles = makeStyles(styles);

const Tools = (props) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [tools, setTools] = useState([]);
    const [isAddActive, setIsAddActive] = useState(false);
    const [projectId, setProjectId] = useState("");
    const { languageData, role } = useContext(WebsiteContext);
    const { setRoutingData } = useContext(RoutingContext);

    const stateToCardMapper = {
        QUOTATION_REQUEST: {
            card: QuotationRequestEntityCard,
            path: "/quotation-request",
        },
        PRODUCTION: {
            card: ProductionToolEntityCard,
            path: "/info",
        },
    };

    const getTools = () => {
        let options = null;
        if (role === 1) {
            options = {
                url: `/customers/projects/${props.match.params.id}/tools`,
                method: "GET",
            };
        } else if (role === 2) {
            options = {
                url: "/tools",
                method: "GET",
            };
        } else {
            setProjectId(props.match.url.split("/")[2]);
            options = {
                url: props.match.url,
                method: "GET",
            };
        }

        if (options !== null) {
            Axios(options).then((response) => {
                setTools(response.data.data);

                if (role === 2) {
                    setRoutingData();
                }
            });
        }
    };

    useEffect(() => {
        getTools();
    }, []);

    const handleOpenModal = () => {
        setIsAddActive(true);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        getTools();
    };

    const toolCards = tools.map((tool) => {
        const toolCard = stateToCardMapper[tool.state];
        return (
            <toolCard.card
                {...props}
                key={tool.id}
                id={tool.id}
                name={tool.name}
                progress={tool.customerGanttChartsPercentage}
                description={tool.description}
                statecolor={tool.customerGanttChartsState.color}
                state={languageData?.MoldStates[tool.state]}
                path={toolCard.path}
            />
        );
    });

    let modal;
    if (openModal) {
        if (isAddActive) {
            modal = <ToolModal {...props} addMode={isAddActive} open={openModal} projectId={projectId} onClose={handleCloseModal} />;
        }
    }

    return (
        <div className={classes.grid}>
            <div className={role === 2 ? classes.toolCardWrapper : classes.tabCardsWrapper}>
                {role === 0 && (
                    <Card classes={{ root: classes.root }}>
                        <div className={classes.cardWrapper}>
                            <div className={classes.addNew}>{languageData?.AddNewTool}</div>
                            <IconButton className={classes.icon} onClick={handleOpenModal}>
                                <AddCircleIcon color="primary" fontSize="inherit" />
                            </IconButton>
                        </div>
                    </Card>
                )}
                {toolCards}
            </div>
            {modal}
        </div>
    );
};

export default withWidth()(Tools);
