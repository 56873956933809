import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import styles from "../../assets/jss/material-dashboard-react/components/gridStyle";
import ToolView from "../../views/Tools/Tool";
import WebsiteContext from "../../utils/Website";

const useStyles = makeStyles(styles);

const Tools = (props) => {
    const { role } = useContext(WebsiteContext);
    return (
        <Switch>
            <Route path={`${props.match.url}/:id/quotation-request`} render={() => <ToolView />} />
            <Route path={`${props.match.url}/:id/info`} render={() => <ToolView />} />
            <Route path={`${props.match.url}/:id/schedule`} render={() => <ToolView />} />
            <Route path={`${props.match.url}/:id/process`} render={() => <ToolView />} />
            <Route path={`${props.match.url}/:id/tool-specification`} render={() => <ToolView />} />
            <Route path={`${props.match.url}/:id/machine/:machineId`} render={() => <ToolView />} />
            <Route path={`${props.match.url}/:id/gms`} render={() => <ToolView />} />
        </Switch>
    );
};

export default withWidth()(Tools);
