export const newRow = [
    {
        name: "factoryMachineNumber",
        value: "",
    },
    {
        name: "machineBrand",
        value: "",
    },
    {
        name: "machineType",
        value: "",
    },
    {
        name: "machineNr",
        value: "",
    },
    {
        name: "tieBarDistance",
        value: "",
    },
    {
        name: "fixSideFlange",
        value: "",
    },
    {
        name: "mouldHeight",
        value: "",
    },
    {
        name: "machineOpening",
        value: "",
    },
    {
        name: "ejectorStroke",
        value: "",
    },
    {
        name: "clampingPlateDimension",
        value: "",
    },
    {
        name: "clampingForce",
        value: "",
    },
    {
        name: "injectionUnitDiameter",
        value: "",
    },
    {
        name: "maximumShotSize",
        value: "",
    },
    {
        name: "injectionUnitMax",
        value: "",
    },
    {
        name: "injectionUnitDiameter2",
        value: "",
    },
    {
        name: "maximumShotSize2",
        value: "",
    },
    {
        name: "injectionUnitMax2",
        value: "",
    },
    {
        name: "nozzleRadius",
        value: "",
    },
    {
        name: "turnTableBrandAndDiameter",
        value: "",
    },
    {
        name: "yearOfManufacture",
        value: "",
    },
    {
        name: "internalTemperatureControllerFor",
        value: "",
    },
    {
        name: "internalAirControllerForValveGate",
        value: "",
    },
    {
        name: "hydraulicCorePullCircleCavitySide",
        value: "",
    },
    {
        name: "robotSupplier",
        value: "",
    },
    {
        name: "miscellaneous",
        value: "",
    },
];
