import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import RoutingContext from "../../../../utils/RoutingContext";
import styles from "../../../../assets/jss/material-dashboard-react/components/DFMReviewListStyle";
import { Collapse, Divider, Grid, makeStyles } from "@material-ui/core";
import AddNew from "../../Common/AddNew";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddMediaModal from "../../Common/AddMediaModal";
import EditModal from "../../Common/EditModal";
import DeleteModal from "../../Common/DeleteModal";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import StyledButton from "../../../StyledButton/StyledButton";
import { ExpandMoreRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import CoolingLayoutTable from "./CoolingLayoutTable/CoolingLayoutTable";
import StyledInput from "../../../StyledInput/StyledInput";
import FormLayout from "../../../../containers/FormLayout/FormLayout";

const useStyles = makeStyles(styles);

const CoolingLayout = (props) => {
    const [completedColor, setCompletedColor] = useState("#0DC08A");
    const [underReviewColor, setUnderReviewColor] = useState("#E39600");
    const [needsReviewColor, setNeedsReviewColor] = useState("#C00D0E");

    const [open, setOpen] = useState(null);
    const [openUpload, setOpenUpload] = useState("");
    const [openEditor, setOpenEditor] = useState(false);
    const [openAddNew, setOpenAddNew] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [elementToEdit, setElementToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [data, setData] = useState([]);
    const [overviewTable, setOverviewTable] = useState([]);

    const { languageData, role } = useContext(WebsiteContext);
    const { setRoutingData, setStepData, setBackPath, stepData } = useContext(RoutingContext);

    const classes = useStyles({
        completedColor: completedColor,
        underReviewColor: underReviewColor,
        needsReviewColor: needsReviewColor,
        role: role,
    });

    const handleAddItem = (nameDe, nameEn) => {
        const data = {
            de: nameDe,
            en: nameEn,
        };
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts`,
            method: "POST",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            setOpenAddNew(false);
            getData();
        });
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setData(response.data.data);
            const tempArray = [];
            response.data.data.items.forEach((item) => {
                item.table.forEach((tab) => {
                    const newObj = {
                        itemName: item.name,
                    };
                    tempArray.push(Object.assign(newObj, tab));
                });
            });
            setOverviewTable(tempArray);
        });
    };

    const handleDelete = () => {
        const deleteOptions = {
            url: "/file-operations/" + `${props.match.params.id}-mold-trail-fot` + `/files/${elementToDelete.fileId}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(deleteOptions).then((response) => {
            const options = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${open}/media/${elementToDelete.fileId}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((_) => {
                setElementToDelete(null);
                getData();
            });
        });
    };

    const handleCancelDelete = () => {
        setElementToDelete(null);
        setItemToDelete(null);
    };

    const deleteListItem = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${itemToDelete}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            getData();
            setItemToDelete(null);
        });
    };

    const moveMedia = (up, id, mediaId) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${id}/media/${mediaId}`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                up: up,
            },
        };
        Axios(options).then((_) => {
            getData();
        });
    };

    const uploadFile = (file) => {
        let filesFormatData = new FormData();
        filesFormatData.append("files", file, file.name);

        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-trail-fot` + "/files",
            method: "POST",
            data: filesFormatData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            const mediaOptions = {
                url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${openUpload}/media`,
                method: "POST",
                headers: {
                    "X-Loading": false,
                },
                data: {
                    fileId: response.data.data.files[0].id,
                    type: 0,
                },
            };
            Axios(mediaOptions).then((response) => {
                setOpenUpload("");
                getData();
            });
        });
    };

    const saveNewMedia = (formData, edit, fileId) => {
        const options = {
            url: "/file-operations/" + `${props.match.params.id}-mold-trail-fot` + "/files",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            let mediaOptions;
            if (edit === true) {
                mediaOptions = {
                    url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${openUpload}/media/${fileId}`,
                    method: "PUT",
                    headers: {
                        "X-Loading": false,
                    },
                    data: {
                        newFileId: response.data.data.files[0].id,
                    },
                };
            } else {
                mediaOptions = {
                    url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/${openUpload}/media`,
                    method: "POST",
                    headers: {
                        "X-Loading": false,
                    },
                    data: {
                        fileId: response.data.data.files[0].id,
                        type: 0,
                    },
                };
            }
            Axios(mediaOptions).then((response) => {
                setOpenEditor(false);
                setElementToEdit(null);
                setOpenUpload("");
                getData();
            });
        });
    };

    const saveMedia = (formData) => {
        if (!openEditor) {
            const deleteOptions = {
                url: "/file-operations/" + `${props.match.params.id}-mold-trail-fot` + "/files/" + elementToEdit?.fileId,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(deleteOptions).then((_) => {
                saveNewMedia(formData, true, elementToEdit?.fileId);
            });
        } else {
            saveNewMedia(formData, false, elementToEdit?.fileId);
        }
    };

    const changeInput = (index, value) => {
        const tempData = { ...data };
        tempData.overview[index].answer = value;
        setData(tempData);
    };

    const saveOverview = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/overview`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: { overview: data.overview },
        };
        Axios(options).then((_) => {
            getData();
        });
    };

    const copyOverview = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/cooling-layouts/overview`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            getData();
        });
    };

    return (
        <div className={classes.listWrapper + " " + classes.padding}>
            <div className={classes.innerWrapper}>
                <AddNew handleAdd={handleAddItem} open={openAddNew} onClose={() => setOpenAddNew(false)} />
                <AddMediaModal
                    openEditor={() => {
                        setOpenEditor(true);
                    }}
                    open={openUpload !== "" && openEditor === false}
                    onClose={() => setOpenUpload("")}
                    uploadFile={uploadFile}
                />
                <EditModal
                    open={!!(elementToEdit || openEditor)}
                    onClose={() => {
                        setOpenEditor(false);
                        setElementToEdit(null);
                        setOpenUpload("");
                    }}
                    saveMedia={saveMedia}
                    src={
                        openEditor === false
                            ? `${process.env.REACT_APP_URL}file-operations/` + props.match.params.id + "-mold-trail-fot/files/" + elementToEdit?.fileId
                            : ""
                    }
                />
                <DeleteModal
                    open={!!(elementToDelete || itemToDelete)}
                    onCancel={() => handleCancelDelete()}
                    onDelete={itemToDelete ? deleteListItem : handleDelete}
                />
                <div key={-1} className={classes.listElement}>
                    <div className={classes.title}>
                        <div className={classes.stepName}>{languageData?.OverviewCoolingLayout}</div>
                        <div className={classes.status}>
                            {open !== -1 ? (
                                <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === -1 ? null : -1)} />
                            ) : (
                                <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === -1 ? null : -1)} />
                            )}
                        </div>
                    </div>
                    <Collapse in={open === -1}>
                        <div style={{ marginBottom: 10 }}>
                            {(role === 0 || role === 2) && (
                                <div className={classes.buttonWrapper}>
                                    <StyledButton className={classes.button} color="primary" variant="contained" type="submit" onClick={saveOverview}>
                                        {languageData?.Save.toUpperCase()}
                                    </StyledButton>
                                    <StyledButton className={classes.copyButton} color="primary" variant="contained" type="submit" onClick={copyOverview}>
                                        {languageData?.CopyFromSamplingProcess.toUpperCase()}
                                    </StyledButton>
                                </div>
                            )}
                            <div className={classes.contentWrapper}>
                                <Grid item container xs={12} sm={12} md={12}>
                                    {data?.overview?.map((question, questionIndex) => {
                                        return (
                                            <Grid item container xs={12} sm={12} md={4} lg={3} key={questionIndex}>
                                                <div className={classes.sectionWrapper}>
                                                    <div className={classes.questionWrapper}>{languageData && languageData[question.question]}</div>
                                                    <StyledInput
                                                        disabled={role === 1}
                                                        defaultValue={question.answer}
                                                        onChange={(e) => changeInput(questionIndex, e.target.value)}
                                                        className={classes.answerWrapper}
                                                        type="text"
                                                    />
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        </div>
                        <CoolingLayoutTable data={overviewTable} readOnly={true} {...props} coolingLayoutId={-1} getData={getData} />
                        <Divider className={classes.divider} />
                    </Collapse>
                </div>
                {data?.items?.map((element) => (
                    <div key={element.id} className={classes.listElement}>
                        <div className={classes.title}>
                            <div className={classes.stepName}>{element.name}</div>
                            <div className={classes.status}>
                                {/* {element.state === 0 ? (
                                <span className={classes.needsReview}>needs review</span>
                            ) : element.state === 1 ? (
                                <span className={classes.underReview}>under review</span>
                            ) : null} */}
                                {element.canDelete && role === 0 && (
                                    <DeleteIcon onClick={() => setItemToDelete(element.id)} className={classes.pointerIcons + " " + classes.textareaEdit} />
                                )}
                                {open !== element.id ? (
                                    <ExpandMoreIcon className={classes.pointerIcons} onClick={() => setOpen(open === element.id ? null : element.id)} />
                                ) : (
                                    <ExpandLessIcon className={classes.pointerIcons} onClick={() => setOpen(open === element.id ? null : element.id)} />
                                )}
                            </div>
                        </div>
                        <Collapse in={open === element.id}>
                            <div className={classes.content}>
                                {element.media?.map((mediaElement, index) => (
                                    <div key={index} className={classes.mediaElement}>
                                        <div className={classes.flexCard}>
                                            {(role === 0 || role === 2) && stepData.state.id === 1 && (
                                                <div className={classes.positionButtons}>
                                                    {index > 0 && (
                                                        <div className={classes.upDownButton} onClick={() => moveMedia(true, element.id, mediaElement.fileId)}>
                                                            <ExpandLessIcon color="inherit" />
                                                        </div>
                                                    )}
                                                    {index >= 0 && index < element.media?.length && index !== element.media?.length - 1 && (
                                                        <div className={classes.upDownButton} onClick={() => moveMedia(false, element.id, mediaElement.fileId)}>
                                                            <ExpandMoreRounded color="inherit" />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <img
                                                src={`${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-mold-trail-fot/files/${mediaElement.fileId}`}
                                                alt={mediaElement.id}
                                                className={classes.img}
                                            />
                                        </div>
                                        {(role === 0 || role === 2) && stepData.state.id === 1 && (
                                            <div className={classes.actions}>
                                                <StyledButton
                                                    onClick={() => {
                                                        setOpenUpload(element.id);
                                                        setElementToEdit(mediaElement);
                                                    }}
                                                    className={classes.actionButton}
                                                    variant="outlined"
                                                    startIcon={<EditIcon color="primary" />}
                                                >
                                                    {languageData?.Edit}
                                                </StyledButton>
                                                <StyledButton
                                                    onClick={() => setElementToDelete(mediaElement)}
                                                    className={classes.actionButton}
                                                    variant="outlined"
                                                    startIcon={<DeleteIcon color="primary" />}
                                                >
                                                    {languageData?.Delete}
                                                </StyledButton>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {(role === 0 || role === 2) && stepData.state.id === 1 && (
                                    <div className={classes.addMediaButton} onClick={() => setOpenUpload(element.id)}>
                                        <AddCircleIcon className={classes.addMediaIcon} color="primary" />
                                        {languageData?.AddMedia}
                                    </div>
                                )}
                            </div>
                            <CoolingLayoutTable readOnly={false} data={element?.table} {...props} coolingLayoutId={element.id} getData={getData} />
                            <Divider className={classes.divider} />
                        </Collapse>
                    </div>
                ))}
                {(role === 0 || role === 2) && stepData?.state?.id !== 2 && (
                    <div className={classes.listElement + " " + classes.addNewItem}>
                        <div className={classes.title} onClick={() => setOpenAddNew(true)}>
                            <div className={classes.stepName}>
                                <div className={classes.addNew}>
                                    <AddCircleIcon color="inherit" />
                                </div>
                                {languageData?.AddNew}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CoolingLayout;
