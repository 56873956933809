import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import logo from "../../assets/img/logo.png";
import FormLayout from "../../containers/FormLayout/FormLayout";
import StyledInput from "../StyledInput/StyledInput";
import StyledButton from "../StyledButton/StyledButton";
import { Link } from "react-router-dom";
import Axios from "axios";
import WebsiteContext from "../../utils/Website";

const useStyles = makeStyles((theme) => ({
    form: {
        background: "rgba(255, 255, 255, 0.9)",
        boxShadow: "0px 1.9275px 3.2125px rgba(0, 0, 0, 0.05), 0px 3.2125px 5.14px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
    },
    wrapper: {
        padding: "73px 150px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    image: {
        margin: "0 auto 50px auto",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    forgotPassword: {
        textAlign: "right",
        fontSize: 10,
        lineHeight: "12px",
        color: theme.palette.secondary.main,
        marginBottom: 10,
        cursor: "pointer",
        fontWeight: 500,
        fontStyle: "normal",
    },
    error: {
        color: "red",
        fontSize: 10,
        lineHeight: "12px",
        fontWeight: 500,
        fontStyle: "normal",
    },
    successful: {
        color: "#00A7D1",
        fontSize: 10,
        lineHeight: "12px",
        fontWeight: 500,
        fontStyle: "normal",
    },
}));

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [okResponse, setOkResponse] = useState("");
    const [globalError, setGlobalError] = useState("");

    const classes = useStyles(useStyles);

    const { languageData } = useContext(WebsiteContext);

    const handleSubmit = () => {
        const data = {
            email: email,
        };
        const options = {
            url: "/user/reset-password",
            method: "POST",
            data: data,
        };
        Axios(options)
            .then((response) => {
                setOkResponse(languageData.ResetPasswordEmailSentConfirm);
                setEmailError("");
                setGlobalError("");
            })
            .catch((err) => {
                if (err?.response?.data?.error?.code === "E0010") {
                    setOkResponse(languageData.ResetPasswordEmailSentConfirm);
                    setEmailError("");
                    setGlobalError("");
                } else {
                    setOkResponse("");
                    setEmailError("");
                    setGlobalError(err.response.data.error.message || languageData.SomethingWentWrong);
                }
            });
    };

    return (
        <div className={classes.form}>
            <div className={classes.wrapper}>
                <img src={logo} alt="Spannagl logo" className={classes.image} />
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <StyledInput
                        defaultValue={email}
                        showLabel={true}
                        label="Email"
                        className={classes.input}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required={true}
                        helperText={emailError}
                        error={emailError ? true : false}
                    />
                    <Link to="/login">
                        <div className={classes.forgotPassword}>Back to login</div>
                    </Link>
                    {globalError && <div className={classes.error}>{globalError}</div>}
                    {okResponse && <div className={classes.successful}>{okResponse}</div>}
                    <StyledButton variant="contained" type="submit" fullWidth>
                        SUBMIT
                    </StyledButton>
                </FormLayout>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;
