import { Button, GridList, GridListTile, IconButton, makeStyles } from "@material-ui/core";
import StyledInput from "../../../../StyledInput/StyledInput";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React, { useContext, useState } from "react";
import WebsiteContext from "../../../../../utils/Website";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Axios from "axios";
import StyledButton from "../../../../StyledButton/StyledButton";

const useStyles = makeStyles((theme) => ({
    inputs: {
        display: "flex",
        flexDirection: "column",
    },
    addButton: {
        width: "fit-content",
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },

    ulWrapper: {
        listStyleType: "disc",
    },
    liWrapper: {
        "&::marker": {
            color: theme.palette.primary.main,
        },
    },
    textWrapper: {
        display: "inline",
        marginRight: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    filenameWrapper: {
        display: "inline",
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    filenameEditModeWrapper: {
        marginLeft: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
        height: "15p",
        lineHeight: "15px",
        textOverflow: "ellipsis",
    },
    uploadFileButton: {
        backgroundColor: "white",
        margin: "6px 10px 7px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    inputClass: {
        width: "210px",
    },
    collapseExpandAll: {
        backgroundColor: "#F8F8F8",
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        justifyContent: "left",
        height: 28.48,
        width: 150,
        padding: "0 10px",
        margin: "5px",
    },
    expandIcon: {
        margin: "0 8px 0 0",
        // fontFamily: "Montserrat",
        // fontWeight: "600"
    },
    gtsForm: {
        background: "#ffffff",
        width: "100%",
        height: "100%",
        borderRadius: "3px",
        // padding: "24px -24px 24px 24px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
    },
    layoutWrapper: {
        height: "100%",
        paddingBottom: 20,
    },
    form: {
        height: "calc(100% - 77px)",
        paddingTop: 18,
        paddingBottom: -18,
        paddingLeft: 18,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down(680)]: {
            height: "calc(100% - 115px)",
        },
    },
    gtsButtons: {
        background: "#ffffff",
        float: "right",
        borderRadius: "3px",
        display: "flex",
        flexDirection: "column",
        padding: 24,
        width: 150,
    },
    editSwitch: {
        display: "block",
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    treeItemStyle: {
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    listItemStyle: {
        height: "auto",
    },
    root: {
        padding: 0,
    },
    content: {
        padding: "0 18px 18px 18px",
    },
}));

const Media = (props) => {
    const { languageData } = useContext(WebsiteContext);

    const classes = useStyles(useStyles);

    const [files, setFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);

    const changeInput = (index, field, value) => {
        const tmpData = [...props.data];
        tmpData[index][field] = value;
        props.changeObject("media", tmpData);
    };

    const uploadFile = (file, index) => {
        const tmpFiles = [...files];
        const fileIndex = tmpFiles.findIndex((el) => el.index === index);
        if (fileIndex > -1) {
            tmpFiles[fileIndex].file = file;
            tmpFiles[fileIndex].fileToBeDeleted = props.data[index].fileId;
        } else {
            tmpFiles.push({
                file: file,
                index: index,
                fileToBeDeleted: props.data[index].fileId,
            });
        }
        changeInput(index, "fileName", file.name);
        setFiles(tmpFiles);
    };

    const removeInput = (fileId, index) => {
        const tmpFiles = [...files];
        const fileIndex = tmpFiles.findIndex((el) => el.index === index);
        if (fileIndex > -1) {
            tmpFiles.splice(fileIndex, 1);
        }
        tmpFiles.forEach((el) => {
            if (el.index > index) {
                el.index -= 1;
            }
        });
        setFiles(tmpFiles);
        if (fileId !== "") {
            const tmpFilesToDelete = [...filesToDelete];
            tmpFilesToDelete.push(fileId);
            setFilesToDelete(tmpFilesToDelete);

            const tmpData = [...props.data];
            tmpData.splice(index, 1);
            props.changeObject("media", tmpData);
        } else {
            const tmpData = [...props.data];
            tmpData.splice(index, 1);
            props.changeObject("media", tmpData);
        }
    };

    const addInput = () => {
        const tmpData = [...props.data];
        tmpData.push({
            title: "",
            fileName: "",
            fileId: "",
        });
        props.changeObject("media", tmpData);
    };

    const saveMedia = async () => {
        const tmpData = [...props.data];
        filesToDelete.forEach((file) => {
            const options = {
                url:
                    "/file-operations/" +
                    `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` +
                    `/files/${file}`,
                headers: {
                    "X-Loading": false,
                },
                method: "DELETE",
            };
            Axios(options).then((response) => {});
        });

        if (files.length === 0) {
            submitData(props.data);
        } else {
            for (const file of files) {
                const idx = files.findIndex((el) => el === file);
                if (file.fileToBeDeleted !== "") {
                    const deleteOptions = {
                        url:
                            "/file-operations/" +
                            `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` +
                            `/files/${file.fileToBeDeleted}`,
                        method: "DELETE",
                        headers: {
                            "X-Loading": false,
                        },
                    };
                    await Axios(deleteOptions);
                    let filesFormatData = new FormData();
                    filesFormatData.append("files", file.file, file.file.name);

                    const options = {
                        url:
                            "/file-operations/" +
                            `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` +
                            "/files",
                        method: "POST",
                        data: filesFormatData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    const response = await Axios(options);

                    if (response.status === 200) {
                        tmpData[file.index].fileId = response.data.data.files[0].id;
                        tmpData[file.index].fileName = response.data.data.files[0].name;
                        if (idx === files.length - 1) {
                            submitData(tmpData);
                        }
                    }
                } else {
                    let filesFormatData = new FormData();
                    filesFormatData.append("files", file.file, file.file.name);

                    const options = {
                        url:
                            "/file-operations/" +
                            `${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}` +
                            "/files",
                        method: "POST",
                        data: filesFormatData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    const response = await Axios(options);
                    if (response.status === 200) {
                        tmpData[file.index].fileId = response.data.data.files[0].id;
                        tmpData[file.index].fileName = response.data.data.files[0].name;
                        if (idx === files.length - 1) {
                            submitData(tmpData);
                        }
                    }
                }
            }
        }
    };

    const submitData = (tmpData) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${props.match.params.injectionNumber}/media`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: { media: tmpData },
        };
        Axios(options).then((_) => {
            setFiles([]);
            setFilesToDelete([]);
            props.getData();
        });
    };

    const handleClickFilename = (fileId) => {
        window.open(
            `${process.env.REACT_APP_URL}file-operations/${props.match.params.id}-mold-trail-fot-injection-parameter-${props.match.params.injectionNumber}/files/${fileId}`
        );
    };

    return (
        <div className={classes.content}>
            {props.data?.length > 0 && (
                <StyledButton className={classes.button} color="primary" variant="contained" type="submit" onClick={saveMedia}>
                    {languageData?.Save.toUpperCase()}
                </StyledButton>
            )}
            {props.data?.length > 0 &&
                props.data?.map((el, index) => {
                    return (
                        <GridList key={index}>
                            <GridListTile style={{ height: "auto", width: "auto" }}>
                                <StyledInput
                                    className={classes.inputClass}
                                    defaultValue={el.title}
                                    label="text"
                                    onChange={(e) => changeInput(index, "title", e.target.value)}
                                    type="text"
                                    required={true}
                                    multiline={true}
                                    showLabel={false}
                                />
                            </GridListTile>
                            <GridListTile style={{ height: "auto", width: "auto" }}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    className={classes.uploadFileButton}
                                    startIcon={<CloudUploadIcon color="primary" />}
                                >
                                    {el.fileName !== "" ? languageData?.ChangeFile : languageData?.UploadFile}
                                    <input
                                        type="file"
                                        onChange={(e) => uploadFile(e.target.files[0], index)}
                                        onClick={(e) => {
                                            e.target.value = "";
                                        }}
                                        style={{ display: "none" }}
                                    />
                                </Button>
                            </GridListTile>
                            <GridListTile style={{ height: "auto" }}>
                                <GridList cols={12} style={{ height: "auto", alignItems: "center", flex: 1 }}>
                                    <GridListTile cols={8} style={{ height: "auto" }}>
                                        {el.fileName !== "" && (
                                            <div
                                                className={
                                                    el.fileId !== "" && !(files.findIndex((fileItem) => fileItem.index === index) > -1)
                                                        ? classes.filenameWrapper
                                                        : classes.filenameEditModeWrapper
                                                }
                                                onClick={() =>
                                                    el.fileId !== "" &&
                                                    !(files.findIndex((fileItem) => fileItem.index === index) > -1) &&
                                                    handleClickFilename(el.fileId)
                                                }
                                            >
                                                {el.fileName.length > 15 ? el.fileName.slice(0, 15) + "..." : el.fileName}
                                            </div>
                                        )}
                                    </GridListTile>
                                    <GridListTile cols={4} style={{ height: "50px", width: "50px" }}>
                                        {props.data?.length > 1 && (
                                            <IconButton className={classes.addButton} onClick={() => removeInput(el.fileId, index)}>
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        )}
                                    </GridListTile>
                                </GridList>
                            </GridListTile>
                        </GridList>
                    );
                })}
            <IconButton classes={{ root: classes.root }} color="primary" className={classes.addButton} onClick={() => addInput()}>
                <AddCircleIcon />
            </IconButton>
        </div>
    );
};

export default Media;
