import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import WebsiteContext from "../../../utils/Website";
import StyledButton from "../../StyledButton/StyledButton";
import FormLayout from "../../../containers/FormLayout/FormLayout";
import StyledInput from "../../StyledInput/StyledInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    dialogContentWrapper: {
        padding: 0,
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
    },
    shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        transformOrigin: "top left",
    },
    addRemoveButtons: {
        width: "100%",
    },
    saveButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    deleteButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    globalError: {
        color: "red",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    gridListWrapper: {
        height: "auto !important",
    },
    selectWrapper: {
        marginBottom: "10px",
        height: "33px",
        width: "100%",
    },
    textareaWrapper: {
        width: "89%",
        border: "0.6425px solid #CCCCCC",
        marginBottom: "5px",
        borderRadius: "2.57px",
        minHeight: "60px",
        height: "auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
        position: "unset",
        padding: "10px",
        resize: "none",
    },
    datePickerWrapper: {
        width: "89%",
        border: "0.6425px solid #CCCCCC",
        marginBottom: "5px",
        borderRadius: "2.57px",
        height: "auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
        position: "unset",
        padding: "0 10px 0 10px",
        resize: "none",
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "column",
        margin: "10px",
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    checked: {},
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
}));

const AddNewDiscussion = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);

    const [nameDe, setNameDe] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [isMoldMaker, setIsMoldMaker] = useState(true);

    const [globalError, setGlobalError] = useState("");

    const handleCloseModal = () => {
        props.onClose();
    };

    let buttons = (
        <StyledButton variant="contained" type="submit" fullWidth className={classes.saveButton}>
            {languageData?.Save}
        </StyledButton>
    );

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.addNew}>Add new</div>
            <div className={classes.formWrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        props.handleAdd(nameDe, nameEn, isMoldMaker);
                        setNameDe("");
                        setNameEn("");
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={nameEn}
                        label={languageData?.NameEn}
                        className={classes.input}
                        onChange={(e) => setNameEn(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <StyledInput
                        showLabel
                        defaultValue={nameDe}
                        label={languageData?.NameDe}
                        className={classes.input}
                        onChange={(e) => setNameDe(e.target.value)}
                        type="text"
                        required={true}
                    />
                    <div className={classes.textWrapper}>{languageData?.DiscussionWith}</div>
                    <RadioGroup className={classes.radioButtonsWrapper} value={isMoldMaker} onChange={(e) => setIsMoldMaker(e.target.value === "true")}>
                        <FormControlLabel
                            classes={{ label: classes.textWrapper }}
                            name="control"
                            value={true}
                            control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                            label={languageData?.MoldMaker}
                        />
                        <FormControlLabel
                            classes={{ label: classes.textWrapper }}
                            name="control"
                            value={false}
                            control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                            label={languageData?.Customer}
                        />
                    </RadioGroup>
                    <div className={classes.globalError}>{globalError}</div>
                    {buttons}
                </FormLayout>
            </div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

export default AddNewDiscussion;
