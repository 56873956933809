import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import clsx from "clsx";

import { KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles, InputLabel, Typography } from "@material-ui/core";

import { inputHasValue } from "../../utils/app-functions";

const useStyles = makeStyles(
    (theme) => ({
        datePicker: {
            width: "100%",
        },
        input: {
            fontSize: 12,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "15px",
            padding: "10px 0px 10px 0px",
        },
        dateInput: {
            backgroundColor: "white",
            paddingRight: 10,
            paddingLeft: 3,
        },
        calendarButton: {
            "& button": {
                padding: 2,
            },
            "& svg": {
                height: "0.7em",
                width: "0.7em",
            },
        },
    }),
    { name: "CustomDatePickerStyles" }
);

const DatePickerTypes = {
    Date: "date",
    Time: "time",
    DateTime: "datetime",
    Month: "month",
};

const StyledDatePicker = ({
    value,
    type = DatePickerTypes.Date,
    placeholder = type == DatePickerTypes.DateTime
        ? "YYYY-MM-DD HH:MM"
        : type == DatePickerTypes.Month
        ? "YYYY-MM"
        : type === DatePickerTypes.Time
        ? "HH:mm"
        : "YYYY-MM-DD",
    format = type == DatePickerTypes.DateTime
        ? "yyyy-MM-dd HH:mm"
        : type == DatePickerTypes.Month
        ? "yyyy-MM"
        : type === DatePickerTypes.Time
        ? "HH:mm"
        : "yyyy-MM-dd",
    mask = type == DatePickerTypes.DateTime
        ? "____-__-__ __:__"
        : type == DatePickerTypes.Month
        ? "____-__"
        : type == DatePickerTypes.Time
        ? "__:__"
        : "____-__-__",
    activeLabel,
    label,
    wrapperClassName,
    labelClassName,
    datePickerClassName,
    inputClassName,
    rootClassName,
    positionEndClassName,
    viewMode,
    viewModeClassName,
    onChange,
    onError,
    allowKeyboardControl = false,
    disableFuture = false,
    autoOk = true,
    invalidDateMessage = "Data incorecta",
    inputVariant = "outlined",
    variant = "inline",
    required,
    requiredLabel = true,
    minDate,
    minDateMessage = minDate ? "Data prea mica" : undefined,
    maxDate,
    maxDateMessage = maxDate ? "Data prea mare" : undefined,
    ampm = false,
    ...rest
}) => {
    const classes = useStyles(useStyles);

    return (
        <div className={wrapperClassName}>
            {activeLabel && (
                <InputLabel required={!!required && !!requiredLabel} className={clsx(classes.label, labelClassName)}>
                    {label}
                </InputLabel>
            )}
            {!viewMode ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {type === DatePickerTypes.DateTime ? (
                        <KeyboardDateTimePicker
                            className={clsx(classes.datePicker, datePickerClassName)}
                            placeholder={placeholder}
                            autoOk={autoOk}
                            format={format}
                            invalidDateMessage={invalidDateMessage}
                            InputProps={{
                                classes: {
                                    input: clsx(classes.input, classes.dateInput, inputClassName),
                                    root: clsx(classes.dateInput, rootClassName),
                                },
                            }}
                            InputAdornmentProps={{
                                classes: {
                                    positionEnd: clsx(classes.calendarButton, positionEndClassName),
                                },
                            }}
                            inputVariant={inputVariant}
                            required={!!required}
                            variant={variant}
                            value={value || null}
                            onChange={onChange}
                            allowKeyboardControl={allowKeyboardControl}
                            disableFuture={disableFuture}
                            mask={mask}
                            onError={onError}
                            minDate={minDate}
                            minDateMessage={minDateMessage}
                            maxDate={maxDate}
                            maxDateMessage={maxDateMessage}
                            ampm={ampm}
                            strictCompareDates
                            {...rest}
                        />
                    ) : type === DatePickerTypes.Time ? (
                        <KeyboardTimePicker
                            className={clsx(classes.datePicker, datePickerClassName)}
                            placeholder={placeholder}
                            autoOk={autoOk}
                            format={format}
                            invalidDateMessage={invalidDateMessage}
                            InputProps={{
                                classes: {
                                    input: clsx(classes.input, classes.dateInput, inputClassName),
                                    root: clsx(classes.dateInput, rootClassName),
                                },
                            }}
                            InputAdornmentProps={{
                                classes: {
                                    positionEnd: clsx(classes.calendarButton, positionEndClassName),
                                },
                            }}
                            inputVariant={inputVariant}
                            required={!!required}
                            variant={variant}
                            value={value || null}
                            onChange={onChange}
                            allowKeyboardControl={allowKeyboardControl}
                            disableFuture={disableFuture}
                            mask={mask}
                            onError={onError}
                            minDate={minDate}
                            minDateMessage={minDateMessage}
                            maxDate={maxDate}
                            maxDateMessage={maxDateMessage}
                            ampm={ampm}
                            strictCompareDates
                            {...rest}
                        />
                    ) : (
                        <KeyboardDatePicker
                            className={clsx(classes.datePicker, datePickerClassName)}
                            placeholder={placeholder}
                            autoOk={autoOk}
                            format={format}
                            invalidDateMessage={invalidDateMessage}
                            InputProps={{
                                classes: {
                                    input: clsx(classes.input, classes.dateInput, inputClassName),
                                    root: clsx(classes.dateInput, rootClassName),
                                },
                            }}
                            InputAdornmentProps={{
                                classes: {
                                    positionEnd: clsx(classes.calendarButton, positionEndClassName),
                                },
                            }}
                            inputVariant={inputVariant}
                            required={!!required}
                            variant={variant}
                            value={value || null}
                            onChange={onChange}
                            allowKeyboardControl={allowKeyboardControl}
                            disableFuture={disableFuture}
                            mask={mask}
                            onError={onError}
                            openTo={type == DatePickerTypes.Month ? "month" : undefined}
                            views={type == DatePickerTypes.Month ? ["year", "month"] : undefined}
                            minDate={minDate}
                            minDateMessage={minDateMessage}
                            maxDate={maxDate}
                            maxDateMessage={maxDateMessage}
                            {...rest}
                        />
                    )}
                </MuiPickersUtilsProvider>
            ) : (
                <Typography variant={"h6"} className={clsx(classes.value, viewModeClassName)}>
                    {inputHasValue(value) ? value : "-"}
                </Typography>
            )}
        </div>
    );
};

export default StyledDatePicker;
