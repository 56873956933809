import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Card, Divider, makeStyles, Tooltip } from "@material-ui/core";

import defaultLogo from "../assets/img/no_logo.png";

const useStyles = makeStyles(() => ({
    wrapper: {},
    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    root: {
        width: 219,
        height: 147,
        display: "block",
        float: "left",
        cursor: "pointer",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "1.35951px",
    },
    name: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#191919",
        padding: "16px 16px 10px 16px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    address: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        padding: "10px 16px 16px 16px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    logo: {
        height: "35px",
        width: "auto",
    },
    missingLogo: {
        height: "67px",
        width: "100%",
    },
    logoWrapper: {
        padding: 12,
    },
    divider: {
        width: "100%",
    },
}));

const SimpleEntityCard = (props) => {
    const classes = useStyles(useStyles);
    const [logo, setLogo] = useState("");
    const [id, setId] = useState("");

    useEffect(() => {
        if (props.logo) {
            setLogo(props.logo);
        } else {
            setLogo("");
        }
        setId(props.id);
    }, [props.logo, props.id]);

    // const handleClickEvent = () => {
    //     props.history.push(props.match.path + "/" + id + "/projects");
    // };

    return (
        <Card component={Link} to={props.link} classes={{ root: classes.root }}>
            <div className={classes.cardWrapper}>
                {props.logo ? (
                    <React.Fragment>
                        <div className={classes.logoWrapper}>
                            <img className={classes.logo} src={logo} alt="" />
                        </div>
                        <Divider className={classes.divider} />
                    </React.Fragment>
                ) : (
                    <img className={classes.missingLogo} src={defaultLogo} alt="" />
                )}
                <Tooltip title={props.name}>
                    <div className={classes.name}>{props.name}</div>
                </Tooltip>
                <Tooltip title={props.address}>
                    <div className={classes.address}>{props.address}</div>
                </Tooltip>
            </div>
        </Card>
    );
};

SimpleEntityCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    logo: PropTypes.string,
};

export default SimpleEntityCard;
