import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Card, Divider, makeStyles } from "@material-ui/core";

import WebsiteContext from "../../../../utils/Website";

const useStyles = makeStyles(() => ({
    wrapper: {},
    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    root: (props) => ({
        width: 219,
        height: 100,
        display: "block",
        float: "left",
        cursor: props.disabled ? "default" : "pointer",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "1.35951px",
    }),
    name: (props) => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "15px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: "#191919",
        opacity: props.disabled ? 0.5 : 1,
    }),
    details: (props) => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#191919",
        padding: "0px 16px 7.5px 16px",
        opacity: props.disabled ? 0.5 : 1,
    }),
    nameWrapper: {
        padding: "16px 16px 6px 16px",
    },
    stateWrapper: (props) => ({
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        padding: "12px 12px 12px 16px",
        opacity: props.disabled ? 0.5 : 1,
        color: props.stateColor,
    }),
    divider: {
        width: "100%",
        backgroundColor: "#191919",
        opacity: 0.1,
    },
    icons: {
        color: "#C00D0E",
        height: "13px",
        weight: "13px",
    },
}));

const StepCard = (props) => {
    const classes = useStyles({
        disabled: props.step.disabled,
        stateColor: props.step.state.color,
    });
    const { languageData, role } = useContext(WebsiteContext);

    return (
        <Card classes={{ root: classes.root }} onClick={() => props.handleClickEvent(props.step)}>
            <div className={classes.cardWrapper}>
                <div className={classes.nameWrapper}>
                    <div className={classes.name}>{props.step.name}</div>
                </div>
                <div className={classes.details}>{languageData?.Step + " " + props.step.stepNo}</div>
                <Divider className={classes.divider} />
                <div className={classes.stateWrapper}>{props.step.state.name}</div>
            </div>
        </Card>
    );
};

StepCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
};

export default StepCard;
