import React, { useContext } from "react";
import WebsiteContext from "../../../../utils/Website";
import { makeStyles } from "@material-ui/core";
import StyledInput from "../../../StyledInput/StyledInput";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: "8px",
    },
    subtitleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
    },
    input: {
        width: "210px",
        marginRight: "10px",
        marginBottom: "4px",
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
}));

const Process = (props) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Process.toUpperCase()}</div>
            <div className={classes.contentWrapper}>
                {Object.keys(props?.processValues ? props.processValues : {}).map((key) => (
                    <div key={key}>
                        <InputLabel required={key === "estimatedCycleTime" || key === "calculatedClampingForce"} className={classes.subtitleWrapper}>
                            {languageData[key]}
                        </InputLabel>
                        <StyledInput
                            disabled={props.disabled}
                            className={classes.input}
                            defaultValue={props.processValues[key] ? props.processValues[key] : ""}
                            onChange={(e) => props.updateProcessValue("process", key, e.target.value)}
                            type="text"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

Process.propTypes = {
    processValues: PropTypes.any,
    updateProcessValue: PropTypes.func,
};

export default Process;
