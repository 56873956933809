import styles from "../../../../assets/jss/material-dashboard-react/components/gridStyle";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import RoutingContext from "../../../../utils/RoutingContext";
import Axios from "axios";
import InjectionReportCard from "./InjectionReportCard/InjectionReportCard";
import InjectionReportModal from "./InjectionReportModal/InjectionReportModal";

const useStyles = makeStyles(styles);

const InjectionReport = (props) => {
    const classes = useStyles();
    const { languageData, role } = useContext(WebsiteContext);
    const { setRoutingData } = useContext(RoutingContext);
    const [openModal, setOpenModal] = useState(false);
    const [injectionReports, setInjectionReports] = useState([]);
    const [isAddActive, setIsAddActive] = useState(false);
    const [isDuplicateActive, setIsDuplicateActive] = useState(false);
    const [elementToEdit, setElementToEdit] = useState(null);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [elementName, setElementName] = useState("");

    const handleOpenModal = () => {
        setIsAddActive(true);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setElementToEdit(null);
        setElementName("");
        setIsDuplicateActive(false);
        setIsAddActive(false);
        getInjectionReports();
    };

    useEffect(() => {
        getInjectionReports();
    }, []);

    const getInjectionReports = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports`,
            method: "GET",
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setInjectionReports(data);
        });
    };

    const handleEdit = (id, name) => {
        setElementToEdit(id);
        setElementName(name);
        setOpenModal(true);
        setIsAddActive(false);
    };

    const handleDuplicate = (id) => {
        setElementToEdit(id);
        setIsDuplicateActive(true);
        setOpenModal(true);
    };

    const duplicate = (name) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${elementToEdit}/duplicate`,
            method: "POST",
            headers: {
                "X-Loading": false,
            },
            data: {
                folder: `${props.match.params.id}-mold-trail-fot-injection-parameter-${elementToEdit}`,
                name: name,
            },
        };
        Axios(options).then((_) => {
            setElementToEdit(null);
            setIsDuplicateActive(false);
            setOpenModal(false);
            getInjectionReports();
        });
    };

    const handleDelete = (id) => {
        setElementToDelete(id);
    };

    const deleteInjection = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/mold-trail-fot/injection-reports/${elementToDelete}`,
            method: "DELETE",
            data: {
                folder: `${props.match.params.id}-mold-trail-fot-injection-parameter-${elementToDelete}`,
            },
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            setElementToDelete(null);
            getInjectionReports();
        });
    };

    const cards = injectionReports.map((injection) => {
        return (
            <InjectionReportCard
                {...props}
                key={injection.id}
                id={injection.id}
                name={injection.name}
                handleEdit={handleEdit}
                duplicate={handleDuplicate}
                handleDelete={handleDelete}
            />
        );
    });

    let modal;
    if (openModal) {
        modal = (
            <InjectionReportModal
                {...props}
                duplicateMode={isDuplicateActive}
                duplicate={duplicate}
                addMode={isAddActive}
                open={openModal}
                toolId={props.match.params.id}
                name={elementName}
                injectionId={elementToEdit}
                onClose={handleCloseModal}
            />
        );
    }

    return (
        <div className={classes.grid}>
            <div className={role === 2 ? classes.toolCardWrapper : classes.tabCardsWrapper}>
                {(role === 0 || role === 2) && (
                    <Card classes={{ root: classes.root }}>
                        <div className={classes.cardWrapper}>
                            <div className={classes.addNew}>{languageData?.AddNewInjectionReport}</div>
                            <IconButton className={classes.icon} onClick={handleOpenModal}>
                                <AddCircleIcon color="primary" fontSize="inherit" />
                            </IconButton>
                        </div>
                    </Card>
                )}
                {cards}
            </div>
            {modal}
            <Dialog
                open={elementToDelete !== null}
                onClose={() => {
                    setElementToDelete(null);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{languageData?.DeleteInjectionReportMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setElementToDelete(null);
                        }}
                        color="primary"
                        autoFocus
                    >
                        {languageData?.No}
                    </Button>
                    <Button onClick={deleteInjection} color="primary" autoFocus>
                        {languageData?.Yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InjectionReport;
