import React, { useContext, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WebsiteContext from "../../utils/Website";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Axios from "axios";
import Members from "./Members/Members";
import Tools from "./Tools/Tools";
import { Link } from "react-router-dom";
import ProjectSchedule from "./Schedule/Schedule";
import RoutingContext from "../../utils/RoutingContext";

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    padding: {
        height: "calc(100% - 67px)",
        padding: 9,
    },
}));

const Project = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role } = useContext(WebsiteContext);
    const { setCustomerData, setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData } = useContext(RoutingContext);

    useEffect(() => {
        const options = {
            url: `/customers/projects/${props.match.params.id}`,
            method: "GET",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setRoutingData();
            setProjectData({
                visibleContent: true,
                name: data.name,
                number: data.project_number,
                customerName: data.customer_name,
                progress: data.customerToolsPercentage,
                stateColor: data.customerToolsState.color,
            });
            setEntityPath(`/customers/projects/${props.match.params.id}`);
            if (role === 0) {
                setBackPath("/customers/" + data.customer_id + "/projects");
            } else if (role === 1) {
                setBackPath("/customer/projects");
            }
        });
    }, []);

    return (
        <React.Fragment>
            {/* <Divider /> */}
            <Divider />
            <Tabs value={props.location.pathname} className={classes.wrapper} variant="scrollable" indicatorColor={"primary"} aria-label="simple tabs example">
                {(role === 0 || role === 1) && (
                    <Tab
                        label={languageData.Members}
                        component={Link}
                        to={role === 0 ? `/customers/projects/${props.match.params.id}/members` : `/project/${props.match.params.id}/members`}
                        value={role === 0 ? `/customers/projects/${props.match.params.id}/members` : `/project/${props.match.params.id}/members`}
                    />
                )}
                {(role === 0 || role === 1) && (
                    <Tab
                        label={role === 0 ? languageData.CustomerSchedule : languageData.Schedule}
                        component={Link}
                        to={role === 0 ? `/customers/projects/${props.match.params.id}/schedule/customer` : `/project/${props.match.params.id}/schedule`}
                        value={role === 0 ? `/customers/projects/${props.match.params.id}/schedule/customer` : `/project/${props.match.params.id}/schedule`}
                    />
                )}
                {(role === 0 || role === 2) && (
                    <Tab
                        label={role === 0 ? languageData.MoldMakerSchedule : languageData.Schedule}
                        component={Link}
                        to={role === 0 ? `/customers/projects/${props.match.params.id}/schedule/mold-maker` : `/project/${props.match.params.id}/schedule`}
                        value={role === 0 ? `/customers/projects/${props.match.params.id}/schedule/mold-maker` : `/project/${props.match.params.id}/schedule`}
                    />
                )}
                {(role === 0 || role === 1) && (
                    <Tab
                        label={languageData.Tools}
                        component={Link}
                        to={role === 0 ? `/customers/projects/${props.match.params.id}/tools` : `/project/${props.match.params.id}/tools`}
                        value={role === 0 ? `/customers/projects/${props.match.params.id}/tools` : `/project/${props.match.params.id}/tools`}
                    />
                )}
            </Tabs>
            <div className={classes.padding}>
                <Switch>
                    {(role === 0 || role === 1) && (
                        <Route path={role === 0 ? `/customers/projects/:id/members` : `/project/:id/members`} render={(props) => <Members {...props} />} />
                    )}
                    {(role === 0 || role === 1) && (
                        <Route
                            path={role === 0 ? `/customers/projects/:id/schedule/customer` : `/project/:id/schedule`}
                            render={(props) => <ProjectSchedule {...props} isCustomerSchedule={true} />}
                        />
                    )}
                    {(role === 0 || role === 2) && (
                        <Route
                            path={role === 0 ? `/customers/projects/:id/schedule/mold-maker` : `/project/:id/schedule`}
                            render={(props) => <ProjectSchedule {...props} isCustomerSchedule={false} />}
                        />
                    )}
                    {(role === 0 || role === 1) && (
                        <Route path={role === 0 ? `/customers/projects/:id/tools` : `/project/:id/tools`} render={(props) => <Tools {...props} />} />
                    )}
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Project);
