import React, { useContext, useEffect, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import Axios from "axios";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import CachedIcon from "@material-ui/icons/Cached";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import VisibilityIcon from "@material-ui/icons/Visibility";

import WebsiteContext from "../../../../utils/Website";
import FormLayout from "../../../../containers/FormLayout/FormLayout";
import StyledInput from "../../../StyledInput/StyledInput";
import StyledButton from "../../../StyledButton/StyledButton";
import HistoryFiles from "../../../HistoryFiles/HistoryFiles";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
        // padding: "16px 16px 30px 16px",
    },
    contentWrapper: {
        height: "calc(100% - 87px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    sectionTitleWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        marginBottom: "16px",
        color: "#A5A5A5",
    },
    inputWrapper: {
        width: 210,
        marginTop: 20,
        marginRight: "20px",
    },
    projectInformationContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: 500,
    },
    generalInformationContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
    },
    contentMargin: {
        marginTop: 4,
        [theme.breakpoints.down(500)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    fileInputWrapper: { width: 1, height: 1, opacity: 1 },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    linkWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        maxWidth: "190px",
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "12px",
        color: "#646363",
        marginRight: 5,
    },
    accessibleLink: {
        textDecorationLine: "underline",
        cursor: "pointer",
        color: "#00A7D1",
    },
    imageWrapper: {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
    },
    uploadPictureButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        height: 33,
        marginTop: 5,
    },
    generalInformationWrapper: {
        marginTop: 40,
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        // marginBottom: 24,
        height: "33px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    buttonWrapper: {
        background: "white",
        padding: 18,
    },
}));

const Info = (props) => {
    const actual3DFolder = props.match.params.id + "-specification-3d";
    const actual2DPartDrawingFolder = props.match.params.id + "-specification-2d";
    const pictureFolder = props.match.params.id + "-specification";

    const classes = useStyles(useStyles);
    const [stepInfo, setStepInfo] = useState(null);
    const { languageData, role } = useContext(WebsiteContext);
    const [actual3DFile, setActual3DFile] = useState(null);
    const [actual2DPartDrawing, setActual2DPartDrawing] = useState(null);
    const [picture, setPicture] = useState(null);
    const [newPicture, setNewPicture] = useState(null);
    const [showHistory, setShowHistory] = useState("");
    const [historyFiles, setHistoryFiles] = useState([]);
    const [pictureToDelete, setPictureToDelete] = useState(null);

    useEffect(() => {
        getDFMInfo();
    }, []);

    const getDFMInfo = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/dfm/info`,
            method: "GET",
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setStepInfo(data);

            if (data.actual3DFile) {
                setActual3DFile({
                    filename: data.actual3DFile.name,
                    fileId: data.actual3DFile.id,
                    accessible: true,
                });
            }

            if (data.actual2DPartDrawing) {
                setActual2DPartDrawing({
                    filename: data.actual2DPartDrawing.name,
                    fileId: data.actual2DPartDrawing.id,
                    accessible: true,
                });
            }

            if (data.picture) {
                setPicture({
                    filename: data.picture.name,
                    fileId: data.picture.id,
                    accessible: true,
                });
            }
        });
    };

    const handleSubmit = () => {
        const filesToSave = getFilesToSave();

        const stepInfoClone = { ...stepInfo };

        if (pictureToDelete) {
            const options = {
                url: "/file-operations/" + `${pictureFolder}` + `/files/${pictureToDelete}`,
                method: "DELETE",
                headers: {
                    "X-Loading": false,
                },
            };
            Axios(options).then((response) => {});
        }

        if (filesToSave.length !== 0) {
            filesToSave.forEach((fileToSave, index) => {
                let files = new FormData();
                files.append("files", fileToSave.file, fileToSave.filename);

                const options = {
                    url: "/file-operations/" + fileToSave.folder + "/files",
                    method: "POST",
                    data: files,
                    headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                };
                Axios(options).then((response) => {
                    stepInfoClone[fileToSave.category] = {
                        id: response.data.data.files[0].id,
                        name: response.data.data.files[0].name,
                    };
                    if (index === filesToSave.length - 1) {
                        setStepInfo(stepInfoClone);
                        saveInfo(stepInfoClone);
                    }
                });
            });
        } else {
            saveInfo(stepInfo);
        }
    };

    const saveInfo = (data) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/dfm/info`,
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options)
            .then((response) => {
                getDFMInfo();
            })
            .catch((err) => {
                console.log(err.response.data.error);
            });
    };

    const getFilesToSave = () => {
        const filesToSave = [];

        if (actual3DFile.accessible === false) {
            filesToSave.push(actual3DFile);
        }

        if (actual2DPartDrawing.accessible === false) {
            filesToSave.push(actual2DPartDrawing);
        }

        if (picture.accessible === false) {
            filesToSave.push(picture);
        }
        return filesToSave;
    };

    const onTextInputChanged = (category, field, value) => {
        const tmpData = { ...stepInfo };

        tmpData[category][field] = value;

        setStepInfo(tmpData);
    };

    const handleOpenFile = (id, accessible, subsection) => {
        if (accessible) {
            switch (subsection) {
                case actual3DFolder:
                    window.open(`${process.env.REACT_APP_URL}file-operations/${actual3DFolder}/files/${id}`);
                    break;
                case actual2DPartDrawingFolder:
                    window.open(`${process.env.REACT_APP_URL}file-operations/${actual2DPartDrawingFolder}/files/${id}`);
                    break;
                default:
                    break;
            }
        }
    };

    const viewHistory = (folder) => {
        const options = {
            url: "/file-operations/" + folder + "/files",
            method: "GET",
        };
        Axios(options).then((response) => {
            setHistoryFiles(response?.data?.data);
        });

        setShowHistory(folder);
    };

    const handleClose = () => {
        setShowHistory("");
    };

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    const uploadFile = (e, fileType) => {
        const file = e.target.files[0];

        if (file) {
            switch (fileType) {
                case "actual3DFile":
                    setActual3DFile({
                        filename: file.name,
                        file: file,
                        accessible: false,
                        category: "actual3DFile",
                        folder: actual3DFolder,
                    });
                    break;
                case "actual2DPartDrawing":
                    setActual2DPartDrawing({
                        filename: file.name,
                        file: file,
                        accessible: false,
                        category: "actual2DPartDrawing",
                        folder: actual2DPartDrawingFolder,
                    });
                    break;
                case "picture":
                    if (picture.fileId) {
                        setPictureToDelete(picture.fileId);
                    }
                    setPicture({
                        filename: file.name,
                        file: file,
                        accessible: false,
                        category: "picture",
                        folder: pictureFolder,
                    });
                    getBase64(file, (result) => {
                        setNewPicture(result);
                    });
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div className={classes.wrapper}>
            <HistoryFiles files={historyFiles} open={showHistory !== ""} onClose={handleClose} onClick={(id) => handleOpenFile(id, true, showHistory)} />
            <FormLayout
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                className={classes.layoutWrapper}
            >
                <div className={classes.buttonWrapper}>
                    <StyledButton className={classes.button} color="primary" variant="contained" type="submit">
                        {languageData?.Save.toUpperCase()}
                    </StyledButton>
                </div>
                <div className={classes.contentWrapper}>
                    <div className={classes.sectionTitleWrapper}>{languageData?.ProjectInformationDatabase}</div>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className={classes.projectInformationContainer}>
                                {stepInfo &&
                                    Object.keys(stepInfo.projectInformationDatabase).map((key, value) => {
                                        return (
                                            <StyledInput
                                                required
                                                key={value}
                                                showLabel
                                                label={languageData[key]}
                                                className={classes.inputWrapper}
                                                defaultValue={
                                                    stepInfo?.projectInformationDatabase[key] !== null ? stepInfo?.projectInformationDatabase[key] : ""
                                                }
                                                onChange={(e) => onTextInputChanged("projectInformationDatabase", key, e.target.value)}
                                                type="text"
                                            />
                                        );
                                    })}
                            </div>
                            <div>
                                <InputLabel required className={classes.labelWrapper}>
                                    {languageData?.Actual3DFile}
                                </InputLabel>
                                <div className={classes.contentMargin}>
                                    {actual3DFile && (
                                        <span
                                            onClick={() => handleOpenFile(actual3DFile.fileId, actual3DFile.accessible, actual3DFolder)}
                                            className={classes.linkWrapper + " " + (actual3DFile.accessible && classes.accessibleLink)}
                                        >
                                            {actual3DFile.filename}
                                        </span>
                                    )}{" "}
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.uploadFileButton}
                                        startIcon={actual3DFile ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                    >
                                        {actual3DFile ? languageData?.ChangeFile : languageData?.UploadFile}
                                        <input
                                            required={actual3DFile === null}
                                            defaultValue={actual3DFile ? actual3DFile.filename : ""}
                                            type="file"
                                            onChange={(e) => uploadFile(e, "actual3DFile")}
                                            className={classes.fileInputWrapper}
                                        />
                                    </Button>
                                    {actual3DFile && actual3DFile.accessible && (
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className={classes.uploadFileButton}
                                            startIcon={<VisibilityIcon color="primary" />}
                                            onClick={() => viewHistory(actual3DFolder)}
                                        >
                                            {languageData?.ViewHistory}
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div>
                                <InputLabel required className={classes.labelWrapper}>
                                    {languageData?.Actual2DPartDrawing}
                                </InputLabel>
                                <div className={classes.contentMargin}>
                                    {actual2DPartDrawing && (
                                        <span
                                            onClick={() =>
                                                handleOpenFile(actual2DPartDrawing.fileId, actual2DPartDrawing.accessible, actual2DPartDrawingFolder)
                                            }
                                            className={classes.linkWrapper + " " + (actual2DPartDrawing.accessible && classes.accessibleLink)}
                                        >
                                            {actual2DPartDrawing.filename}
                                        </span>
                                    )}{" "}
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.uploadFileButton}
                                        startIcon={actual2DPartDrawing ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                    >
                                        {actual2DPartDrawing ? languageData?.ChangeFile : languageData?.UploadFile}
                                        <input
                                            required={actual2DPartDrawing === null}
                                            defaultValue={actual2DPartDrawing ? actual2DPartDrawing.filename : ""}
                                            type="file"
                                            onChange={(e) => uploadFile(e, "actual2DPartDrawing")}
                                            className={classes.fileInputWrapper}
                                        />
                                    </Button>
                                    {actual2DPartDrawing && actual2DPartDrawing.accessible && (
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className={classes.uploadFileButton}
                                            startIcon={<VisibilityIcon color="primary" />}
                                            onClick={() => viewHistory(actual2DPartDrawingFolder)}
                                        >
                                            {languageData?.ViewHistory}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel required className={classes.labelWrapper}>
                                {languageData?.Picture}
                            </InputLabel>
                            <div>
                                <div>
                                    {picture && (
                                        <img
                                            className={classes.imageWrapper}
                                            src={
                                                picture?.accessible === true
                                                    ? `${process.env.REACT_APP_URL}file-operations/${pictureFolder}/files/${picture.fileId}`
                                                    : newPicture
                                            }
                                            alt="Picture image"
                                        />
                                    )}
                                </div>
                                <Button
                                    variant="contained"
                                    component="label"
                                    className={classes.uploadPictureButton}
                                    startIcon={stepInfo?.picture ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                >
                                    {stepInfo?.picture ? languageData?.ChangeFile : languageData?.UploadFile}
                                    <input
                                        type="file"
                                        required={stepInfo?.picture === null}
                                        defaultValue={stepInfo?.picture ? stepInfo?.picture : ""}
                                        onChange={(e) => uploadFile(e, "picture")}
                                        className={classes.fileInputWrapper}
                                    />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.generalInformationWrapper}>
                        <div className={classes.sectionTitleWrapper}>{languageData?.GeneralInformation}</div>
                    </div>
                    <div className={classes.generalInformationContainer}>
                        {stepInfo &&
                            Object.keys(stepInfo.generalInformation).map((key, value) => {
                                return (
                                    <StyledInput
                                        required
                                        key={value}
                                        showLabel
                                        label={languageData[key]}
                                        className={classes.inputWrapper}
                                        defaultValue={stepInfo?.generalInformation[key] !== null ? stepInfo?.generalInformation[key] : ""}
                                        onChange={(e) => onTextInputChanged("generalInformation", key, e.target.value)}
                                        type="text"
                                    />
                                );
                            })}
                    </div>
                </div>
            </FormLayout>
        </div>
    );
};

export default Info;
