/*eslint-disable*/
import React, { useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import { AccountData } from "../Navbars/AdminNavbarLinks";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import WebsiteContext from "../../utils/Website";
import { useHistory } from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Axios from "axios";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
    const classes = useStyles();

    const { language, setLanguage, languageData } = useContext(WebsiteContext);
    const history = useHistory();

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return props.location.pathname.includes(routeName) ? true : false;
    }

    const { color, logo, image, logoText, routes } = props;
    var links = (
        <List className={classes.list}>
            {routes
                .filter((route) => route.isOnMenu)
                .map((prop, key) => {
                    var activePro = " ";
                    var listItemClasses;

                    listItemClasses = classNames({
                        [" " + classes.activeRoute]: activeRoute(prop.path),
                    });

                    const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: activeRoute(prop.path),
                    });

                    return (
                        <NavLink to={prop.path} className={activePro + classes.item} activeClassName="active" key={key}>
                            <ListItem className={classes.itemLink + listItemClasses}>
                                {typeof prop.icon === "string" ? (
                                    <Icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive,
                                        })}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(classes.itemIcon, whiteFontClasses, {
                                            [classes.itemIconRTL]: props.rtlActive,
                                        })}
                                    />
                                )}
                                <ListItemText
                                    primary={props.rtlActive ? prop.rtlName : prop.name}
                                    className={classNames(classes.itemText, whiteFontClasses, {
                                        [classes.itemTextRTL]: props.rtlActive,
                                    })}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </NavLink>
                    );
                })}
        </List>
    );

    const handleBrandClick = () => {
        history.push("/");
    };

    var brand = (
        <div className={classes.brandComponent}>
            <div onClick={handleBrandClick} className={classes.brand}>
                HANNES
            </div>
        </div>
    );

    const changeLanguage = (value) => {
        setLanguage(value);
        localStorage.setItem("language", value);
        const options = {
            url: `/users/language`,
            method: "PUT",
            data: {
                language: value,
            },
        };
        Axios(options).then((_) => {});
    };

    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={"left"}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive,
                        }),
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.languageComponent}>
                        <RadioGroup className={classes.radioButtonsWrapper} value={language} onChange={(e) => changeLanguage(e.target.value)}>
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                name="switches"
                                value={"en"}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.English.toLocaleUpperCase()}
                            />
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                name="switches"
                                value={"de"}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.Deutsch.toLocaleUpperCase()}
                            />
                        </RadioGroup>
                    </div>
                    <div className={classes.sidebarWrapper}>
                        {props.rtlActive ? <RTLNavbarLinks /> : <AccountData />}
                        {links}
                    </div>
                    {/*{image !== undefined ?*/}
                    {/*    <div className={classes.background} style={{backgroundImage: "url(" + image + ")"}}/> : null}*/}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor={props.rtlActive ? "right" : "left"}
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive,
                        }),
                    }}
                >
                    {brand}
                    <div className={classes.languageComponent}>
                        <RadioGroup className={classes.radioButtonsWrapper} value={language} onChange={(e) => changeLanguage(e.target.value)}>
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                name="switches"
                                value={"en"}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.English.toLocaleUpperCase()}
                            />
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                name="switches"
                                value={"de"}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.checked }} />}
                                label={languageData?.Deutsch.toLocaleUpperCase()}
                            />
                        </RadioGroup>
                    </div>
                    <div className={classes.sidebarWrapper}>
                        <div className={classes.sidebarAccount}>
                            <AccountData />
                        </div>
                        {links}
                    </div>
                    {/*{image !== undefined ?*/}
                    {/*    <div className={classes.background} style={{backgroundImage: "url(" + image + ")"}}/> : null}*/}
                </Drawer>
            </Hidden>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    // logo: PropTypes.string,
    image: PropTypes.string,
    // logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
};
