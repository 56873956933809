import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../utils/Website";
import FormLayout from "../../../containers/FormLayout/FormLayout";
import StyledButton from "../../StyledButton/StyledButton";
import { Alert } from "@material-ui/lab";
import { Divider, makeStyles } from "@material-ui/core";
import ProjectData from "../ToolSpecification/Sections/ProjectData";
import MoldType from "../ToolSpecification/Sections/MoldType";
import Process from "../ToolSpecification/Sections/Process";
import Material from "../ToolSpecification/Sections/Material";
import MoldLayout from "../ToolSpecification/Sections/MoldLayout";
import RunnerSystem from "../ToolSpecification/Sections/RunnerSystem";
import Demolding from "../ToolSpecification/Sections/Demolding";
import Cooling from "../ToolSpecification/Sections/Cooling";
import Surface from "../ToolSpecification/Sections/Surface";
import MoldBase from "../ToolSpecification/Sections/MoldBase";
import Steel from "../ToolSpecification/Sections/Steel";
import Coating from "../ToolSpecification/Sections/Coating";
import Connector from "../ToolSpecification/Sections/Connector";
import DocumentsForDelivery from "../ToolSpecification/Sections/DocumentsForDelivery";
import Sketch from "../ToolSpecification/Sections/Sketch";
import QuotationRequestContacts from "./Sections/QuotationRequestContacts";
import MoldMakerModal from "./MoldmakerModal/MoldmakerModal";
import classNames from "classnames";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100% - 18px)",
        padding: 9,
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 82px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",

        [theme.breakpoints.down(400)]: {
            height: "calc(100% - 118px)",
        },
    },
    customerContentWrapper: {
        height: "calc(100% - 80px)",

        [theme.breakpoints.down(400)]: {
            height: "calc(100% - 80px)",
        },
    },
    moldMakerContentWrapper: {
        height: "calc(100% - 18px)",
    },
    saveButton: {
        borderRadius: "3px",
        width: "120px",
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    chooseButton: {
        borderRadius: "3px",
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
        backgroundColor: "white",
        color: "#C00D0E",
        border: "1px solid #C00D0E",
        whiteSpace: "nowrap",
    },
    divider: {
        width: "100%",
    },
    buttonWrapper: {
        padding: 18,
        background: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: "30px",

        [theme.breakpoints.down(400)]: {
            flexDirection: "column",
            gap: "10px",
        },
    },
    messageGrid: {
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "12px",
        textAlign: "center",
        marginBottom: "10px",
    },
    rowWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));

const QuotationRequest = (props) => {
    const classes = useStyles(useStyles);
    const [resources, setResources] = useState({});
    const [quotationRequest, setQuotationRequest] = useState(null);
    const [newFiles, setNewFiles] = useState([]);
    const [historyFiles, setHistoryFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);

    const { languageData, role } = useContext(WebsiteContext);

    const [newMoldMaker, setNewMoldMaker] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);

    const [initialPictureFiles, setInitialPictureFiles] = useState([]);
    const [finalPictureFiles, setFinalPictureFiles] = useState([]);

    const getPictureFilesIds = (projectData) => {
        let ids = [];
        if (projectData?.picture) {
            ids.push(projectData?.picture?.id);
        }
        for (let i = 0; i < projectData?.additionalFiles?.length; i++) {
            if (projectData?.additionalFiles[i].picture) {
                ids.push(projectData?.additionalFiles[i].picture?.id);
            }
        }
        return ids;
    };

    useEffect(() => {
        //TODO replace response after GET endpoint is done
        if (props.toolId && props.projectId) {
            const data = {
                tool_id: props.toolId,
            };
            const options = {
                url: "/customers/projects/" + props.projectId + "/quotation-request-resources",
                method: "POST",
                data: data,
            };
            Axios(options).then((response) => {
                setResources(response.data.data.quotationRequestResources);
                if (response.data.data.quotationRequest !== null) {
                    const pictureIds = getPictureFilesIds(response?.data?.data?.quotationRequest?.projectData);
                    setInitialPictureFiles(pictureIds);
                    setQuotationRequest({
                        ...response.data.data.quotationRequest,
                        projectData: {
                            estimatedInjectionMA: response?.data?.data?.quotationRequest?.projectData?.estimatedInjectionMA,
                            projectFiles: [
                                {
                                    name: response?.data?.data?.quotationRequest?.projectData?.name,
                                    picture: {
                                        id: response?.data?.data?.quotationRequest?.projectData?.picture?.id,
                                        name: response?.data?.data?.quotationRequest?.projectData?.picture?.name,
                                    },
                                    dataFile2D: {
                                        id: response?.data?.data?.quotationRequest?.projectData?.dataFile2D?.id,
                                        name: response?.data?.data?.quotationRequest?.projectData?.dataFile2D?.name,
                                    },
                                    dataFile3D: {
                                        id: response?.data?.data?.quotationRequest?.projectData?.dataFile3D?.id,
                                        name: response?.data?.data?.quotationRequest?.projectData?.dataFile3D?.name,
                                    },
                                },
                                ...response?.data?.data?.quotationRequest?.projectData?.additionalFiles,
                            ],
                        },
                    });
                } else {
                    setQuotationRequest({
                        contacts: [],
                        moldType: {
                            moldTypeId: null,
                            shotWarranty: {
                                id: null,
                                other: null,
                            },
                            moldStandardId: null,
                        },
                        projectData: {
                            projectFiles: [],
                            estimatedInjectionMA: null,
                        },
                        process: {
                            estimatedCycleTime: "",
                            calculatedClampingForce: "",
                            calculatedPartWeight: "",
                            calculatedRunnerWeight: "",
                            calculatedShotWeight: "",
                        },
                        cooling: {
                            temperatureCavitySide: null,
                            temperatureCoreSide: null,
                            isolationPlate: [],
                            coolingMediumId: 2,
                        },
                        moldLayout: {
                            numberOfCavity: "",
                            kindOfTool: {
                                firstLevelId: null,
                                secondLevelId: null,
                                thirdLevelId: null,
                            },
                            kindOfToolOthers: [
                                {
                                    id: null,
                                    value: "",
                                },
                            ],
                            partTakeOutId: null,
                        },
                        material: {
                            names: [],
                            inserts: [],
                            films: [],
                        },
                        demolding: {
                            slider: [],
                            lifter: [],
                            ejectorDesign: [],
                            ejectorFunction: [],
                            unscrewing: null,
                        },
                        runnerSystem: [],
                        steel: {
                            insertSteel: {
                                cavitySide: {
                                    checked: false,
                                    value: "",
                                },
                                coreSide: {
                                    checked: false,
                                    value: "",
                                },
                                slider: {
                                    checked: false,
                                    value: "",
                                },
                                lifter: {
                                    checked: false,
                                    value: "",
                                },
                                stripperPlate: {
                                    checked: false,
                                    value: "",
                                },
                            },
                            mouldBase: {
                                aPlate: {
                                    checked: false,
                                    value: "",
                                },
                                bPlate: {
                                    checked: false,
                                    value: "",
                                },
                                backupPlate: {
                                    checked: false,
                                    value: "",
                                },
                                hotrunnerPlate: {
                                    checked: false,
                                    value: "",
                                },
                                clampingPlate: {
                                    checked: false,
                                    value: "",
                                },
                                ejectorBasePlate: {
                                    checked: false,
                                    value: "",
                                },
                                ejectorHoldingPlate: {
                                    checked: false,
                                    value: "",
                                },
                            },
                        },
                        coating: {
                            cavitySide: null,
                            coreSide: null,
                            movingParts: null,
                        },
                        documentsForDelivery: {
                            steelCertification: true,
                            hardnessCertification: true,
                            coolingLayout: true,
                            injectionParameter: true,
                            measurementReport: true,
                            weeklyReport: true,
                            updated3dAssembly: true,
                            updated3dDetail: true,
                            updated2dAssembly: true,
                            updated2dDetail: true,
                            sparePartList: true,
                            maintenanceManual: true,
                            electricConnectingLayout: null,
                            hotrunnerData: null,
                            hydraulicConnecting: null,
                            functionFlowchart: null,
                        },
                        surface: {
                            cavitySide: {
                                id: null,
                                value: "",
                            },
                            coreSide: {
                                id: null,
                                value: "",
                            },
                        },
                        connector: {
                            electricConnector: false,
                            hydraulicConnector: true,
                            airConnector: false,
                            switches: false,
                        },
                        moldbase: {
                            ejectorSystemId: null,
                            interlockSystemId: null,
                            clampingSystemId: null,
                        },
                        sketch: {
                            file: null,
                        },
                    });
                }
            });
        }
    }, [props.toolMakerId, props.toolId, props.projectId]);

    const addNewContact = () => {
        const newQuot = { ...quotationRequest };

        newQuot.contacts.push({ moldMakerId: null, contactPersonId: null, notSaved: true });

        setQuotationRequest(newQuot);
    };

    const updateContact = (index, type, value) => {
        const newQuot = { ...quotationRequest };

        newQuot.contacts[index][type] = value;

        setQuotationRequest(newQuot);
    };

    const deleteContact = (index) => {
        const newQuot = { ...quotationRequest };

        newQuot.contacts.splice(index, 1);

        setQuotationRequest(newQuot);
    };

    const updateQuotationRequest = (category, info, value, index) => {
        let newQuot = { ...quotationRequest };

        if (index !== undefined && index !== null) {
            newQuot[category][index][info] = value;
        } else {
            newQuot[category][info] = value;
        }

        setQuotationRequest(newQuot);
    };

    const updateQuotationRequestLists = (category, info, value, field, index) => {
        let newQuot = { ...quotationRequest };

        newQuot[category][info][index][field] = value;

        setQuotationRequest(newQuot);
    };

    const addNewRow = (category, info, value) => {
        let newQuot = { ...quotationRequest };

        if (info !== null) {
            newQuot[category][info].push(value);
        } else {
            newQuot[category].push(value);
        }

        setQuotationRequest(newQuot);
    };

    const removeRow = (category, info, index, runnerSystem) => {
        let newQuot = { ...quotationRequest };
        const tmp = [...filesToDelete];
        if (runnerSystem === false) {
            if (
                newQuot[category][info][index].fileId !== undefined &&
                newQuot[category][info][index].fileId !== null &&
                newQuot[category][info][index].fileId !== ""
            ) {
                if (info !== "projectFiles") tmp.push(newQuot[category][info][index].fileId);
            }
            setFilesToDelete(tmp);
            newQuot[category][info].splice(index, 1);
        } else {
            if (
                newQuot[category][info][index].fileId !== undefined &&
                newQuot[category][info][index].fileId !== null &&
                newQuot[category][info][index].fileId !== ""
            ) {
                tmp.push(newQuot[category][info][index].fileId);
            }
            newQuot.runnerSystem[index].gateSystemOthers.forEach((f) => {
                if (f.fileId !== undefined && f.fileId !== null && f.fileId !== "") {
                    tmp.push(f.fileId);
                }
            });
            setFilesToDelete(tmp);
            newQuot[category][info].splice(index, 1);
            newQuot.runnerSystem.splice(index, 1);
        }
        setQuotationRequest(newQuot);
    };

    const submitData = async (data) => {
        const payload = { ...data };
        payload.contacts = data.contacts.map((el) => {
            return { moldMakerId: el.moldMakerId, contactPersonId: el.contactPersonId };
        });
        const options = {
            url: "/business-logic/tools/" + props.toolId + "/quotation-request",
            method: "PUT",
            data: payload,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options)
            .then((response) => {
                getQuotationRequest();
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    const updatePayload = async (set) => {
        let newQuotReq = { ...quotationRequest };
        let additionalFiles = [];
        let mainFiles = {};
        for (let i = 0; i < set.length; i++) {
            for (const [key, value] of Object.entries(set[i])) {
                if (value) {
                    switch (key) {
                        case "picture":
                            if (!value.id) {
                                updateNewFiles("projectData", "projectFiles", null, value.file, value.name, null, "picture", i, set[i]?.id);
                            } else {
                                let picture = {
                                    id: value.id,
                                    name: value.name,
                                };
                                newQuotReq.projectData.projectFiles[i][key] = picture;
                                setFinalPictureFiles((current) => [...current, value.id]);
                            }
                            break;
                        case "dataFile3D":
                            if (!value.id) {
                                updateHistoryFiles("projectData", "projectFiles", value.file, value.file.name, "dataFile3D", i, set[i]?.id);
                            } else {
                                let dataFile3D = {
                                    id: value.id,
                                    name: value.name,
                                };
                                newQuotReq.projectData.projectFiles[i][key] = dataFile3D;
                            }
                            break;
                        case "dataFile2D":
                            if (!value.id) {
                                updateHistoryFiles("projectData", "projectFiles", value.file, value.file.name, "dataFile2D", i, set[i]?.id);
                            } else {
                                let dataFile2D = {
                                    id: value.id,
                                    name: value.name,
                                };
                                newQuotReq.projectData.projectFiles[i][key] = dataFile2D;
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
            if (i === 0) {
                mainFiles = { ...newQuotReq.projectData.projectFiles[i] };
            } else {
                additionalFiles.push({ ...newQuotReq.projectData.projectFiles[i] });
            }
        }
        newQuotReq.projectData = { ...newQuotReq.projectData, ...mainFiles };
        newQuotReq.projectData.additionalFiles = additionalFiles;
        delete newQuotReq.projectData.projectFiles;

        return newQuotReq;
    };

    const handleSubmit = async () => {
        let quotationRequestClone = await updatePayload(quotationRequest.projectData.projectFiles);

        let filesToBeDeleted = newFiles.filter((el) => el.idToBeDeleted).map((el) => el.idToBeDeleted);
        if (filesToDelete.length > 0 || filesToBeDeleted.length > 0) {
            const options = {
                url: "/file-operations/" + props.toolId + "-specification/files",
                method: "DELETE",
                headers: {
                    "X-Files": [...filesToDelete, ...filesToBeDeleted, ...initialPictureFiles.filter((id) => !finalPictureFiles.includes(id))],
                    "X-Loading": false,
                },
            };
            Axios(options).then((rsp) => {});
        }

        if (historyFiles.length > 0 || newFiles.length > 0) {
            if (historyFiles.length > 0) {
                for (const [historyIndex, historyFile] of historyFiles.entries()) {
                    let historyFileFormData = new FormData();
                    historyFileFormData.append("files", historyFile.newFile, historyFile.newFileName);

                    const optionsHistory = {
                        url:
                            "/file-operations/" +
                            props.toolId +
                            `${historyFile.projectId ? "-" + historyFile.projectId : ""}` +
                            `${historyFile.subInfo === "dataFile3D" ? "-specification-3d" : "-specification-2d"}` +
                            "/files",
                        method: "POST",
                        data: historyFileFormData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    let historyFileRes = await Axios(optionsHistory);

                    if (historyFile.projectIndex === 0) {
                        quotationRequestClone[historyFile.category][historyFile.subInfo] = {
                            id: historyFileRes?.data?.data?.files[0].id,
                            name: historyFileRes?.data?.data?.files[0].name,
                        };
                    } else {
                        quotationRequestClone[historyFile.category].additionalFiles[historyFile.projectIndex - 1][historyFile.subInfo] = {
                            id: historyFileRes?.data?.data?.files[0].id,
                            name: historyFileRes?.data?.data?.files[0].name,
                        };
                    }

                    if (historyIndex === historyFiles.length - 1) {
                        if (newFiles.length > 0) {
                            let newFilesFormData = new FormData();
                            const newFilesWithoutAdditionalPictures = newFiles.filter((file) => file.info !== "projectFiles" || file.projectIndex === 0);
                            const filesWithAdditionalPictures = newFiles.filter((file) => file.info === "projectFiles" && file.projectIndex !== 0);
                            if (newFilesWithoutAdditionalPictures.length > 0) {
                                newFilesWithoutAdditionalPictures.forEach((file) => {
                                    newFilesFormData.append("files", file.newFile, file.newFileName);
                                });

                                const options = {
                                    url: "/file-operations/" + props.toolId + "-specification/files",
                                    method: "POST",
                                    data: newFilesFormData,
                                    headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                                };
                                const response = await Axios(options);
                                response.data.data.files.forEach((el, i) => {
                                    if (newFilesWithoutAdditionalPictures[i].index !== undefined && newFilesWithoutAdditionalPictures[i].index !== null) {
                                        if (
                                            newFilesWithoutAdditionalPictures[i].sectionIndex !== undefined &&
                                            newFilesWithoutAdditionalPictures[i].sectionIndex !== null
                                        ) {
                                            quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][
                                                newFilesWithoutAdditionalPictures[i].sectionIndex
                                            ][newFilesWithoutAdditionalPictures[i].info][newFilesWithoutAdditionalPictures[i].index].fileId = el.id;
                                            quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][
                                                newFilesWithoutAdditionalPictures[i].sectionIndex
                                            ][newFilesWithoutAdditionalPictures[i].info][newFilesWithoutAdditionalPictures[i].index].filename = el.name;
                                        } else {
                                            quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info][
                                                newFilesWithoutAdditionalPictures[i].index
                                            ].fileId = el.id;
                                            quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info][
                                                newFilesWithoutAdditionalPictures[i].index
                                            ].filename = el.name;
                                        }
                                    } else {
                                        if (newFilesWithoutAdditionalPictures[i].info === "projectFiles") {
                                            if (newFilesWithoutAdditionalPictures[i].projectIndex === 0) {
                                                quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][
                                                    newFilesWithoutAdditionalPictures[i].sectionIndex
                                                ] = {
                                                    id: el.id,
                                                    name: el.name,
                                                };
                                            } else {
                                                quotationRequestClone[newFilesWithoutAdditionalPictures[i].category].additionalFiles[
                                                    newFilesWithoutAdditionalPictures[i].projectIndex - 1
                                                ][newFilesWithoutAdditionalPictures[i].sectionIndex] = {
                                                    id: el.id,
                                                    name: el.name,
                                                };
                                            }
                                        } else {
                                            quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info] = {
                                                id: el.id,
                                                name: el.name,
                                            };
                                        }
                                    }
                                });
                            }
                            if (filesWithAdditionalPictures.length > 0) {
                                for (const [pictureIndex, pictureFile] of filesWithAdditionalPictures.entries()) {
                                    let newPictureFormData = new FormData();
                                    newPictureFormData.append("files", pictureFile.newFile, pictureFile.newFileName);
                                    const optionsPicture = {
                                        url:
                                            "/file-operations/" +
                                            props.toolId +
                                            `${pictureFile.projectId ? "-" + pictureFile.projectId : ""}` +
                                            "-specification/files",
                                        method: "POST",
                                        data: newPictureFormData,
                                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                                    };
                                    let pictureFileRes = await Axios(optionsPicture);
                                    quotationRequestClone[pictureFile.category].additionalFiles[pictureFile.projectIndex - 1][pictureFile.sectionIndex] = {
                                        id: pictureFileRes?.data?.data?.files[0].id,
                                        name: pictureFileRes?.data?.data?.files[0].name,
                                    };
                                }
                            }
                            setHistoryFiles([]);
                            setNewFiles([]);
                            await submitData(quotationRequestClone);
                        } else {
                            setHistoryFiles([]);
                            setNewFiles([]);
                            await submitData(quotationRequestClone);
                        }
                    }
                }
            } else {
                let newFilesFormData = new FormData();
                const newFilesWithoutAdditionalPictures = newFiles.filter((file) => file.info !== "projectFiles" || file.projectIndex === 0);
                const filesWithAdditionalPictures = newFiles.filter((file) => file.info === "projectFiles" && file.projectIndex !== 0);
                if (newFilesWithoutAdditionalPictures.length > 0) {
                    newFilesWithoutAdditionalPictures.forEach((file) => {
                        newFilesFormData.append("files", file.newFile, file.newFileName);
                    });

                    const options = {
                        url: "/file-operations/" + props.toolId + "-specification/files",
                        method: "POST",
                        data: newFilesFormData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    let response = await Axios(options);
                    response.data.data.files.forEach((el, i) => {
                        if (newFilesWithoutAdditionalPictures[i].index !== undefined && newFilesWithoutAdditionalPictures[i].index !== null) {
                            if (newFilesWithoutAdditionalPictures[i].sectionIndex !== undefined && newFilesWithoutAdditionalPictures[i].sectionIndex !== null) {
                                quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].sectionIndex][
                                    newFilesWithoutAdditionalPictures[i].info
                                ][newFilesWithoutAdditionalPictures[i].index].fileId = el.id;
                                quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].sectionIndex][
                                    newFilesWithoutAdditionalPictures[i].info
                                ][newFilesWithoutAdditionalPictures[i].index].filename = el.name;
                            } else {
                                quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info][
                                    newFilesWithoutAdditionalPictures[i].index
                                ].fileId = el.id;
                                quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info][
                                    newFilesWithoutAdditionalPictures[i].index
                                ].filename = el.name;
                            }
                        } else {
                            if (newFilesWithoutAdditionalPictures[i].info === "projectFiles") {
                                if (newFilesWithoutAdditionalPictures[i].projectIndex === 0) {
                                    quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].sectionIndex] = {
                                        id: el.id,
                                        name: el.name,
                                    };
                                } else {
                                    quotationRequestClone[newFilesWithoutAdditionalPictures[i].category].additionalFiles[
                                        newFilesWithoutAdditionalPictures[i].projectIndex - 1
                                    ][newFilesWithoutAdditionalPictures[i].sectionIndex] = {
                                        id: el.id,
                                        name: el.name,
                                    };
                                }
                            } else {
                                quotationRequestClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info] = {
                                    id: el.id,
                                    name: el.name,
                                };
                            }
                        }
                    });
                }
                if (filesWithAdditionalPictures.length > 0) {
                    for (const [pictureIndex, pictureFile] of filesWithAdditionalPictures.entries()) {
                        let newPictureFormData = new FormData();
                        newPictureFormData.append("files", pictureFile.newFile, pictureFile.newFileName);
                        const optionsPicture = {
                            url: "/file-operations/" + props.toolId + `${pictureFile.projectId ? "-" + pictureFile.projectId : ""}` + "-specification/files",
                            method: "POST",
                            data: newPictureFormData,
                            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                        };
                        let pictureFileRes = await Axios(optionsPicture);
                        quotationRequestClone[pictureFile.category].additionalFiles[pictureFile.projectIndex - 1][pictureFile.sectionIndex] = {
                            id: pictureFileRes?.data?.data?.files[0].id,
                            name: pictureFileRes?.data?.data?.files[0].name,
                        };
                    }
                }
                setNewFiles([]);
                await submitData(quotationRequestClone);
            }
        } else {
            submitData(quotationRequestClone);
        }
    };

    const getQuotationRequest = () => {
        const options = {
            url: "/business-logic/tools/" + props.toolId + "/quotation-request",
            method: "GET",
        };
        Axios(options).then((response) => {
            setQuotationRequest({
                ...response.data.data,
                projectData: {
                    estimatedInjectionMA: response?.data?.data?.projectData?.estimatedInjectionMA,
                    projectFiles: [
                        {
                            name: response?.data?.data?.projectData?.name,
                            picture: response?.data?.data?.projectData.picture,
                            dataFile2D: response?.data?.data?.projectData.dataFile2D,
                            dataFile3D: response?.data?.data?.projectData.dataFile3D,
                        },
                        ...response?.data?.data?.projectData.additionalFiles,
                    ],
                },
            });
        });
    };

    const updateNewFiles = (category, info, id, newFile, newFileName, indexToInsert, sectionIndex, projectIndex, projectId) => {
        let newFilesClone = newFiles;

        if (indexToInsert === null) {
            if (info !== "projectFiles") {
                const index = newFilesClone.findIndex((el) => el.category === category && el.info === info);
                if (index > -1) {
                    newFilesClone.splice(index, 1);
                }
            }
        } else {
            if (sectionIndex && sectionIndex !== "" && sectionIndex !== null) {
                const index = newFilesClone.findIndex(
                    (el) => el.category === category && el.info === info && el.index === indexToInsert && el.sectionIndex === sectionIndex
                );
                if (index > -1) {
                    newFilesClone.splice(index, 1);
                }
            } else {
                const index = newFilesClone.findIndex((el) => el.category === category && el.info === info && el.index === indexToInsert);
                if (index > -1) {
                    newFilesClone.splice(index, 1);
                }
            }
        }
        newFilesClone.push({
            idToBeDeleted: id,
            category: category,
            info: info,
            newFile: newFile,
            newFileName: newFileName,
            index: indexToInsert,
            sectionIndex: sectionIndex,
            projectIndex: projectIndex,
            projectId: projectId,
        });
        setNewFiles(newFilesClone);
    };

    const updateHistoryFiles = (category, info, newFile, newFileName, subInfo, projectIndex, projectId) => {
        let historyFilesClone = historyFiles;

        let index = historyFilesClone.findIndex((el) => el.category === category && el.info === info);
        if (subInfo) {
            index = historyFilesClone.findIndex(
                (el) => el.category === category && el.info === info && el.subInfo == subInfo && el.projectIndex == projectIndex
            );
        }
        if (index > -1) {
            historyFilesClone.splice(index, 1);
        }

        historyFilesClone.push({
            category: category,
            info: info,
            newFile: newFile,
            newFileName: newFileName,
            subInfo: subInfo,
            projectIndex: projectIndex,
            projectId: projectId,
        });

        setHistoryFiles(historyFilesClone);
    };

    const deleteFromNewFiles = (category, info, index, sectionIndex) => {
        let newFilesClone = newFiles;

        let indexToDelete = -1;
        if (sectionIndex !== undefined && sectionIndex !== "" && sectionIndex !== null) {
            const tmp = [...filesToDelete];
            if (
                quotationRequest[category][sectionIndex][info][index].fileId !== undefined &&
                quotationRequest[category][sectionIndex][info][index].fileId !== null &&
                quotationRequest[category][sectionIndex][info][index].fileId !== ""
            ) {
                tmp.push(quotationRequest[category][sectionIndex][info][index].fileId);
            }
            setFilesToDelete(tmp);
            indexToDelete = newFilesClone.findIndex(
                (el) => el.category === category && el.info === info && el.index === index && el.sectionIndex === sectionIndex
            );
            if (indexToDelete > -1) {
                newFilesClone.splice(indexToDelete, 1);
            }
            newFilesClone.forEach((newFile) => {
                if (newFile.category === category && newFile.info === info && newFile.sectionIndex === sectionIndex) {
                    if (newFile.index !== null && newFile.index !== undefined && newFile.index > index) {
                        newFile.index--;
                    }
                }
            });
        } else {
            indexToDelete = newFilesClone.findIndex((el) => el.category === category && el.info === info && el.index === index);
            if (indexToDelete > -1) {
                newFilesClone.splice(indexToDelete, 1);
            }
            newFilesClone.forEach((newFile) => {
                if (newFile.category === category && newFile.info === info) {
                    if (newFile.index && newFile.index > index) {
                        newFile.index--;
                    }
                }
            });
            if (category === "material" && info === "names") {
                newFilesClone = newFilesClone.filter((f, idx) => {
                    return !(f.category === "runnerSystem" && f.info === "gateSystemOthers" && f.sectionIndex === index);
                });
                newFilesClone.forEach((f) => {
                    if (f.sectionIndex > index) {
                        f.sectionIndex -= 1;
                    }
                });
            }
        }
        setNewFiles(newFilesClone);
    };

    useEffect(() => {
        setFormDisabled(role === 2);
    }, [role]);

    const onModalClose = () => {
        setNewMoldMaker(false);
    };

    const openMoldMakerModalHandler = () => {
        setNewMoldMaker(true);
    };

    return (
        <>
            <div className={classes.wrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit().then((r) => {});
                    }}
                    className={classes.layoutWrapper}
                >
                    {role !== 2 && (
                        <div className={classes.rowWrapper}>
                            <div className={classes.buttonWrapper}>
                                <StyledButton className={classes.saveButton} color="primary" variant="contained" type="submit">
                                    {languageData?.Save.toUpperCase()}
                                </StyledButton>
                                {role === 0 && (
                                    <StyledButton className={classes.chooseButton} variant="contained" onClick={openMoldMakerModalHandler}>
                                        {languageData?.ChooseMoldMaker.toUpperCase()}
                                    </StyledButton>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className={classNames(classes.contentWrapper, {
                            [classes.customerContentWrapper]: role === 1,
                            [classes.moldMakerContentWrapper]: role === 2,
                        })}
                    >
                        {role === 0 && (
                            <div className={classes.messageGrid}>
                                <Alert severity="warning">{languageData?.MoldSpecWarning}</Alert>
                            </div>
                        )}
                        {role === 0 && (
                            <QuotationRequestContacts
                                moldMakers={resources.tool_makers}
                                contacts={quotationRequest?.contacts}
                                updateContact={updateContact}
                                addNewContact={addNewContact}
                                deleteContact={deleteContact}
                            />
                        )}
                        {role === 0 && <Divider className={classes.divider} />}
                        <ProjectData
                            disabled={formDisabled}
                            resources={resources}
                            updateProjectData={updateQuotationRequest}
                            updateProjectDataList={updateQuotationRequestLists}
                            projectData={quotationRequest?.projectData}
                            updateNewFiles={updateNewFiles}
                            updateHistoryFiles={updateHistoryFiles}
                            customerId={props.customerId}
                            toolId={props.toolId}
                            addNewRow={addNewRow}
                            removeRow={removeRow}
                            moldSpecification={quotationRequest}
                        />
                        <Divider className={classes.divider} />
                        <MoldType
                            disabled={formDisabled}
                            updateMoldType={updateQuotationRequest}
                            selectedMoldType={quotationRequest?.moldType}
                            moldStandardValues={resources?.moldStandard}
                        />
                        <Divider className={classes.divider} />
                        <Process disabled={formDisabled} processValues={quotationRequest?.process} updateProcessValue={updateQuotationRequest} />
                        <Divider className={classes.divider} />
                        <Material
                            disabled={formDisabled}
                            resources={resources}
                            updateMaterialData={updateQuotationRequestLists}
                            addNewRow={addNewRow}
                            removeRow={removeRow}
                            deleteFromNewFiles={deleteFromNewFiles}
                            materialData={quotationRequest?.material}
                            updateNewFiles={updateNewFiles}
                            toolId={props.toolId}
                        />
                        <Divider className={classes.divider} />
                        <MoldLayout disabled={formDisabled} updateMoldLayout={updateQuotationRequest} moldLayout={quotationRequest?.moldLayout} />
                        <Divider className={classes.divider} />
                        {quotationRequest?.runnerSystem.map((el, index) => (
                            <div key={index}>
                                <RunnerSystem
                                    disabled={formDisabled}
                                    files={newFiles.filter(
                                        (el) => el.category === "runnerSystem" && el.info === "gateSystemOthers" && el.sectionIndex === index
                                    )}
                                    update={updateQuotationRequest}
                                    data={el}
                                    resources={resources}
                                    updateNewFiles={updateNewFiles}
                                    deleteFromNewFiles={deleteFromNewFiles}
                                    customerId={props.customerId}
                                    toolId={props.toolId}
                                    index={index}
                                />
                            </div>
                        ))}
                        <Divider className={classes.divider} />
                        <Demolding disabled={formDisabled} updateDemoldingData={updateQuotationRequest} demoldingData={quotationRequest?.demolding} />
                        <Divider className={classes.divider} />
                        <Cooling
                            disabled={formDisabled}
                            updateCooling={updateQuotationRequest}
                            toolId={props.toolId}
                            data={quotationRequest?.cooling}
                            customerId={props.customerId}
                        />
                        <Divider className={classes.divider} />
                        <Surface
                            disabled={formDisabled}
                            updateSurfaceData={updateQuotationRequest}
                            toolId={props.toolId}
                            surfaceData={quotationRequest?.surface}
                            customerId={props.customerId}
                        />
                        <Divider className={classes.divider} />
                        <MoldBase
                            disabled={formDisabled}
                            moldbaseData={quotationRequest?.moldbase}
                            guidingSystem={resources?.moldStandard?.find((el) => el.id === quotationRequest?.moldType?.moldStandardId)?.text}
                            ejectorSystem={resources?.ejectorSystem}
                            interlockSystem={resources?.interlockSystem}
                            clampingSystem={resources?.clampingSystem}
                            updateMoldbaseData={updateQuotationRequest}
                        />
                        <Divider className={classes.divider} />
                        <Steel
                            disabled={formDisabled}
                            steelData={quotationRequest?.steel}
                            toolId={props.toolId}
                            updateSteelData={updateQuotationRequest}
                            customerId={props.customerId}
                        />
                        <Divider className={classes.divider} />
                        <Coating
                            disabled={formDisabled}
                            coatingData={quotationRequest?.coating}
                            updateCoatingData={updateQuotationRequest}
                            customerId={props.customerId}
                            gms12={resources?.gms12}
                            toolId={props.toolId}
                        />
                        <Divider className={classes.divider} />
                        <Connector
                            disabled={formDisabled}
                            connectorData={quotationRequest?.connector}
                            updateConnectorData={updateQuotationRequest}
                            toolId={props.toolId}
                            customerId={props.customerId}
                        />
                        <Divider className={classes.divider} />
                        <DocumentsForDelivery
                            disabled={formDisabled}
                            updateDocumentsForDelivery={updateQuotationRequest}
                            data={quotationRequest?.documentsForDelivery}
                            values={resources.gms12}
                            customerId={props.customerId}
                            toolId={props.toolId}
                        />
                        <Sketch disabled={formDisabled} data={quotationRequest?.sketch} toolId={props.toolId} updateNewFiles={updateNewFiles} />
                    </div>
                </FormLayout>
            </div>
            {role === 0 && (
                <MoldMakerModal
                    save={handleSubmit}
                    toolId={props.toolId}
                    getData={props.getData}
                    isOpen={newMoldMaker}
                    onClose={onModalClose}
                    moldDescription={props.tool?.description || ""}
                    moldName={props.tool?.name || ""}
                    toolMakers={resources?.tool_makers
                        ?.filter((el) => quotationRequest?.contacts?.some((contact) => contact.moldMakerId === el.id && !contact.notSaved) || false)
                        .map((el) => {
                            return {
                                id: el.id,
                                name: el.name,
                            };
                        })}
                />
            )}
        </>
    );
};

export default QuotationRequest;
