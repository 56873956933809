import { Button, Dialog, DialogContent, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import BackupIcon from "@material-ui/icons/Backup";
import StyledButton from "../../StyledButton/StyledButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React, { useContext, useState } from "react";
import WebsiteContext from "../../../utils/Website";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: "500px",
        maxWidth: "unset",
        height: "auto",
        borderRadius: "1.4px",
        padding: "10px",
        [theme.breakpoints.down(700)]: {
            width: "80vw",
        },
    },
    dialog: {
        maxWidth: "unset",
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    dialogContentWrapper: {
        "&:first-child": {
            padding: 0,
        },
        padding: 0,
    },
    title: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "center",
        marginTop: "50px",
        marginBottom: "25px",
        color: "#1F1F1F",
    },
    commentArea: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    textarea: {
        minHeight: 70,
        width: "100%",
        padding: 10,
        lineHeight: "12px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#191919",
        fontFamily: "Montserrat",
        background: "#FFFFFF",
        border: "1px solid #ECECEC",
        boxSizing: "border-box",
        borderRadius: 2.57,
    },
    commentAuthor: {
        display: "flex",
        marginBottom: 10,
    },
    acceptButtonDisagree: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: 33,
        width: 110,
        padding: "0 10px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            opacity: "50%",
        },
        border: "none",
    },
    commentButton: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        padding: "0 -10px 10px 0",
    },
    language: {
        width: 30,
        textAlign: "right",
        lineHeight: "24px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#A5A5A5",
        marginRight: 16,
    },
}));

const AddCommentModal = (props) => {
    const classes = useStyles();
    const { languageData } = useContext(WebsiteContext);

    const [commentEn, setCommentEn] = useState("");
    const [commentDe, setCommentDe] = useState("");

    const submitComment = () => {
        props.addComment(true, commentDe, commentEn, clean);
    };

    const clean = () => {
        setCommentEn("");
        setCommentDe("");
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} classes={{ paperWidthSm: classes.dialog }}>
            <DialogContent className={classes.dialogContentWrapper}>
                <div className={classes.modalWrapper}>
                    <IconButton className={classes.icon} onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                    <div className={classes.title}>{languageData?.DisagreeMessage}</div>
                    <div className={classes.commentArea}>
                        <div className={classes.commentAuthor}>
                            <div className={classes.language}>EN</div>
                            <textarea
                                value={commentEn}
                                onChange={(e) => setCommentEn(e.target.value)}
                                className={classes.textarea}
                                placeholder="Add a comment.."
                            />
                        </div>
                        <div className={classes.commentAuthor}>
                            <div className={classes.language}>D</div>
                            <textarea
                                value={commentDe}
                                onChange={(e) => setCommentDe(e.target.value)}
                                className={classes.textarea}
                                placeholder="Einen Kommentar hinzufügen.."
                            />
                        </div>
                        <div className={classes.commentButton}>
                            <StyledButton
                                disabled={commentEn || commentDe ? false : true}
                                onClick={() => submitComment()}
                                className={classes.acceptButtonDisagree}
                                variant="outlined"
                            >
                                {languageData?.Comment}
                            </StyledButton>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AddCommentModal;
