import { Button, Grid, makeStyles, MenuItem, Select, IconButton, Divider } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import StyledInput from "../../../StyledInput/StyledInput";
import CachedIcon from "@material-ui/icons/Cached";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import HistoryFiles from "../../../HistoryFiles/HistoryFiles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
const { v4: uuidv4 } = require("uuid");
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        marginTop: 15,
    },
    inputWrapper: {
        width: 210,
        marginTop: 20,
    },
    nameInputWrapper: {
        width: 210,
        marginTop: 4,
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    showMaButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginLeft: "15px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    linkWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        maxWidth: "190px",
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "12px",
        color: "#646363",
        marginRight: 5,
    },
    accessibleLink: {
        textDecorationLine: "underline",
        cursor: "pointer",
        color: "#00A7D1",
    },
    selectWrapper: {
        width: "210px",
    },
    contentMargin: {
        marginTop: 4,
        [theme.breakpoints.down(500)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    fileInputWrapper: { width: 1, height: 1, opacity: 1 },
    divider: {
        width: "100%",
        marginTop: "20px",
    },
}));

const ProjectData = (props) => {
    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);
    const [selectedEstimatedInjection, setSelectedEstimatedInjection] = useState("");
    const [showHistory, setShowHistory] = useState("");
    const [projectIdHistory, setProjectIdHistory] = useState();
    const [historyFiles, setHistoryFiles] = useState([]);
    const fileNameLength = 15;

    useEffect(() => {
        if (props.projectData) {
            if (props.projectData?.projectFiles?.length === 0) {
                addNewProjectFiles(true);
            } else {
                for (let i = 0; i < props.projectData?.projectFiles?.length; i++) {
                    for (const [key, value] of Object.entries(props.projectData?.projectFiles[i])) {
                        switch (key) {
                            case "picture":
                                if (
                                    props.projectData?.projectFiles[i].picture &&
                                    props.projectData?.projectFiles[i]?.picture?.id &&
                                    props.projectData?.projectFiles[i]?.picture?.name
                                ) {
                                    let picture = {
                                        id: props.projectData?.projectFiles[i]?.picture?.id,
                                        name: createFileName(props.projectData?.projectFiles[i]?.picture?.name),
                                        accessible: true,
                                    };
                                    props.updateProjectDataList("projectData", "projectFiles", picture, "picture", i);
                                } else {
                                    props.updateProjectDataList("projectData", "projectFiles", null, "picture", i);
                                }
                            case "dataFile3D":
                                if (
                                    props.projectData?.projectFiles[i].dataFile3D &&
                                    props.projectData?.projectFiles[i]?.dataFile3D?.id &&
                                    props.projectData?.projectFiles[i]?.dataFile3D?.name
                                ) {
                                    let dataFile3D = {
                                        id: props.projectData?.projectFiles[i]?.dataFile3D?.id,
                                        name: createFileName(props.projectData?.projectFiles[i]?.dataFile3D?.name),
                                        accessible: true,
                                    };
                                    props.updateProjectDataList("projectData", "projectFiles", dataFile3D, "dataFile3D", i);
                                } else {
                                    props.updateProjectDataList("projectData", "projectFiles", null, "dataFile3D", i);
                                }
                            case "dataFile2D":
                                if (
                                    props.projectData?.projectFiles[i].dataFile2D &&
                                    props.projectData?.projectFiles[i]?.dataFile2D?.id &&
                                    props.projectData?.projectFiles[i]?.dataFile2D?.name
                                ) {
                                    let dataFile2D = {
                                        id: props.projectData?.projectFiles[i]?.dataFile2D?.id,
                                        name: createFileName(props.projectData?.projectFiles[i]?.dataFile2D?.name),
                                        accessible: true,
                                    };
                                    props.updateProjectDataList("projectData", "projectFiles", dataFile2D, "dataFile2D", i);
                                } else {
                                    props.updateProjectDataList("projectData", "projectFiles", null, "dataFile2D", i);
                                }
                        }
                    }
                }
            }
        }
        setSelectedEstimatedInjection(props.projectData?.estimatedInjectionMA ? props.projectData.estimatedInjectionMA : "");
    }, [props.projectData]);

    const handleOpenCustomerGMS = () => {
        if (role === 0) {
            window.open(window.location.origin + "/customers/" + props.customerId + "/gts", "_blank", "noopener,noreferrer");
        } else if (role === 1) {
            window.open(window.location.origin + "/customer/gts", "_blank", "noopener,noreferrer");
        } else {
            window.open(window.location.origin + "/tool/" + props.toolId + "/gms", "_blank", "noopener,noreferrer");
        }
    };

    const createFileName = (fileName) => {
        let filename;
        const sections = fileName.split(".");
        if (sections.length > 1) {
            let firstPart = sections.slice(0, sections.length - 1).join(".");
            if (firstPart.length > fileNameLength) {
                firstPart = firstPart.substr(0, fileNameLength) + "...";
            }
            const lastPart = "." + sections[sections.length - 1];
            filename = firstPart + lastPart;
        } else {
            filename = sections[0].length > fileNameLength ? sections[0].substr(0, fileNameLength) : sections[0];
        }
        return filename;
    };

    const uploadFile = (event, subsection, id, index) => {
        let file = event.target.files[0];
        if (file) {
            let filename = createFileName(file.name);

            switch (subsection) {
                case "picture":
                    let picture = {
                        id: null,
                        name: filename,
                        file: file,
                    };
                    props.updateProjectDataList("projectData", "projectFiles", picture, "picture", index);
                    break;
                case "dataFile3D":
                    let file3D = {
                        id: null,
                        name: filename,
                        accessible: false,
                        file: file,
                    };
                    props.updateProjectDataList("projectData", "projectFiles", file3D, "dataFile3D", index);
                    break;
                case "dataFile2D":
                    let file2D = {
                        id: null,
                        name: filename,
                        accessible: false,
                        file: file,
                    };
                    props.updateProjectDataList("projectData", "projectFiles", file2D, "dataFile2D", index);
                    break;
                default:
                    break;
            }
        }
    };

    const handleOpenFile = (id, accessible, subsection, projectId) => {
        if (accessible) {
            switch (subsection) {
                case "picture":
                    window.open(`${process.env.REACT_APP_URL}file-operations/${props.toolId}${projectId ? "-" + projectId : ""}-specification/files/${id}`);
                    break;
                case "dataFile3D":
                    window.open(`${process.env.REACT_APP_URL}file-operations/${props.toolId}${projectId ? "-" + projectId : ""}-specification-3d/files/${id}`);
                    break;
                case "dataFile2D":
                    window.open(`${process.env.REACT_APP_URL}file-operations/${props.toolId}${projectId ? "-" + projectId : ""}-specification-2d/files/${id}`);
                    break;
                default:
                    break;
            }
        }
    };

    const handleSelectChange = (e) => {
        setSelectedEstimatedInjection(e.target.value);
        props.updateProjectData("projectData", "estimatedInjectionMA", e.target.value);
    };

    const viewHistory = (projectId, file) => {
        const options = {
            url:
                "/file-operations/" +
                props.toolId +
                `${projectId ? "-" + projectId : ""}` +
                `${file === "dataFile3D" ? "-specification-3d/files" : "-specification-2d/files"}`,
            method: "GET",
        };
        Axios(options).then((response) => {
            setHistoryFiles(response?.data?.data);
        });
        setProjectIdHistory(projectId);
        setShowHistory(file === "dataFile3D" ? "dataFile3D" : "dataFile2D");
    };

    const handleClose = () => {
        setShowHistory("");
        setProjectIdHistory(null);
    };

    const addNewProjectFiles = (isFirstSet = false) => {
        props.addNewRow("projectData", "projectFiles", {
            id: !isFirstSet ? uuidv4() : undefined,
            name: "",
            picture: null,
            dataFile3D: null,
            dataFile2D: null,
        });
    };

    const removeProjectFiles = (index) => {
        props.removeRow("projectData", "projectFiles", index, false);
    };

    return (
        <div className={classes.wrapper}>
            <HistoryFiles
                files={historyFiles}
                open={showHistory !== ""}
                onClose={handleClose}
                onClick={(id) => handleOpenFile(id, true, showHistory, projectIdHistory)}
            />
            <div className={classes.titleWrapper}>{languageData?.ProjectData.toUpperCase()}</div>
            <div>
                <div className={classes.labelWrapper}>{languageData?.ProjectName}</div>
                <div className={classes.textWrapper}>{props.resources.project_name}</div>
            </div>
            {props.projectData?.projectFiles?.map((fileGroup, index) => {
                return (
                    <React.Fragment key={index}>
                        <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                            <InputLabel required className={classes.labelWrapper}>
                                {languageData?.Name}
                            </InputLabel>
                            <StyledInput
                                disabled={props.disabled}
                                defaultValue={props.projectData && fileGroup.name ? fileGroup.name : ""}
                                required={index >= 1}
                                className={classes.nameInputWrapper}
                                onChange={(e) => props.updateProjectDataList("projectData", "projectFiles", e.target.value, "name", index)}
                                type="text"
                            />
                        </Grid>
                        <div>
                            <InputLabel required className={classes.labelWrapper}>
                                {languageData?.Picture}
                            </InputLabel>
                            <div className={classes.contentMargin}>
                                {fileGroup?.picture && (
                                    <span
                                        onClick={() => handleOpenFile(fileGroup?.picture?.id, fileGroup?.picture?.accessible, "picture", fileGroup?.id)}
                                        className={classes.linkWrapper + " " + (fileGroup?.picture?.accessible && classes.accessibleLink)}
                                    >
                                        {fileGroup?.picture?.name}
                                    </span>
                                )}{" "}
                                <Button
                                    disabled={props.disabled}
                                    variant="contained"
                                    component="label"
                                    className={classes.uploadFileButton}
                                    startIcon={fileGroup?.picture ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                >
                                    {fileGroup?.picture ? languageData?.ChangeFile : languageData?.UploadFile}
                                    <input
                                        type="file"
                                        required={index >= 1 && fileGroup?.picture == null}
                                        // required={pictureFile === null}
                                        onChange={(e) => uploadFile(e, "picture", fileGroup?.picture ? fileGroup?.picture?.fileId : null, index)}
                                        className={classes.fileInputWrapper}
                                    />
                                </Button>
                            </div>
                        </div>
                        <div>
                            <InputLabel required className={classes.labelWrapper}>
                                {languageData?.FileName3D}
                            </InputLabel>
                            <div className={classes.contentMargin}>
                                {fileGroup?.dataFile3D && (
                                    <span
                                        onClick={() =>
                                            handleOpenFile(fileGroup?.dataFile3D?.id, fileGroup?.dataFile3D?.accessible, "dataFile3D", fileGroup?.id)
                                        }
                                        className={classes.linkWrapper + " " + (fileGroup?.dataFile3D?.accessible && classes.accessibleLink)}
                                    >
                                        {fileGroup?.dataFile3D?.name}
                                    </span>
                                )}{" "}
                                <Button
                                    disabled={props.disabled}
                                    variant="contained"
                                    component="label"
                                    className={classes.uploadFileButton}
                                    startIcon={fileGroup?.dataFile3D ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                >
                                    {fileGroup?.dataFile3D ? languageData?.ChangeFile : languageData?.UploadFile}
                                    <input
                                        // required={file3D === null}
                                        required={index >= 1 && fileGroup?.dataFile3D == null}
                                        type="file"
                                        onChange={(e) => uploadFile(e, "dataFile3D", fileGroup?.dataFile3D ? fileGroup?.dataFile3D?.fileId : null, index)}
                                        className={classes.fileInputWrapper}
                                    />
                                </Button>
                                {fileGroup?.dataFile3D && fileGroup?.dataFile3D?.accessible && (
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.uploadFileButton}
                                        startIcon={<VisibilityIcon color="primary" />}
                                        onClick={() => viewHistory(fileGroup?.id, "dataFile3D")}
                                    >
                                        {languageData?.ViewHistory}
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div>
                            <InputLabel required className={classes.labelWrapper}>
                                {languageData?.FileName2D}
                            </InputLabel>
                            <div className={classes.contentMargin}>
                                {fileGroup?.dataFile2D && (
                                    <span
                                        onClick={() =>
                                            handleOpenFile(fileGroup?.dataFile2D?.id, fileGroup?.dataFile2D?.accessible, "dataFile2D", fileGroup?.id)
                                        }
                                        className={classes.linkWrapper + " " + (fileGroup?.dataFile2D?.accessible && classes.accessibleLink)}
                                    >
                                        {fileGroup?.dataFile2D?.name}
                                    </span>
                                )}{" "}
                                <Button
                                    disabled={props.disabled}
                                    variant="contained"
                                    component="label"
                                    className={classes.uploadFileButton}
                                    startIcon={fileGroup?.dataFile2D ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                                    style={
                                        !fileGroup?.dataFile2D?.accessible && index === props.projectData?.projectFiles?.length - 1
                                            ? { marginRight: 0 }
                                            : undefined
                                    }
                                >
                                    {fileGroup?.dataFile2D ? languageData?.ChangeFile : languageData?.UploadFile}
                                    <input
                                        type="file"
                                        // required={file2D === null}
                                        required={index >= 1 && fileGroup?.dataFile2D == null}
                                        onChange={(e) => uploadFile(e, "dataFile2D", fileGroup?.dataFile2D ? fileGroup?.dataFile2D?.fileId : null, index)}
                                        className={classes.fileInputWrapper}
                                    />
                                </Button>
                                {fileGroup?.dataFile2D && fileGroup?.dataFile2D?.accessible && (
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.uploadFileButton}
                                        startIcon={<VisibilityIcon color="primary" />}
                                        onClick={() => viewHistory(fileGroup?.id, "dataFile2D")}
                                        style={index === props.projectData?.projectFiles?.length - 1 ? { marginRight: 0 } : undefined}
                                    >
                                        {languageData?.ViewHistory}
                                    </Button>
                                )}
                                {index === props.projectData?.projectFiles?.length - 1 && (
                                    <IconButton
                                        disabled={props.disabled}
                                        classes={{ root: classes.root }}
                                        color="primary"
                                        className={classes.addButton}
                                        onClick={() => addNewProjectFiles()}
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                )}
                                {props.projectData?.projectFiles?.length !== 1 && index !== 0 && (
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.uploadFileButton}
                                        startIcon={<DeleteIcon color="primary" />}
                                        onClick={() => removeProjectFiles(index)}
                                    >
                                        {languageData?.Delete}
                                    </Button>
                                )}
                            </div>
                        </div>
                        {props.projectData?.projectFiles?.length > 1 && <Divider className={classes.divider} />}
                    </React.Fragment>
                );
            })}
            <div>
                <InputLabel required className={classes.labelWrapper}>
                    {languageData?.EstimatedInjectionMA}
                </InputLabel>
                <div className={classes.contentMargin}>
                    {props.resources.machineList && (
                        <FormControl>
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                classes={{
                                    root: classes.rootWrapper,
                                    selectMenu: classes.selectMenuWrapper,
                                }}
                                className={classes.selectWrapper}
                                variant={"outlined"}
                                value={selectedEstimatedInjection}
                                displayEmpty={selectedEstimatedInjection === ""}
                                renderValue={
                                    selectedEstimatedInjection === ""
                                        ? () => {
                                              return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                          }
                                        : null
                                }
                                onChange={(e) => handleSelectChange(e)}
                            >
                                <MenuItem value="" key={-1} disabled>
                                    <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                                </MenuItem>
                                {props.resources?.machineList?.map((machine) => {
                                    return (
                                        <MenuItem key={machine.id} value={machine.id}>
                                            <div className={classes.selectTextWrapper}>
                                                {machine.machineBrand}, {machine.machineType}, {machine.machineNr}, {machine.factoryMachineNumber}
                                            </div>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                    {role === 2 && (
                        <Button
                            variant="contained"
                            component="label"
                            className={classes.showMaButton}
                            disabled={selectedEstimatedInjection === ""}
                            startIcon={<VisibilityIcon color="primary" />}
                            onClick={() => {
                                window.open(window.location.origin + "/tool/" + props.toolId + "/machine/" + selectedEstimatedInjection, "_blank");
                            }}
                        >
                            {languageData?.ShowEstimatedInjection}
                        </Button>
                    )}
                </div>
            </div>
            <div>
                <div className={classes.labelWrapper}>{languageData?.CustomerGMS}</div>
                <div className={classes.contentMargin}>
                    <Button
                        variant="contained"
                        component="label"
                        className={classes.uploadFileButton}
                        startIcon={<OpenInNewIcon color="primary" />}
                        onClick={handleOpenCustomerGMS}
                    >
                        {languageData?.OpenCustomerGMS}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ProjectData;
