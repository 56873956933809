import React, { useContext, useEffect } from "react";
import WebsiteContext from "../../../../utils/Website";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import StyledInput from "../../../StyledInput/StyledInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: "8px",
    },
    subtitleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginBottom: 4,
    },
    selectWrapper: {
        width: "210px",
        height: "33px",
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    inputWrapper: {
        padding: "10px 14px",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    selectWithOthers: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    input: {
        width: "210px",
        marginTop: 10,
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    selectWithLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginRight: "10px",
        marginBottom: "4px",
    },
}));

const MoldType = (props) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const moldTypes = [
        { id: 1, key: "PrototypeMold" },
        { id: 2, key: "PreseriesMold" },
        { id: 3, key: "ProductionMold" },
    ];
    const shotWarranty = [
        { id: 1, value: "10 000" },
        { id: 2, value: "250 000" },
        { id: 3, value: "500 000" },
        { id: 4, value: "1 000 000" },
        { id: 5, value: "2 000 000" },
    ];

    useEffect(() => {
        if (props.selectedMoldType) {
            checkIfSelectedMoldStandardStillExists();
        }
    }, [props.selectedMoldType]);

    const checkIfSelectedMoldStandardStillExists = () => {
        if (
            !(
                props.selectedMoldType?.moldStandardId &&
                props.moldStandardValues.find((standardValue) => standardValue.id === props.selectedMoldType.moldStandardId)
            )
        ) {
            props.updateMoldType("moldType", "moldStandardId", "");
        }
    };

    const handleShotWarrantyChange = (event, column) => {
        const updatedShotWarranty = { ...props?.selectedMoldType?.shotWarranty };
        updatedShotWarranty[column] = event.target.value;
        props.updateMoldType("moldType", "shotWarranty", updatedShotWarranty);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.MoldType.toUpperCase()}</div>
            <div className={classes.contentWrapper}>
                <div className={classes.selectWithLabel}>
                    <InputLabel className={classes.subtitleWrapper}>{languageData?.MoldType}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={props.selectedMoldType?.moldTypeId && props.selectedMoldType?.moldTypeId !== null ? props.selectedMoldType.moldTypeId : ""}
                            displayEmpty={props.selectedMoldType?.moldTypeId === null}
                            renderValue={
                                props.selectedMoldType?.moldTypeId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateMoldType("moldType", "moldTypeId", e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {moldTypes.map((moldType) => {
                                return (
                                    <MenuItem value={moldType.id} key={moldType.id}>
                                        <div className={classes.selectTextWrapper}>{languageData[moldType.key]}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.selectWithLabel}>
                    <InputLabel className={classes.subtitleWrapper}>{languageData?.ShotWarranty}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={
                                props.selectedMoldType?.shotWarranty && props.selectedMoldType.shotWarranty.id !== null
                                    ? props.selectedMoldType.shotWarranty.id
                                    : ""
                            }
                            displayEmpty={props.selectedMoldType?.shotWarranty?.id === null}
                            renderValue={
                                props.selectedMoldType?.shotWarranty?.id === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                handleShotWarrantyChange(e, "id");
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {shotWarranty.map((shot) => {
                                return (
                                    <MenuItem value={shot.id} key={shot.id}>
                                        <div className={classes.selectTextWrapper}>{shot.value}</div>
                                    </MenuItem>
                                );
                            })}
                            <MenuItem value={0} key={0}>
                                <div className={classes.selectTextWrapper}>{languageData?.Others}</div>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {props.selectedMoldType?.shotWarranty?.id === 0 && (
                        <StyledInput
                            disabled={props.disabled}
                            className={classes.input}
                            defaultValue={props.selectedMoldType?.shotWarranty?.other ? props.selectedMoldType.shotWarranty.other : ""}
                            onChange={(e) => handleShotWarrantyChange(e, "other")}
                            type="text"
                            required={true}
                        />
                    )}
                </div>
                <div className={classes.selectWithLabel}>
                    <InputLabel className={classes.subtitleWrapper}>{languageData?.MoldStandard}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={
                                props.selectedMoldType?.moldStandardId && props.selectedMoldType?.moldStandardId !== null
                                    ? props.selectedMoldType.moldStandardId
                                    : ""
                            }
                            displayEmpty={props.selectedMoldType?.moldStandardId === null}
                            renderValue={
                                props.selectedMoldType?.moldStandardId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateMoldType("moldType", "moldStandardId", e.target.value);
                            }}
                        >
                            <MenuItem value="" disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props.moldStandardValues?.map((moldStandardItem) => {
                                return (
                                    <MenuItem value={moldStandardItem.id} key={moldStandardItem.id}>
                                        <div className={classes.selectTextWrapper}>{moldStandardItem.text}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

MoldType.propTypes = {
    updateMoldType: PropTypes.func,
    selectedMoldType: PropTypes.any,
    moldStandardValues: PropTypes.array,
};

export default MoldType;
