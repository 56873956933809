import { Card, Divider, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import WebsiteContext from "../../../utils/Website";

const useStyles = makeStyles(() => ({
    wrapper: {},
    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% - 5px)",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px",
        margin: "auto",
    },
    root: {
        minWidth: 194,
        margin: "2px 0 5px",
        width: "100%",
        height: "100%",
        float: "left",
        cursor: "pointer",
    },
    name: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "15px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    alignElements: {
        display: "flex",
        alignItems: "center",
        padding: "16px 0px 10px 16px",
    },
    nameWrapper: {
        padding: "16px 16px 10px 16px",
    },
    roleWrapper: {
        padding: "0px 0px 10px 16px",
    },
    divider: {
        width: "100%",
    },
    infoText: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "9px",
        lineHeight: "11px",
        color: "rgba(31, 31, 31, 0.5)",
        marginLeft: "2px",
    },
    roleText: {
        display: "inline",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "9px",
        lineHeight: "11px",
        color: "rgba(31, 31, 31, 0.5)",
    },
    roleName: {
        display: "inline",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "9px",
        lineHeight: "11px",
        marginLeft: "2px",
    },
}));

const MemberCard = (props) => {
    const classes = useStyles(useStyles);
    const [id, setId] = useState("");
    const { languageData } = useContext(WebsiteContext);

    useEffect(() => {
        setId(props.id);
    }, [props.id, id]);

    return (
        <Card classes={{ root: classes.root }}>
            <div className={classes.cardWrapper}>
                <div className={classes.nameWrapper}>
                    <div className={classes.name}>{props.name}</div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.alignElements}>
                    <CheckCircleOutlineIcon style={{ color: "#40CC78", fontSize: "11px" }} />
                    <div className={classes.infoText}>{languageData?.MemberAssigned}</div>
                </div>
                <div className={classes.roleWrapper}>
                    <div className={classes.roleText}>{languageData?.Role}:</div>
                    <div className={classes.roleName}>{props.roleName}</div>
                </div>
            </div>
        </Card>
    );
};

MemberCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    roleId: PropTypes.string,
    roleName: PropTypes.string,
};

export default MemberCard;
