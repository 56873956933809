const FilesIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 14 14" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M4 3V10H10V6.5H6.57143V3H4ZM7.42857 3V5.625H10L7.42857 3Z" />
            <path d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.906 12.6 1.4 10.094 1.4 7C1.4 3.906 3.906 1.4 7 1.4C10.094 1.4 12.6 3.906 12.6 7C12.6 10.094 10.094 12.6 7 12.6Z" />
        </svg>
    );
};

export default FilesIcon;
