import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import { IconButton, makeStyles, MenuItem, Select } from "@material-ui/core";
import StyledInput from "../../../StyledInput/StyledInput";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
        marginBottom: "8px",
    },
    contentWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    selectWithLabel: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginRight: "10px",
        marginBottom: "4px",
    },
    input: {
        width: "210px",
        marginRight: "10px",
        marginBottom: "4px",
    },
    subtitleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginBottom: "4px",
    },
    selectWrapper: {
        width: "210px",
        height: "35.88px",
        marginBottom: "4px",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    inputWrapper: {
        padding: "10px 14px",
    },
    inputOthers: {
        display: "flex",
        marginTop: 8,
    },
    fullWidth: {
        width: "100%",
    },
    root: {
        padding: 0,
        marginTop: 2,
    },
    addButton: {
        width: "fit-content",
        marginLeft: 16,
    },
    removeButton: {
        width: "fit-content",
    },
}));

const MoldLayout = (props) => {
    const [isSecondLevelVisible, setIsSecondLevelVisible] = useState(false);
    const [isThirdLevelVisible, setIsThirdLevelVisible] = useState(false);

    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const partTakeOutList = [
        { id: 1, key: "FallAutomatically" },
        { id: 2, key: "ByRobot" },
        { id: 3, key: "ByHand" },
    ];

    const kindOfToolFirstLevelList = [
        { id: 1, key: "OneKMold" },
        { id: 2, key: "OneKInsertMold" },
        { id: 3, key: "OneKIMLMold" },
        { id: 4, key: "TwoKMold" },
        { id: 5, key: "TwoKInsertMold" },
        { id: 6, key: "TwoKIMLMold" },
    ];

    const kindOfToolInsertList = [
        { id: 1, key: "InsertTakeByRobot" },
        { id: 2, key: "InsertTakeByHand" },
    ];

    const kindOfToolFilmList = [
        { id: 1, key: "FilmTakeByRobot" },
        { id: 2, key: "FilmTakeByHand" },
    ];

    const kindOfToolSecondLevelList = [
        { id: 1, key: "WithTurntable" },
        { id: 2, key: "WithIndexPlate" },
        { id: 3, key: "MoveByRobot" },
        { id: 4, key: "MoveByHand" },
    ];

    useEffect(() => {
        updateSecondLevelVisibility();
        updateThirdLevelVisibility();

        if (props?.moldLayout?.kindOfToolOthers.length === 0) {
            addOthersInput();
        }
    }, [props.moldLayout]);

    const updateSecondLevelVisibility = () => {
        const firstLevelValue = props.moldLayout?.kindOfTool ? props.moldLayout.kindOfTool.firstLevelId : "";
        setIsSecondLevelVisible(firstLevelValue === 2 || firstLevelValue === 3 || firstLevelValue === 4 || firstLevelValue === 5 || firstLevelValue === 6);
    };

    const updateThirdLevelVisibility = () => {
        const firstLevelValue = props.moldLayout?.kindOfTool ? props.moldLayout.kindOfTool.firstLevelId : "";
        const secondLevelValue = props.moldLayout?.kindOfTool ? props.moldLayout.kindOfTool.secondLevelId : "";
        setIsThirdLevelVisible((firstLevelValue === 5 || firstLevelValue === 6) && (secondLevelValue === 1 || secondLevelValue === 2));
    };

    const handleFirstLevelUpdate = (event) => {
        const updatedKindOfTool = { ...props?.moldLayout?.kindOfTool };
        updatedKindOfTool.firstLevelId = event.target.value;
        updatedKindOfTool.secondLevelId = null;
        updatedKindOfTool.thirdLevelId = null;
        props.updateMoldLayout("moldLayout", "kindOfTool", updatedKindOfTool);
        updateSecondLevelVisibility();
        updateThirdLevelVisibility();
    };

    const handleSecondLevelUpdate = (event) => {
        const updatedKindOfTool = { ...props?.moldLayout?.kindOfTool };
        updatedKindOfTool.secondLevelId = event.target.value;
        updatedKindOfTool.thirdLevelId = null;
        props.updateMoldLayout("moldLayout", "kindOfTool", updatedKindOfTool);
        updateThirdLevelVisibility();
    };

    const handleThirdLevelUpdate = (event) => {
        const updatedKindOfTool = { ...props?.moldLayout?.kindOfTool };
        updatedKindOfTool.thirdLevelId = event.target.value;
        props.updateMoldLayout("moldLayout", "kindOfTool", updatedKindOfTool);
    };

    const updateOthersInput = (event, index) => {
        let arrayCopy = JSON.parse(JSON.stringify(props.moldLayout.kindOfToolOthers));
        arrayCopy[index].value = event.target.value;
        props.updateMoldLayout("moldLayout", "kindOfToolOthers", arrayCopy);
    };

    const removeOthersInput = (index) => {
        let arrayCopy = JSON.parse(JSON.stringify(props.moldLayout.kindOfToolOthers));
        arrayCopy.splice(index, 1);
        props.updateMoldLayout("moldLayout", "kindOfToolOthers", arrayCopy);
    };

    const addOthersInput = () => {
        let arrayCopy = JSON.parse(JSON.stringify(props.moldLayout.kindOfToolOthers));
        arrayCopy.push({
            id: uuidv4(),
            value: "",
        });
        props.updateMoldLayout("moldLayout", "kindOfToolOthers", arrayCopy);
    };

    const secondLevelValues =
        props.moldLayout?.kindOfTool?.firstLevelId === 2
            ? kindOfToolInsertList
            : props.moldLayout?.kindOfTool?.firstLevelId === 3
            ? kindOfToolFilmList
            : props.moldLayout?.kindOfTool?.firstLevelId > 3
            ? kindOfToolSecondLevelList
            : [];

    const thirdLevelValues =
        props.moldLayout?.kindOfTool?.firstLevelId === 5 ? kindOfToolInsertList : props.moldLayout?.kindOfTool?.firstLevelId === 6 ? kindOfToolFilmList : [];

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.MoldLayout.toUpperCase()}</div>
            <div className={classes.contentWrapper}>
                <div>
                    <InputLabel required className={classes.subtitleWrapper}>
                        {languageData?.NumberOfCavity}
                    </InputLabel>
                    <StyledInput
                        disabled={props.disabled}
                        className={classes.input}
                        defaultValue={props.moldLayout?.numberOfCavity ? props.moldLayout.numberOfCavity : ""}
                        onChange={(e) => props.updateMoldLayout("moldLayout", "numberOfCavity", e.target.value)}
                        type="text"
                    />
                </div>
                <div className={classes.selectWithLabel}>
                    <InputLabel required className={classes.subtitleWrapper}>
                        {languageData?.KindOfTool}
                    </InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={
                                props.moldLayout?.kindOfTool && props.moldLayout.kindOfTool.firstLevelId !== null
                                    ? props.moldLayout.kindOfTool.firstLevelId
                                    : ""
                            }
                            displayEmpty={props?.moldLayout?.kindOfTool?.firstLevelId === null}
                            renderValue={
                                props?.moldLayout?.kindOfTool?.firstLevelId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                handleFirstLevelUpdate(e);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {kindOfToolFirstLevelList.map((moldType) => {
                                return (
                                    <MenuItem value={moldType.id} key={moldType.id}>
                                        <div className={classes.selectTextWrapper}>{languageData[moldType.key]}</div>
                                    </MenuItem>
                                );
                            })}
                            <MenuItem value={0} key={0}>
                                <div className={classes.selectTextWrapper}>{languageData?.Others}</div>
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {props?.moldLayout?.kindOfTool?.firstLevelId === 0 && (
                        <React.Fragment>
                            {props?.moldLayout?.kindOfToolOthers?.length > 0 &&
                                props?.moldLayout?.kindOfToolOthers?.map((input, index) => (
                                    <div key={input.id} className={classes.inputOthers}>
                                        <StyledInput
                                            disabled={props.disabled}
                                            className={classes.fullWidth}
                                            defaultValue={input.value}
                                            onChange={(e) => updateOthersInput(e, index)}
                                            type="text"
                                            required={true}
                                        />
                                        {props?.moldLayout?.kindOfToolOthers?.length > 1 && (
                                            <IconButton
                                                disabled={props.disabled}
                                                classes={{ root: classes.root }}
                                                className={classes.addButton}
                                                onClick={() => removeOthersInput(index)}
                                            >
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                ))}
                            <IconButton
                                disabled={props.disabled}
                                classes={{ root: classes.root }}
                                color="primary"
                                className={classes.removeButton}
                                onClick={() => addOthersInput()}
                            >
                                <AddCircleIcon />
                            </IconButton>
                        </React.Fragment>
                    )}
                    {isSecondLevelVisible && (
                        <FormControl required>
                            <Select
                                disabled={props.disabled}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                classes={{
                                    root: classes.rootWrapper,
                                    selectMenu: classes.selectMenuWrapper,
                                    outlined: classes.inputWrapper,
                                }}
                                className={classes.selectWrapper}
                                variant={"outlined"}
                                value={
                                    props.moldLayout?.kindOfTool?.secondLevelId && props.moldLayout?.kindOfTool?.secondLevelId !== null
                                        ? props.moldLayout?.kindOfTool?.secondLevelId
                                        : ""
                                }
                                displayEmpty={props?.moldLayout?.kindOfTool?.secondLevelId === null}
                                renderValue={
                                    props?.moldLayout?.kindOfTool?.secondLevelId === null
                                        ? () => {
                                              return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                          }
                                        : null
                                }
                                onChange={(e) => {
                                    handleSecondLevelUpdate(e);
                                }}
                            >
                                <MenuItem value="" key={-1} disabled>
                                    <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                                </MenuItem>
                                {secondLevelValues.map((secondLevelValue) => {
                                    return (
                                        <MenuItem value={secondLevelValue.id} key={secondLevelValue.id}>
                                            <div className={classes.selectTextWrapper}>{languageData[secondLevelValue.key]}</div>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                    {isThirdLevelVisible && (
                        <FormControl required>
                            <Select
                                disabled={props.disabled}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                classes={{
                                    root: classes.rootWrapper,
                                    selectMenu: classes.selectMenuWrapper,
                                    outlined: classes.inputWrapper,
                                }}
                                className={classes.selectWrapper}
                                variant={"outlined"}
                                value={
                                    props.moldLayout?.kindOfTool?.thirdLevelId && props.moldLayout?.kindOfTool?.thirdLevelId !== null
                                        ? props.moldLayout?.kindOfTool?.thirdLevelId
                                        : ""
                                }
                                displayEmpty={props?.moldLayout?.kindOfTool?.thirdLevelId === null}
                                renderValue={
                                    props?.moldLayout?.kindOfTool?.thirdLevelId === null
                                        ? () => {
                                              return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                          }
                                        : null
                                }
                                onChange={(e) => {
                                    handleThirdLevelUpdate(e);
                                }}
                            >
                                <MenuItem value="" key={-1} disabled>
                                    <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                                </MenuItem>
                                {thirdLevelValues.map((thirdLevelValue) => {
                                    return (
                                        <MenuItem value={thirdLevelValue.id} key={thirdLevelValue.id}>
                                            <div className={classes.selectTextWrapper}>{languageData[thirdLevelValue.key]}</div>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                </div>
                <div className={classes.selectWithLabel}>
                    <InputLabel required className={classes.subtitleWrapper}>
                        {languageData?.PartTakeOut}
                    </InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={props.moldLayout?.partTakeOutId && props.moldLayout?.partTakeOutId !== null ? props.moldLayout.partTakeOutId : ""}
                            displayEmpty={props?.moldLayout?.partTakeOutId === null}
                            renderValue={
                                props?.moldLayout?.partTakeOutId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateMoldLayout("moldLayout", "partTakeOutId", e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {partTakeOutList.map((moldType) => {
                                return (
                                    <MenuItem value={moldType.id} key={moldType.id}>
                                        <div className={classes.selectTextWrapper}>{languageData[moldType.key]}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

MoldLayout.propTypes = {
    updateMoldLayout: PropTypes.func,
    moldLayout: PropTypes.any,
};

export default MoldLayout;
