import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { Card, makeStyles, Tooltip } from "@material-ui/core";

import WebsiteContext from "../../../utils/Website";
import LinearProgressWithLabel from "../../LinearProgress/LinearProgressWithLabel";
import RoutingContext from "../../../utils/RoutingContext";

const useStyles = makeStyles(() => ({
    cardWrapper: {
        height: "100%",
        display: "grid",
        gridTemplateRows: "calc(50% - 2px) 4px calc(50% - 2px)",
    },
    root: {
        width: 219,
        height: 147,
        display: "block",
        float: "left",
        cursor: "pointer",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "1.35951px",
    },
    projectNumber: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "15px",
        overflow: "hidden",
        maxHeight: 30,
        lineClamp: 2,
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
    },
    projectName: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        padding: "12px 16px 0px 16px",
        overflow: "hidden",
        maxHeight: 30,
        lineClamp: 2,
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
    },
    clientName: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        padding: "12px 16px 0px 16px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: 187,
    },
    projectNumberWrapper: {
        padding: "14px 16px 18px 16px",
        display: "flex",
        alignItems: "center",
    },
}));

const ProjectCard = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role } = useContext(WebsiteContext);
    const { name } = useContext(WebsiteContext);
    const { customerData } = useContext(RoutingContext);

    //TODO move the percentage value above the LinearProgress and change the color to blue if props.overdue=false, otherwise keep it red
    return (
        <Card classes={{ root: classes.root }} component={Link} to={role === 0 ? `/customers/projects/${props.id}/members` : `/project/${props.id}/members`}>
            <div className={classes.cardWrapper}>
                <div className={classes.projectNumberWrapper}>
                    <Tooltip title={props.project_number}>
                        <div className={classes.projectNumber}>
                            {languageData?.ProjectNumber}: {props.project_number}
                        </div>
                    </Tooltip>
                </div>
                <LinearProgressWithLabel style={{ backgroundColor: "#ECECEC" }} width="100%" value={props.progress} statecolor={props.statecolor} />
                <div>
                    <Tooltip title={props.name}>
                        <div className={classes.projectName}>{props.name}</div>
                    </Tooltip>
                    {customerData?.name && (
                        <Tooltip title={customerData?.name}>
                            <div className={classes.clientName}>{customerData?.name}</div>
                        </Tooltip>
                    )}
                </div>
            </div>
        </Card>
    );
};

ProjectCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    project_number: PropTypes.string,
    progress: PropTypes.number,
};

export default ProjectCard;
