import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/tui-image-editor.css";
import "../../assets/css/tui-color-picker.css";
import ImageEditor from "@toast-ui/react-image-editor";
import WebsiteContext from "../../utils/Website";
import StyledButton from "../../components/StyledButton/StyledButton";
import { makeStyles } from "@material-ui/core";

const myTheme = {
    // Theme object to extends default dark theme.
};

const useStyles = makeStyles((theme) => ({
    wrapper: {
        // padding: 40,
    },
    button: {
        marginBottom: 18,
        position: "absolute",
        zIndex: 1,
        top: 16,
        left: 16,
    },
    message: {
        position: "absolute",
        zIndex: 10,
        bottom: 16,
        left: 16,
        color: "white",
    },
    editor: {
        position: "relative",
    },
}));

const ToastImageEditor = (props) => {
    const [imageSrc, setImageSrc] = useState(
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAA1BMVEX///+nxBvIAAAAR0lEQVR4nO3BAQ0AAADCoPdPbQ8HFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPBgxUwAAU+n3sIAAAAASUVORK5CYII="
    );
    const [imageName, setImageName] = useState("basicImage");
    const [showEditor, setShowEditor] = useState(false);
    const imageEditor = React.createRef();
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles();

    const saveImage = () => {
        const imageEditorInst = imageEditor.current.imageEditorInst;
        const data = imageEditorInst.toDataURL();
        const file = DataURIToBlob(data);
        const formData = new FormData();
        formData.append("files", file, "image.jpg");

        props.saveMedia(formData);
    };

    const DataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    };

    const retrieveImageFromClipboardAsBlob = (pasteEvent, callback) => {
        if (pasteEvent.clipboardData == false) {
            if (typeof callback == "function") {
                callback(undefined);
            }
        }

        var items = pasteEvent.clipboardData.items;

        if (items == undefined) {
            if (typeof callback == "function") {
                callback(undefined);
            }
        }

        for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();

            if (typeof callback == "function") {
                callback(blob, imageEditor);
            }
        }
    };

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    useEffect(() => {
        if (props.src !== "") {
            setImageSrc(props.src);
        }
        setShowEditor(true);
        window.addEventListener("paste", function (e) {
            retrieveImageFromClipboardAsBlob(e, function (imageBlob, test) {
                setShowEditor(false);
                getBase64(imageBlob, (result) => {
                    setImageSrc(result);
                    setShowEditor(true);
                });
                // setShowEditor(false);
                // const imageFormData = new FormData();
                // imageFormData.append("files", imageBlob, imageBlob.name);
                // const options = {
                //     url: "/file-operations/image-editor/files",
                //     method: "POST",
                //     data: imageFormData,
                //     headers: { "Content-Type": "multipart/form-data" },
                // };
                // Axios(options).then((response) => {
                //     setImageSrc(`http://178.77.97.69:8000/api/file-operations/image-editor/files/${response.data.data.files[0].id}`);
                //     setImageName(response.data.data.files[0].name);
                //     setShowEditor(true);
                // });
            });
        });
    }, []);

    return (
        <div className={classes.wrapper}>
            {showEditor && (
                <div className={classes.editor}>
                    <StyledButton className={classes.button} color="primary" variant="contained" onClick={saveImage}>
                        {languageData?.Save.toUpperCase()}
                    </StyledButton>
                    <div className={classes.message}>Use Ctrl+V to copy the image in editor.</div>
                    <ImageEditor
                        includeUI={{
                            loadImage: {
                                path: imageSrc,
                                name: imageName,
                            },
                            theme: myTheme,
                            menu: ["draw", "shape", "text", "icon"],
                            initMenu: "",
                            uiSize: {
                                height: `calc(100vh - 147px)`,
                            },
                            menuBarPosition: "bottom",
                        }}
                        cssMaxHeight={window.innerHeight}
                        cssMaxWidth={window.innerWidth}
                        selectionStyle={{
                            cornerSize: 20,
                            rotatingPointOffset: 70,
                        }}
                        usageStatistics={false}
                        ref={imageEditor}
                    />
                </div>
            )}
        </div>
    );
};

export default ToastImageEditor;
