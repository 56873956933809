import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WebsiteContext from "../../utils/Website";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Axios from "axios";
import { Link, Route, Switch } from "react-router-dom";
import ToolSchedule from "./Schedule/Schedule";
import Info from "./Info/Info";
import RoutingContext from "../../utils/RoutingContext";
import ToolSpecification from "./ToolSpecification/ToolSpecification";
import Process from "./Process/Process";
import TreelikeForm from "../TreelikeForm/TreelikeForm";
import MachineSpecification from "./ToolSpecification/MachineSpecification/MachineSpecification";
import QuotationRequest from "./QuotationRequest/QuotationRequest";

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    padding: {
        padding: 9,
        height: "calc(100% - 67px)",
    },
}));

const MOLD_STATES = {
    QUOTATION_REQUEST: "QUOTATION_REQUEST",
    PRODUCTION: "PRODUCTION",
};

const Tool = (props) => {
    const classes = useStyles(useStyles);
    const [projectId, setProjectId] = useState(null);
    const [tool, setTool] = useState(null);
    const { languageData, role, setLoading } = useContext(WebsiteContext);
    const { setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData } = useContext(RoutingContext);

    const [toolMakerId, setToolMakerId] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [toolState, setToolState] = useState("");
    const history = useHistory();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setToolState(tool?.state);

        if (props.location.pathname.includes("quotation-request") && tool?.state === MOLD_STATES.PRODUCTION) {
            history.push(
                role === 0
                    ? `/customers/projects/tools/${props.match.params.id}/info`
                    : role === 1
                    ? `/project/tools/${props.match.params.id}/info`
                    : `/tool/${props.match.params.id}/info`
            );
        }
    }, [tool]);

    const getData = () => {
        const options = {
            url: `/customers/projects/tools/${props.match.params.id}`,
            method: "GET",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data;
            setRoutingData();
            setToolData({
                visibleContent: true,
                name: data.name,
                tool_maker_name: data.toolMakerName ? data.toolMakerName : "",
                tool_maker_id: data.tool_maker_id,
                progress: data.customerGanttChartsPercentage,
                stateColor: data.customerGanttChartsState.color,
                description: data.description,
                startDate: data.estimatedStartDate !== null ? data.estimatedStartDate : "",
                state: data.state === languageData?.ProductionState ? 0 : 1,
            });
            setEntityPath(`/customers/projects/tools/${props.match.params.id}`);
            if (role === 0) {
                setBackPath("/customers/projects/" + data.project_id + "/tools");
            } else if (role === 1) {
                setBackPath("/project/" + data.project_id + "/tools");
            } else if (role === 2) {
                if (props.location.pathname.includes("tool-specification/")) setBackPath(`/tool/${props.match.params.id}/tool-specification`);
                else setBackPath("/tools");
            }

            setProjectId(data.project_id);
            setToolMakerId(data.tool_maker_id);
            setCustomerId(data.customer_id);
            setTool(data);
        });
    };

    return (
        <React.Fragment>
            <Divider />
            {/* <Divider /> */}
            <Tabs
                value={props.location.pathname.includes("tool-specification/") ? `/tool/${props.match.params.id}/tool-specification` : props.location.pathname}
                className={classes.wrapper}
                variant="scrollable"
                indicatorColor={"primary"}
                aria-label="simple tabs example"
            >
                {toolState === MOLD_STATES.QUOTATION_REQUEST && (
                    <Tab
                        label={languageData?.QuotationRequest.toUpperCase()}
                        component={Link}
                        to={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/quotation-request`
                                : role === 1
                                ? `/project/tools/${props.match.params.id}/quotation-request`
                                : `/tool/${props.match.params.id}/quotation-request`
                        }
                        value={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/quotation-request`
                                : role === 1
                                ? `/project/tools/${props.match.params.id}/quotation-request`
                                : `/tool/${props.match.params.id}/quotation-request`
                        }
                    />
                )}
                {toolState === MOLD_STATES.PRODUCTION && (
                    <Tab
                        label={languageData?.INFO}
                        component={Link}
                        to={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/info`
                                : role === 1
                                ? `/project/tools/${props.match.params.id}/info`
                                : `/tool/${props.match.params.id}/info`
                        }
                        value={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/info`
                                : role === 1
                                ? `/project/tools/${props.match.params.id}/info`
                                : `/tool/${props.match.params.id}/info`
                        }
                    />
                )}
                {toolState === MOLD_STATES.PRODUCTION && (role === 0 || role === 1) && (
                    <Tab
                        label={role === 0 ? languageData?.CustomerSchedule : languageData.Schedule}
                        component={Link}
                        to={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/schedule/customer`
                                : `/project/tools/${props.match.params.id}/schedule`
                        }
                        value={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/schedule/customer`
                                : `/project/tools/${props.match.params.id}/schedule`
                        }
                    />
                )}
                {toolState === MOLD_STATES.PRODUCTION && (role === 0 || role === 2) && (
                    <Tab
                        label={role === 0 ? languageData?.MoldMakerSchedule : languageData.Schedule}
                        component={Link}
                        to={role === 0 ? `/customers/projects/tools/${props.match.params.id}/schedule/mold-maker` : `/tool/${props.match.params.id}/schedule`}
                        value={
                            role === 0 ? `/customers/projects/tools/${props.match.params.id}/schedule/mold-maker` : `/tool/${props.match.params.id}/schedule`
                        }
                    />
                )}
                {toolState === MOLD_STATES.PRODUCTION && role === 2 && (
                    <Tab
                        label={languageData.CustomerGMS}
                        component={Link}
                        to={`/tool/${props.match.params.id}/gms`}
                        value={`/tool/${props.match.params.id}/gms`}
                    />
                )}
                {toolState === MOLD_STATES.PRODUCTION && (
                    <Tab
                        label={languageData.ToolSpecification}
                        component={Link}
                        to={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/tool-specification`
                                : role === 1
                                ? `/project/tools/${props.match.params.id}/tool-specification`
                                : `/tool/${props.match.params.id}/tool-specification`
                        }
                        value={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/tool-specification`
                                : role === 1
                                ? `/project/tools/${props.match.params.id}/tool-specification`
                                : `/tool/${props.match.params.id}/tool-specification`
                        }
                    />
                )}
                {toolState === MOLD_STATES.PRODUCTION && (
                    <Tab
                        label={languageData.Process}
                        component={Link}
                        to={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/process`
                                : role === 1
                                ? `/project/tools/${props.match.params.id}/process`
                                : `/tool/${props.match.params.id}/process`
                        }
                        value={
                            role === 0
                                ? `/customers/projects/tools/${props.match.params.id}/process`
                                : role === 1
                                ? `/project/tools/${props.match.params.id}/process`
                                : `/tool/${props.match.params.id}/process`
                        }
                    />
                )}
            </Tabs>
            <div className={classes.padding}>
                <Switch>
                    {toolState === MOLD_STATES.QUOTATION_REQUEST && (
                        <Route
                            path={
                                role === 0
                                    ? `/customers/projects/tools/:id/quotation-request`
                                    : role === 1
                                    ? `/project/tools/:id/quotation-request`
                                    : `/tool/:id/quotation-request`
                            }
                            render={(props) => (
                                <QuotationRequest getData={getData} {...props} projectId={projectId} customerId={customerId} toolId={tool?.id} tool={tool} />
                            )}
                        />
                    )}
                    {toolState === MOLD_STATES.PRODUCTION && (
                        <Route
                            path={role === 0 ? `/customers/projects/tools/:id/info` : role === 1 ? `/project/tools/:id/info` : `/tool/:id/info`}
                            render={(props) => <Info tool={tool} {...props} />}
                        />
                    )}
                    {toolState === MOLD_STATES.PRODUCTION && (role === 0 || role === 1) && (
                        <Route
                            path={role === 0 ? `/customers/projects/tools/:id/schedule/customer` : `/project/tools/:id/schedule`}
                            render={(props) => (
                                <ToolSchedule disableProjSchedule={false} projectId={projectId} isCustomerSchedule={true} getData={getData} {...props} />
                            )}
                        />
                    )}

                    {toolState === MOLD_STATES.PRODUCTION && (role === 0 || role === 2) && (
                        <Route
                            path={role === 0 ? `/customers/projects/tools/:id/schedule/mold-maker` : `/tool/:id/schedule`}
                            render={(props) => (
                                <ToolSchedule disableProjSchedule={false} projectId={projectId} isCustomerSchedule={false} getData={getData} {...props} />
                            )}
                        />
                    )}
                    {role === 2 && <Route path={`/tool/:id/gms`} render={(props) => <TreelikeForm {...props} customerId={tool?.customerId} />} />}
                    {toolState === MOLD_STATES.PRODUCTION && (
                        <Route
                            exact
                            path={
                                role === 0
                                    ? `/customers/projects/tools/:id/tool-specification`
                                    : role === 1
                                    ? `/project/tools/:id/tool-specification`
                                    : `/tool/:id/tool-specification`
                            }
                            render={(props) => (
                                <ToolSpecification {...props} projectId={projectId} toolMakerId={toolMakerId} customerId={customerId} toolId={tool?.id} />
                            )}
                        />
                    )}
                    {role === 2 && (
                        <Route path={`/tool/:id/machine/:machineId`} render={(props) => <MachineSpecification {...props} customerId={customerId} />} />
                    )}
                    <Route
                        path={role === 0 ? `/customers/projects/tools/:id/process` : role === 1 ? `/project/tools/:id/process` : `/tool/:id/process`}
                        render={(props) => <Process toolId={tool?.id} {...props} />}
                    />
                </Switch>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Tool);
