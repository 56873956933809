import { makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../utils/Website";
import RoutingContext from "../../../utils/RoutingContext";
import Axios from "axios";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link, Route, Switch } from "react-router-dom";
import SamplingFlowProcess from "../MoldTrailFOT/SamplingFlowProcess/SamplingFlowProcess";
import CoolingLayout from "../MoldTrailFOT/CoolingLayout/CoolingLayout";
import InjectionReport from "../MoldTrailFOT/InjectionReport/InjectionReport";
import MoldChecklist from "./MoldChecklist/MoldChecklist";
import FinalRunReport from "./FinalRunReport/FinalRunReport";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: "white",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    padding: {
        padding: 9,
        height: "calc(100% - 67px)",
    },
    messageGrid: {
        height: "80vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "12px",
        textAlign: "center",
    },
}));

const MoldReleaseProcess = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role, language } = useContext(WebsiteContext);
    const [isVisible, setIsVisible] = useState(false);
    const { setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData, setStepData, stepData } = useContext(RoutingContext);

    const getData = () => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}`,
            method: "GET",
            headers: {
                "X-Locale": language,
            },
        };
        Axios(options).then((response) => {
            const data = response.data.data.processes;
            setIsVisible(response.data.data.isSecondCompsVisible);
            setRoutingData();
            setStepData({
                visibleContent: true,
                id: props.match.params.stepNumber,
                toolId: props.match.params.id,
                name: data.name,
                state: data.state,
                canExport: false,
                canClose: false,
                visible: data.visible,
                changeVisibility,
            });
            if (role === 0) {
                setBackPath(`/customers/projects/tools/${props.match.params.id}/process`);
            } else if (role === 1) {
                setBackPath(`/project/tools/${props.match.params.id}/process`);
            } else if (role === 2) {
                setBackPath(`/tool/${props.match.params.id}/process`);
            }
        });
    };

    const changeVisibility = (value) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}/processes/${props.match.params.stepNumber}/change-visibility`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: {
                visible: value,
            },
        };
        Axios(options).then((_) => {
            getData();
        });
    };

    useEffect(() => {
        getData();
    }, [language]);

    const changeSecondCompVisibility = (value) => {
        const options = {
            url: `/business-logic/tools/${props.match.params.id}`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: {
                isSecondCompsVisible: value,
            },
        };
        Axios(options).then((_) => {
            getData();
        });
    };

    return (
        <div>
            {(role === 1 && stepData?.visible === true) || role !== 1 ? (
                <React.Fragment>
                    <Divider />
                    {/* <Divider /> */}

                    <Tabs
                        value={props.location.pathname}
                        className={classes.wrapper}
                        variant="scrollable"
                        indicatorColor={"primary"}
                        aria-label="simple tabs example"
                    >
                        <Tab
                            label={languageData?.FinalRunReport.toLocaleUpperCase()}
                            component={Link}
                            to={
                                role === 0
                                    ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/final-run-report`
                                    : role === 1
                                    ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/final-run-report`
                                    : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/final-run-report`
                            }
                            value={
                                role === 0
                                    ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/final-run-report`
                                    : role === 1
                                    ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/final-run-report`
                                    : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/final-run-report`
                            }
                        />
                        <Tab
                            label={languageData?.MoldChecklist.toLocaleUpperCase()}
                            component={Link}
                            to={
                                role === 0
                                    ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/mold-checklist`
                                    : role === 1
                                    ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/mold-checklist`
                                    : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/mold-checklist`
                            }
                            value={
                                role === 0
                                    ? `/customers/projects/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/mold-checklist`
                                    : role === 1
                                    ? `/project/tools/${props.match.params.id}/process/step/${props.match.params.stepNumber}/mold-checklist`
                                    : `/tool/${props.match.params.id}/process/step/${props.match.params.stepNumber}/mold-checklist`
                            }
                        />
                    </Tabs>
                    <div className={classes.padding}>
                        <Switch>
                            <Route
                                path={
                                    role === 0
                                        ? `/customers/projects/tools/:id/process/step/:stepNumber/final-run-report`
                                        : role === 1
                                        ? `/project/tools/:id/process/step/:stepNumber/final-run-report`
                                        : `/tool/:id/process/step/:stepNumber/final-run-report`
                                }
                                render={(props) => <FinalRunReport {...props} visible={isVisible} changeVisibility={changeSecondCompVisibility} />}
                            />
                            <Route
                                path={
                                    role === 0
                                        ? `/customers/projects/tools/:id/process/step/:stepNumber/mold-checklist`
                                        : role === 1
                                        ? `/project/tools/:id/process/step/:stepNumber/mold-checklist`
                                        : `/tool/:id/process/step/:stepNumber/mold-checklist`
                                }
                                render={(props) => <MoldChecklist {...props} visible={isVisible} changeVisibility={changeSecondCompVisibility} />}
                            />
                        </Switch>
                    </div>
                </React.Fragment>
            ) : (
                <div className={classes.messageGrid}>
                    <Alert severity="warning">{languageData?.UnavailableStep6Info}</Alert>
                </div>
            )}
        </div>
    );
};

export default MoldReleaseProcess;
