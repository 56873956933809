import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import Axios from "axios";
import classNames from "classnames";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

import { makeStyles, Divider, InputLabel, IconButton, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import WebsiteContext from "../../utils/Website";
import RoutingContext from "../../utils/RoutingContext";
import { dailySendDates, weeklySendDates, reminderTypes } from "../../utils/constants";
import StyledDropdown from "../StyledDropdown/StyledDropdown";
import StyledButton from "../StyledButton/StyledButton";
import StyledDatePicker from "../StyledDatePicker/StyledDatePicker";
import FormLayout from "../../containers/FormLayout/FormLayout";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100% - 40px)",
        width: "calc(100% - 40px)",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
        padding: "20px",
        overflow: "hidden",
    },
    tabsWrapper: {
        height: "100%",
    },
    tabsTitle: {
        textTransform: "uppercase",
        fontFamily: "Montserrat",
        fontSize: 12,
        lineHeight: "15px",
        fontWeight: 700,
        color: "#A5A5A5",
    },
    tabChoices: {
        display: "flex",
        marginTop: 10,
    },
    tabChoice: {
        width: 154,
        minWidth: 154,
        height: 33,
        border: "1px solid #D9D9D9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        color: "#000",
        cursor: "pointer",
    },
    selectedTab: {
        color: "#FFF",
        backgroundColor: theme.palette.primary.main,
    },
    tabs: {
        marginTop: 10,
        height: "calc(100% - 90px)",
    },
    divider: {
        width: "100%",
        height: 1,
        margin: "20px 0 10px 0",
        backgroundColor: "#191919",
        opacity: 0.1,
    },
    input: {
        width: 210,
        height: 34,
    },
    inputTimezone: {
        width: 423,
        height: 34,
        [theme.breakpoints.down(750)]: {
            width: 293,
        },
    },
    tab: {
        height: "100%",
    },
    reminder: {
        marginTop: 30,
        overflow: "auto",
        height: "100%",
    },
    reminderButton: {
        color: "#FFF",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "5px",
        width: 210,
        marginTop: 20,
        "&:hover": {
            background: "#900a0b",
            color: "#FFFFFF",
        },
        "&:disabled": {
            color: "#FFF",
            backgroundColor: theme.palette.primary.main,
            opacity: 0.5,
        },
    },
    endDateWrapper: {
        display: "flex",
        flexDiretion: "row",
        "& > label": {
            marginRight: 0,
        },
    },
    label: {
        textTransform: "uppercase",
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 700,
        color: "#A5A5A5",
        marginBottom: 10,
        marginTop: 20,
    },
    reminderHour: {
        display: "grid",
        gridTemplateColumns: "210px 1fr",
        gap: 20,
        [theme.breakpoints.down(750)]: {
            gridTemplateColumns: "100%",
            gap: 0,
        },
    },
    reminderInterval: {
        display: "grid",
        gridTemplateColumns: "210px 1fr",
        gap: 20,
        [theme.breakpoints.down(750)]: {
            gridTemplateColumns: "100%",
            gap: 0,
        },
    },
    radioGroupWrapper: {
        marginTop: -5,
        width: 250,
    },
    textWrapper: {
        fontWeight: 500,
        fontSize: 12,
        fontFamily: "Montserrat",
    },
    sendDateRow: {
        display: "grid",
        gridTemplateColumns: "210px 34px 34px",
        gap: 10,
    },
    addButton: {
        backgroundColor: theme.palette.primary.main,
        height: 24,
        width: 24,
        margin: "5px",
        color: "white",
        "&:hover": {
            background: "#900a0b",
            color: "#FFFFFF",
        },
    },
    deleteButton: {
        height: 34,
        width: 28,
        color: theme.palette.primary.main,
        "& svg": {
            width: 28,
            height: 28,
        },
        "&:hover": {
            background: "white",
            color: "#900a0b",
        },
    },
    sendDates: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
    },
    warning: {
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 600,
        color: "red",
    },
}));

const NewReminder = ({ isWeekly = false, data, isAdd, reminderId = null }) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const router = useHistory();

    const [hasEndDate, setHasEndDate] = useState(false);

    const [sendDates, setSendDates] = useState([{ id: 1, value: null }]);
    const [sendTime, setSendTime] = useState(null);
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [isError, setIsError] = useState(false);

    const labelStyle = "original";
    const timezones = allTimezones;
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

    useEffect(() => {
        if (
            moment()?.tz(timezone?.value)?.format("YYYY-MM-DD") === moment(startDate)?.tz(timezone?.value)?.format("YYYY-MM-DD") &&
            moment(sendTime)?.tz(timezone?.value)?.format("HH:mm") <= moment()?.tz(timezone?.value)?.format("HH:mm")
        ) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    }, [sendTime, timezone, startDate]);

    useEffect(() => {
        if (data !== null) {
            setStartDate(moment(data?.startDate, "YYYY-MM-DD"));
            const time = moment().set("hour", data?.hour).set("minute", data?.minute);
            setSendTime(time);
            const tz = options.find((el) => el.value === data?.timezone);
            if (tz) {
                setTimezone(tz);
            }
            if (data?.endDate) {
                setHasEndDate(true);
                setEndDate(moment(data?.endDate, "YYYY-MM-DD").add(-1, "day"));
            }
            if (data?.type === reminderTypes.Daily) {
                if (data?.dayOfWeek.includes("sat")) {
                    setSendDates([dailySendDates[1]]);
                } else {
                    setSendDates([dailySendDates[0]]);
                }
            } else {
                const splitValues = data?.dayOfWeek?.split(",");
                setSendDates(
                    splitValues.map((val) => {
                        return weeklySendDates.find((el) => el.value === val);
                    })
                );
            }
        }
    }, [data]);

    const saveReminder = () => {
        const payload = {
            dayOfWeek: sendDates?.map((el) => el.value).join(","),
            endDate: endDate !== null ? moment(endDate).add(1, "day").format("YYYY-MM-DD") : null,
            hour: moment(sendTime).hour(),
            minute: moment(sendTime).minute(),
            startDate: moment(startDate).format("YYYY-MM-DD"),
            timezone: timezone?.value,
            type: isWeekly ? reminderTypes.Weekly : reminderTypes.Daily,
        };

        const options = {
            url: isAdd ? "/schedulers" : "/schedulers/" + reminderId,
            method: isAdd ? "POST" : "PUT",
            data: payload,
            headers: {
                "X-Loading": true,
            },
        };
        Axios(options).then((res) => {
            if (res.status === 200) {
                router.push("/reminders/schedule");
            }
        });
    };

    const addSendDate = () => {
        setSendDates([...sendDates, { id: sendDates?.length + 1, value: null }]);
    };

    const removeSendDate = (id) => {
        setSendDates([...sendDates].filter((el) => el.id !== id));
    };

    const changeValue = (index, value) => {
        let tmpData = [...sendDates];
        tmpData[index] = value;
        setSendDates(tmpData);
    };

    const computeOptions = (index) => {
        if (isWeekly) {
            return weeklySendDates.filter((el) => !sendDates.includes(el) || sendDates[index] === el);
        } else {
            return dailySendDates;
        }
    };

    return (
        <FormLayout
            className={classes.reminder}
            onSubmit={(e) => {
                e.preventDefault();
                saveReminder();
            }}
        >
            <div className={classes.reminderRepetition}>
                <InputLabel className={classes.label}>{languageData?.SendDate}</InputLabel>
                <div className={classes.sendDates}>
                    {sendDates?.map((sendDate, index) => {
                        return (
                            <div className={classes.sendDateRow} key={sendDate?.id}>
                                <StyledDropdown
                                    required
                                    className={classes.input}
                                    options={computeOptions(index)}
                                    onChange={(e, value) => {
                                        changeValue(index, value);
                                    }}
                                    value={sendDates[index]}
                                />
                                {isWeekly && (
                                    <>
                                        {index === sendDates?.length - 1 && computeOptions(index + 1)?.length > 0 && (
                                            <IconButton className={classes.addButton} onClick={() => addSendDate()}>
                                                <AddIcon />
                                            </IconButton>
                                        )}
                                        {sendDates?.length > 1 && (
                                            <IconButton className={classes.deleteButton} onClick={() => removeSendDate(sendDate?.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.reminderHour}>
                <div>
                    <InputLabel className={classes.label}>{languageData?.SendTime}</InputLabel>
                    <StyledDatePicker
                        required
                        className={classes.input}
                        type="time"
                        value={sendTime}
                        onChange={(value) => {
                            setSendTime(value);
                        }}
                    />
                </div>
                <div>
                    <InputLabel className={classes.label}>{languageData?.Timezone}</InputLabel>
                    <StyledDropdown
                        required
                        options={options}
                        displayField="label"
                        className={classes.inputTimezone}
                        value={timezone}
                        onChange={(e, value) => {
                            setTimezone(parseTimezone(value));
                        }}
                    />
                </div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.reminderInterval}>
                <div>
                    <InputLabel className={classes.label}>{languageData?.StartDate}</InputLabel>
                    <StyledDatePicker required className={classes.input} value={startDate} onChange={(e, value) => setStartDate(value)} minDate={moment()} />
                </div>
                <div className={classes.radioGroupWrapper}>
                    <InputLabel className={classes.label}>{languageData?.EndDate}</InputLabel>
                    <RadioGroup
                        className={classes.radioButtonsWrapper}
                        value={hasEndDate}
                        onChange={(e) => {
                            setHasEndDate(e.target.value === "true");
                            setEndDate(null);
                        }}
                    >
                        <FormControlLabel
                            classes={{ label: classes.textWrapper }}
                            value={false}
                            control={<Radio className={classes.radioButton} classes={{ checked: classes.radioButtonChecked }} />}
                            label={languageData?.NoEndDate}
                        />
                        <div className={classes.endDateWrapper}>
                            <FormControlLabel
                                classes={{ label: classes.textWrapper }}
                                value={true}
                                control={<Radio className={classes.radioButton} classes={{ checked: classes.radioButtonChecked }} />}
                            />
                            <StyledDatePicker
                                required={hasEndDate}
                                disabled={!hasEndDate}
                                className={classes.input}
                                value={endDate}
                                minDate={startDate !== null ? startDate : moment()}
                                onChange={(e, value) => setEndDate(value)}
                            />
                        </div>
                    </RadioGroup>
                </div>
            </div>
            <div className={classes.reminderButtonWrapper}>
                {isError && <div className={classes.warning}>{languageData?.TooEarlyReminder}</div>}
                <StyledButton type="submit" className={classes.reminderButton} disabled={isError}>
                    {languageData?.Save}
                </StyledButton>
            </div>
        </FormLayout>
    );
};

const RemindersAddEdit = (props) => {
    const classes = useStyles(useStyles);
    const { languageData } = useContext(WebsiteContext);
    const { setCustomerData, setRoutingData, setEntityPath, setProjectData, setBackPath, setToolData } = useContext(RoutingContext);

    const [tab, setTab] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props?.match?.params?.id) {
            const options = {
                url: "/schedulers/" + props?.match?.params?.id,
                method: "GET",
                headers: {
                    "X-Loading": true,
                },
            };
            Axios(options).then((res) => {
                setData(res?.data?.data);
            });
        } else {
            setTab(0);
            setIsAdd(true);
        }
    }, [props?.match?.params?.id]);

    useEffect(() => {
        if (data !== null) {
            if (data?.type === reminderTypes.Weekly) {
                setTab(1);
            } else {
                setTab(0);
            }
        }
    }, [data]);

    return (
        <div className={classes.wrapper}>
            <div className={classes.tabsWrapper}>
                <div className={classes.tabsTitle}>{languageData?.Repeats}</div>
                <div className={classes.tabChoices}>
                    <div className={classNames(classes.tabChoice, { [classes.selectedTab]: tab === 0 })} onClick={() => setTab(0)}>
                        {languageData?.Daily}
                    </div>
                    <div className={classNames(classes.tabChoice, { [classes.selectedTab]: tab === 1 })} onClick={() => setTab(1)}>
                        {languageData?.Weekly}
                    </div>
                </div>
                <div className={classes.tabs}>
                    {tab === 0 && (
                        <div className={classes.tab}>
                            <NewReminder data={data} isAdd={isAdd} reminderId={!isAdd ? props?.match?.params?.id : null} />
                        </div>
                    )}
                    {tab === 1 && (
                        <div className={classes.tab}>
                            <NewReminder isWeekly reminderId={!isAdd ? props?.match?.params?.id : null} data={data} isAdd={isAdd} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withRouter(RemindersAddEdit);
