import { makeStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import StyledButton from "../../../StyledButton/StyledButton";
import classNames from "classnames";
import FormLayout from "../../../../containers/FormLayout/FormLayout";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#191919",
    },
    questionLabelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "700",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#616161",
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 10,
        marginLeft: 5,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
    },
    radioButtonChecked: {
        color: "#C00D0E !important",
    },
    questionWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
    },
    questionsWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: 32,
    },
    textarea: {
        minHeight: 70,
        width: "100%",
        padding: 10,
        lineHeight: "12px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#191919",
        fontFamily: "Montserrat",
        background: "#FFFFFF",
        border: "1px solid #ECECEC",
        boxSizing: "border-box",
        borderRadius: 2.57,
        maxWidth: "100%",
    },
    textareaEdit: {
        marginRight: 16,
    },
    saveButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: 33,
        minWidth: 120,
        padding: "0 10px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            opacity: "50%",
        },
        border: "none",
    },
    commentButton: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
    },
}));

const questions = [
    {
        answer: "yes",
        comment: "Test comment",
        question: "Parts as received final release 1",
    },
    {
        answer: "no",
        comment: "Test comment",
        question: "Parts as received final release 2",
    },
    {
        answer: null,
        comment: "Test comment",
        question: "Parts as received final release 3",
    },
];

const ReleaseProcess = (props) => {
    const classes = useStyles();

    const { languageData, language } = useContext(WebsiteContext);

    const [checked, setChecked] = useState({});
    const [comment, setComment] = useState({});

    useEffect(() => {
        setChecked(
            props.data.reduce(
                (prevSet, questionSet) => ({
                    ...prevSet,
                    [questionSet.name]: questionSet.questions.reduce(
                        (prev, question) => ({
                            ...prev,
                            [question.question]: question.answer === undefined || question.answer === null ? null : question.answer,
                        }),
                        {}
                    ),
                }),
                {}
            )
        );
        setComment(
            props.data.reduce(
                (prevSet, questionSet) => ({
                    ...prevSet,
                    [questionSet.name]: questionSet.questions.reduce((prev, question) => ({ ...prev, [question.question]: question.comment }), {}),
                }),
                {}
            )
        );
    }, [props.data]);

    const getCheckedValue = (setName, question) => {
        if (checked[setName] === null || checked[setName] === undefined || checked[setName][question] === null || checked[setName][question] === undefined)
            return null;
        return checked[setName][question];
    };

    const getCommentValue = (setName, question) => {
        if (comment[setName] && comment[setName][question]) return comment[setName][question];
        return "";
    };

    const setCheckedValue = (newValue, setName, question) => {
        const newSet = { ...checked[setName], [question]: newValue === "true" };
        setChecked((old) => ({ ...old, [setName]: newSet }));
    };

    const setCommentValue = (newValue, setName, question) => {
        const newSet = { ...comment[setName], [question]: newValue };
        setComment((old) => ({ ...old, [setName]: newSet }));
    };

    const createSavePayload = () => {
        const data = props.data.map((questionSet) => ({
            ...questionSet,
            questions: questionSet.questions.map((question) => ({
                ...question,
                answer: checked[questionSet.name][question.question],
                comment:
                    !checked[questionSet.name][question.question] &&
                    comment[questionSet.name][question.question] &&
                    comment[questionSet.name][question.question].length > 0
                        ? comment[questionSet.name][question.question]
                        : null,
            })),
        }));

        return {
            questions: data,
        };
    };

    const saveHandler = (payload) => {
        const options = {
            url: `/business-logic/tools/${props.id}/processes/customer-acceptance/release-process`,
            method: "PUT",
            data: payload,
            headers: {
                "X-Locale": language,
            },
        };

        Axios(options).then((response) => {
            props.setReleaseProcess(response.data.data.releaseProcess);
        });
    };

    return (
        <FormLayout
            onSubmit={(e) => {
                e.preventDefault();
                saveHandler(createSavePayload());
            }}
            className={classes.questionsWrapper}
        >
            {props.data.map((questionSet) => {
                return (
                    <div key={questionSet.name} className={classes.questionWrapper}>
                        <InputLabel className={classes.labelWrapper}>{questionSet.name}</InputLabel>
                        {questionSet.questions.map((question) => {
                            return (
                                <React.Fragment key={question.question}>
                                    <InputLabel className={classes.questionLabelWrapper}>{question.question}</InputLabel>
                                    <RadioGroup
                                        className={classes.radioButtonsWrapper}
                                        value={getCheckedValue(questionSet.name, question.question)}
                                        onChange={(e) => setCheckedValue(e.target.value, questionSet.name, question.question)}
                                    >
                                        <FormControlLabel
                                            disabled={props.disabled}
                                            classes={{ label: classes.textWrapper }}
                                            value={true}
                                            control={<Radio className={classes.radioButton} classes={{ checked: classes.radioButtonChecked }} />}
                                            label={languageData?.Yes}
                                        />
                                        <FormControlLabel
                                            disabled={props.disabled}
                                            classes={{ label: classes.textWrapper }}
                                            value={false}
                                            control={<Radio className={classes.radioButton} classes={{ checked: classes.radioButtonChecked }} />}
                                            label={languageData?.No}
                                        />
                                    </RadioGroup>
                                    {checked[questionSet.name] && !checked[questionSet.name][question.question] && (
                                        <textarea
                                            disabled={props.disabled}
                                            required={checked[questionSet.name] && !checked[questionSet.name][question.question]}
                                            onChange={(e) => setCommentValue(e.target.value, questionSet.name, question.question)}
                                            value={getCommentValue(questionSet.name, question.question)}
                                            className={classNames(classes.textarea, classes.textareaEdit)}
                                            placeholder="Add a comment.."
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                );
            })}
            {props.role !== 2 && (
                <div className={classes.commentButton}>
                    <StyledButton disabled={props.disabled} type="submit" className={classes.saveButton} variant="outlined">
                        {languageData?.Save}
                    </StyledButton>
                </div>
            )}
        </FormLayout>
    );
};

export default ReleaseProcess;
