import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import WebsiteContext from "../../../../utils/Website";
import Axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Dialog, DialogContent, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import FormLayout from "../../../../containers/FormLayout/FormLayout";
import StyledInput from "../../../StyledInput/StyledInput";
import StyledButton from "../../../StyledButton/StyledButton";
import RoutingContext from "../../../../utils/RoutingContext";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    dialogContentWrapper: {
        padding: 0,
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
    },
    shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        transformOrigin: "top left",
    },
    addRemoveButtons: {
        width: "100%",
    },
    saveButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    deleteButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    globalError: {
        color: "red",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    gridListWrapper: {
        height: "auto !important",
    },
    selectWrapper: {
        marginBottom: "10px",
        height: "33px",
        width: "100%",
    },
    hidden: {
        display: "none",
    },
    textareaWrapper: {
        width: "89%",
        border: "0.6425px solid #CCCCCC",
        marginBottom: "5px",
        borderRadius: "2.57px",
        minHeight: "60px",
        height: "auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
        position: "unset",
        padding: "10px",
        resize: "none",
        fontFamily: "'Montserrat', sans-serif",
    },
    datePickerWrapper: {
        width: "89%",
        border: "0.6425px solid #CCCCCC",
        marginBottom: "5px",
        borderRadius: "2.57px",
        height: "auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
        position: "unset",
        padding: "0 10px 0 10px",
        resize: "none",
    },
    checkboxWrapper: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
            "&:disabled": {
                opacity: "50%",
            },
        },
        "&.Mui-disabled": {
            opacity: "50%",
        },
        margin: "5px 5px 5px 7px",
    },
    checked: {},
    parentCheckbox: {
        width: "200px",
    },
    textWrapper: {
        fontWeight: "500 !important",
        fontSize: "10px !important",
    },
}));

const ToolModal = (props) => {
    const classes = useStyles(useStyles);
    const { languageData, role } = useContext(WebsiteContext);
    const { entityPath, toolData } = useContext(RoutingContext);

    const [name, setName] = useState(props.addMode ? "" : props.name);
    const [description, setDescription] = useState(props.addMode ? "" : props.description);
    const [startDate, setStartDate] = useState(props.addMode ? "" : props.startDate);
    const [toolMakers, setToolMakers] = useState([]);
    const [toolMakerId, setToolMakerId] = useState("");
    const [nameError] = useState("");
    const [globalError, setGlobalError] = useState("");

    const [requestForQuotation, setRequestForQuotation] = useState(false);

    const handleToolMakerChange = (event) => {
        setToolMakerId(event.target.value);
    };

    const handleCloseModal = () => {
        props.onClose();
    };

    const handleAddToolEntity = () => {
        const data = {
            name: name,
            description: description,
            ...(!requestForQuotation && {
                toolMakerId: toolMakerId,
                estimatedStartDate: startDate,
            }),
            state: requestForQuotation ? 1 : 0,
        };
        const options = {
            url: entityPath + "/tools",
            method: "POST",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            props.onClose();
        });
    };

    const handleEditToolEntity = () => {
        const data = {
            name: name,
            description: description,
            toolMakerId: toolMakerId,
        };

        const options = {
            url: entityPath,
            method: "PUT",
            data: data,
        };
        Axios(options).then((_) => {
            props.onClose();
            window.location.reload();
        });
    };

    useEffect(() => {
        if (role === 0 && props.open === true && toolData.state !== 1) {
            const options = {
                url: "/tool-makers",
                method: "GET",
                headers: {
                    "X-Fields": ["id", "name"],
                },
            };
            Axios(options).then((response) => {
                setToolMakers(response.data.data);
                if (props.addMode) {
                    setToolMakerId(response.data.data[0].id);
                } else {
                    const selectedToolMaker = response.data.data.find((tm) => tm.id === toolData.tool_maker_id);
                    setToolMakerId(selectedToolMaker.id);
                }
            });
        }
        if (role === 0 && props.open === true && !props.addMode) {
            setRequestForQuotation(toolData.state === 1 ? true : false);
        }
    }, [props.open]);

    const toolMakersEntities = toolMakers.map((toolMaker) => (
        <MenuItem key={toolMaker.id} value={toolMaker.id}>
            {toolMaker.name}
        </MenuItem>
    ));

    let buttons = (
        <StyledButton variant="contained" type="submit" fullWidth className={classes.saveButton}>
            {languageData?.Save}
        </StyledButton>
    );

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.addNew}>{props.addMode ? languageData?.AddNewTool : languageData?.EditTool}</div>
            <div className={classes.formWrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (props.addMode) {
                            handleAddToolEntity();
                        } else {
                            handleEditToolEntity();
                        }
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={name}
                        label={languageData?.Name}
                        className={classes.input}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        helperText={nameError}
                        error={!!nameError}
                    />
                    <InputLabel
                        classes={{
                            root: classes.root,
                            shrink: classes.shrink,
                            formControl: classes.labelFormControl,
                        }}
                    >
                        {languageData?.Description}
                    </InputLabel>
                    <textarea className={classes.textareaWrapper} value={description} onChange={(e) => setDescription(e.target.value)} />
                    <FormControlLabel
                        className={classes.parentCheckbox}
                        classes={{ label: classes.textWrapper }}
                        control={
                            <Checkbox
                                checked={requestForQuotation}
                                className={classes.checkboxWrapper}
                                classes={{ checked: classes.checked }}
                                onChange={(e) => {
                                    setRequestForQuotation(e.target.checked);
                                }}
                            />
                        }
                        disabled={!props.addMode}
                        label={languageData?.MoldsCheckboxLabel}
                    />
                    {(props.addMode || (!props.addMode && toolData?.state !== 1)) && (
                        <InputLabel
                            required
                            className={classNames({ [classes.hidden]: requestForQuotation })}
                            classes={{
                                root: classes.root,
                                shrink: classes.shrink,
                                formControl: classes.labelFormControl,
                            }}
                        >
                            {languageData?.ToolMaker}
                        </InputLabel>
                    )}
                    {(props.addMode || (!props.addMode && toolData?.state !== 1)) && (
                        <Select
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            // disabled={!props.addMode}
                            variant={"outlined"}
                            className={classNames(classes.selectWrapper, { [classes.hidden]: requestForQuotation })}
                            value={toolMakerId}
                            onChange={handleToolMakerChange}
                        >
                            {toolMakersEntities}
                        </Select>
                    )}
                    {(props.addMode || (!props.addMode && toolData?.state === 0)) && (
                        <InputLabel
                            required
                            className={classNames({ [classes.hidden]: requestForQuotation })}
                            classes={{
                                root: classes.root,
                                shrink: classes.shrink,
                                formControl: classes.labelFormControl,
                            }}
                        >
                            {languageData?.StartDate}
                        </InputLabel>
                    )}
                    {(props.addMode || (!props.addMode && toolData?.state === 0)) && (
                        <TextField
                            className={classNames(classes.datePickerWrapper, { [classes.hidden]: requestForQuotation })}
                            disabled={!props.addMode || requestForQuotation}
                            type="date"
                            defaultValue={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required={props.addMode}
                            inputProps={{
                                min: new Date().toISOString().split("T")[0],
                            }}
                        />
                    )}
                    <div className={classes.globalError}>{globalError}</div>
                    {buttons}
                </FormLayout>
            </div>
        </div>
    );

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

export default ToolModal;
