import React, { useContext, useEffect, useState } from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { Button, GridList, GridListTile, IconButton, makeStyles } from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import FormLayout from "../../containers/FormLayout/FormLayout";
import StyledInput from "../StyledInput/StyledInput";
import StyledButton from "../StyledButton/StyledButton";
import WebsiteContext from "../../utils/Website";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Axios from "axios";

import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
    inputs: {
        display: "flex",
        flexDirection: "column",
    },
    addButton: {
        width: "fit-content",
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },

    ulWrapper: {
        listStyleType: "disc",
    },
    liWrapper: {
        "&::marker": {
            color: theme.palette.primary.main,
        },
    },
    textWrapper: {
        display: "inline",
        marginRight: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    filenameWrapper: {
        display: "inline",
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    filenameEditModeWrapper: {
        marginLeft: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
        height: "15p",
        lineHeight: "15px",
        textOverflow: "ellipsis",
    },
    uploadFileButton: {
        backgroundColor: "white",
        margin: "6px 10px 7px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    inputClass: {
        width: "210px",
    },
    collapseExpandAll: {
        backgroundColor: "#F8F8F8",
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        justifyContent: "left",
        height: 28.48,
        width: 150,
        padding: "0 10px",
        margin: "5px",
    },
    expandIcon: {
        margin: "0 8px 0 0",
        // fontFamily: "Montserrat",
        // fontWeight: "600"
    },
    gtsForm: {
        background: "#ffffff",
        width: "100%",
        height: "100%",
        borderRadius: "3px",
        // padding: "24px -24px 24px 24px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
    },
    layoutWrapper: {
        height: "100%",
        paddingBottom: 20,
    },
    form: {
        height: "calc(100% - 77px)",
        paddingTop: 18,
        paddingBottom: -18,
        paddingLeft: 18,
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down(680)]: {
            height: "calc(100% - 115px)",
        },
    },
    gtsButtons: {
        background: "#ffffff",
        float: "right",
        borderRadius: "3px",
        display: "flex",
        flexDirection: "column",
        padding: 24,
        width: 150,
    },
    editSwitch: {
        display: "block",
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    treeItemStyle: {
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    listItemStyle: {
        height: "auto",
    },
    root: {
        padding: 0,
    },
}));

const TreelikeForm = (props) => {
    const [formData, setFormData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [newFiles, setNewFiles] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [fileIdsToBeDeleted, setFileIdsToBeDeleted] = useState([]);

    const [customerId, setCustomerId] = useState();

    const { languageData, role } = useContext(WebsiteContext);

    const classes = useStyles(useStyles);

    useEffect(() => {
        if (!editMode) {
            if (role === 0) {
                const options = {
                    url: "/business-logic/customers/" + props.match.params.id + "/general-tool-standard",
                    method: "GET",
                };
                Axios(options).then((response) => {
                    setFormData(response.data.data);
                });
            } else if (role === 1) {
                const customerIdOptions = {
                    url: "/user/customer",
                    method: "GET",
                    headers: {
                        "X-Loading": false,
                    },
                };
                Axios(customerIdOptions).then((rsp) => {
                    const data = rsp.data.data;
                    setCustomerId(data.customerId);
                    const options = {
                        url: "/business-logic/customers/" + data.customerId + "/general-tool-standard",
                        method: "GET",
                    };
                    Axios(options).then((response) => {
                        setFormData(response.data.data);
                    });
                });
            } else {
                if (props.customerId) {
                    setCustomerId(props.customerId);
                    const options = {
                        url: "/business-logic/customers/" + props.customerId + "/general-tool-standard",
                        method: "GET",
                    };
                    Axios(options).then((response) => {
                        setFormData(response.data.data);
                    });
                }
            }
        }
    }, [editMode, props.match.params.id, props.customerId]);

    const findNode = (node, id) => {
        if (node.id === id) return node; // we found the node with the id given, return it
        var result = null; // if the id wasn´t the one we were looking, we need to look if it is in its children
        if (node.children) {
            for (var i = 0; i < node.children.length && result == null; i++) {
                result = findNode(node.children[i], id);
            }
        }
        return result; // return null if it wasn´t in its children, return the node if it was
    };

    const addInput = (id) => {
        for (let i = 0; i <= formData.length; i++) {
            let treeCopy = { ...formData[i] };
            let node = findNode(treeCopy, id);
            if (node) {
                let updatedFormData = JSON.parse(JSON.stringify(formData));
                node.values.push({
                    id: getUniqueUuid(id),
                    text: "",
                    fileName: null,
                    fileId: null,
                    required: false,
                });

                updatedFormData[i] = treeCopy;
                setFormData(updatedFormData);
            }
        }
    };

    const getUniqueUuid = (id) => {
        let newId = uuidv4();
        for (let i = 0; i <= formData.length; i++) {
            let treeCopy = { ...formData[i] };
            let node = findNode(treeCopy, id);
            if (node) {
                const existingIds = node.values.map((value) => value.id);
                while (existingIds.indexOf(newId) > -1) {
                    newId = uuidv4();
                }
            }
        }
        return newId;
    };

    const removeInput = (id, rowIndex) => {
        for (let i = 0; i <= formData.length; i++) {
            let treeCopy = { ...formData[i] };
            let node = findNode(treeCopy, id);
            if (node) {
                if (node.values[rowIndex].fileId) {
                    const copyFileIdsToBeDeleted = [...fileIdsToBeDeleted];
                    copyFileIdsToBeDeleted.push(node.values[rowIndex].fileId);
                    setFileIdsToBeDeleted(copyFileIdsToBeDeleted);
                }
                let updatedFormData = JSON.parse(JSON.stringify(formData));
                node.values.splice(rowIndex, 1);
                updatedFormData[i] = treeCopy;
                updateNewFilesAfterRemoveInput(id, rowIndex);
                setFormData(updatedFormData);
            }
        }
    };

    const setInputText = (event, id, rowIndex) => {
        let value = event.target.value;

        for (let i = 0; i <= formData.length; i++) {
            let treeCopy = { ...formData[i] };
            let node = findNode(treeCopy, id);
            if (node) {
                let updatedFormData = JSON.parse(JSON.stringify(formData));
                node.values[rowIndex].text = value;
                updatedFormData[i] = treeCopy;
                setFormData(updatedFormData);
            }
        }
    };

    const uploadFile = (event, id, rowIndex) => {
        let file = event.target.files[0];

        for (let i = 0; i <= formData.length; i++) {
            let treeCopy = { ...formData[i] };
            let node = findNode(treeCopy, id);

            if (node) {
                let updatedFormData = JSON.parse(JSON.stringify(formData));
                node.values[rowIndex].fileName = file.name;
                const files = [...newFiles];
                files.push({
                    id: id,
                    index: rowIndex,
                    value: file,
                    name: file.name,
                });
                setNewFiles(files);

                updatedFormData[i] = treeCopy;
                setFormData(updatedFormData);
            }
        }
    };

    const updateNewFilesAfterRemoveInput = (id, rowIndex) => {
        const files = [...newFiles];
        const filesFiltered = files.filter((file) => !(file.id === id && file.index === rowIndex));
        filesFiltered.forEach((file) => {
            if (file.id === id && file.index > rowIndex) {
                file.index--;
            }
        });
        setNewFiles(filesFiltered);
    };

    const handleToggleButton = () => {
        if (editMode === true) {
            setOpenModal(true);
        } else {
            setEditMode(true);
            initChildren();
        }
    };

    const initChildren = () => {
        const gms = [...formData];
        for (const value of gms) {
            for (const child of value.children) {
                for (const value of child.values) {
                    if (!value.id) {
                        value.id = uuidv4();
                    }
                }
            }
        }
        setFormData(gms);
    };

    const handleAccept = () => {
        setOpenModal(false);
        setEditMode(false);
    };

    const handleDecline = () => {
        setOpenModal(false);
    };

    const handleClickFilename = (fileId) => {
        window.open(`${process.env.REACT_APP_URL}file-operations/${role === 0 ? props.match.params.id : customerId}/files/${fileId}`);
    };

    const handleFormSubmit = async () => {
        if (newFiles.length > 0) {
            for (const newFile of newFiles) {
                const index = newFiles.findIndex((el) => el === newFile);

                for (let i = 0; i <= formData.length; i++) {
                    let treeCopy = { ...formData[i] };
                    let node = findNode(treeCopy, newFile.id);
                    if (node) {
                        let updatedFormData = JSON.parse(JSON.stringify(formData));
                        if (node.values[newFile.index].fileId) {
                            const options = {
                                url: "/file-operations/" + (role === 0 ? props.match.params.id : customerId) + "/files/" + node.values[newFile.index].fileId,
                                method: "DELETE",
                                headers: {
                                    "X-Loading": false,
                                },
                            };
                            await Axios(options);
                            // .then((_) => {
                            const filesFormData = new FormData();
                            filesFormData.append("files", newFile.value, newFile.name);
                            const options2 = {
                                url: "/file-operations/" + (role === 0 ? props.match.params.id : customerId) + "/files",
                                method: "POST",
                                data: filesFormData,
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                    "X-Loading": false,
                                },
                            };
                            const response = await Axios(options2);

                            // .then((response) => {
                            if (response.status === 200) {
                                node.values[newFile.index].fileId = response.data.data.files[0].id;
                                node.values[newFile.index].fileName = response.data.data.files[0].name;
                                updatedFormData[i] = treeCopy;
                                setFormData(updatedFormData);
                                if (index === newFiles.length - 1) {
                                    updateGts();
                                }
                            }
                            // });
                            // });
                        } else {
                            const filesFormData = new FormData();
                            filesFormData.append("files", newFile.value, newFile.name);
                            const options = {
                                url: "/file-operations/" + (role === 0 ? props.match.params.id : customerId) + "/files",
                                method: "POST",
                                data: filesFormData,
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                    "X-Loading": false,
                                },
                            };
                            const response = await Axios(options);
                            // .then((response) => {
                            if (response.status === 200) {
                                node.values[newFile.index].fileId = response.data.data.files[0].id;
                                node.values[newFile.index].fileName = response.data.data.files[0].name;
                                updatedFormData[i] = treeCopy;
                                setFormData(updatedFormData);
                                if (index === newFiles.length - 1) {
                                    updateGts();
                                }
                            }
                            // });
                        }
                    }
                }
            }
            // newFiles.forEach((newFile, index) => {
            //   for (let i = 0; i <= formData.length; i++) {
            //     let treeCopy = { ...formData[i] };
            //     let node = findNode(treeCopy, newFile.id);
            //     if (node) {
            //       let updatedFormData = JSON.parse(JSON.stringify(formData));
            //       if (node.values[newFile.index].fileId) {
            //         const options = {
            //           url:
            //             "/file-operations/" +
            //             props.match.params.id +
            //             "/files/" +
            //             node.values[newFile.index].fileId,
            //           method: "DELETE",
            //         };
            //         Axios(options).then((_) => {
            //           const filesFormData = new FormData();
            //           filesFormData.append("files", newFile.value, newFile.name);
            //           const options2 = {
            //             url: "/file-operations/" + props.match.params.id + "/files",
            //             method: "POST",
            //             data: filesFormData,
            //             headers: { "Content-Type": "multipart/form-data" },
            //           };
            //           Axios(options2).then((response) => {
            //             node.values[newFile.index].fileId =
            //               response.data.data.files[0].id;
            //             node.values[newFile.index].fileName =
            //               response.data.data.files[0].name;
            //             updatedFormData[i] = treeCopy;
            //             setFormData(updatedFormData);
            //             if (index === newFiles.length - 1) {
            //               updateGts();
            //             }
            //           });
            //         });
            //       } else {
            //         const filesFormData = new FormData();
            //         filesFormData.append("files", newFile.value, newFile.name);
            //         const options = {
            //           url: "/file-operations/" + props.match.params.id + "/files",
            //           method: "POST",
            //           data: filesFormData,
            //           headers: { "Content-Type": "multipart/form-data" },
            //         };
            //         Axios(options).then((response) => {
            //           node.values[newFile.index].fileId =
            //             response.data.data.files[0].id;
            //           node.values[newFile.index].fileName =
            //             response.data.data.files[0].name;
            //           updatedFormData[i] = treeCopy;
            //           setFormData(updatedFormData);
            //           if (index === newFiles.length - 1) {
            //             updateGts();
            //           }
            //         });
            //       }
            //     }
            //   }
            // });
        } else {
            updateGts();
        }
    };

    const updateGts = () => {
        const data = {
            gts: formData,
        };
        const options = {
            url: "/business-logic/customers/" + (role === 0 ? props.match.params.id : customerId) + "/general-tool-standard",
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options).then((_) => {
            deleteFiles();
            setNewFiles([]);
            setEditMode(false);
            setOpenModal(false);
            window.scrollTo(0, 0);
        });
    };

    const update = async () => {
        await handleFormSubmit();
    };

    const deleteFiles = () => {
        if (fileIdsToBeDeleted.length > 0) {
            const options = {
                url: "/file-operations/" + (role === 0 ? props.match.params.id : customerId) + "/files",
                method: "DELETE",
                headers: { "X-Files": fileIdsToBeDeleted },
            };
            Axios(options).then(() => {
                setFileIdsToBeDeleted([]);
            });
        }
    };

    const renderTree = (nodes, parentNode = null) => {
        var labelValue = parentNode != null ? languageData?.gms[parentNode][nodes.name] : languageData?.[nodes.name];
        return (
            <TreeItem classes={{ label: classes.treeItemStyle }} key={nodes.id} nodeId={nodes.id} label={labelValue} onClick={() => onExpandChange(nodes.id)}>
                <div className={classes.inputs}>
                    {!nodes.children.length && (
                        <React.Fragment>
                            {editMode ? (
                                nodes.values?.map((value, index) => (
                                    <GridList key={value.id}>
                                        <GridListTile style={{ height: "auto", width: "auto" }}>
                                            <StyledInput
                                                disabled={role === 2}
                                                className={classes.inputClass}
                                                defaultValue={value.text}
                                                label="text"
                                                onChange={(e) => setInputText(e, nodes.id, index)}
                                                type="text"
                                                required={value?.required}
                                                multiline={true}
                                                showLabel={false}
                                            />
                                        </GridListTile>
                                        <GridListTile style={{ height: "auto", width: "auto" }}>
                                            {role !== 2 && (
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    className={classes.uploadFileButton}
                                                    startIcon={<CloudUploadIcon color="primary" />}
                                                >
                                                    {value.fileName ? languageData?.ChangeFile : languageData?.UploadFile}
                                                    <input type="file" onChange={(e) => uploadFile(e, nodes.id, index)} style={{ display: "none" }} />
                                                </Button>
                                            )}
                                        </GridListTile>
                                        <GridListTile style={{ height: "auto" }}>
                                            <GridList cols={12} style={{ height: "auto", alignItems: "center", flex: 1 }}>
                                                <GridListTile cols={8} style={{ height: "auto" }}>
                                                    {value.fileName && <span className={classes.filenameEditModeWrapper}>{value.fileName}</span>}
                                                </GridListTile>
                                                <GridListTile cols={4} style={{ height: "50px", width: "50px" }}>
                                                    {nodes.values.length > 1 && role !== 2 && (
                                                        <IconButton className={classes.addButton} onClick={() => removeInput(nodes.id, index)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    )}
                                                </GridListTile>
                                            </GridList>
                                        </GridListTile>
                                    </GridList>
                                ))
                            ) : (
                                <ul className={classes.ulWrapper}>
                                    {nodes.values?.map((value, index) => {
                                        if (value.text !== "" || value.fileName != null) {
                                            return (
                                                <li key={index} className={classes.liWrapper}>
                                                    {value.text !== "" && <div className={classes.textWrapper}>{value.text}</div>}
                                                    {value.fileName != null && (
                                                        <div className={classes.filenameWrapper} onClick={() => handleClickFilename(value.fileId)}>
                                                            {value.fileName.length > 15 ? value.fileName.slice(0, 15) + "..." : value.fileName}
                                                        </div>
                                                    )}
                                                </li>
                                            );
                                        }
                                        return "";
                                    })}
                                </ul>
                            )}
                            {editMode && role !== 2 && (
                                <IconButton classes={{ root: classes.root }} color="primary" className={classes.addButton} onClick={() => addInput(nodes.id)}>
                                    <AddCircleIcon />
                                </IconButton>
                            )}
                        </React.Fragment>
                    )}
                </div>
                {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node, nodes.name)) : null}
            </TreeItem>
        );
    };

    const expandNode = (nodes) => {
        let list = [];
        list.push(nodes.id);
        if (nodes.children) {
            nodes.children.forEach((nodeChild) => {
                let childList = expandNode(nodeChild);
                list = [...list, ...childList];
            });
        }
        return list;
    };

    const expandAll = () => {
        let newExpanded = [];
        formData.forEach((item) => {
            let itemList = expandNode(item);
            newExpanded = [...newExpanded, ...itemList];
        });
        setExpanded(newExpanded);
    };

    const collapseAll = () => {
        setExpanded([]);
    };

    const onExpandChange = (id) => {
        const expandedIndex = expanded.indexOf(id);
        let newExpanded = expanded.slice();
        if (expandedIndex !== -1) {
            newExpanded.splice(expandedIndex, 1);
        } else {
            newExpanded.push(id);
        }
        setExpanded(newExpanded);
    };

    return (
        <React.Fragment>
            <div className={classes.gtsForm}>
                <FormLayout
                    onSubmit={async (event) => {
                        event.preventDefault();
                        await update();
                    }}
                    className={classes.layoutWrapper}
                >
                    <GridList cols={12} style={{ marginLeft: 10 }}>
                        <GridListTile cols={window.innerWidth > 650 ? 3 : 12} style={{ height: "auto", marginTop: 5 }}>
                            {editMode && role !== 2 && (
                                <StyledButton className={classes.button} color="primary" variant="contained" type="submit">
                                    {languageData?.Save.toUpperCase()}
                                </StyledButton>
                            )}
                        </GridListTile>
                        {role !== 2 && (
                            <GridListTile cols={window.innerWidth > 650 ? 3 : 12} style={{ height: "auto", padding: 0, marginTop: 5 }}>
                                <div className={classes.editSwitch}>
                                    {languageData?.EditMode}
                                    <Switch checked={editMode} color="primary" onChange={handleToggleButton} />
                                </div>
                            </GridListTile>
                        )}
                        <GridListTile cols={window.innerWidth > 650 ? 6 : 12} style={{ height: "auto" }}>
                            <StyledButton
                                className={classes.collapseExpandAll}
                                variant="outlined"
                                onClick={expandAll}
                                startIcon={<ExpandMoreIcon color="primary" />}
                            >
                                {languageData?.ExpandAll.toUpperCase()}
                            </StyledButton>
                            <StyledButton
                                className={classes.collapseExpandAll}
                                variant="outlined"
                                onClick={collapseAll}
                                startIcon={<ExpandLessIcon color="primary" />}
                            >
                                {languageData?.CollapseAll.toUpperCase()}
                            </StyledButton>
                        </GridListTile>
                    </GridList>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        defaultExpanded={["root"]}
                        expanded={expanded}
                        className={classes.form}
                    >
                        {formData.length > 0 && formData.map((data) => renderTree(data))}
                    </TreeView>
                </FormLayout>
                <Dialog open={openModal} onClose={handleDecline} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{languageData?.EditModeExitAlert}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDecline} color="primary">
                            {languageData?.Cancel}
                        </Button>
                        <Button onClick={handleAccept} color="primary" autoFocus>
                            {languageData?.ExitWithoutSaving}
                        </Button>
                        <Button
                            onClick={async (_) => {
                                await update();
                            }}
                            color="primary"
                            autoFocus
                        >
                            {languageData?.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            {/*<div className={classes.gtsButtons}>*/}
            {/*</div>*/}
        </React.Fragment>
    );
};

export default TreelikeForm;
