import React from "react";
import { Helmet } from "react-helmet";

import Tools from "../../components/Tools/Tools";

const ToolsView = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Tools</title>
            </Helmet>
            <Tools {...props} />
        </React.Fragment>
    );
};

export default ToolsView;
