import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Collapse, Divider, makeStyles } from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import WebsiteContext from "../../../utils/Website";
import RoutingContext from "../../../utils/RoutingContext";
import HourglassIcon from "../../Icons/HourglassIcon";
import AddMediaModal from "../Common/AddMediaModal";
import EditModal from "../Common/EditModal";
import StyledButton from "../../StyledButton/StyledButton";
import DeleteModal from "../Common/DeleteModal";
import AddNew from "../Common/AddNew";

const useStyles = makeStyles((theme) => ({
    listWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "default",
    },
    listElement: {
        [theme.breakpoints.down("md")]: {
            width: "calc(100% - 2px)",
        },
        width: "70%",
        backgroundColor: theme.palette.common.white,
        margin: "0 auto 10px auto",
        padding: "10px 0",
        border: "1px solid #ECECEC",
        borderRadius: "2px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
    },
    title: {
        display: "flex",
        padding: "0 16px",
        width: "calc(100% - 32px)",
        justifyContent: "space-between",
    },
    stepName: {
        lineHeight: "12px",
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
    },
    icon: {
        display: "flex",
        marginRight: 12,
    },
    status: {
        display: "flex",
    },
    pointerIcons: {
        cursor: "pointer",
    },
    textareaEdit: {
        marginRight: 16,
    },
    divider: {
        width: "100%",
        height: 1,
        margin: "20px 0 10px 0",
        backgroundColor: "#191919",
        opacity: 0.1,
    },
    addNew: {
        display: "flex",
        marginRight: 12,
    },
    content: {
        padding: "0 16px",
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
    },
    addMediaButton: {
        margin: "30px 0 20px",
        display: "flex",
        lineHeight: "24px",
        justifyContent: "center",
        padding: 6,
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        cursor: "pointer",
        fontSize: 10,
        color: "#A5A5A5",
        fontWeight: "600",
    },
    normalCursor: {
        color: "rgba(0, 0, 0, 0.26)",
        cursor: "default",
    },
    addMediaIcon: {
        marginRight: 10,
    },
    actionButton: {
        backgroundColor: theme.palette.common.white,
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        justifyContent: "left",
        height: 28,
        width: 120,
        padding: "0 10px",
        marginBottom: 10,
        marginLeft: 16,
        [theme.breakpoints.down("800")]: {
            marginLeft: 0,
        },
    },
    flexCard: {
        display: "flex",
        flex: 1,
    },
    img: {
        minWidth: 1,
        flex: 1,
        maxWidth: "100%",
        height: "auto",
    },
    positionButtons: {
        display: "flex",
        flexDirection: "column",
    },
    upDownButton: {
        backgroundColor: theme.palette.common.white,
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        height: 28,
        width: 28,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderRadius: "3.05172px",
        marginRight: 16,
        marginBottom: 16,
    },
    mediaElement: {
        display: "flex",
        marginBottom: 24,
        [theme.breakpoints.down("800")]: {
            flexDirection: "column",
        },
    },
    actions: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("800")]: {
            marginTop: 16,
        },
    },
    clickable: {
        cursor: "pointer",
    },
    comments: {
        padding: "20px 16px 0",
    },
    comment: {
        marginBottom: 15,
    },
    commentAuthor: {
        display: "flex",
        marginBottom: 10,
    },
    name: {
        lineHeight: "30px",
        fontSize: "12px",
        fontWeight: "500",
        color: "#191919F",
        marginRight: 16,
    },
    date: {
        lineHeight: "30px",
        fontSize: "8px",
        fontWeight: "500",
        color: "rgba(31, 31, 31, 0.5)",
    },
    commentTranslation: {
        display: "flex",
        marginBottom: 2,
    },
    language: {
        width: 30,
        textAlign: "right",
        lineHeight: "24px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#A5A5A5",
        marginRight: 16,
    },
    textarea: {
        minHeight: 70,
        width: "100%",
        padding: 10,
        lineHeight: "12px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#191919",
        fontFamily: "Montserrat",
        background: "#FFFFFF",
        border: "1px solid #ECECEC",
        boxSizing: "border-box",
        borderRadius: 2.57,
    },
    textareaEdit: {
        marginRight: 16,
    },
    editButton: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #ECECEC",
        color: theme.palette.primary.main,
        height: 22,
        width: 22,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderRadius: "3.05172px",
    },
    flex: {
        display: "flex",
    },
    nameIcon: {
        height: 30,
        width: 30,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: "#C00D0E",
        borderRadius: "50%",
        lineHeight: "21px",
        fontSize: "10px",
        fontWeight: "500",
        color: theme.palette.common.white,
        marginRight: 16,
    },
    commentArea: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    commentButton: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
    },
    acceptButtonDisagree: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: 33,
        padding: "0 10px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            opacity: "50%",
        },
        border: "none",
    },
    commentDescription: {
        lineHeight: "24px",
        fontSize: "10px",
        fontWeight: "500",
        color: "#A5A5A5",
        marginRight: 16,
    },
}));

const AcceptanceExchange = ({ data, setData, stepData, id, open, setOpen }) => {
    const classes = useStyles();

    const { languageData, role } = useContext(WebsiteContext);
    const [openAddNew, setOpenAddNew] = useState(false);
    const [openUpload, setOpenUpload] = useState("");
    const [openEditor, setOpenEditor] = useState(false);
    const [accountNameInitials, setAccountNameInitials] = useState("");

    const [elementToEdit, setElementToEdit] = useState(null);
    const [elementToDelete, setElementToDelete] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const [commentToEdit, setCommentToEdit] = useState(null);
    const [commentEn, setCommentEn] = useState("");
    const [commentDe, setCommentDe] = useState("");

    useEffect(() => {
        const name = localStorage.getItem("name");
        setAccountNameInitials(
            name
                .match(/\b(\w)/g)
                .join("")
                .substring(0, 2)
        );
    }, []);

    const uploadFile = (file) => {
        let filesFormatData = new FormData();
        filesFormatData.append("files", file, file.name);

        const options = {
            url: "/file-operations/" + `${id}-customer-acceptance` + "/files",
            method: "POST",
            data: filesFormatData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            const mediaOptions = {
                url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${openUpload}/media`,
                method: "POST",
                data: {
                    fileId: response.data.data.files[0].id,
                    type: 0,
                },
            };
            Axios(mediaOptions).then((response) => {
                setOpenUpload("");
                setData(response.data.data.items);
            });
        });
    };

    const saveNewMedia = (formData, edit, fileId) => {
        const options = {
            url: "/file-operations/" + `${id}-customer-acceptance` + "/files",
            method: "POST",
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
        };
        Axios(options).then((response) => {
            let mediaOptions;
            if (edit === true) {
                mediaOptions = {
                    url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${openUpload}/media/${fileId}`,
                    method: "PUT",
                    data: {
                        newFileId: response.data.data.files[0].id,
                    },
                };
            } else {
                mediaOptions = {
                    url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${openUpload}/media`,
                    method: "POST",
                    data: {
                        fileId: response.data.data.files[0].id,
                        type: 0,
                    },
                };
            }
            Axios(mediaOptions).then((response) => {
                setOpenEditor(false);
                setElementToEdit(null);
                setOpenUpload("");
                setData(response.data.data.items);
            });
        });
    };

    const saveMedia = (formData) => {
        if (!openEditor) {
            const deleteOptions = {
                url: "/file-operations/" + `${id}-customer-acceptance` + "/files/" + elementToEdit?.fileId,
                headers: {
                    "X-Loading": false,
                },
                method: "DELETE",
            };
            Axios(deleteOptions).then((_) => {
                saveNewMedia(formData, true, elementToEdit?.fileId);
            });
        } else {
            saveNewMedia(formData, false, elementToEdit?.fileId);
        }
    };

    const duplicate = (reviewId, mediaId) => {
        const options = {
            url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${reviewId}/media/${mediaId}/duplicate`,
            method: "POST",
            data: {
                folder: `${id}-customer-acceptance`,
            },
        };
        Axios(options).then((response) => {
            setData(response.data.data.items);
        });
    };

    const moveMedia = (up, itemId, mediaId) => {
        const options = {
            url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${itemId}/media/${mediaId}`,
            method: "POST",
            data: {
                up: up,
            },
        };
        Axios(options).then((response) => {
            setData(response.data.data.items);
        });
    };

    const handleDelete = () => {
        const deleteOptions = {
            url: "/file-operations/" + `${id}-customer-acceptance` + `/files/${elementToDelete.fileId}`,
            method: "DELETE",
            headers: {
                "X-Loading": false,
            },
        };
        Axios(deleteOptions).then((response) => {
            const options = {
                url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${open}/media/${elementToDelete.fileId}`,
                method: "DELETE",
            };
            Axios(options).then((response) => {
                setElementToDelete(null);
                setData(response.data.data.items);
            });
        });
    };

    const submitComment = (de, en) => {
        const options = {
            url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${open}/comments`,
            method: "POST",
            data: {
                de: de,
                en: en,
            },
        };
        Axios(options).then((response) => {
            setCommentDe("");
            setCommentEn("");
            setData(response.data.data.items);
        });
    };

    const handleCancelDelete = () => {
        setElementToDelete(null);
        setItemToDelete(null);
    };

    const saveComment = (itemId) => {
        const options = {
            url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${itemId}/comments/${commentToEdit.comment.id}`,
            method: "PUT",
            data: {
                de: commentToEdit.language === "de" ? commentToEdit.editedComment : commentToEdit.comment.text.de,
                en: commentToEdit.language === "en" ? commentToEdit.editedComment : commentToEdit.comment.text.en,
            },
        };
        Axios(options).then((response) => {
            setData(response.data.data.items);
            setCommentToEdit(null);
        });
    };

    const cancelEditComment = () => {
        setCommentToEdit(null);
    };

    const deleteListItem = () => {
        const options = {
            url: `/business-logic/tools/${id}/processes/customer-acceptance-item/${itemToDelete}`,
            method: "DELETE",
        };
        Axios(options).then((response) => {
            setData(response.data.data.items);
            setItemToDelete(null);
        });
    };

    const handleAddAspect = (nameDe, nameEn) => {
        const data = {
            de: nameDe,
            en: nameEn,
        };
        const options = {
            url: `/business-logic/tools/${id}/processes/customer-acceptance-item`,
            method: "POST",
            data: data,
        };
        Axios(options).then((response) => {
            setOpenAddNew(false);
            setData(response.data.data.items);
        });
    };

    return (
        <React.Fragment>
            <AddMediaModal
                openEditor={() => {
                    setOpenEditor(true);
                }}
                open={openUpload !== "" && openEditor === false}
                onClose={() => setOpenUpload("")}
                uploadFile={uploadFile}
            />
            <EditModal
                open={elementToEdit || openEditor ? true : false}
                onClose={() => {
                    setOpenEditor(false);
                    setElementToEdit(null);
                    setOpenUpload("");
                }}
                saveMedia={saveMedia}
                src={openEditor === false ? `${process.env.REACT_APP_URL}file-operations/` + id + "-customer-acceptance/files/" + elementToEdit?.fileId : ""}
            />
            <DeleteModal
                open={elementToDelete || itemToDelete ? true : false}
                onCancel={() => handleCancelDelete()}
                onDelete={itemToDelete ? deleteListItem : handleDelete}
            />
            <AddNew handleAdd={handleAddAspect} open={openAddNew} onClose={() => setOpenAddNew(false)} />
            {data?.map((element, index) => (
                <div key={element.id} className={classes.listElement}>
                    <div className={classes.title}>
                        <div className={classes.stepName}>
                            <div className={classes.icon}>
                                <HourglassIcon color="inherit" />
                            </div>
                            {element.name}
                        </div>
                        <div className={classes.status}>
                            {index !== 0 && role === 0 && element?.media?.length === 0 && element?.comments?.length === 0 && (
                                <DeleteIcon onClick={() => setItemToDelete(element.id)} className={classes.pointerIcons + " " + classes.textareaEdit} />
                            )}
                            {open !== element.id ? (
                                <ExpandMoreIcon
                                    className={classes.pointerIcons}
                                    onClick={() => {
                                        setCommentEn("");
                                        setCommentDe("");
                                        setOpen(open === element.id ? null : element.id);
                                    }}
                                />
                            ) : (
                                <ExpandLessIcon
                                    className={classes.pointerIcons}
                                    onClick={() => {
                                        setOpen(open === element.id ? null : element.id);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <Collapse in={open === element.id}>
                        <div className={classes.content}>
                            {element.media?.map((mediaElement, index) => (
                                <div key={index} className={classes.mediaElement}>
                                    <div className={classes.flexCard}>
                                        <div className={classes.positionButtons}>
                                            {index > 0 && (
                                                <div className={classes.upDownButton} onClick={() => moveMedia(true, element.id, mediaElement.fileId)}>
                                                    <ExpandLessIcon color="inherit" />
                                                </div>
                                            )}
                                            {index >= 0 && index < element.media?.length && index !== element.media?.length - 1 && (
                                                <div className={classes.upDownButton} onClick={() => moveMedia(false, element.id, mediaElement.fileId)}>
                                                    <ExpandMoreRounded color="inherit" />
                                                </div>
                                            )}
                                        </div>
                                        <img
                                            src={`${process.env.REACT_APP_URL}file-operations/${id}-customer-acceptance/files/${mediaElement.fileId}`}
                                            alt={mediaElement.id}
                                            className={classes.img}
                                        />
                                    </div>
                                    <div className={classes.actions}>
                                        <StyledButton
                                            onClick={() => {
                                                setOpenUpload(element.id);
                                                setElementToEdit(mediaElement);
                                            }}
                                            className={classes.actionButton}
                                            variant="outlined"
                                            startIcon={<EditIcon color="primary" />}
                                        >
                                            {languageData?.Edit}
                                        </StyledButton>
                                        <StyledButton
                                            onClick={() => duplicate(element.id, mediaElement.fileId)}
                                            className={classes.actionButton}
                                            variant="outlined"
                                            startIcon={<FileCopyIcon color="primary" />}
                                        >
                                            {languageData?.Duplicate}
                                        </StyledButton>
                                        {role === 0 && (
                                            <StyledButton
                                                onClick={() => setElementToDelete(mediaElement)}
                                                className={classes.actionButton}
                                                variant="outlined"
                                                startIcon={<DeleteIcon color="primary" />}
                                            >
                                                {languageData?.Delete}
                                            </StyledButton>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div
                                className={classNames(classes.addMediaButton)}
                                onClick={() => {
                                    setOpenUpload(element.id);
                                }}
                            >
                                <AddCircleIcon className={classes.addMediaIcon} color="primary" />
                                {languageData?.AddMedia}
                            </div>
                            <Divider className={classes.divider} />
                            <div className={classes.comments}>
                                {element.comments?.map((comment, index) => (
                                    <div key={comment.id} className={classes.comment}>
                                        <div className={classes.commentAuthor}>
                                            <div className={classes.nameIcon}>
                                                {comment.owner
                                                    .match(/\b(\w)/g)
                                                    .join("")
                                                    .substring(0, 2)}
                                            </div>
                                            <div className={classes.name}>{comment.owner}</div>
                                            <div className={classes.date}>{comment.date}</div>
                                        </div>
                                        <div className={classes.commentTranslation}>
                                            <div className={classes.language}>EN</div>
                                            {commentToEdit?.comment.id === comment.id && commentToEdit?.language === "en" ? (
                                                <>
                                                    <textarea
                                                        onChange={(e) =>
                                                            setCommentToEdit({
                                                                ...commentToEdit,
                                                                editedComment: e.target.value,
                                                            })
                                                        }
                                                        value={commentToEdit.editedComment}
                                                        className={classes.textarea + " " + classes.textareaEdit}
                                                        placeholder="Add a comment.."
                                                    />
                                                    <div className={classes.listWrapper}>
                                                        <div onClick={() => saveComment(element.id)} className={classes.editButton}>
                                                            <SaveIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                        <div onClick={cancelEditComment} className={classes.editButton + " " + classes.margin}>
                                                            <CloseIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={classes.commentDescription}>{comment.text.en}</div>
                                                    {role === 0 && comment.owner === localStorage.getItem("name") && (
                                                        <div
                                                            onClick={() =>
                                                                setCommentToEdit({
                                                                    comment: comment,
                                                                    language: "en",
                                                                    editedComment: comment.text.en,
                                                                })
                                                            }
                                                            className={classes.editButton}
                                                        >
                                                            <EditIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className={classes.commentTranslation}>
                                            <div className={classes.language}>D</div>
                                            {commentToEdit?.comment.id === comment.id && commentToEdit?.language === "de" ? (
                                                <>
                                                    <textarea
                                                        onChange={(e) =>
                                                            setCommentToEdit({
                                                                ...commentToEdit,
                                                                editedComment: e.target.value,
                                                            })
                                                        }
                                                        value={commentToEdit.editedComment}
                                                        className={classes.textarea + " " + classes.textareaEdit}
                                                        placeholder="Einen Kommentar hinzufügen.."
                                                    />
                                                    <div className={classes.listWrapper}>
                                                        <div onClick={() => saveComment(element.id)} className={classes.editButton}>
                                                            <SaveIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                        <div onClick={cancelEditComment} className={classes.editButton + " " + classes.margin}>
                                                            <CloseIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={classes.commentDescription}>{comment.text.de}</div>
                                                    {role === 0 && comment.owner === localStorage.getItem("name") && (
                                                        <div
                                                            onClick={() =>
                                                                setCommentToEdit({
                                                                    comment: comment,
                                                                    language: "de",
                                                                    editedComment: comment.text.de,
                                                                })
                                                            }
                                                            className={classes.editButton}
                                                        >
                                                            <EditIcon color="inherit" fontSize="inherit" />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {stepData?.state?.id !== 2 && (
                                    <div className={classes.flex}>
                                        <div className={classes.nameIcon}>{accountNameInitials}</div>
                                        <div className={classes.commentArea}>
                                            <div className={classes.commentAuthor}>
                                                <div className={classes.language}>EN</div>
                                                <textarea
                                                    value={commentEn}
                                                    onChange={(e) => setCommentEn(e.target.value)}
                                                    className={classes.textarea}
                                                    placeholder="Add a comment.."
                                                />
                                            </div>
                                            <div className={classes.commentAuthor}>
                                                <div className={classes.language}>D</div>
                                                <textarea
                                                    value={commentDe}
                                                    onChange={(e) => setCommentDe(e.target.value)}
                                                    className={classes.textarea}
                                                    placeholder="Einen Kommentar hinzufügen.."
                                                />
                                            </div>
                                            <div className={classes.commentButton}>
                                                <StyledButton
                                                    disabled={commentEn || commentDe ? false : true}
                                                    onClick={() => submitComment(commentDe, commentEn)}
                                                    className={classes.acceptButtonDisagree}
                                                    variant="outlined"
                                                >
                                                    {languageData?.Comment}
                                                </StyledButton>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Collapse>
                </div>
            ))}
            {role !== 2 && (
                <div className={classes.listElement}>
                    <div className={classNames(classes.title, classes.clickable)} onClick={() => setOpenAddNew(true)}>
                        <div className={classes.stepName}>
                            <div className={classes.addNew}>
                                <AddCircleIcon color="inherit" />
                            </div>
                            {languageData?.AddNew}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default AcceptanceExchange;
