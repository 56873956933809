import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, makeStyles, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../../utils/Website";
import StyledButton from "../../../../StyledButton/StyledButton";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { range } from "rrule/dist/esm/src/helpers";
import StyledInput from "../../../../StyledInput/StyledInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "100%",
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        // boxShadow:
        //     "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        marginRight: 16,
        height: "33px",
        fontFamily: "Montserrat",
        fontWeight: "600",
        marginLeft: 10,
    },
    modifyDimensionsButton: {
        borderRadius: "3px",
        marginRight: 18,
        marginTop: 15,
        height: "37px",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    buttonWrapper: {
        background: "white",
        padding: 18,
    },
    header: {
        background: "#FAFAFA",
        border: "1px solid #ECECEC",
    },
    row: {
        background: "#FFFFFF",
        border: "1px solid #ECECEC",
    },
    cell: {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        left: "unset",
    },
    headerCell: {
        color: "#777777",
    },
    tableContainer: {
        height: "100%",
        margin: "25px 0 25px 0",
    },
    inputFile: {
        opacity: 0,
        width: 1,
    },
    openFile: {
        color: "#00A7D1",
        cursor: "pointer",
    },
    uploadFile: {
        backgroundColor: "white",
        marginRight: "10px",
    },
    filenameEditModeWrapper: {
        marginLeft: "10px",
        fontFamily: "Montserrat",
        fontWeight: "500",
        overflow: "hidden",
        height: "15p",
        lineHeight: "15px",
        textOverflow: "ellipsis",
    },
    inputWrapper: {
        width: 250,
        margin: "15px 0 15px 0",
        marginRight: 10,
    },
    answerWrapper: {
        maxWidth: 100,
        minWidth: 75,
        height: 33,
    },
    cavityWrapper: {
        marginTop: 30,
        marginBottom: 30,
    },
    radioButtonsWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        margin: "5px 10px 5px",
    },
    rowWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    radioButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        height: 8.5,
        width: 8.5,
        "&$checked": {
            color: "#C00D0E",
        },
    },
    checked: {},
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
    },
    messageGrid: {
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "12px",
        textAlign: "center",
    },
}));

const CavityParams = (props) => {
    const classes = useStyles();
    const { languageData, role } = useContext(WebsiteContext);
    const [nrOfColumns, setNrOfColumns] = useState(3);

    const defaultShotTime = {
        cavityInsertTemp: "",
        checkSurface: false,
        coreInsertTemp: "",
        moldbaseTemp: "",
        name: "",
        partWeight: "",
        sliderInsertTemp: "",
        temperature: "",
    };

    useEffect(() => {
        if (props.data?.cavityParams?.length !== 0 && props.data?.cavityParams?.length !== undefined) {
            setNrOfColumns(props.data?.cavityParams[0].dimensions.length);
        }
        if (props.nrOfCavities === "") {
            if (props.data) {
                const tmpData = [];
                for (let i = 0; i < props.currentNrOfCavities; i++) {
                    const defColumns = [];
                    const defDimensions = [];
                    for (let i = 0; i < nrOfColumns; i++) {
                        defColumns.push("");
                        defDimensions.push({
                            dimension: "",
                            tolerance: "",
                        });
                    }
                    tmpData.push({
                        dimensions: [...defDimensions],
                        shotTimes: [
                            {
                                ...defaultShotTime,
                                columns: defColumns.slice(),
                            },
                        ],
                    });
                }
                props.saveData("cavityParams", tmpData);
            }
        } else if (props.nrOfCavities !== null && props.data?.cavityParams.length === 0) {
            if (props.data) {
                const tmpData = [];
                for (let i = 0; i < props.currentNrOfCavities; i++) {
                    const defColumns = [];
                    const defDimensions = [];
                    for (let i = 0; i < nrOfColumns; i++) {
                        defColumns.push("");
                        defDimensions.push({
                            dimension: "",
                            tolerance: "",
                        });
                    }
                    tmpData.push({
                        dimensions: [...defDimensions],
                        shotTimes: [
                            {
                                ...defaultShotTime,
                                columns: defColumns.slice(),
                            },
                        ],
                    });
                }
                props.saveData("cavityParams", tmpData);
            }
        }
    }, [props.currentNrOfCavities]);

    const modifyDimensions = () => {
        const tmpData = [...props.data?.cavityParams];
        const dim = tmpData[0].dimensions.length;
        for (let i = 0; i < props.currentNrOfCavities; i++) {
            if (nrOfColumns > dim) {
                for (let j = 0; j < nrOfColumns - dim; j++) {
                    if (tmpData[i] !== undefined) {
                        tmpData[i].dimensions.push({
                            dimension: "",
                            tolerance: "",
                        });
                        tmpData[i].shotTimes.forEach((el) => {
                            el.columns.push("");
                        });
                    }
                }
            } else if (nrOfColumns < dim) {
                if (tmpData[i] !== undefined) {
                    tmpData[i].dimensions.splice(nrOfColumns, dim - nrOfColumns);
                    tmpData[i].shotTimes.forEach((el) => {
                        el.columns.splice(nrOfColumns, dim - nrOfColumns);
                    });
                }
            }
        }
        props.saveData("cavityParams", tmpData);
    };

    const addNewRow = (tableIndex) => {
        const tmpData = [...props.data.cavityParams];
        const defColumns = [];
        for (let i = 0; i < nrOfColumns; i++) {
            defColumns.push("");
        }
        tmpData[tableIndex].shotTimes.push({
            ...defaultShotTime,
            columns: defColumns.slice(),
        });
        props.saveData(tmpData);
    };

    return (
        <div>
            <div className={classes.wrapper}>
                <div className={classes.layoutWrapper}>
                    {props.nrOfCavities && props.nrOfCavities !== "" ? (
                        <div>
                            {role !== 1 && (
                                <div className={classes.rowWrapper}>
                                    <StyledInput
                                        showLabel
                                        label={languageData?.NumberOfColumns}
                                        disabled={role === 1}
                                        defaultValue={nrOfColumns ? nrOfColumns : ""}
                                        onChange={(e) => {
                                            setNrOfColumns(e.target.value);
                                        }}
                                        className={classes.inputWrapper}
                                        type="number"
                                    />
                                    <StyledButton
                                        onClick={() => {
                                            modifyDimensions();
                                        }}
                                        className={classes.modifyDimensionsButton}
                                        color="primary"
                                        variant="contained"
                                        disabled={nrOfColumns === "" || Number(nrOfColumns) < 0}
                                    >
                                        {languageData?.ModifyDimensions.toLocaleUpperCase()}
                                    </StyledButton>
                                </div>
                            )}
                            {props.data?.cavityParams.map((cavity, cavityIndex) => (
                                <div key={cavityIndex} className={classes.cavityWrapper}>
                                    <div className={classes.rowWrapper}>
                                        <div>
                                            {languageData?.CavityNumber}: {cavityIndex + 1}
                                        </div>
                                        {role !== 1 && (
                                            <div>
                                                <StyledButton
                                                    onClick={() => {
                                                        addNewRow(cavityIndex);
                                                    }}
                                                    className={classes.button}
                                                    color="primary"
                                                    variant="contained"
                                                >
                                                    {languageData?.AddNewShotTime.toLocaleUpperCase()}
                                                </StyledButton>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <TableContainer className={classes.tableContainer}>
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow className={classes.header}>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Dimension}</TableCell>
                                                        {cavity.dimensions.map((dim, index) => (
                                                            <TableCell key={index} className={classes.cell + " " + classes.headerCell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={dim.dimension}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].dimensions[index].dimension = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                        ))}
                                                        <TableCell className={classes.cell + " " + classes.headerCell}></TableCell>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}></TableCell>
                                                    </TableRow>
                                                    <TableRow className={classes.header}>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.Tolerance}</TableCell>
                                                        {cavity.dimensions.map((dim, index) => (
                                                            <TableCell key={index} className={classes.cell + " " + classes.headerCell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={dim.tolerance}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].dimensions[index].tolerance = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                        ))}
                                                        <TableCell className={classes.cell + " " + classes.headerCell}></TableCell>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}></TableCell>
                                                    </TableRow>
                                                    <TableRow className={classes.header}>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.ShotTime}</TableCell>
                                                        {cavity.dimensions.map((dim, index) => (
                                                            <TableCell key={index} className={classes.cell + " " + classes.headerCell}></TableCell>
                                                        ))}
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.PartWeight}</TableCell>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.SurfaceCheck}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {cavity.shotTimes.map((shotTime, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell className={classes.cell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={shotTime.name}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].shotTimes[index].name = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                            {shotTime.columns.map((col, colIndex) => (
                                                                <TableCell className={classes.cell} key={colIndex}>
                                                                    <StyledInput
                                                                        disabled={role === 1}
                                                                        defaultValue={col}
                                                                        onChange={(e) => {
                                                                            const tmpData = [...props.data.cavityParams];
                                                                            tmpData[cavityIndex].shotTimes[index].columns[colIndex] = e.target.value;
                                                                            props.saveData("cavityParams", tmpData);
                                                                        }}
                                                                        className={classes.answerWrapper}
                                                                        type="text"
                                                                    />
                                                                </TableCell>
                                                            ))}
                                                            <TableCell className={classes.cell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={shotTime.partWeight}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].shotTimes[index].partWeight = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.cell}>
                                                                <RadioGroup
                                                                    className={classes.radioButtonsWrapper}
                                                                    value={props.data && props.data.cavityParams[cavityIndex].shotTimes[index].checkSurface}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].shotTimes[index].checkSurface = e.target.value === "true";
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                >
                                                                    <FormControlLabel
                                                                        disabled={role === 1}
                                                                        classes={{ label: classes.textWrapper }}
                                                                        name="switches"
                                                                        value={true}
                                                                        control={
                                                                            <Radio className={classes.radioButton} classes={{ checked: classes.checked }} />
                                                                        }
                                                                        label={languageData?.Yes}
                                                                    />
                                                                    <FormControlLabel
                                                                        disabled={role === 1}
                                                                        classes={{ label: classes.textWrapper }}
                                                                        name="switches"
                                                                        value={false}
                                                                        control={
                                                                            <Radio className={classes.radioButton} classes={{ checked: classes.checked }} />
                                                                        }
                                                                        label={languageData?.No}
                                                                    />
                                                                </RadioGroup>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TableContainer className={classes.tableContainer}>
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow className={classes.header}>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>{languageData?.ShotTime}</TableCell>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>
                                                            {languageData?.CavityInsertTemperature}
                                                        </TableCell>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>
                                                            {languageData?.CoreInsertTemperature}
                                                        </TableCell>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>
                                                            {languageData?.SliderTemperature}
                                                        </TableCell>
                                                        <TableCell className={classes.cell + " " + classes.headerCell}>
                                                            {languageData?.MoldbaseTemperature}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {cavity.shotTimes.map((shotTime, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell className={classes.cell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={shotTime.name}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].shotTimes[index].name = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.cell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={shotTime.cavityInsertTemp}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].shotTimes[index].cavityInsertTemp = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.cell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={shotTime.coreInsertTemp}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].shotTimes[index].coreInsertTemp = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.cell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={shotTime.sliderInsertTemp}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].shotTimes[index].sliderInsertTemp = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                            <TableCell className={classes.cell + " " + classes.headerCell}>
                                                                <StyledInput
                                                                    disabled={role === 1}
                                                                    defaultValue={shotTime.moldbaseTemp}
                                                                    onChange={(e) => {
                                                                        const tmpData = [...props.data.cavityParams];
                                                                        tmpData[cavityIndex].shotTimes[index].moldbaseTemp = e.target.value;
                                                                        props.saveData("cavityParams", tmpData);
                                                                    }}
                                                                    className={classes.answerWrapper}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className={classes.messageGrid}>
                            <Alert severity="warning">{languageData?.CavitiesMessage}</Alert>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CavityParams;
