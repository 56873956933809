import React, { useContext, useEffect, useState } from "react";
import Contacts from "./Sections/Contacts";
import { Divider, makeStyles } from "@material-ui/core";
import WebsiteContext from "../../../utils/Website";
import FormLayout from "../../../containers/FormLayout/FormLayout";
import StyledButton from "../../StyledButton/StyledButton";
import ProjectData from "./Sections/ProjectData";
import MoldType from "./Sections/MoldType";
import Process from "./Sections/Process";
import Material from "./Sections/Material";
import MoldLayout from "./Sections/MoldLayout";
import RunnerSystem from "./Sections/RunnerSystem";
import Demolding from "./Sections/Demolding";
import Cooling from "./Sections/Cooling";
import Surface from "./Sections/Surface";
import MoldBase from "./Sections/MoldBase";
import Steel from "./Sections/Steel";
import Coating from "./Sections/Coating";
import Connector from "./Sections/Connector";
import DocumentsForDelivery from "./Sections/DocumentsForDelivery";
import Axios from "axios";
import { Alert } from "@material-ui/lab";
import Sketch from "./Sections/Sketch";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(100% - 18px)",
        padding: 9,
    },
    layoutWrapper: {
        height: "100%",
        background: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        fontFamily: "Montserrat",
        fontWeight: "500",
    },
    contentWrapper: {
        height: "calc(100% - 80px)",
        overflow: "auto",
        padding: "0 18px 18px 18px",
    },
    button: {
        borderRadius: "3px",
        width: "120px",
        // marginBottom: 24,
        height: "auto",
        fontFamily: "Montserrat",
        fontWeight: "600",
    },
    divider: {
        width: "100%",
    },
    buttonWrapper: {
        padding: 18,
        background: "white",
    },
    messageGrid: {
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "12px",
        textAlign: "center",
        marginBottom: "10px",
    },
    rowWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));

const ToolSpecification = (props) => {
    const [resources, setResources] = useState({});
    const [moldSpecification, setMoldSpecification] = useState(null);
    const [newFiles, setNewFiles] = useState([]);
    const [historyFiles, setHistoryFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [initialPictureFiles, setInitialPictureFiles] = useState([]);
    const [finalPictureFiles, setFinalPictureFiles] = useState([]);

    const { languageData, role } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    const getPictureFilesIds = (projectData) => {
        let ids = [];
        if (projectData?.picture) {
            ids.push(projectData?.picture?.id);
        }
        for (let i = 0; i < projectData?.additionalFiles?.length; i++) {
            if (projectData?.additionalFiles[i].picture) {
                ids.push(projectData?.additionalFiles[i].picture?.id);
            }
        }
        return ids;
    };

    useEffect(() => {
        //TODO replace response after GET endpoint is done
        if (props.toolId && props.toolMakerId && props.projectId) {
            const data = {
                tool_id: props.toolId,
                tool_maker_id: props.toolMakerId,
            };
            const options = {
                url: "/customers/projects/" + props.projectId + "/tool-specification-resources",
                method: "POST",
                data: data,
            };
            Axios(options).then((response) => {
                setResources(response.data.data.toolSpecificationsResources);
                if (response.data.data.toolSpecifications !== null) {
                    const pictureIds = getPictureFilesIds(response?.data?.data?.toolSpecifications?.projectData);
                    setInitialPictureFiles(pictureIds);
                    setMoldSpecification({
                        ...response.data.data.toolSpecifications,
                        projectData: {
                            estimatedInjectionMA: response?.data?.data?.toolSpecifications?.projectData?.estimatedInjectionMA,
                            projectFiles: [
                                {
                                    name: response?.data?.data?.toolSpecifications?.projectData?.name,
                                    picture: {
                                        id: response?.data?.data?.toolSpecifications?.projectData?.picture?.id,
                                        name: response?.data?.data?.toolSpecifications?.projectData?.picture?.name,
                                    },
                                    dataFile2D: {
                                        id: response?.data?.data?.toolSpecifications?.projectData?.dataFile2D?.id,
                                        name: response?.data?.data?.toolSpecifications?.projectData?.dataFile2D?.name,
                                    },
                                    dataFile3D: {
                                        id: response?.data?.data?.toolSpecifications?.projectData?.dataFile3D?.id,
                                        name: response?.data?.data?.toolSpecifications?.projectData?.dataFile3D?.name,
                                    },
                                },
                                ...response?.data?.data?.toolSpecifications?.projectData?.additionalFiles,
                            ],
                        },
                    });
                } else {
                    setMoldSpecification({
                        contacts: {
                            customerId: null,
                            swsId: null,
                            moldMakerId: null,
                        },
                        moldType: {
                            moldTypeId: null,
                            shotWarranty: {
                                id: null,
                                other: null,
                            },
                            moldStandardId: null,
                        },
                        projectData: {
                            projectFiles: [],
                            estimatedInjectionMA: null,
                        },
                        process: {
                            estimatedCycleTime: "",
                            calculatedClampingForce: "",
                            calculatedPartWeight: "",
                            calculatedRunnerWeight: "",
                            calculatedShotWeight: "",
                        },
                        cooling: {
                            temperatureCavitySide: null,
                            temperatureCoreSide: null,
                            isolationPlate: [],
                            coolingMediumId: 2,
                        },
                        moldLayout: {
                            numberOfCavity: "",
                            kindOfTool: {
                                firstLevelId: null,
                                secondLevelId: null,
                                thirdLevelId: null,
                            },
                            kindOfToolOthers: [
                                {
                                    id: null,
                                    value: "",
                                },
                            ],
                            partTakeOutId: null,
                        },
                        material: {
                            names: [],
                            inserts: [],
                            films: [],
                        },
                        demolding: {
                            slider: [],
                            lifter: [],
                            ejectorDesign: [],
                            ejectorFunction: [],
                            unscrewing: null,
                        },
                        runnerSystem: [],
                        steel: {
                            insertSteel: {
                                cavitySide: {
                                    checked: false,
                                    value: "",
                                },
                                coreSide: {
                                    checked: false,
                                    value: "",
                                },
                                slider: {
                                    checked: false,
                                    value: "",
                                },
                                lifter: {
                                    checked: false,
                                    value: "",
                                },
                                stripperPlate: {
                                    checked: false,
                                    value: "",
                                },
                            },
                            mouldBase: {
                                aPlate: {
                                    checked: false,
                                    value: "",
                                },
                                bPlate: {
                                    checked: false,
                                    value: "",
                                },
                                backupPlate: {
                                    checked: false,
                                    value: "",
                                },
                                hotrunnerPlate: {
                                    checked: false,
                                    value: "",
                                },
                                clampingPlate: {
                                    checked: false,
                                    value: "",
                                },
                                ejectorBasePlate: {
                                    checked: false,
                                    value: "",
                                },
                                ejectorHoldingPlate: {
                                    checked: false,
                                    value: "",
                                },
                            },
                        },
                        coating: {
                            cavitySide: null,
                            coreSide: null,
                            movingParts: null,
                        },
                        documentsForDelivery: {
                            steelCertification: true,
                            hardnessCertification: true,
                            coolingLayout: true,
                            injectionParameter: true,
                            measurementReport: true,
                            weeklyReport: true,
                            updated3dAssembly: true,
                            updated3dDetail: true,
                            updated2dAssembly: true,
                            updated2dDetail: true,
                            sparePartList: true,
                            maintenanceManual: true,
                            electricConnectingLayout: null,
                            hotrunnerData: null,
                            hydraulicConnecting: null,
                            functionFlowchart: null,
                        },
                        surface: {
                            cavitySide: {
                                id: null,
                                value: "",
                            },
                            coreSide: {
                                id: null,
                                value: "",
                            },
                        },
                        connector: {
                            electricConnector: false,
                            hydraulicConnector: true,
                            airConnector: false,
                            switches: false,
                        },
                        moldbase: {
                            ejectorSystemId: null,
                            interlockSystemId: null,
                            clampingSystemId: null,
                        },
                        sketch: {
                            file: null,
                        },
                    });
                }
            });
        }
    }, [props.toolMakerId, props.toolId, props.projectId]);

    const updateMoldSpecification = (category, info, value, index) => {
        let newMoldSpec = { ...moldSpecification };

        if (index !== undefined && index !== null) {
            newMoldSpec[category][index][info] = value;
        } else {
            newMoldSpec[category][info] = value;
        }

        setMoldSpecification(newMoldSpec);
    };

    const updateMoldSpecificationLists = (category, info, value, field, index) => {
        let newMoldSpec = { ...moldSpecification };

        newMoldSpec[category][info][index][field] = value;

        setMoldSpecification(newMoldSpec);
    };

    const addNewRow = (category, info, value) => {
        let newMoldSpec = { ...moldSpecification };

        if (info !== null) {
            newMoldSpec[category][info].push(value);
        } else {
            newMoldSpec[category].push(value);
        }

        setMoldSpecification(newMoldSpec);
    };

    const removeRow = (category, info, index, runnerSystem) => {
        let newMoldSpec = { ...moldSpecification };
        const tmp = [...filesToDelete];
        if (runnerSystem === false) {
            if (
                newMoldSpec[category][info][index].fileId !== undefined &&
                newMoldSpec[category][info][index].fileId !== null &&
                newMoldSpec[category][info][index].fileId !== ""
            ) {
                if (info !== "projectFiles") tmp.push(newMoldSpec[category][info][index].fileId);
            }
            setFilesToDelete(tmp);
            newMoldSpec[category][info].splice(index, 1);
        } else {
            if (
                newMoldSpec[category][info][index].fileId !== undefined &&
                newMoldSpec[category][info][index].fileId !== null &&
                newMoldSpec[category][info][index].fileId !== ""
            ) {
                tmp.push(newMoldSpec[category][info][index].fileId);
            }
            newMoldSpec.runnerSystem[index].gateSystemOthers.forEach((f) => {
                if (f.fileId !== undefined && f.fileId !== null && f.fileId !== "") {
                    tmp.push(f.fileId);
                }
            });
            setFilesToDelete(tmp);
            newMoldSpec[category][info].splice(index, 1);
            newMoldSpec.runnerSystem.splice(index, 1);
        }
        setMoldSpecification(newMoldSpec);
    };

    const submitData = async (data) => {
        const options = {
            url: "/business-logic/tools/" + props.toolId + "/specifications",
            method: "PUT",
            data: data,
            headers: {
                "X-Loading": false,
            },
        };
        Axios(options)
            .then((response) => {
                getMoldSpecifications();
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    const updatePayload = async (set) => {
        let newMoldSpec = { ...moldSpecification };
        let additionalFiles = [];
        let mainFiles = {};
        for (let i = 0; i < set.length; i++) {
            for (const [key, value] of Object.entries(set[i])) {
                if (value) {
                    switch (key) {
                        case "picture":
                            if (!value.id) {
                                updateNewFiles("projectData", "projectFiles", null, value.file, value.name, null, "picture", i, set[i]?.id);
                            } else {
                                let picture = {
                                    id: value.id,
                                    name: value.name,
                                };
                                newMoldSpec.projectData.projectFiles[i][key] = picture;
                                setFinalPictureFiles((current) => [...current, value.id]);
                            }
                            break;
                        case "dataFile3D":
                            if (!value.id) {
                                updateHistoryFiles("projectData", "projectFiles", value.file, value.file.name, "dataFile3D", i, set[i]?.id);
                            } else {
                                let dataFile3D = {
                                    id: value.id,
                                    name: value.name,
                                };
                                newMoldSpec.projectData.projectFiles[i][key] = dataFile3D;
                            }
                            break;
                        case "dataFile2D":
                            if (!value.id) {
                                updateHistoryFiles("projectData", "projectFiles", value.file, value.file.name, "dataFile2D", i, set[i]?.id);
                            } else {
                                let dataFile2D = {
                                    id: value.id,
                                    name: value.name,
                                };
                                newMoldSpec.projectData.projectFiles[i][key] = dataFile2D;
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
            if (i === 0) {
                mainFiles = { ...newMoldSpec.projectData.projectFiles[i] };
            } else {
                additionalFiles.push({ ...newMoldSpec.projectData.projectFiles[i] });
            }
        }
        newMoldSpec.projectData = { ...newMoldSpec.projectData, ...mainFiles };
        newMoldSpec.projectData.additionalFiles = additionalFiles;
        delete newMoldSpec.projectData.projectFiles;

        return newMoldSpec;
    };

    const handleSubmit = async () => {
        let moldSpecClone = await updatePayload(moldSpecification.projectData.projectFiles);

        let filesToBeDeleted = newFiles.filter((el) => el.idToBeDeleted).map((el) => el.idToBeDeleted);
        if (filesToDelete.length > 0 || filesToBeDeleted.length > 0) {
            const options = {
                url: "/file-operations/" + props.toolId + "-specification/files",
                method: "DELETE",
                headers: {
                    "X-Files": [...filesToDelete, ...filesToBeDeleted, ...initialPictureFiles.filter((id) => !finalPictureFiles.includes(id))],
                    "X-Loading": false,
                },
            };
            Axios(options).then((rsp) => {});
        }
        if (historyFiles.length > 0 || newFiles.length > 0) {
            if (historyFiles.length > 0) {
                for (const [historyIndex, historyFile] of historyFiles.entries()) {
                    let historyFileFormData = new FormData();
                    historyFileFormData.append("files", historyFile.newFile, historyFile.newFileName);
                    const optionsHistory = {
                        url:
                            "/file-operations/" +
                            props.toolId +
                            `${historyFile.projectId ? "-" + historyFile.projectId : ""}` +
                            `${historyFile.subInfo === "dataFile3D" ? "-specification-3d" : "-specification-2d"}` +
                            "/files",
                        method: "POST",
                        data: historyFileFormData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    let historyFileRes = await Axios(optionsHistory);

                    if (historyFile.projectIndex === 0) {
                        moldSpecClone[historyFile.category][historyFile.subInfo] = {
                            id: historyFileRes?.data?.data?.files[0].id,
                            name: historyFileRes?.data?.data?.files[0].name,
                        };
                    } else {
                        moldSpecClone[historyFile.category].additionalFiles[historyFile.projectIndex - 1][historyFile.subInfo] = {
                            id: historyFileRes?.data?.data?.files[0].id,
                            name: historyFileRes?.data?.data?.files[0].name,
                        };
                    }

                    if (historyIndex === historyFiles.length - 1) {
                        if (newFiles.length > 0) {
                            let newFilesFormData = new FormData();
                            const newFilesWithoutAdditionalPictures = newFiles.filter((file) => file.info !== "projectFiles" || file.projectIndex === 0);
                            const filesWithAdditionalPictures = newFiles.filter((file) => file.info === "projectFiles" && file.projectIndex !== 0);
                            if (newFilesWithoutAdditionalPictures.length > 0) {
                                newFilesWithoutAdditionalPictures.forEach((file) => {
                                    newFilesFormData.append("files", file.newFile, file.newFileName);
                                });
                                const options = {
                                    url: "/file-operations/" + props.toolId + "-specification/files",
                                    method: "POST",
                                    data: newFilesFormData,
                                    headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                                };
                                const response = await Axios(options);
                                response.data.data.files.forEach((el, i) => {
                                    if (newFilesWithoutAdditionalPictures[i].index !== undefined && newFilesWithoutAdditionalPictures[i].index !== null) {
                                        if (
                                            newFilesWithoutAdditionalPictures[i].sectionIndex !== undefined &&
                                            newFilesWithoutAdditionalPictures[i].sectionIndex !== null
                                        ) {
                                            moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].sectionIndex][
                                                newFilesWithoutAdditionalPictures[i].info
                                            ][newFilesWithoutAdditionalPictures[i].index].fileId = el.id;
                                            moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].sectionIndex][
                                                newFilesWithoutAdditionalPictures[i].info
                                            ][newFilesWithoutAdditionalPictures[i].index].filename = el.name;
                                        } else {
                                            moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info][
                                                newFilesWithoutAdditionalPictures[i].index
                                            ].fileId = el.id;
                                            moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info][
                                                newFilesWithoutAdditionalPictures[i].index
                                            ].filename = el.name;
                                        }
                                    } else {
                                        if (newFilesWithoutAdditionalPictures[i].info === "projectFiles") {
                                            if (newFilesWithoutAdditionalPictures[i].projectIndex === 0) {
                                                moldSpecClone[newFilesWithoutAdditionalPictures[i].category][
                                                    newFilesWithoutAdditionalPictures[i].sectionIndex
                                                ] = {
                                                    id: el.id,
                                                    name: el.name,
                                                };
                                            } else {
                                                moldSpecClone[newFilesWithoutAdditionalPictures[i].category].additionalFiles[
                                                    newFilesWithoutAdditionalPictures[i].projectIndex - 1
                                                ][newFilesWithoutAdditionalPictures[i].sectionIndex] = {
                                                    id: el.id,
                                                    name: el.name,
                                                };
                                            }
                                        } else {
                                            moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info] = {
                                                id: el.id,
                                                name: el.name,
                                            };
                                        }
                                    }
                                });
                            }
                            if (filesWithAdditionalPictures.length > 0) {
                                for (const [pictureIndex, pictureFile] of filesWithAdditionalPictures.entries()) {
                                    let newPictureFormData = new FormData();
                                    newPictureFormData.append("files", pictureFile.newFile, pictureFile.newFileName);
                                    const optionsPicture = {
                                        url:
                                            "/file-operations/" +
                                            props.toolId +
                                            `${pictureFile.projectId ? "-" + pictureFile.projectId : ""}` +
                                            "-specification/files",
                                        method: "POST",
                                        data: newPictureFormData,
                                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                                    };
                                    let pictureFileRes = await Axios(optionsPicture);
                                    moldSpecClone[pictureFile.category].additionalFiles[pictureFile.projectIndex - 1][pictureFile.sectionIndex] = {
                                        id: pictureFileRes?.data?.data?.files[0].id,
                                        name: pictureFileRes?.data?.data?.files[0].name,
                                    };
                                }
                            }
                            setHistoryFiles([]);
                            setNewFiles([]);
                            await submitData(moldSpecClone);
                        } else {
                            setHistoryFiles([]);
                            setNewFiles([]);
                            await submitData(moldSpecClone);
                        }
                    }
                }
            } else {
                let newFilesFormData = new FormData();
                const newFilesWithoutAdditionalPictures = newFiles.filter((file) => file.info !== "projectFiles" || file.projectIndex === 0);
                const filesWithAdditionalPictures = newFiles.filter((file) => file.info === "projectFiles" && file.projectIndex !== 0);
                if (newFilesWithoutAdditionalPictures.length > 0) {
                    newFilesWithoutAdditionalPictures.forEach((file) => {
                        newFilesFormData.append("files", file.newFile, file.newFileName);
                    });
                    const options = {
                        url: "/file-operations/" + props.toolId + "-specification/files",
                        method: "POST",
                        data: newFilesFormData,
                        headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                    };
                    let response = await Axios(options);
                    response.data.data.files.forEach((el, i) => {
                        if (newFilesWithoutAdditionalPictures[i].index !== undefined && newFilesWithoutAdditionalPictures[i].index !== null) {
                            if (newFilesWithoutAdditionalPictures[i].sectionIndex !== undefined && newFilesWithoutAdditionalPictures[i].sectionIndex !== null) {
                                moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].sectionIndex][
                                    newFilesWithoutAdditionalPictures[i].info
                                ][newFilesWithoutAdditionalPictures[i].index].fileId = el.id;
                                moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].sectionIndex][
                                    newFilesWithoutAdditionalPictures[i].info
                                ][newFilesWithoutAdditionalPictures[i].index].filename = el.name;
                            } else {
                                moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info][
                                    newFilesWithoutAdditionalPictures[i].index
                                ].fileId = el.id;
                                moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info][
                                    newFilesWithoutAdditionalPictures[i].index
                                ].filename = el.name;
                            }
                        } else {
                            if (newFilesWithoutAdditionalPictures[i].info === "projectFiles") {
                                if (newFilesWithoutAdditionalPictures[i].projectIndex === 0) {
                                    moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].sectionIndex] = {
                                        id: el.id,
                                        name: el.name,
                                    };
                                } else {
                                    moldSpecClone[newFilesWithoutAdditionalPictures[i].category].additionalFiles[
                                        newFilesWithoutAdditionalPictures[i].projectIndex - 1
                                    ][newFilesWithoutAdditionalPictures[i].sectionIndex] = {
                                        id: el.id,
                                        name: el.name,
                                    };
                                }
                            } else {
                                moldSpecClone[newFilesWithoutAdditionalPictures[i].category][newFilesWithoutAdditionalPictures[i].info] = {
                                    id: el.id,
                                    name: el.name,
                                };
                            }
                        }
                    });
                }
                if (filesWithAdditionalPictures.length > 0) {
                    for (const [pictureIndex, pictureFile] of filesWithAdditionalPictures.entries()) {
                        let newPictureFormData = new FormData();
                        newPictureFormData.append("files", pictureFile.newFile, pictureFile.newFileName);
                        const optionsPicture = {
                            url: "/file-operations/" + props.toolId + `${pictureFile.projectId ? "-" + pictureFile.projectId : ""}` + "-specification/files",
                            method: "POST",
                            data: newPictureFormData,
                            headers: { "Content-Type": "multipart/form-data", "X-Loading": false },
                        };
                        let pictureFileRes = await Axios(optionsPicture);
                        moldSpecClone[pictureFile.category].additionalFiles[pictureFile.projectIndex - 1][pictureFile.sectionIndex] = {
                            id: pictureFileRes?.data?.data?.files[0].id,
                            name: pictureFileRes?.data?.data?.files[0].name,
                        };
                    }
                }
                setHistoryFiles([]);
                setNewFiles([]);
                await submitData(moldSpecClone);
            }
        } else {
            await submitData(moldSpecClone);
        }
    };

    const getMoldSpecifications = () => {
        const options = {
            url: "/business-logic/tools/" + props.toolId + "/specifications",
            method: "GET",
        };
        Axios(options).then((response) => {
            setMoldSpecification({
                ...response?.data?.data,
                projectData: {
                    estimatedInjectionMA: response?.data?.data?.projectData?.estimatedInjectionMA,
                    projectFiles: [
                        {
                            name: response?.data?.data?.projectData?.name,
                            picture: response?.data?.data?.projectData.picture,
                            dataFile2D: response?.data?.data?.projectData.dataFile2D,
                            dataFile3D: response?.data?.data?.projectData.dataFile3D,
                        },
                        ...response?.data?.data?.projectData.additionalFiles,
                    ],
                },
            });
        });
    };

    const updateNewFiles = (category, info, id, newFile, newFileName, indexToInsert, sectionIndex, projectIndex, projectId) => {
        let newFilesClone = newFiles;

        if (indexToInsert === null) {
            if (info !== "projectFiles") {
                const index = newFilesClone.findIndex((el) => el.category === category && el.info === info);
                if (index > -1) {
                    newFilesClone.splice(index, 1);
                }
            }
        } else {
            if (sectionIndex && sectionIndex !== "" && sectionIndex !== null) {
                const index = newFilesClone.findIndex(
                    (el) => el.category === category && el.info === info && el.index === indexToInsert && el.sectionIndex === sectionIndex
                );
                if (index > -1) {
                    newFilesClone.splice(index, 1);
                }
            } else {
                const index = newFilesClone.findIndex((el) => el.category === category && el.info === info && el.index === indexToInsert);
                if (index > -1) {
                    newFilesClone.splice(index, 1);
                }
            }
        }

        newFilesClone.push({
            idToBeDeleted: id,
            category: category,
            info: info,
            newFile: newFile,
            newFileName: newFileName,
            index: indexToInsert,
            sectionIndex: sectionIndex,
            projectIndex: projectIndex,
            projectId: projectId,
        });

        setNewFiles(newFilesClone);
    };

    const updateHistoryFiles = (category, info, newFile, newFileName, subInfo, projectIndex, projectId) => {
        let historyFilesClone = historyFiles;

        let index = historyFilesClone.findIndex((el) => el.category === category && el.info === info);
        if (subInfo) {
            index = historyFilesClone.findIndex(
                (el) => el.category === category && el.info === info && el.subInfo == subInfo && el.projectIndex == projectIndex
            );
        }

        if (index > -1) {
            historyFilesClone.splice(index, 1);
        }

        historyFilesClone.push({
            category: category,
            info: info,
            newFile: newFile,
            newFileName: newFileName,
            subInfo: subInfo,
            projectIndex: projectIndex,
            projectId: projectId,
        });

        setHistoryFiles(historyFilesClone);
    };

    const deleteFromNewFiles = (category, info, index, sectionIndex) => {
        let newFilesClone = newFiles;

        let indexToDelete = -1;
        if (sectionIndex !== undefined && sectionIndex !== "" && sectionIndex !== null) {
            const tmp = [...filesToDelete];
            if (
                moldSpecification[category][sectionIndex][info][index].fileId !== undefined &&
                moldSpecification[category][sectionIndex][info][index].fileId !== null &&
                moldSpecification[category][sectionIndex][info][index].fileId !== ""
            ) {
                tmp.push(moldSpecification[category][sectionIndex][info][index].fileId);
            }
            setFilesToDelete(tmp);
            indexToDelete = newFilesClone.findIndex(
                (el) => el.category === category && el.info === info && el.index === index && el.sectionIndex === sectionIndex
            );
            if (indexToDelete > -1) {
                newFilesClone.splice(indexToDelete, 1);
            }
            newFilesClone.forEach((newFile) => {
                if (newFile.category === category && newFile.info === info && newFile.sectionIndex === sectionIndex) {
                    if (newFile.index !== null && newFile.index !== undefined && newFile.index > index) {
                        newFile.index--;
                    }
                }
            });
        } else {
            indexToDelete = newFilesClone.findIndex((el) => el.category === category && el.info === info && el.index === index);
            if (indexToDelete > -1) {
                newFilesClone.splice(indexToDelete, 1);
            }
            newFilesClone.forEach((newFile) => {
                if (newFile.category === category && newFile.info === info) {
                    if (newFile.index && newFile.index > index) {
                        newFile.index--;
                    }
                }
            });
            if (category === "material" && info === "names") {
                newFilesClone = newFilesClone.filter((f, idx) => {
                    return !(f.category === "runnerSystem" && f.info === "gateSystemOthers" && f.sectionIndex === index);
                });
                newFilesClone.forEach((f) => {
                    if (f.sectionIndex > index) {
                        f.sectionIndex -= 1;
                    }
                });
            }
        }
        setNewFiles(newFilesClone);
    };

    return (
        <div className={classes.wrapper}>
            <FormLayout
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit().then((r) => {});
                }}
                className={classes.layoutWrapper}
            >
                <div className={classes.rowWrapper}>
                    <div className={classes.buttonWrapper}>
                        <StyledButton className={classes.button} color="primary" variant="contained" type="submit">
                            {languageData?.Save.toUpperCase()}
                        </StyledButton>
                    </div>
                </div>
                <div className={classes.contentWrapper}>
                    <div className={classes.messageGrid}>
                        <Alert severity="warning">{languageData?.MoldSpecWarning}</Alert>
                    </div>

                    <div className={classes.messageGrid}>
                        <Alert severity="warning">{languageData?.MoldSpecRequiredFields}</Alert>
                    </div>
                    {role === 0 && (
                        <Contacts existingContacts={resources} updateContacts={updateMoldSpecification} selectedContacts={moldSpecification?.contacts} />
                    )}
                    {role === 0 && <Divider className={classes.divider} />}
                    <ProjectData
                        resources={resources}
                        updateProjectData={updateMoldSpecification}
                        updateProjectDataList={updateMoldSpecificationLists}
                        projectData={moldSpecification?.projectData}
                        updateNewFiles={updateNewFiles}
                        updateHistoryFiles={updateHistoryFiles}
                        customerId={props.customerId}
                        toolId={props.toolId}
                        addNewRow={addNewRow}
                        removeRow={removeRow}
                        moldSpecification={moldSpecification}
                    />
                    <Divider className={classes.divider} />
                    <MoldType
                        updateMoldType={updateMoldSpecification}
                        selectedMoldType={moldSpecification?.moldType}
                        moldStandardValues={resources?.moldStandard}
                    />
                    <Divider className={classes.divider} />
                    <Process processValues={moldSpecification?.process} updateProcessValue={updateMoldSpecification} />
                    <Divider className={classes.divider} />
                    <Material
                        resources={resources}
                        updateMaterialData={updateMoldSpecificationLists}
                        addNewRow={addNewRow}
                        removeRow={removeRow}
                        deleteFromNewFiles={deleteFromNewFiles}
                        materialData={moldSpecification?.material}
                        updateNewFiles={updateNewFiles}
                        toolId={props.toolId}
                    />
                    <Divider className={classes.divider} />
                    <MoldLayout updateMoldLayout={updateMoldSpecification} moldLayout={moldSpecification?.moldLayout} />
                    <Divider className={classes.divider} />
                    {moldSpecification?.runnerSystem.map((el, index) => (
                        <div key={index}>
                            <RunnerSystem
                                files={newFiles.filter((el) => el.category === "runnerSystem" && el.info === "gateSystemOthers" && el.sectionIndex === index)}
                                update={updateMoldSpecification}
                                data={el}
                                resources={resources}
                                updateNewFiles={updateNewFiles}
                                deleteFromNewFiles={deleteFromNewFiles}
                                customerId={props.customerId}
                                toolId={props.toolId}
                                index={index}
                            />
                        </div>
                    ))}
                    <Divider className={classes.divider} />
                    <Demolding updateDemoldingData={updateMoldSpecification} demoldingData={moldSpecification?.demolding} />
                    <Divider className={classes.divider} />
                    <Cooling updateCooling={updateMoldSpecification} toolId={props.toolId} data={moldSpecification?.cooling} customerId={props.customerId} />
                    <Divider className={classes.divider} />
                    <Surface
                        updateSurfaceData={updateMoldSpecification}
                        toolId={props.toolId}
                        surfaceData={moldSpecification?.surface}
                        customerId={props.customerId}
                    />
                    <Divider className={classes.divider} />
                    <MoldBase
                        moldbaseData={moldSpecification?.moldbase}
                        guidingSystem={resources?.moldStandard?.find((el) => el.id === moldSpecification?.moldType?.moldStandardId)?.text}
                        ejectorSystem={resources?.ejectorSystem}
                        interlockSystem={resources?.interlockSystem}
                        clampingSystem={resources?.clampingSystem}
                        updateMoldbaseData={updateMoldSpecification}
                    />
                    <Divider className={classes.divider} />
                    <Steel steelData={moldSpecification?.steel} toolId={props.toolId} updateSteelData={updateMoldSpecification} customerId={props.customerId} />
                    <Divider className={classes.divider} />
                    <Coating
                        coatingData={moldSpecification?.coating}
                        updateCoatingData={updateMoldSpecification}
                        customerId={props.customerId}
                        gms12={resources?.gms12}
                        toolId={props.toolId}
                    />
                    <Divider className={classes.divider} />
                    <Connector
                        connectorData={moldSpecification?.connector}
                        updateConnectorData={updateMoldSpecification}
                        toolId={props.toolId}
                        customerId={props.customerId}
                    />
                    <Divider className={classes.divider} />
                    <DocumentsForDelivery
                        updateDocumentsForDelivery={updateMoldSpecification}
                        data={moldSpecification?.documentsForDelivery}
                        values={resources.gms12}
                        customerId={props.customerId}
                        toolId={props.toolId}
                    />
                    <Sketch data={moldSpecification?.sketch} toolId={props.toolId} updateNewFiles={updateNewFiles} />
                </div>
            </FormLayout>
        </div>
    );
};

export default ToolSpecification;
