import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import WebsiteContext from "../../../../utils/Website";
import Axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import FormLayout from "../../../../containers/FormLayout/FormLayout";
import StyledInput from "../../../StyledInput/StyledInput";
import StyledButton from "../../../StyledButton/StyledButton";
import RoutingContext from "../../../../utils/RoutingContext";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        width: 375,
        height: "auto",
        borderRadius: "1.4px",
        padding: "0",
        [theme.breakpoints.down(450)]: {
            width: "80vw",
        },
    },
    dialogContentWrapper: {
        padding: 0,
    },
    icon: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    formWrapper: {
        padding: "10% 23%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: 32,
        },
    },
    addNew: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "12px",
        lineHeight: "15px",
        textAlign: "center",
        marginTop: "5%",
        color: "#1F1F1F",
    },
    input: {
        width: "100%",
        marginBottom: 10,
    },
    root: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
    },
    shrink: {
        transform: "translate(0, 1.5px) scale(1)",
        transformOrigin: "top left",
    },
    addRemoveButtons: {
        width: "100%",
    },
    saveButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    deleteButton: {
        marginTop: "20px",
        background: "#C00D0E",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "16px",
    },
    globalError: {
        color: "red",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    gridListWrapper: {
        height: "auto !important",
    },
    selectWrapper: {
        marginBottom: "10px",
        height: "33px",
        width: "100%",
    },
    textareaWrapper: {
        width: "89%",
        border: "0.6425px solid #CCCCCC",
        marginBottom: "5px",
        borderRadius: "2.57px",
        minHeight: "60px",
        height: "auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
        position: "unset",
        padding: "10px",
        resize: "none",
    },
    datePickerWrapper: {
        width: "89%",
        border: "0.6425px solid #CCCCCC",
        marginBottom: "5px",
        borderRadius: "2.57px",
        height: "auto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        color: theme.palette.gray.main,
        position: "unset",
        padding: "0 10px 0 10px",
        resize: "none",
    },
    checkboxWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "10px",
        marginBottom: "5px",
    },
    checkbox: {
        padding: 0,

        "&:disabled": {
            opacity: "50%",
        },
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
}));

const MoldMakerModal = ({ getData, moldName, moldDescription, onClose, toolMakers, isOpen, toolId }) => {
    const classes = useStyles(useStyles);
    const { setLoading, languageData } = useContext(WebsiteContext);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState("");
    const [toolMakerId, setToolMakerId] = useState("");
    const [globalError, setGlobalError] = useState("");

    useEffect(() => {
        if (isOpen) {
            setName(moldName);
            setDescription(moldDescription);
        }
    }, [isOpen]);

    const handleToolMakerChange = (event) => {
        setToolMakerId(event.target.value);
    };

    const handleCloseModal = () => {
        onClose();
    };

    const toolMakersEntities = toolMakers?.map((toolMaker) => (
        <MenuItem key={toolMaker.id} value={toolMaker.id}>
            {toolMaker.name}
        </MenuItem>
    ));

    let buttons = (
        <StyledButton variant="contained" type="submit" fullWidth className={classes.saveButton}>
            {languageData?.Save}
        </StyledButton>
    );

    const changeState = () => {
        const data = {
            description: description,
            estimatedStartDate: startDate,
            name: name,
            toolMakerId: toolMakerId,
        };
        const options = {
            url: `/tools/${toolId}/change-to-production`,
            method: "PUT",
            headers: {
                "X-Loading": false,
            },
            data: data,
        };
        Axios(options)
            .then((response) => {
                getData();
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    const body = (
        <div className={classes.modalWrapper}>
            <IconButton className={classes.icon} onClick={handleCloseModal}>
                <CloseIcon />
            </IconButton>
            <div className={classes.addNew}>{languageData?.ChooseMoldMaker}</div>
            <div className={classes.formWrapper}>
                <FormLayout
                    onSubmit={(e) => {
                        e.preventDefault();
                        changeState();
                    }}
                >
                    <StyledInput
                        showLabel
                        defaultValue={name}
                        label={languageData?.Name}
                        className={classes.input}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        required
                    />
                    <InputLabel
                        required
                        classes={{
                            root: classes.root,
                            shrink: classes.shrink,
                        }}
                    >
                        {languageData?.Description}
                    </InputLabel>
                    <textarea className={classes.textareaWrapper} value={description} onChange={(e) => setDescription(e.target.value)} />
                    <div className={classes.checkboxWrapper}>
                        <Checkbox disabled color="primary" className={classes.checkbox} />
                        <div className={classes.root}>Request for quotation</div>
                    </div>
                    <InputLabel
                        required
                        classes={{
                            root: classes.root,
                            shrink: classes.shrink,
                        }}
                    >
                        {languageData?.ToolMaker}
                    </InputLabel>
                    <Select
                        required
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                        variant={"outlined"}
                        className={classes.selectWrapper}
                        value={toolMakerId}
                        onChange={handleToolMakerChange}
                        displayEmpty={toolMakerId === ""}
                        renderValue={
                            toolMakerId === ""
                                ? () => {
                                      return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                  }
                                : null
                        }
                    >
                        {toolMakersEntities}
                    </Select>
                    <InputLabel
                        required
                        classes={{
                            root: classes.root,
                            shrink: classes.shrink,
                        }}
                    >
                        {languageData?.StartDate}
                    </InputLabel>
                    <TextField
                        className={classes.datePickerWrapper}
                        type="date"
                        defaultValue={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                        inputProps={{
                            min: new Date().toISOString().split("T")[0],
                        }}
                    />
                    <div className={classes.globalError}>{globalError}</div>
                    {buttons}
                </FormLayout>
            </div>
        </div>
    );

    return (
        <Dialog open={isOpen || false} onClose={onClose}>
            <DialogContent className={classes.dialogContentWrapper}>{body}</DialogContent>
        </Dialog>
    );
};

export default MoldMakerModal;
