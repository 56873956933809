import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

import { Card, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";

import StyledButton from "../../../../StyledButton/StyledButton";

import WebsiteContext from "../../../../../utils/Website";

const useStyles = makeStyles((theme) => ({
    wrapper: {},
    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    root: {
        width: 219,
        height: 147,
        display: "block",
        float: "left",
        cursor: "pointer",
        boxShadow: "0px 2.03926px 3.39877px rgba(0, 0, 0, 0.05), 0px 3.39877px 5.43804px rgba(0, 0, 0, 0.05)",
        borderRadius: "1.35951px",
        zIndex: 1,
    },
    name: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "15px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    details: {
        display: "flex",
        flexDirection: "column",
        padding: "0 16px 0 16px",
        alignItems: "flex-end",
    },
    nameWrapper: {
        padding: "10px 16px 10px 16px",
        height: "1em",
        overflow: "hidden",
    },
    divider: {
        width: "100%",
    },
    icons: {
        color: "#C00D0E",
        height: "13px",
        weight: "13px",
    },
    actionButton: {
        backgroundColor: theme.palette.common.white,
        border: "1.01724px solid #ECECEC",
        color: "#646363",
        height: 28,
        width: 120,
        padding: "0 10px",
        marginBottom: 10,
        zIndex: 10,
    },
}));

const InjectionReportCard = (props) => {
    const classes = useStyles(useStyles);
    const [id, setId] = useState("");
    const { role, languageData } = useContext(WebsiteContext);
    const history = useHistory();

    useEffect(() => {
        setId(props.id);
    }, [props.id, id]);

    const handleClickEvent = () => {
        history.push(
            role === 0
                ? "/customers/projects/tools/" + props.match.params.id + "/process/step/5/injection-report/" + id
                : role === 1
                ? "/project/tools/" + props.match.params.id + "/process/step/5/injection-report/" + id
                : "/tool/" + props.match.params.id + "/process/step/5/injection-report/" + id
        );
    };

    return (
        <Card classes={{ root: classes.root }} onClick={handleClickEvent}>
            <div className={classes.cardWrapper}>
                <div className={classes.nameWrapper}>
                    <div className={classes.name}>{props.name}</div>
                </div>
                {(role === 0 || role === 2) && (
                    <div className={classes.details}>
                        <StyledButton
                            onClick={(event) => {
                                event.stopPropagation();
                                props.duplicate(id);
                            }}
                            className={classes.actionButton}
                            variant="outlined"
                            startIcon={<FileCopyIcon color="primary" />}
                        >
                            {languageData?.Duplicate}
                        </StyledButton>
                        <StyledButton
                            onClick={(event) => {
                                event.stopPropagation();
                                props.handleEdit(id, props.name);
                            }}
                            className={classes.actionButton}
                            variant="outlined"
                            startIcon={<EditIcon color="primary" />}
                        >
                            {languageData?.Edit}
                        </StyledButton>
                        <StyledButton
                            onClick={(event) => {
                                event.stopPropagation();
                                props.handleDelete(id);
                            }}
                            className={classes.actionButton}
                            variant="outlined"
                            startIcon={<DeleteIcon color="primary" />}
                        >
                            {languageData?.Delete}
                        </StyledButton>
                    </div>
                )}
            </div>
        </Card>
    );
};

InjectionReportCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
};

export default InjectionReportCard;
