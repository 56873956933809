import React from "react";
import { Helmet } from "react-helmet";

import Clients from "../../components/Clients/Clients";

const ClientsView = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Clients</title>
            </Helmet>
            <Clients {...props} />
        </React.Fragment>
    );
};

export default ClientsView;
