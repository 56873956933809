import React from "react";
import { Helmet } from "react-helmet";
import ChangePassword from "../../components/ChangePassword/ChangePassword";

const ChangePasswordView = (props) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>HANNES | Change password</title>
            </Helmet>
            <ChangePassword {...props} />
        </React.Fragment>
    );
};

export default ChangePasswordView;
