import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import ProjectView from "../../views/Projects/Project";
import WebsiteContext from "../../utils/Website";

const Projects = (props) => {
    const { role } = useContext(WebsiteContext);

    return (
        <Switch>
            {(role === 0 || role === 1) && <Route path={`${props.match.url}/:id/members`} render={() => <ProjectView />} />}
            {(role === 0 || role === 1) && <Route path={`${props.match.url}/:id/schedule`} render={() => <ProjectView />} />}
            {(role === 0 || role === 1) && <Route path={`${props.match.url}/:id/tools`} render={() => <ProjectView />} />}
        </Switch>
    );
};

export default withWidth()(Projects);
