import { Button, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import WebsiteContext from "../../../../utils/Website";
import InputLabel from "@material-ui/core/InputLabel";
import CachedIcon from "@material-ui/icons/Cached";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        marginTop: 15,
    },
    inputWrapper: {
        width: 210,
        marginTop: 20,
    },
    uploadFileButton: {
        backgroundColor: "white",
        fontFamily: "Montserrat",
        fontWeight: "600",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#646363",
        marginRight: "15px",
        [theme.breakpoints.down(500)]: {
            width: "fit-content",
            margin: "10px 0",
        },
    },
    linkWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        maxWidth: "190px",
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "12px",
        color: "#646363",
        marginRight: 5,
    },
    accessibleLink: {
        textDecorationLine: "underline",
        cursor: "pointer",
        color: "#00A7D1",
    },
    selectWrapper: {
        width: "210px",
    },
    contentMargin: {
        marginTop: 20,
        [theme.breakpoints.down(500)]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    fileInputWrapper: { width: 1, height: 1, opacity: 1 },
}));

const Sketch = (props) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);
    const [file, setFile] = useState(null);

    const fileNameLength = 15;

    useEffect(() => {
        if (props.data) {
            if (props.data.file) {
                const sections = props.data.file.name.split(".");
                if (sections.length > 1) {
                    let firstPart = sections.slice(0, sections.length - 1).join(".");
                    if (firstPart.length > fileNameLength) {
                        firstPart = firstPart.substr(0, fileNameLength) + "...";
                    }
                    const lastPart = "." + sections[sections.length - 1];
                    setFile({
                        id: props.data.file.id,
                        name: firstPart + lastPart,
                        accessible: true,
                    });
                } else {
                    setFile({
                        id: props.data.file.id,
                        name: sections[0].length > fileNameLength ? sections[0].substr(0, fileNameLength) + "..." : sections[0],
                        accessible: true,
                    });
                }
            }
        }
    }, [props.data]);

    const uploadFile = (event) => {
        let file = event.target.files[0];

        if (file) {
            let filename;
            const sections = file.name.split(".");
            if (sections.length > 1) {
                let firstPart = sections.slice(0, sections.length - 1).join(".");
                if (firstPart.length > fileNameLength) {
                    firstPart = firstPart.substr(0, fileNameLength) + "...";
                }
                const lastPart = "." + sections[sections.length - 1];
                filename = firstPart + lastPart;
            } else {
                filename = sections[0].length > fileNameLength ? sections[0].substr(0, fileNameLength) : sections[0];
            }
            setFile({
                id: null,
                name: filename,
                accessible: false,
            });
            props.updateNewFiles("sketch", "file", props.data?.file ? props.data?.file.id : null, file, file.name, null);
        }
    };

    const handleOpenFile = (id, accessible) => {
        if (accessible) {
            window.open(`${process.env.REACT_APP_URL}file-operations/${props.toolId}-specification/files/${id}`);
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.Sketch.toUpperCase()}</div>
            <div>
                <div className={classes.contentMargin}>
                    {file && (
                        <span
                            onClick={() => handleOpenFile(file.id, file.accessible)}
                            className={classes.linkWrapper + " " + (file.accessible && classes.accessibleLink)}
                        >
                            {file.name}
                        </span>
                    )}{" "}
                    <Button
                        variant="contained"
                        component="label"
                        className={classes.uploadFileButton}
                        startIcon={file ? <CachedIcon color="primary" /> : <CloudUploadIcon color="primary" />}
                        disabled={props.disabled}
                    >
                        {file ? languageData?.ChangeFile : languageData?.UploadFile}
                        <input type="file" defaultValue={file ? file.name : ""} onChange={(e) => uploadFile(e)} className={classes.fileInputWrapper} />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Sketch;
