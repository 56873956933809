import React, { useContext } from "react";
import WebsiteContext from "../../../../utils/Website";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    titleWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#A5A5A5",
    },
    labelWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "15px",
        color: "#777777",
        marginTop: 20,
        marginBottom: 4,
    },
    textWrapper: {
        fontFamily: "Montserrat",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#191919",
        marginTop: 15,
        textAlign: "center",
    },
    rowContentWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.down(500)]: {
            flexDirection: "column",
        },
    },
    columnWrapper: {
        marginRight: 40,
    },
    selectWrapper: {
        width: "210px",
        height: "33px",
    },
    selectTextWrapper: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "150%",
        color: "#191919",
    },
    selectMenuWrapper: {
        whiteSpace: "normal",
    },
    inputWrapper: {
        padding: "10px 14px",
    },
    rootWrapper: {
        "&:focus": {
            backgroundColor: "white",
        },
    },
}));

const MoldBase = (props) => {
    const { languageData } = useContext(WebsiteContext);
    const classes = useStyles(useStyles);

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>{languageData?.MoldBase.toUpperCase()}</div>
            <div className={classes.rowContentWrapper}>
                <div className={classes.columnWrapper}>
                    <div className={classes.labelWrapper}>{languageData?.guidingSystem}</div>
                    <div className={classes.textWrapper}>{props.guidingSystem && props.guidingSystem !== "" ? props.guidingSystem : "-"}</div>
                </div>
                <div className={classes.columnWrapper}>
                    <InputLabel className={classes.labelWrapper}>{languageData?.ejectorSystem}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={
                                props.moldbaseData?.ejectorSystemId && props.moldbaseData?.ejectorSystemId !== null ? props.moldbaseData?.ejectorSystemId : ""
                            }
                            displayEmpty={props.moldbaseData?.ejectorSystemId === null}
                            renderValue={
                                props.moldbaseData?.ejectorSystemId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateMoldbaseData("moldbase", "ejectorSystemId", e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props.ejectorSystem?.map((shot) => {
                                return (
                                    <MenuItem value={shot.id} key={shot.id}>
                                        <div className={classes.selectTextWrapper}>{shot.text}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.columnWrapper}>
                    <InputLabel className={classes.labelWrapper}>{languageData?.interlockSystem}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={
                                props.moldbaseData?.interlockSystemId && props.moldbaseData?.interlockSystemId !== null
                                    ? props.moldbaseData?.interlockSystemId
                                    : ""
                            }
                            displayEmpty={props.moldbaseData?.interlockSystemId === null}
                            renderValue={
                                props.moldbaseData?.interlockSystemId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateMoldbaseData("moldbase", "interlockSystemId", e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props.interlockSystem?.map((shot) => {
                                return (
                                    <MenuItem value={shot.id} key={shot.id}>
                                        <div className={classes.selectTextWrapper}>{shot.text}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.columnWrapper}>
                    <InputLabel className={classes.labelWrapper}>{languageData?.clampingSystem}</InputLabel>
                    <FormControl>
                        <Select
                            disabled={props.disabled}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            classes={{
                                root: classes.rootWrapper,
                                selectMenu: classes.selectMenuWrapper,
                                outlined: classes.inputWrapper,
                            }}
                            className={classes.selectWrapper}
                            variant={"outlined"}
                            value={
                                props.moldbaseData?.clampingSystemId && props.moldbaseData?.clampingSystemId !== null
                                    ? props.moldbaseData?.clampingSystemId
                                    : ""
                            }
                            displayEmpty={props.moldbaseData?.clampingSystemId === null}
                            renderValue={
                                props.moldbaseData?.clampingSystemId === null
                                    ? () => {
                                          return <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>;
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                props.updateMoldbaseData("moldbase", "clampingSystemId", e.target.value);
                            }}
                        >
                            <MenuItem value="" key={-1} disabled>
                                <div className={classes.selectTextWrapper}>{languageData?.SelectPlaceholder}</div>
                            </MenuItem>
                            {props.clampingSystem?.map((shot) => {
                                return (
                                    <MenuItem value={shot.id} key={shot.id}>
                                        <div className={classes.selectTextWrapper}>{shot.text}</div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

export default MoldBase;
